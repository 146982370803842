import * as React from 'react';

import { USER_LIST_QUERY } from './../../../api/GraphQLQueries/User';
import ListQueryContainer, {
    IGraphQLListProps,
} from './../../../containers/common/ListQueryContainer';
import IUserModel from './../../../models/IUserModel';

interface IProps extends IGraphQLListProps {
    onResult?: (totalCount: number) => void;
    renderFetchedUserList: (
        users: IUserModel[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => JSX.Element;
}

export default class UserListQueryContainer extends React.Component<IProps, any> {
    public render() {
        const { renderFetchedUserList, query } = this.props;

        return (
            <ListQueryContainer
                {...this.props}
                query={query || USER_LIST_QUERY}
                dataProp="users"
                sortBy={{ field: 'name', direction: 'asc' }}
                renderFetchedList={renderFetchedUserList}
            />
        );
    }
}
