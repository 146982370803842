import * as React from 'react';
import { Query } from 'react-apollo';

import Error from './../../components/layout/Error';
import Loading from './../../components/layout/Loading';

export interface IGraphQLQueryProps {
    onResult?: (data?: any) => void;
    onResultReturnData?: boolean;
    fetchPolicy?: any;
}

interface IProps extends IGraphQLQueryProps {
    hideLoadingIndicator?: boolean;
    variables: any;
    query: any;
    dataProp: string;
    renderFetchedData: (data: any, refetch?: () => void) => any;
}

export default class DetailQueryContainer extends React.Component<IProps, any> {
    private onCompleted = (data: any) => {
        const { onResult, onResultReturnData, dataProp } = this.props;

        if (onResult) {
            onResultReturnData ? onResult(data[dataProp]) : onResult();
        }
    };

    public render() {
        const {
            variables,
            renderFetchedData,
            query,
            dataProp,
            fetchPolicy,
            hideLoadingIndicator,
        } = this.props;

        return (
            <Query
                query={query}
                variables={variables}
                fetchPolicy={fetchPolicy}
                onCompleted={this.onCompleted}
            >
                {({ data, loading, error, refetch }) => {
                    if (loading) {
                        return !hideLoadingIndicator && <Loading />;
                    }
                    if (error) {
                        return <Error>Fetch data error</Error>;
                    }

                    return renderFetchedData(dataProp ? data[dataProp] : data, refetch);
                }}
            </Query>
        );
    }
}
