import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { plantS3Folder } from '../../models/IPlantModel';
import { PLANT_SAVE_MUTATION } from './../../api/GraphQLQueries/Plant';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import Pane from './../../components/ui/Pane';
import IPlantModel, { IPlantCreateModel } from './../../models/IPlantModel';
import { todo } from './../../utils/translate';
import PlantCreateForm from './forms/PlantCreateForm';
import FolderDetailQuery from './queries/FolderDetailQuery';

interface IProps {
    onCancel: () => void;
    onSuccess: (id: string) => void;
}

interface IState {
    plant: IPlantCreateModel;
    error: boolean;
    success: boolean;
    s3Folder: boolean;
}

const plantTemplate: IPlantCreateModel = {
    name: '',
    id: '',
    customerDisplayName: '',
    deliveryAddress: { country: '', responsible: '', street: '', city: '', zip: '' },
    invoiceAddress: { country: '', responsible: '', street: '', city: '', zip: '' },
};

class PlantCreateContainer extends React.Component<IProps, any> {
    public state: IState = {
        s3Folder: false,
        error: false,
        success: false,
        plant: { ...plantTemplate },
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = ({ savePlant }: { savePlant: IPlantModel }): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));

        this.props.onSuccess(savePlant.id);
    };

    private updateValues = (plant: IPlantCreateModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            plant,
            error: false,
            success: false,
        }));
    };

    private formSubmit = (doPlantCreateMutation: MutationFn) => (): void => {
        const { plant } = this.state;

        doPlantCreateMutation({ variables: { plant: _.omit(plant, '__typename') } });
    };

    private isPlantInValid = () => {
        const { plant } = this.state;

        return (
            !plant ||
            plant.id!.length < 3 ||
            plant.name!.length < 3 ||
            _.some(
                [
                    ..._.values(_.omit(plant.invoiceAddress, ['latitude', 'longitude'])),
                    ..._.values(_.omit(plant.deliveryAddress, ['latitude', 'longitude'])),
                ],
                _.isEmpty,
            )
        );
    };

    private renderSaveButton = (doPlantCreate: MutationFn) => {
        return (folderExists: boolean) => {
            const disabled = !folderExists || this.isPlantInValid();
            return (
                <Button onClick={this.formSubmit(doPlantCreate)} disabled={disabled}>
                    Create plant
                </Button>
            );
        };
    };

    private onS3Completed = (s3Folder: boolean): void => {
        if (this.state.s3Folder !== s3Folder) {
            this.setState((prevState: IState) => ({
                ...prevState,
                s3Folder,
            }));
        }
    };

    private renderPlantCreateForm = (doPlantCreate: MutationFn) => {
        const { onCancel } = this.props;
        const { plant } = this.state;

        const s3Folder = plantS3Folder(plant.id, plant.name);

        const createPlant = (
            <FolderDetailQuery
                onResult={this.onS3Completed}
                plantS3Folder={s3Folder}
                renderFetchedFolderData={this.renderSaveButton(doPlantCreate)}
            />
        );
        return (
            <Pane
                onClose={onCancel}
                actionButtons={createPlant}
                title={todo('Add plant')}
                sidepane={true}
            >
                <PlantCreateForm
                    plant={this.state.plant}
                    onUpdate={this.updateValues}
                    s3FolderExists={this.state.s3Folder}
                />
            </Pane>
        );
    };

    public render = () => {
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>Plant create error</Error>}
                {success && <Message type="success">Successfully created plant</Message>}
                <Mutation
                    mutation={PLANT_SAVE_MUTATION}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                >
                    {doPlantCreate => this.renderPlantCreateForm(doPlantCreate)}
                </Mutation>
            </>
        );
    };
}

export default PlantCreateContainer;
