import { Title } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { doUserLogin } from '../../api/authorization';
import AuthLayout from '../AuthLayout';
import { LOGIN_MUTATION } from './../../api/GraphQLQueries/Auth';
import AuthForm from './../../components/auth/AuthForm';
import IAccessTokenModel from './../../models/AccessTokenModel';
import { t } from './../../utils/translate';

export default class LoginContainer extends React.Component<any, any> {
    private formSubmit = (doLoginMutation: any) => (email: string, password: string): void => {
        doLoginMutation({ variables: { email, password }, fetchPolicy: 'no-cache' });
    };

    private onCompleted = async ({ login }: { login: IAccessTokenModel }) => {
        await doUserLogin(login);
        this.props.history.push('/');
    };

    private onError = () => null;

    public render() {
        return (
            <AuthLayout>
                <Title size="4">{t().login}</Title>
                <Mutation
                    mutation={LOGIN_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {doLogin => (
                        <AuthForm
                            buttonText={t().login}
                            isLoginForm={true}
                            onSubmit={this.formSubmit(doLogin)}
                        />
                    )}
                </Mutation>
            </AuthLayout>
        );
    }
}
