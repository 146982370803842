import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import IGroupModel from '../../models/IGroupModel';
import { GROUP_CREATE_MUTATION } from './../../api/GraphQLQueries/Group';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import Pane from './../../components/ui/Pane';
import { IGroupCreateModel } from './../../models/IGroupModel';
import { todo } from './../../utils/translate';
import GroupCreateForm from './forms/GroupCreateForm';

interface IProps {
    onCancel: () => void;
    onSuccess: (id: string) => void;
}

interface IState {
    group: IGroupCreateModel;
    error: boolean;
    success: boolean;
}

const groupTemplate: IGroupCreateModel = {
    name: '',
    description: '',
};

export default class GroupCreateContainer extends React.Component<IProps, any> {
    public state: IState = {
        error: false,
        success: false,
        group: { ...groupTemplate },
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = ({ createGroup }: { createGroup: IGroupModel }): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));

        this.props.onSuccess(`${createGroup.id}`);
    };

    private updateValues = (group: IGroupCreateModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            group,
            error: false,
            success: false,
        }));
    };

    private formSubmit = (doGroupCreateMutation: MutationFn) => (): void => {
        const { group } = this.state;

        doGroupCreateMutation({ variables: { group: _.omit(group, '__typename') } });
    };

    private renderGroupCreateForm = (doGroupCreate: MutationFn) => {
        const { onCancel } = this.props;
        const disabled = !this.state.group || !this.state.group.name;

        const createGroup = (
            <Button onClick={this.formSubmit(doGroupCreate)} disabled={disabled}>
                Create group
            </Button>
        );

        return (
            <Pane
                onClose={onCancel}
                actionButtons={createGroup}
                title={todo('Add group')}
                sidepane={true}
            >
                <GroupCreateForm group={this.state.group} onUpdate={this.updateValues} />
            </Pane>
        );
    };

    public render() {
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>Group create error</Error>}
                {success && <Message type="success">Successfully created group</Message>}
                <Mutation
                    mutation={GROUP_CREATE_MUTATION}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                >
                    {doGroupCreate => this.renderGroupCreateForm(doGroupCreate)}
                </Mutation>
            </>
        );
    }
}
