import { Content, Navbar } from 'rbx';
import * as React from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import { QUERY_MAINTENANCE_MODE } from '../../api/GraphQLQueries/Ops';
import IUserModel from '../../models/IUserModel';
import { storage } from '../../utils/storage';
import IsAdminQuery from '../auth/IsAdminQuery';
import FeedBackModal from '../feedback/FeedBackModal';
import SearchInputField from '../search/SearchInputField';
import Icon from '../ui/Icon';
import Popover from '../ui/Popover';
import LogoutContainer from './../../containers/auth/LogoutContainer';
import Routing from './../../Routing';

interface IProps {
    user: IUserModel;
}

interface IState {
    showFeedBackModal: boolean;
    showMaintenanceBanner: boolean;
    showSearchInfo: boolean;
}

export default class TopMenu extends React.Component<IProps, IState> {
    public state: IState = {
        showFeedBackModal: false,
        showMaintenanceBanner: false,
        showSearchInfo: false,
    };

    private renderAdminLinks = () => {
        return (
            <>
                <Navbar.Item as="span">
                    <Link to={Routing.GROUPS.route}>Groups</Link>
                </Navbar.Item>

                <Navbar.Item as="span">
                    <Link to={Routing.USER_LIST.route}>Users</Link>
                </Navbar.Item>
            </>
        );
    };

    private renderFeedBackLink = () => {
        return (
            <>
                <Navbar.Item as="a" onClick={this.toogleFeedBackButton}>
                    Contact Us <i className="fa fa-envelope" />
                </Navbar.Item>
            </>
        );
    };

    private renderUserIcon = () => {
        return <i className="fa fa-user" />;
    };

    private renderAdminIcon = () => {
        return <i className="fa fa-user-crown" />;
    };

    private toogleFeedBackButton = () => {
        this.setState({
            showFeedBackModal: !this.state.showFeedBackModal,
        });
    };

    private renderSpacyLink = () => {
        return (
            <Navbar.Item as="span">
                <Link to={Routing.SPACY_CONVERTER.route}>Spacy converter</Link>
            </Navbar.Item>
        );
    };

    private renderMaintenanceBanner = () => (
        <Query query={QUERY_MAINTENANCE_MODE} fetchPolicy="network-only">
            {({ data, loading, error }) => {
                if (loading) {
                    return null;
                }
                if (error) {
                    return null;
                }

                const isMaintenanceMode = !!(data && data.maintenanceMode === true);
                storage.set('maintenenceMode', `${isMaintenanceMode}`);

                if (isMaintenanceMode) {
                    return (
                        <strong className="flex-justify-center bg-yellow py-1">
                            Application is currently in maintenance (read-only) mode.
                        </strong>
                    );
                }
                return null;
            }}
        </Query>
    );

    private renderBanner = () => {
        if (process.env.REACT_APP_TARGET_ENV !== 'production') {
            const env = process.env.REACT_APP_TARGET_ENV || process.env.REACT_APP_ENV;

            const colours = {
                integration: 'yellow',
                devtest: 'yellow',
                devtest2: 'yellow',
                staging: 'cyan',
            };
            const colourClasses = [
                'px-1',
                'flex-justify-center',
                `bg-${colours[env] || 'green'}`,
            ].join(' ');

            return <small className={colourClasses}>{env}</small>;
        }
    };

    public toggleSearchInfo = () => {
        this.setState({
            showSearchInfo: !this.state.showSearchInfo,
        });
    };

    private searchInfoButton = React.createRef<HTMLDivElement>();

    public renderSearchInfo = () => {
        const { showSearchInfo } = this.state;
        const refElement = this.searchInfoButton.current;

        return (
            <>
                <div className="search-info-popup" ref={this.searchInfoButton}>
                    <button className="button is-transparent" onClick={this.toggleSearchInfo}>
                        <i className="fa fa-info-circle" />
                    </button>
                </div>

                {showSearchInfo && this.searchInfoButton && (
                    <Popover
                        title="Search Tips"
                        className="search-tips"
                        popoverEventTarget={refElement}
                        onClose={this.toggleSearchInfo}
                    >
                        <Content className="mb-2" style={{ textAlign: 'left' }}>
                            <ul>
                                <li>
                                    When inputting a search string, the asterisk (*) character will
                                    match any words or letters (eg. <code>butter* valve</code>).
                                </li>
                                <li>
                                    To find only exact matches, surround text with quotations (")
                                    (eg. <code>"butterfly valve wafer"</code> or{' '}
                                    <code>"valve" "47343.2"</code>).
                                </li>
                                <IsAdminQuery renderFor="admin">
                                    <li>
                                        Clicking the dropdown (<Icon icon="fa-caret-down" />) will
                                        show other fields which can be searched:
                                        <br />
                                        <strong>Document Title / Filename: </strong>Document title
                                        in documentum or customized title or filename in S3.
                                        <br />
                                    </li>
                                </IsAdminQuery>
                            </ul>
                        </Content>
                    </Popover>
                )}
            </>
        );
    };

    public render() {
        const showFeedBackModal = this.state.showFeedBackModal;

        return (
            <>
                {this.renderMaintenanceBanner()}
                <Navbar>
                    <Navbar.Brand>
                        <Navbar.Item as="div" className="logo-link">
                            <Link to={Routing.INDEX.route}>
                                <img src="/linde-logo.jpg" alt="Linde PLANTSERV®" />
                            </Link>
                        </Navbar.Item>
                        <Navbar.Burger />
                    </Navbar.Brand>

                    <Navbar.Menu>
                        <Navbar.Segment align="start" className="navbar-search">
                            <SearchInputField />
                            {this.renderSearchInfo()}
                        </Navbar.Segment>
                        <Navbar.Segment align="start">
                            <Navbar.Item as="span">{this.renderBanner()}</Navbar.Item>
                        </Navbar.Segment>
                        <Navbar.Segment align="end">
                            <Navbar.Item as="span">
                                <Link to={Routing.PLANTS.route} data-cy="navbar-plants">
                                    Plants
                                </Link>
                            </Navbar.Item>

                            <Navbar.Item as="span">
                                <Link to={Routing.BASKETS.route} data-cy="topMenu-navbar-baskets">
                                    Baskets
                                </Link>
                            </Navbar.Item>

                            <IsAdminQuery
                                renderUser={this.renderFeedBackLink}
                                renderAdmin={this.renderAdminLinks}
                            />

                            {/* <BasketTopMenuList /> */}

                            <Navbar.Item dropdown={true}>
                                <Navbar.Link arrowless={true} data-cy="topMenu-navbar-profile">
                                    <div className="user-profile-icon">
                                        <IsAdminQuery
                                            renderUser={this.renderUserIcon}
                                            renderAdmin={this.renderAdminIcon}
                                        />
                                    </div>
                                </Navbar.Link>
                                <Navbar.Dropdown align="right" boxed={true}>
                                    <IsAdminQuery renderAdmin={this.renderSpacyLink} />
                                    <Navbar.Item as="div">
                                        <Link to={Routing.PROFILE.route}>Profile</Link>
                                    </Navbar.Item>
                                    <Navbar.Item as="div">
                                        <LogoutContainer />
                                    </Navbar.Item>
                                </Navbar.Dropdown>
                            </Navbar.Item>
                        </Navbar.Segment>
                    </Navbar.Menu>
                </Navbar>
                {showFeedBackModal && <FeedBackModal onClose={this.toogleFeedBackButton} />}
            </>
        );
    }
}
