import { Button, Control, Delete, Field, Icon, Input, Label, Modal, Table, Title } from 'rbx';
import * as React from 'react';

import { IGrapQLDataResult } from '../../../api/GraphQLQueries/GraphQLTypes.d';
import IMembershipModel, { friendlyRole } from '../../../models/IMembershipModel';
import Routing from '../../../Routing';
import { isRequired } from '../../../utils/helpers';
import MembershipDeleteMutation from '../../membership/queries/MembershipDeleteMutation';
import NavButton from '../../nav/NavButton';
import PlantListSelectMenu from '../../plant/PlantListSelectMenu';
import ModalContainer from '../../ui/ModalContainer';
import IGroupModel, { IGroupUpdateModel } from './../../../models/IGroupModel';
import IPlantModel from './../../../models/IPlantModel';
import GroupListSelectMenu from './../../group/GroupListSelectMenu';

interface IProps {
    onUpdate: (group: IGroupUpdateModel) => void;
    group: IGroupUpdateModel;
}

interface IState {
    showModal: boolean;
    canSaveModal: boolean;
}

export default class GroupUpdateForm extends React.Component<IProps, IState> {
    public state: IState = {
        showModal: false,
        canSaveModal: false,
    };

    private allPlants: IPlantModel[] = [];
    private allGroups: IGroupModel[] = [];

    private setGroups = (res: IGrapQLDataResult<IGroupModel[]>) => {
        this.allGroups = res.data;
    };

    private setPlants = (res: IGrapQLDataResult<IPlantModel[]>) => {
        this.allPlants = res.data;
    };

    private nameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.group,
            name: context.currentTarget.value,
        });
    };

    private descriptonChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.group,
            description: context.currentTarget.value,
        });
    };

    private parentIdChange = (parentIds: number[]): void => {
        const parentId = parentIds.length ? parentIds[0] : null;
        const parent = parentId ? this.allGroups.find(group => group.id === parentId) : null;

        this.props.onUpdate({
            ...this.props.group,
            parentId: parentId as any,
            parent: parent as any,
        });
    };

    private plantsChange = (plantIds: string[]): void => {
        const plants = this.allPlants.filter(plant => plantIds.includes(plant.id));

        this.props.onUpdate({
            ...this.props.group,
            plantIds,
            plants,
        });

        return this.closeModal();
    };

    private groupsChange = (groupIds: number[]): void => {
        const groups = this.allGroups.filter(group => groupIds.includes(group.id));

        this.props.onUpdate({
            ...this.props.group,
            groupIds,
            groups,
        });

        return this.closeModal();
    };

    private renderGroup = (group: IGroupModel): JSX.Element => {
        return (
            <Table.Row key={group.id}>
                <Table.Cell>{group.name}</Table.Cell>
                <Table.Cell>
                    <NavButton to={Routing.GROUP_DETAILS.getPath(`${group.id}`)} size="small">
                        View
                    </NavButton>
                </Table.Cell>
            </Table.Row>
        );
    };

    private renderGroups = (): JSX.Element => {
        const { groups } = this.props.group;

        if (!groups || !groups.length) {
            return <p>No groups found</p>;
        }

        return (
            <Table fullwidth={true}>
                <Table.Head>
                    <Table.Row>
                        <Table.Heading>Name</Table.Heading>
                        <Table.Heading />
                    </Table.Row>
                </Table.Head>
                <Table.Body>{groups.map(this.renderGroup)}</Table.Body>
            </Table>
        );
    };

    private renderPlant = (plant: IPlantModel): JSX.Element => {
        return (
            <Table.Row key={plant.id}>
                <Table.Cell>{plant.name}</Table.Cell>
                <Table.Cell>
                    <NavButton to={Routing.PLANT_DETAILS.getPath(plant.id)} size="small">
                        View
                    </NavButton>
                </Table.Cell>
            </Table.Row>
        );
    };

    private renderPlants = (): JSX.Element => {
        const { plants } = this.props.group;

        if (!plants || !plants.length) {
            return <p>No plants found</p>;
        }

        return (
            <Table fullwidth={true}>
                <Table.Head>
                    <Table.Row>
                        <Table.Heading>Name</Table.Heading>
                        <Table.Heading />
                    </Table.Row>
                </Table.Head>
                <Table.Body>{plants.map(this.renderPlant)}</Table.Body>
            </Table>
        );
    };

    private renderUser = (membership: IMembershipModel): JSX.Element => {
        const user = membership.user!;

        const confirmRemove = () => {
            const modalBody = () => (
                <>
                    <p>Are you sure you want to remove this membership?</p>
                    <p>
                        {user.name} ({user.email})
                    </p>
                </>
            );

            const removeMembership = () => {
                let { memberships } = this.props.group;
                memberships = memberships!.filter(({ id }) => id !== membership.id);

                this.props.onUpdate({
                    ...this.props.group,
                    memberships,
                });
                this.closeModal();
            };

            const actions = (
                <Button.Group pull="right">
                    <Button onClick={this.closeModal}>Cancel</Button>
                    <MembershipDeleteMutation
                        id={membership.id}
                        onSuccess={removeMembership}
                        buttonText="Yes, Delete This Membership"
                    />
                </Button.Group>
            );

            this.renderModal = () =>
                this.modalTemplate({ title: 'Delete user?' }, () => null, modalBody, actions);

            this.setState({ showModal: true, canSaveModal: true });
        };

        return (
            user && (
                <Table.Row key={membership.id}>
                    <Table.Cell>{user.name}</Table.Cell>
                    <Table.Cell className="ellipsis" style={{ maxWidth: '15vw' }}>
                        {user.email}
                    </Table.Cell>
                    <Table.Cell>{friendlyRole(membership.role)}</Table.Cell>
                    <Table.Cell>
                        <Button.Group pull="right">
                            <NavButton to={Routing.USER_DETAILS.getPath(`${user.id}`)} size="small">
                                View
                            </NavButton>
                            <Button
                                color="danger"
                                size="small"
                                outlined={true}
                                onClick={confirmRemove}
                            >
                                Remove
                            </Button>
                        </Button.Group>
                    </Table.Cell>
                </Table.Row>
            )
        );
    };

    private renderUsers = (): JSX.Element => {
        const { memberships } = this.props.group;

        if (!memberships || !memberships.length) {
            return <p>No users found</p>;
        }

        return (
            <Table fullwidth={true}>
                <Table.Head>
                    <Table.Row>
                        <Table.Heading>Name</Table.Heading>
                        <Table.Heading>Email</Table.Heading>
                        <Table.Heading>Role</Table.Heading>
                        <Table.Heading />
                    </Table.Row>
                </Table.Head>
                <Table.Body>{memberships.map(this.renderUser)}</Table.Body>
            </Table>
        );
    };

    private closeModal = () => {
        this.setState({ showModal: false, canSaveModal: false });
    };

    private renderModal: () => JSX.Element;

    private modalTemplate = (
        modalOpts: { title: string },
        onSave: () => void,
        renderModalBody: () => JSX.Element,
        actions?: JSX.Element,
    ) => {
        return (
            <ModalContainer isOpen={this.state.showModal} onRequestClose={this.closeModal}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>{modalOpts.title}</Modal.Card.Title>
                        <Delete onClick={this.closeModal} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>{renderModalBody()}</Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        {actions || (
                            <Button.Group pull="right">
                                <Button onClick={this.closeModal}>Cancel</Button>
                                <Button
                                    color="primary"
                                    onClick={onSave}
                                    disabled={!this.state.canSaveModal}
                                >
                                    Select
                                </Button>
                            </Button.Group>
                        )}
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };

    private groupModal = () => {
        const { groups } = this.props.group;
        const selected = groups && groups.length ? groups.map(({ id }) => id) : [];

        let groupIds: number[] = [];

        const onUpdate = (ids: number[]) => {
            groupIds = ids;
            const canSaveModal =
                groupIds.sort().join('') !==
                groups!
                    .map(group => group.id)
                    .sort()
                    .join('');
            this.setState({ canSaveModal });
        };

        const onSave = () => {
            this.groupsChange(groupIds);
        };

        const invalidGroups = [
            this.props.group.id,
            this.props.group.parentId,
            ...(this.props.group.groups || []).map(({ id }) => id),
        ].filter(g => g) as number[];

        const modalBody = () => (
            <Field horizontal={true}>
                <Field.Label size="normal">
                    <Label>Group</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control expanded={true}>
                            <GroupListSelectMenu
                                multiple={true}
                                onUpdate={onUpdate}
                                defaultSelected={selected}
                                disabled={invalidGroups}
                                onResult={this.setGroups}
                            />
                        </Control>
                    </Field>
                </Field.Body>
            </Field>
        );

        this.renderModal = () => this.modalTemplate({ title: 'Edit Groups' }, onSave, modalBody);

        this.setState({ showModal: true });
    };

    private plantModal = () => {
        const { plants } = this.props.group;
        const selected = plants && plants.length ? plants.map(({ id }) => id) : [];

        let plantIds: string[] = [];

        const onUpdate = (ids: string[]) => {
            plantIds = ids;
            const canSaveModal =
                plantIds.sort().join('') !==
                plants!
                    .map(plant => plant.id)
                    .sort()
                    .join('');
            this.setState({ canSaveModal });
        };

        const onSave = () => {
            this.plantsChange(plantIds);
        };

        const modalBody = () => (
            <Field horizontal={true}>
                <Field.Label size="normal">
                    <Label>Plant</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control expanded={true}>
                            <PlantListSelectMenu
                                onResult={this.setPlants}
                                rows={5}
                                multiple={true}
                                onUpdate={onUpdate}
                                defaultSelected={selected}
                            />
                        </Control>
                    </Field>
                </Field.Body>
            </Field>
        );

        this.renderModal = () => this.modalTemplate({ title: 'Edit Plants' }, onSave, modalBody);

        this.setState({
            showModal: true,
        });
    };

    public render() {
        const { id, name, description, parentId } = this.props.group;

        return (
            <>
                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>{isRequired('Group name')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Name"
                                    value={name}
                                    onChange={this.nameChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Description</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Description"
                                    value={description || ''}
                                    onChange={this.descriptonChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Parent group (optional)</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <GroupListSelectMenu
                                selectHeader="Group name"
                                onUpdate={this.parentIdChange}
                                defaultSelected={parentId ? [parentId] : undefined}
                                onResult={this.setGroups}
                                disabled={[id]}
                            />
                        </Field>
                    </Field.Body>
                </Field>

                <div>
                    <hr />
                    <Title size="5">
                        Plants
                        <Button pull="right" onClick={this.plantModal}>
                            Edit Plants
                        </Button>
                    </Title>
                    {this.renderPlants()}

                    <hr />
                    <br />
                    <Title size="5">
                        Sub Groups
                        <Button pull="right" onClick={this.groupModal}>
                            Edit Groups
                        </Button>
                    </Title>
                    {this.renderGroups()}

                    <hr />
                    <br />
                    <Title size="5">
                        Users
                        {/*TODO: add multiple memberships <Button pull='right'>Add Users</Button>*/}
                        <NavButton to={Routing.USER_LIST.route} pull="right">
                            Add Users
                        </NavButton>
                    </Title>
                    {this.renderUsers()}

                    {this.state.showModal && this.renderModal()}
                </div>

                <hr />
            </>
        );
    }
}
