import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
    DELETE_DOCUMENT_MUTATION,
    GET_LINKED_PARTS_FOR_DOCUMENT_QUERY,
} from '../../api/GraphQLQueries/Elastic';
import Routing from '../../Routing';
import { notification } from '../../utils/notification';
import Loading from '../layout/Loading';
import ModalContainer from '../ui/ModalContainer';

interface IRouteParams {
    plantId?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    s3Key: string;
    onError?: () => void;
    onSuccess?: () => void;
    closeModal?: () => void;
}

class DocViewDeleteModal extends React.PureComponent<IProps, null, null> {
    private renderLinkedPartList = (linkedDocs: any[]) => {
        const plantId = this.props.match.params.plantId;

        if (linkedDocs.length) {
            const listItems = linkedDocs.map(({ part }) => {
                return (
                    <li className="my-1" key={part.tagNumberAsId}>
                        &middot;
                        <a
                            className="ml-1"
                            href={
                                '#' +
                                Routing.TAG_DETAILS.getPath({
                                    tagNumberAsId: part.tagNumberAsId,
                                    plantId,
                                })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {part.tagNumber} {part.designation && `- ${part.designation}`}
                        </a>
                    </li>
                );
            });

            return (
                <>
                    <h3>
                        The following parts must be unlinked or deleted <em>before</em> deleting
                        this document:
                    </h3>
                    <ul>{listItems}</ul>
                </>
            );
        }

        return (
            <>
                <h3>
                    This will delete <strong>all pages</strong> of this document. This cannot be
                    undone.
                </h3>
            </>
        );
    };

    public render() {
        const plantId = this.props.match.params.plantId;
        const { onError, onSuccess, s3Key, closeModal } = this.props;

        return (
            <ModalContainer isOpen={true} onRequestClose={closeModal}>
                <Query
                    query={GET_LINKED_PARTS_FOR_DOCUMENT_QUERY}
                    variables={{ plantId, s3Key }}
                    fetchPolicy="network-only"
                >
                    {({ data, loading, error }) => {
                        if (loading) {
                            return <Loading />;
                        }
                        if (error) {
                            notification.error(
                                'Failed to get linked parts. Please try again later.',
                            );
                            if (typeof onError === 'function') {
                                onError();
                            }
                            closeModal();
                            return null;
                        }

                        const linkedDocs = data.getLinkedPartsForDocument;

                        return (
                            <div id="deleteModal">
                                <div className="delete-modal-content">
                                    <div className="delete-modal-title">
                                        {linkedDocs.length
                                            ? 'Cannot delete documents with linked parts'
                                            : 'Are you sure you want to do that?'}
                                    </div>
                                    <div className="delete-modal-body">
                                        {this.renderLinkedPartList(linkedDocs)}
                                    </div>
                                    <div className="delete-modal-actions">
                                        <Button onClick={closeModal}>Cancel</Button>
                                        {!linkedDocs.length && (
                                            <Mutation
                                                mutation={DELETE_DOCUMENT_MUTATION}
                                                variables={{ plantId: '1234', s3Key }}
                                                onCompleted={onSuccess}
                                                onError={onError}
                                                awaitRefetchQueries={false}
                                            >
                                                {documentDeleteMutation => (
                                                    <Button
                                                        className="is-danger"
                                                        onClick={documentDeleteMutation}
                                                    >
                                                        Delete
                                                    </Button>
                                                )}
                                            </Mutation>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Query>
            </ModalContainer>
        );
    }
}

export default withRouter(DocViewDeleteModal);
