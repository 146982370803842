import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { SAVE_MEMBERSHIP_MUTATION } from '../../../api/GraphQLQueries/Membership';
import { USER_QUERY } from '../../../api/GraphQLQueries/User';
import { IMembershipSaveModel } from '../../../models/IMembershipModel';
import IUserModel from '../../../models/IUserModel';
import Error from '../../layout/Error';
import Message from '../../layout/Message';
import UserSaveMembership from '../UserSaveMembership';

interface IProps {
    user: IUserModel;
    onClose: () => void;
    membership?: IMembershipSaveModel;
    showGroupPlantSelectMenu?: boolean;
}

interface IState {
    error: boolean;
    success: boolean;
    membershipData?: IMembershipSaveModel;
}

export default class UserSaveMembershipMutation extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
        membershipData: this.props.membership,
    };

    private formSubmit = (doSaveMembershipMutation: MutationFn) => (): void => {
        const variables = {
            membership: {
                ...this.state.membershipData,
                userId: this.props.user.id,
            },
        };

        doSaveMembershipMutation({ variables });
    };

    private onUpdate = (membership: IMembershipSaveModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            membershipData: {
                userId: this.props.user.id,
                ...membership,
            },
        }));
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.props.onClose,
        );
    };

    private renderSaveMembershipForm = (doSaveMembershipMutation: MutationFn) => {
        const { membershipData } = this.state;
        const { showGroupPlantSelectMenu } = this.props;
        return (
            <>
                <UserSaveMembership
                    name={this.props.user.name}
                    membership={membershipData}
                    onUpdate={this.onUpdate}
                    onSubmit={this.formSubmit(doSaveMembershipMutation)}
                    onClose={this.props.onClose}
                    showGroupPlantSelectMenu={
                        showGroupPlantSelectMenu !== undefined ? showGroupPlantSelectMenu : true
                    }
                />
            </>
        );
    };

    public render() {
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>Membership Save error</Error>}
                {success && <Message type="success">Successfully Saved membership</Message>}
                <Mutation
                    mutation={SAVE_MEMBERSHIP_MUTATION}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                    refetchQueries={[{ query: USER_QUERY, variables: { id: this.props.user.id } }]}
                >
                    {doSaveMembership => this.renderSaveMembershipForm(doSaveMembership)}
                </Mutation>
            </>
        );
    }
}
