import * as _ from 'lodash';
import * as fsPath from 'path';
import { Column } from 'rbx';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ES_PAGES_FILTERS_QUERY } from '../../api/GraphQLQueries/Elastic';
import IsAdminQuery from '../../components/auth/IsAdminQuery';
import SearchResultDefaultItem from '../../components/search/SearchResultDefaultItem';
import SearchResultEquimentItem from '../../components/search/SearchResultEquimentItem';
import SearchResultNotIndexedItem from '../../components/search/SearchResultNotIndexedItem';
import SearchSortingSelectMenu, {
    ISortOption,
} from '../../components/search/SearchSortingSelectMenu';
import TagViewer from '../../components/tag/TagViewer';
import MultiSelect from '../../components/ui/MultiSelect';
import { IAggregatedSpindexPageHit } from '../../models/IElasticModel';
import Tracker from '../../utils/Tracker';
import { ISorting } from '../common/ListQueryContainer';
import DocumentHandler from './../../components/documentviewer/DocumentHandler';
import Pane from './../../components/ui/Pane';
import { FILTERS, getFiltersQueries, getSearchQuery } from './../../utils/buildSearchQuery';
import { buildPath, getUrlParams, IUrl, parseTagId } from './../../utils/queryString';
import ElasticSearchQueryFetchMoreContainer from './ElasticSearchFetchMoreQueryContainer';
import ElasticSearchQueryContainer from './ElasticSearchQueryContainer';

const SORT_BY_OPTIONS: ISortOption[] = [
    {
        optionName: 'Relevance',
        sortByField: '_score',
        hideAsc: true,
        hideDescDirLabel: true,
        default: 'desc',
    },
    {
        optionName: 'Content Type',
        sortByField: 'content.managedContentType',
    },
];

interface IProps extends RouteComponentProps<IRouteParams> {
    documentHandlerActionButtons?: any;
    onScroll?: any;
}

interface IState {
    pathinfo: IUrl;
}

interface IRouteParams {
    plantId: string;
}

interface IBucketItem {
    key: string;
    doc_count: number;
}

class Search extends React.Component<IProps, IState> {
    public state: IState = {
        pathinfo: getUrlParams(this.props.location),
    };

    public static getDerivedStateFromProps = (props: any, state: IState) => {
        const pathinfo = getUrlParams(props.location);
        return { ...state, pathinfo };
    };

    private onChangeFilter = (filterName: any) => (filterValue: any) => {
        const { pathinfo } = this.state;
        pathinfo.params[filterName] = _.map(filterValue, 'key');
        const path = buildPath(pathinfo);
        this.props.history.push(path);
    };

    private onAdvancedFilterChange = (filterName: any) => (filterValue: any) => {
        const { pathinfo } = this.state;
        pathinfo.params[filterName] = filterValue ? filterValue.value : '';
        const path = buildPath(pathinfo);
        this.props.history.push(path);
    };

    private getSearchText = (): string => {
        return (this.state.pathinfo.params.query || '').trim();
    };

    private renderSearchResults = (
        results: IAggregatedSpindexPageHit[],
        totalPageHits: number,
        totalCount: number,
    ) => {
        return (
            <>
                {results && totalCount > 0 ? (
                    <>
                        <div className="search-result-count">
                            {totalPageHits} results found in {totalCount} documents
                        </div>
                        <div className="search-results-list">
                            {results.map(this.renderFirstPageHit)}
                        </div>
                    </>
                ) : (
                    <p>No results found</p>
                )}
            </>
        );
    };

    private renderFirstPageHit = (item: IAggregatedSpindexPageHit): JSX.Element => {
        if (!item) {
            return null;
        }

        if (item.key === '__DONE__') {
            return (
                <div className="flex-justify-center mt-2" key={item.key}>
                    No more results
                </div>
            );
        }

        if (!(item.pages || []).length) {
            return null;
        }

        const firstHit = item.pages[0];
        // FIXME: backend should not be returning this
        if (!firstHit) {
            return null;
        }
        const index = firstHit._source.meta.page;

        const isEquipment = firstHit._source.content.managedContentType === 'equipment';
        if (isEquipment) {
            return (
                <SearchResultEquimentItem
                    key={firstHit._id}
                    item={firstHit}
                    hitCount={item.pageHits && item.pageHits.length}
                    cy={`search-result-n${index}`}
                />
            );
        }

        const title =
            firstHit._source.content.title ||
            _.get(firstHit, '_source.spindexDocument.title') ||
            firstHit._id.replace(/.*\//, '');

        const folder = this.getFolder(firstHit);

        if (firstHit._source.indexState !== 'completed') {
            return (
                <SearchResultNotIndexedItem
                    key={firstHit._id}
                    item={firstHit}
                    title={title}
                    folder={folder}
                    cy={`search-result-n${index}`}
                />
            );
        }

        return (
            <SearchResultDefaultItem
                key={firstHit._id}
                item={firstHit}
                title={title}
                hitCount={item.pageHits && item.pageHits.length}
                cy={`search-result-n${index}`}
            />
        );
    };

    private getFolder(item): string {
        const metaFolders = _.get(item, '_source.meta.folder');
        if (!Array.isArray(metaFolders)) {
            return null;
        }
        return fsPath
            .dirname(metaFolders[metaFolders.length - 1])
            .split('/')
            .slice(3)
            .join(' / ');
    }

    private closeDocumentViewerPane = () => {
        const { pathinfo } = this.state;
        pathinfo.params.docId = null;

        const path = buildPath(pathinfo);

        this.props.history.push(path);
    };

    private closeTagViewerPane = () => {
        const { pathinfo } = this.state;
        pathinfo.params.tagId = null;

        const path = buildPath(pathinfo);

        this.props.history.push(path);
    };

    private renderDocumentViewerPane = () => {
        const { docId } = getUrlParams(this.props.location).params;
        const { documentHandlerActionButtons } = this.props;

        if (!docId) {
            return null;
        }

        return (
            <DocumentHandler
                key={docId}
                actionButtons={documentHandlerActionButtons}
                onClose={this.closeDocumentViewerPane}
            />
        );
    };

    private renderTagViewerPane = () => {
        const tagId = parseTagId(this.props.location);
        const { plantId } = this.props.match.params;

        return (
            tagId &&
            plantId && (
                <TagViewer
                    tagNumberAsId={tagId}
                    plantId={plantId}
                    onClose={this.closeTagViewerPane}
                />
            )
        );
    };

    private getOptionType = (
        index: string,
        addCountLabel?: boolean,
    ): ((filterOption: IBucketItem) => string) => {
        void index;

        return (filterOption: IBucketItem) => {
            const countLabel =
                addCountLabel && filterOption.doc_count ? ` (${filterOption.doc_count}) ` : '';
            return filterOption.key + countLabel;
        };
    };

    private renderSearchFiltersItem = (aggregation: any) => {
        const { buckets, id } = aggregation;
        const filtersValues: any = this.getActiveFilters()[id];
        const selectedOption: any = [];

        if (filtersValues) {
            filtersValues.map((filterValue: string) => {
                const filter: IBucketItem = buckets.find((bucketItem: IBucketItem) => {
                    return bucketItem.key === filterValue;
                });

                return selectedOption.push({
                    key: filterValue,
                    doc_count: filter ? filter.doc_count : '',
                });
            });
        }

        return (
            <Column key={id} className="search-filter">
                <MultiSelect<IBucketItem>
                    cy={id.replace(/\s/g, '').toLowerCase()}
                    options={buckets}
                    getOptionValue={this.getOptionType('key')}
                    getOptionLabel={this.getOptionType('key', true)}
                    placeholder={id}
                    onChange={this.onChangeFilter(id)}
                    value={selectedOption}
                    isMulti={true}
                />
            </Column>
        );
    };

    private renderSearchFiltersItems = (results: any, totalCount: any, aggregations: any) => {
        void [results, totalCount];

        aggregations = _.values(
            _.mapValues(aggregations, (value: any, key: any) => {
                value.id = key;
                return value;
            }),
        );

        return (
            <>
                {aggregations.map((aggregation: any) => {
                    return this.renderSearchFiltersItem(aggregation);
                })}
                <IsAdminQuery renderAdmin={this.renderAdvancedFilters} />
            </>
        );
    };

    private renderSearchFilters = () => {
        return (
            <ElasticSearchQueryContainer
                esRequest={this.buildEsRequestFiltersQuery()}
                query={ES_PAGES_FILTERS_QUERY}
                renderFetchedSearchResults={this.renderSearchFiltersItems}
            />
        );
    };

    private getActivePlant = () => {
        const { plantId } = this.props.match.params;
        if (plantId) {
            return plantId;
        }
        return '';
    };

    private getActiveFilters = (): string[][] => {
        const filters: string[][] = [];
        for (const e of FILTERS) {
            const filter: string = this.state.pathinfo.params[e];
            if (filter) {
                filters[e] = [];
                filters[e] = filters[e].concat(filter);
            }
        }
        return filters;
    };

    private getActiveSorting = () => {
        const { sortBy, sortByDir } = this.state.pathinfo.params;

        if (sortBy && sortByDir) {
            return {
                field: sortBy,
                direction: sortByDir,
            };
        }
    };

    private trackSearch = (searchText: string) => {
        Tracker.trackSearch(searchText);
    };

    private trackFilter = (filters: any) => {
        Tracker.trackFilter(filters);
    };

    private buildEsRequestQuery = (): string => {
        const searchText = this.getSearchText();
        const plantId = this.getActivePlant();
        const managedContentTypeFilter = this.getActiveFilters();
        const sort = this.getActiveSorting();
        this.trackFilter(managedContentTypeFilter);
        this.trackSearch(searchText);
        return getSearchQuery(searchText, plantId, managedContentTypeFilter, sort);
    };

    private buildEsRequestFiltersQuery = (): string => {
        const plantId = this.getActivePlant();

        return getFiltersQueries(plantId);
    };

    private onSorting = (sortByOptions?: ISorting) => {
        const { pathinfo } = this.state;
        pathinfo.params.sortBy = sortByOptions ? sortByOptions.field : '';
        pathinfo.params.sortByDir = sortByOptions ? sortByOptions.direction : '';
        const path = buildPath(pathinfo);
        this.props.history.push(path);
    };

    private renderAdvancedFilters = () => {
        const indexStateValues = [
            { value: 'fileOnly', label: 'File Only' },
            { value: 'error', label: 'Error' },
            { value: 'ignored', label: 'Ignored' },
            { value: 'completed', label: 'Completed' },
        ];

        const sourceValues = [
            { value: 's3', label: 'S3 only' },
            { value: 'dctm', label: 'DCTM only' },
            { value: 'both', label: 'Both' },
        ];

        const active = this.getActiveFilters();
        const getFilter = (key: string) => (active[key] || [])[0];

        const indexStatus = indexStateValues.find(v => v.value === getFilter('Index Status'));
        const source = sourceValues.find(v => v.value === getFilter('Source'));

        return (
            <>
                <Column className="search-filter">
                    <MultiSelect<{ value: string; label: string }>
                        placeholder="Index Status"
                        options={indexStateValues}
                        isClearable={true}
                        value={indexStatus}
                        onChange={this.onAdvancedFilterChange('Index Status')}
                    />
                </Column>
                <Column className="search-filter">
                    <MultiSelect<{ value: string; label: string }>
                        placeholder="Source"
                        options={sourceValues}
                        isClearable={true}
                        value={source}
                        onChange={this.onAdvancedFilterChange('Source')}
                    />
                </Column>
            </>
        );
    };

    public render() {
        const esRequest = this.buildEsRequestQuery();

        return (
            <>
                <Pane classNames="flex-column-content">
                    <Column.Group>
                        {this.renderSearchFilters()}
                        <Column className="search-filter">
                            <SearchSortingSelectMenu
                                sortOptions={SORT_BY_OPTIONS}
                                onSelect={this.onSorting}
                                selected={this.getActiveSorting()}
                            />
                        </Column>
                    </Column.Group>

                    <ElasticSearchQueryFetchMoreContainer
                        esRequest={esRequest}
                        renderFetchedSearchResults={this.renderSearchResults}
                    />
                </Pane>
                {/* IMPORTANT: don't change the order of the render stuff below! */}
                {/* It's possible to open doc viewer from inside the tag viewer */}
                {/* and the tag viewer should be under the doc viewer. */}
                {this.renderTagViewerPane()}
                {this.renderDocumentViewerPane()}
            </>
        );
    }
}

export default withRouter(Search);
