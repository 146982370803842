import { Button } from 'rbx';
import * as React from 'react';

import { storage } from '../../utils/storage';

// HOC wrapper to disable button when in maintenance mode
export class MaintenanceButton extends React.Component<any> {
    public render = () => {
        const { disabled, ...rest } = this.props;
        const disabledDuringMaintenance = storage.get('maintenenceMode') === 'true';

        return (
            <Button type="button" disabled={disabledDuringMaintenance || disabled} {...rest}>
                {disabledDuringMaintenance ? 'Disabled during maintenance' : this.props.children}
            </Button>
        );
    };
}
