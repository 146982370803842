import * as React from 'react';
import { Mutation } from 'react-apollo';

import { MaintenanceButton } from '../../../../components/ui/MaintenanceButton';
import { notification } from '../../../../utils/notification';
import { ONBOARDING_PUBLISHFORINDEXING_MUTATION } from './../../../../api/GraphQLQueries/Onboarding';
import { IIndeaxable } from './IndexableRow';

interface IProps {
    plantS3Folder: string;
    disabled?: boolean;
    indexable: IIndeaxable;
}

interface IState {
    disabled: boolean;
}

export default class IndexDocumentMutation extends React.Component<IProps, IState> {
    public state: IState = {
        disabled: false,
    };

    private onError = (): void => {
        notification.error('Document sync error');
        this.setState({ disabled: false });
    };

    private onCompleted = (): void => {
        notification.success('Successfully started document sync');

        this.setState({ disabled: true });

        // const isDev = process.env.REACT_APP_ENV === 'development';
        // this.setState({ disabled: !isDev });
    };

    public render = () => {
        const { disabled } = this.state;
        const { plantS3Folder, indexable } = this.props;
        const plantId = plantS3Folder.substring(plantS3Folder.indexOf('-') + 1);
        const indexFileOptions = {
            plantId,
            query: indexable.query,
            action: indexable.name,
            dryRun: indexable.dryRun,
            forceSplit: indexable.forceSplit,
            forceNormalize: indexable.forceNormalize,
            forceExtract: indexable.forceExtract,
            onlyErrored: indexable.onlyErrored,
            forceOcr: indexable.forceOcr,
            forceIndex: indexable.forceIndex,
        };

        return (
            <>
                <Mutation
                    mutation={ONBOARDING_PUBLISHFORINDEXING_MUTATION}
                    variables={{ indexFileOptions }}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    awaitRefetchQueries={true}
                >
                    {doDocumentSyncMutation => (
                        <MaintenanceButton
                            type="button"
                            disabled={disabled}
                            onClick={doDocumentSyncMutation}
                        >
                            Index S3 Documents to Spindex
                        </MaintenanceButton>
                    )}
                </Mutation>
            </>
        );
    };
}
