import { Dropdown, Label } from 'rbx';
import React from 'react';

import { BASE_URI } from '../../api/connection';

interface IProps {
    label: string;
    href?: string;
    port?: number;
    divider?: boolean;
}

export default class OpsLink extends React.Component<IProps> {
    public render() {
        const { href, label, port, divider } = this.props;
        const showDivider = typeof divider === 'undefined' ? true : divider;

        return (
            <>
                <Dropdown.Item target="_blank" href={port ? `${BASE_URI}:${port}` : href}>
                    <Label>{label}</Label>
                </Dropdown.Item>
                {showDivider && <Dropdown.Divider />}
            </>
        );
    }
}
