import * as React from 'react';

import { GROUP_LIST_QUERY } from './../../../api/GraphQLQueries/Group';
import ListQueryContainer, {
    IGraphQLListProps,
} from './../../../containers/common/ListQueryContainer';
import IGroupModel from './../../../models/IGroupModel';

interface IProps extends IGraphQLListProps {
    renderFetchedGroupList: (
        groups: IGroupModel[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => JSX.Element;
}

export default class GroupListQuery extends React.Component<IProps, any> {
    public render() {
        const { renderFetchedGroupList, query } = this.props;

        return (
            <ListQueryContainer
                {...this.props}
                query={query || GROUP_LIST_QUERY}
                dataProp="groups"
                renderFetchedList={renderFetchedGroupList}
                sortBy={{ field: 'name', direction: 'asc' }}
            >
                {(
                    groupList: IGroupModel[],
                    totalCount: number,
                    limitRes: number,
                    offsetRes: number,
                ) => {
                    return renderFetchedGroupList(groupList, totalCount, limitRes, offsetRes);
                }}
            </ListQueryContainer>
        );
    }
}
