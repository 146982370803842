import { Button, Column, Title } from 'rbx';
import * as React from 'react';

interface IProps {
    buttons?: any;
}

export default class PageHeader extends React.Component<IProps, any> {
    private renderActionButtons = () => {
        const { buttons } = this.props;
        return (
            buttons && (
                <Column narrow={true}>
                    <Button.Group>{buttons}</Button.Group>
                </Column>
            )
        );
    };

    public render() {
        return (
            <div className="page-header">
                <Column.Group marginless={true}>
                    <Column>
                        <Title size="5">{this.props.children}</Title>
                    </Column>
                    {this.renderActionButtons()}
                </Column.Group>
            </div>
        );
    }
}
