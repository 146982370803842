import gql from 'graphql-tag';

const PLANT_AND_GROUP_QUERY = gql`
    query plantGroups($offset: Int, $limit: Int) {
        plants(offset: $offset, limit: $limit) {
            data {
                id
                name
                s3Folder
                groups {
                    id
                    name
                    description
                }
            }
            limit
            offset
            totalCount
        }
        groups(offset: $offset, limit: $limit) {
            data {
                id
                name
                description
                parent {
                    id
                    name
                }
                groups {
                    id
                    name
                }
                plants {
                    id
                    name
                }
            }
            limit
            offset
            totalCount
        }
    }
`;

const GROUP_AND_MEMBERSHIPS_QUERY = gql`
    query GroupAndMemberships($id: Int!) {
        group(id: $id) {
            id
            name
            description
            parent {
                id
                name
            }
            groups {
                id
                name
            }
            plants {
                id
                name
            }
        }
        memberships(filter: { groupId: $id }) {
            data {
                group {
                    id
                    name
                }
                plant {
                    id
                    name
                }
                user {
                    id
                    name
                    email
                }
            }
        }
    }
`;

export { PLANT_AND_GROUP_QUERY, GROUP_AND_MEMBERSHIPS_QUERY };
