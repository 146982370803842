import axios from 'axios';
import classNames from 'classnames';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Button, Checkbox, Input, Label } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { camelToTitle, isMIE } from '../../utils/helpers';
import { notification } from '../../utils/notification';
import { buildPath, getUrlParams, parseDecoded } from '../../utils/queryString';
import { storage } from '../../utils/storage';
import IsAdminQuery from '../auth/IsAdminQuery';
import Icon from '../ui/Icon';
import { MaintenanceButton } from '../ui/MaintenanceButton';
import Pane from './../ui/Pane';
import { DocumentSaveOverrideMutation } from './DocumentSaveOverrideMutation';
import DocumentView from './DocumentView';
import DocViewDeleteModal from './DocViewDeleteModal';
import IDocumentView from './IDocumentView';
import * as Matrix from './matrix';

const ZOOM_STEP: number = 1;
const WHEEL_STEP: number = 0.1;
const ZOOM_MAX: number = 5;
const ROTATE_STEP: number = 90;
const ENABLE_ZOOM_ON_MOUSE_WHEEL = true;
const ALWAYS_PAN = true;

interface IRouteParams {
    plantId?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    data: IDocumentView;
    className?: string;
    title?: string;
    onClose?: () => void;
    renderPaging?: any;
    actionButtons?: any;
    downloadKey?: string;
    page?: number;
    onIsPanning?: (isPanning: boolean) => void;
    searchResultPaging?: () => void;
}

interface IState {
    width: string;
    height: string;
    tx: number;
    ty: number;
    zoom: number;
    transformMatrix: number[];
    panable: boolean;
    rotate: number;
    editingTitle: boolean;
    newTitle: string;
    showPathPopover: boolean;
    modal?: boolean;
    showTags?: boolean;
    showWords?: boolean;
    optsVisible?: boolean;
    indexOptions: {
        forceExtract?: boolean;
        forceOcr?: boolean;
        forceNormalize?: boolean;
        forceSplit?: boolean;
    };
}

const indexOptions = {
    forceSplit: false,
    forceExtract: false,
    forceOcr: false,
    forceNormalize: false,
};

class DocumentViewHandler extends React.Component<IProps, IState> {
    public state: IState = {
        width: '750',
        height: '500', // berechen
        tx: 0,
        ty: 0,
        zoom: 1,
        transformMatrix: Matrix.baseMatrix(),
        panable: !!ALWAYS_PAN,
        rotate: 0,
        editingTitle: false,
        newTitle: this.props.title || 'Untitled',
        showPathPopover: false,
        indexOptions,
    };

    public componentDidMount() {
        this.setState({ showWords: storage.getObj('dev.outlineWords') });
    }

    private resetAll = (): void => {
        this.setState(
            {
                zoom: 1,
                tx: 0,
                ty: 0,
                rotate: 0,
            },
            this.setTransformMatrix,
        );
    };

    private reachedMinSize = (factor: number): boolean => {
        return this.state.zoom + factor < 1;
    };

    private reachedMaxSize = (factor: number): boolean => {
        return this.state.zoom + factor > ZOOM_MAX;
    };

    private shouldZoom = (factor: number): boolean => {
        return !this.reachedMinSize(factor) && !this.reachedMaxSize(factor);
    };

    private setTransformMatrix = (): void => {
        const { zoom, rotate, tx, ty } = this.state;

        const baseMatrix = Matrix.baseMatrix();
        const zoomMatrix = Matrix.scale(zoom);
        const rotateMatrix = Matrix.rotate(rotate);
        const translateMatrix = Matrix.translate(tx, ty);

        const newTransformMatrix = [baseMatrix, translateMatrix, zoomMatrix, rotateMatrix].reduce(
            Matrix.multiply,
        );

        this.setState({ transformMatrix: [...newTransformMatrix] });
    };

    private isPanable = (zoom: number) => {
        return ALWAYS_PAN ? true : zoom > 1;
    };

    private shouldRotate = () => {
        const { rotate } = this.state;

        return rotate + ROTATE_STEP < 360;
    };

    private resetRotate = (): void => {
        this.setState({ rotate: 0 }, this.setTransformMatrix);
    };

    private rotate = (): void => {
        const shouldRotate = this.shouldRotate();

        if (!shouldRotate) {
            this.resetRotate();
            return;
        }

        this.setState(
            {
                rotate: this.state.rotate + ROTATE_STEP,
            },
            this.setTransformMatrix,
        );
    };

    private resetZoom = () => {
        const defaultZoom = 1;

        this.setState(
            {
                zoom: defaultZoom,
                panable: this.isPanable(defaultZoom),
                tx: 0,
                ty: 0,
            },
            this.setTransformMatrix,
        );
    };

    private setZoom = (factor: number): void => {
        const shouldZoom = this.shouldZoom(factor);

        if (shouldZoom) {
            this.setState((prevState: IState) => {
                const newZoom = prevState.zoom + factor;
                return {
                    zoom: _.round(newZoom, 2),
                    panable: this.isPanable(newZoom),
                };
            }, this.setTransformMatrix);
        }
    };

    private zoomIn = (stepOverride?: number): void => {
        const step: number = stepOverride && stepOverride > 0 ? stepOverride : ZOOM_STEP;
        this.setZoom(step);
    };

    private zoomOut = (stepOverride?: number): void => {
        const step: number = stepOverride && stepOverride > 0 ? stepOverride : ZOOM_STEP;
        this.setZoom(-step);
    };

    private onPan = (): void => {
        if (this.panContainer.current) {
            const existingTransformStyle = getComputedStyle(this.panContainer.current).transform;
            const currentTransformMatrix = Matrix.parse(existingTransformStyle);

            this.setState(
                {
                    tx: currentTransformMatrix[12],
                    ty: currentTransformMatrix[13],
                },
                this.setTransformMatrix,
            );
        }
    };

    public renderZoomControls = () => {
        return (
            <>
                <Button size="small" onClick={this.resetZoom}>
                    <Icon size="small" icon="fas fa-expand-arrows-alt" />
                </Button>
                <Button size="small" onClick={this.zoomIn}>
                    <Icon size="small" icon="fa fa-search-plus" />
                </Button>
                <Button size="small" onClick={this.zoomOut}>
                    <Icon size="small" icon="fa fa-search-minus" />
                </Button>
            </>
        );
    };

    private renderRotateControls = () => {
        return (
            <>
                <Button size="small" onClick={this.rotate}>
                    <Icon size="small" icon="fa fa-redo" />
                </Button>
                <Button size="small" onClick={this.resetAll}>
                    Reset
                </Button>
            </>
        );
    };

    private handleDownload = event => {
        const href = event.currentTarget.dataset.arg;
        axios
            .get(href)
            .then(() => {
                window.location = href;
            })
            .catch(error => {
                if (error.response.status === 404) {
                    notification.error('Document Not Found');
                } else {
                    notification.error(error.response.data);
                }
            });
    };

    private renderDownload = () => {
        const { downloadKey } = this.props;
        const href = `${downloadKey}&download=true`;

        return (
            <>
                <Button as="a" size="small" data-arg={href} onClick={this.handleDownload}>
                    <Icon size="small" icon="fa fa-download" />
                    <span>Download</span>
                </Button>
            </>
        );
    };

    public wheelEvent = (e: React.WheelEvent<HTMLDivElement>) => {
        if (!ENABLE_ZOOM_ON_MOUSE_WHEEL || !e.shiftKey) {
            return;
        }
        e.stopPropagation();
        if (isMIE()) {
            e.preventDefault();
        }
        e.nativeEvent.stopImmediatePropagation();

        if (e.deltaY < 0) {
            this.zoomIn(WHEEL_STEP);
        }
        if (e.deltaY > 0) {
            this.zoomOut(WHEEL_STEP);
        }
    };

    private panContainer = React.createRef<HTMLDivElement>();

    private saveTitleChange = () => {
        this.setState({ editingTitle: !this.state.editingTitle });
    };

    private toggleEdit = () => {
        this.setState({
            editingTitle: !this.state.editingTitle,
            newTitle: this.props.title,
        });
    };

    private updateTitle = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const newTitle = event.currentTarget.value;
        this.setState({ newTitle });
    };

    private renderCustomerTitleEdit = () => {
        const title = this.state.newTitle || this.props.title;

        return <div className="document-title">{title || 'Untitled'}</div>;
    };

    private togglePathPopover = () => {
        this.setState({
            showPathPopover: !this.state.showPathPopover,
            optsVisible: false,
            indexOptions,
        });
    };

    private onDeletionSuccess = () => {
        notification.success('Deleted document');
        this.setState({ modal: false });

        const urlParams = getUrlParams(this.props.location);
        delete urlParams.params.docId;
        const path = buildPath(urlParams);
        this.props.history.replace(`#${path.pathname}${path.search}`);
        window.location.reload();
    };

    private onDeletionError = () => {
        notification.error('Error deleting document');
        this.setState({ modal: false });
    };

    private openDeletionModal = () => {
        this.setState({ modal: true });
    };

    private closeDeletionModal = () => {
        this.setState({ modal: false });
    };

    private renderDeletionModal = () => {
        const { downloadKey } = this.props;
        const s3Key = downloadKey && downloadKey.split('&key=').pop();

        return (
            <DocViewDeleteModal
                s3Key={decodeURIComponent(s3Key)}
                closeModal={this.closeDeletionModal}
                onError={this.onDeletionError}
                onSuccess={this.onDeletionSuccess}
            />
        );
    };

    private toggleTags = () => {
        storage.setObj('dev.outlineTags', !this.state.showTags);
        this.setState({ showTags: !this.state.showTags });
    };

    private toggleWords = () => {
        storage.setObj('dev.outlineWords', !this.state.showWords);
        this.setState({ showWords: !this.state.showWords });
    };

    private renderDocInfoIcons = () => {
        const { skippedOcr, mergedOcr, detectedTags } = this.props.data;
        const isTextDocument = this.props.data.isTextDocument > 75;

        const uniqueTags = [...new Set((detectedTags || []).map(w => w.text))].length;

        const tagTitle = `Found ${(detectedTags || []).length} tags on page (${uniqueTags} unique)`;

        let ocrTitle = 'Sent to OCR';
        if (skippedOcr) {
            ocrTitle = 'Not sent to OCR';
            if (mergedOcr) {
                ocrTitle += ' (has ocr data)';
            }
        }

        return (
            <>
                <Icon
                    icon={isTextDocument ? 'fa-file-alt' : 'fa-file-code'}
                    title={isTextDocument ? 'Detected Text Document' : 'Detected Technical Drawing'}
                />
                <Icon
                    icon={`fa-search${uniqueTags ? '' : '-minus'} is-clickable`}
                    onClick={this.toggleTags}
                    title={tagTitle}
                />
                <Icon
                    icon={`fa-eye${skippedOcr ? '-slash' : ''} is-clickable`}
                    onClick={this.toggleWords}
                    title={ocrTitle}
                />
            </>
        );
    };

    private renderReindexButton = () => {
        const { downloadKey } = this.props;
        const { optsVisible } = this.state;

        const s3Key = downloadKey && downloadKey.split('&key=').pop();

        const query = gql`
            mutation publishRamrodJob($jobName: String!, $opts: JSON!) {
                publishRamrodJob(jobName: $jobName, opts: $opts)
            }
        `;

        const vars = {
            jobName: 'reindexWithIndexOptions',
            opts: JSON.stringify({
                plantId: this.props.match.params.plantId,
                count: 'no clue - scripted',
                request: {
                    _source: ['meta.original_filekey'],
                    query: {
                        bool: {
                            filter: {
                                bool: {
                                    must: [
                                        {
                                            prefix: {
                                                'meta.original_filekey': decodeURIComponent(s3Key),
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
                indexFileOptions: {
                    forceIndex: true,
                    forceSplit: this.state.indexOptions.forceSplit,
                    forceOcr: this.state.indexOptions.forceOcr,
                    forceNormalize: this.state.indexOptions.forceNormalize,
                    forceExtract: this.state.indexOptions.forceExtract,
                },
            }),
        };

        const toggleOpts = () => this.setState({ optsVisible: !this.state.optsVisible });

        const notSucces = e => {
            toggleOpts();
            notification.error('Something went wrong. Please try again later');
            console.error(e);
        };

        const success = () => {
            toggleOpts();
            notification.success('started');
        };

        const updateOpts = (context: React.SyntheticEvent<HTMLInputElement>) => {
            const { name, checked } = context.currentTarget;
            this.setState({ indexOptions: { ...this.state.indexOptions, [name]: checked } });
        };

        if (!optsVisible) {
            return (
                <MaintenanceButton type="button" onClick={toggleOpts} className="is-secondary ma-1">
                    Reindex
                </MaintenanceButton>
            );
        }

        return (
            <>
                {Object.keys(indexOptions).map((advOpt: string) => {
                    return (
                        <Label className="mr-15" key={advOpt}>
                            <Checkbox
                                className="mr-05"
                                name={advOpt}
                                onChange={updateOpts}
                                checked={this.state.indexOptions[advOpt]}
                            />
                            {camelToTitle(advOpt)}
                        </Label>
                    );
                })}
                <Mutation
                    mutation={query}
                    variables={vars}
                    onCompleted={success}
                    onError={notSucces}
                >
                    {reindexMut => (
                        <Button type="button" onClick={reindexMut} className="is-secondary ma-1">
                            Send
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };

    private renderDocumentInfo = () => {
        const { showPathPopover } = this.state;
        const { downloadKey, data } = this.props;
        const s3Key = downloadKey && decodeURIComponent(downloadKey.split('&key=').pop());

        return (
            <div className={showPathPopover ? 'document-path open' : 'document-path'}>
                {showPathPopover ? (
                    <>
                        <small id="s3KeyText">{decodeURIComponent(s3Key)}</small>
                        <br />
                        <strong className="processed-date">
                            Processed: {new Date(data.processedDate).toLocaleString('en-GB')}
                        </strong>

                        {/*actions*/}
                        <div className="document-path-bar">
                            <span>
                                <Icon
                                    onClick={this.togglePathPopover}
                                    icon="fa fa-times-circle is-clickable"
                                />
                                {this.renderDocInfoIcons()}
                            </span>

                            <Button.Group>
                                {this.renderReindexButton()}
                                <MaintenanceButton
                                    className="is-danger ma-1"
                                    onClick={this.openDeletionModal}
                                >
                                    Delete document
                                </MaintenanceButton>
                            </Button.Group>
                        </div>
                        {this.state.modal && this.renderDeletionModal()}
                    </>
                ) : (
                    <Icon onClick={this.togglePathPopover} icon="fa fa-info-circle is-clickable" />
                )}
                {!showPathPopover && this.renderDocInfoIcons()}
            </div>
        );
    };

    private renderAdminTitleEdit = () => {
        const { downloadKey, page } = this.props;
        const { newTitle } = this.state;
        const orginalS3Key = decodeURIComponent(parseDecoded(`${downloadKey}`).key);
        const disabledDuringMaintenance = storage.get('maintenenceMode') === 'true';

        if (this.state.editingTitle) {
            return (
                <>
                    <DocumentSaveOverrideMutation
                        orginalS3Key={orginalS3Key}
                        page={page}
                        overrideableSpindexPage={{ content: { title: newTitle } }}
                        onCompleted={this.saveTitleChange}
                    />
                    <Button size="small" onClick={this.toggleEdit}>
                        <Icon size="small" icon="fa fa-times" />
                    </Button>
                    <Input defaultValue={newTitle} onChange={this.updateTitle} />
                </>
            );
        }

        return (
            <>
                <Button
                    size="small"
                    onClick={this.toggleEdit}
                    disabled={disabledDuringMaintenance}
                    title={disabledDuringMaintenance ? 'Disabled in maintenance mode' : null}
                >
                    <Icon size="small" icon="fa fa-pencil" />
                </Button>
                <span>{newTitle}</span>
            </>
        );
    };

    private paneHeaderContent = () => {
        const { renderPaging, downloadKey } = this.props;

        return (
            <div className="document-viewer-header">
                <div className="document-title">
                    <IsAdminQuery
                        renderAdmin={this.renderAdminTitleEdit}
                        renderUser={this.renderCustomerTitleEdit}
                    />
                </div>
                <div className="document-paging">{renderPaging && renderPaging()}</div>
                <div className="document-download">{downloadKey && this.renderDownload()}</div>
                <div className="document-controls">
                    <Button.Group marginless={true} hasAddons={true}>
                        {this.renderZoomControls()}
                        {this.renderRotateControls()}
                    </Button.Group>
                </div>
            </div>
        );
    };

    public render = () => {
        const { height, width, transformMatrix, panable, showTags, showWords } = this.state;
        const { className, onClose, actionButtons, onIsPanning, searchResultPaging } = this.props;
        const style = { height, width };

        const classes = classNames({
            'pane-content-relative': true,
            'show-tags': showTags,
            'show-words': showWords,
        });

        return (
            <Pane
                sidepane={true}
                cy="documentViewHandler-pane"
                onClose={onClose}
                classNames={classes}
                headerContent={this.paneHeaderContent}
                actionButtons={actionButtons}
            >
                {searchResultPaging && searchResultPaging()}
                <div
                    className={`document-viewer ${
                        ENABLE_ZOOM_ON_MOUSE_WHEEL ? 'has-wheel-zoom' : ''
                    }`}
                >
                    <div
                        className={`zoom-wrapper ${className || ''}`}
                        style={style}
                        onWheel={this.wheelEvent}
                    >
                        <DocumentView
                            transformMatrix={transformMatrix}
                            panable={panable}
                            onPan={this.onPan}
                            enablePan={true}
                            height={height}
                            panContainer={this.panContainer}
                            onIsPanning={onIsPanning}
                        >
                            <div className="inner">{this.props.children}</div>
                        </DocumentView>
                    </div>
                    <IsAdminQuery renderAdmin={this.renderDocumentInfo} />
                </div>
            </Pane>
        );
    };
}

export default withRouter(DocumentViewHandler);
