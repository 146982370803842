interface IRoute {
    route: string;
    getPath(params?: string): string;
}

interface IParamRoute<T> {
    route: string;
    getPath(params: T): string;
}

class Routing {
    public static INDEX: IRoute = {
        route: '/',
        getPath: () => `/`,
    };

    public static SEARCH_PLANT: IRoute = {
        route: '/plant/:plantId/search',
        getPath: (plantId: string) => `/plant/${plantId}/search`,
    };

    public static GROUPS: IRoute = {
        route: '/group',
        getPath: () => `/group`,
    };

    public static GROUP_CREATE: IRoute = {
        route: '/group-new',
        getPath: () => `/group-new`,
    };

    public static GROUP_DETAILS: IRoute = {
        route: '/group/:groupId',
        getPath: (groupId: string) => `/group/${groupId}`,
    };

    public static PLANTS: IRoute = {
        route: '/plant',
        getPath: () => `/plant`,
    };

    public static PLANT_CREATE: IRoute = {
        route: '/plant-new',
        getPath: () => `/plant-new`,
    };

    public static PLANT_STATS: IRoute = {
        route: '/plant-stats',
        getPath: () => `/plant-stats`,
    };

    public static PLANT_ACTIONS: IRoute = {
        route: '/plant-actions',
        getPath: () => `/plant-actions`,
    };

    public static PLANT_DETAILS: IRoute = {
        route: '/plant/:plantId',
        getPath: (plantId: string) => `/plant/${plantId}`,
    };

    public static PLANT_INDEX_STATUS: IRoute = {
        route: '/plant/:plantId/index-status',
        getPath: (plantId: string) => `/plant/${plantId}/index-status`,
    };

    public static PLANT_ONBOARDING: IRoute = {
        route: '/plant/:plantId/onboarding',
        getPath: (plantId: string) => `/plant/${plantId}/onboarding`,
    };

    public static SPACY_CONVERTER: IRoute = {
        route: '/spacy-converter',
        getPath: () => `/spacy-converter`,
    };

    public static PLANT_TAGS: IRoute = {
        route: '/plant/:plantId/tags',
        getPath: (plantId: string) => `/plant/${plantId}/tags`,
    };

    public static TAG_DETAILS: IParamRoute<{ plantId: string; tagNumberAsId: string }> = {
        route: '/plant/:plantId/tags/:tagNumberAsId',
        getPath: ({ plantId, tagNumberAsId }) => `/plant/${plantId}/tags/${tagNumberAsId}`,
    };

    public static BASKETS: IRoute = {
        route: '/basket',
        getPath: () => `/basket`,
    };

    public static BASKET_DETAILS: IRoute = {
        route: '/basket/:basketId',
        getPath: (basketId: string) => `/basket/${basketId}`,
    };

    public static USER_LIST: IRoute = {
        route: '/user',
        getPath: () => `/user`,
    };

    public static USER_DETAILS: IRoute = {
        route: '/user/:userId',
        getPath: (userId: string) => `/user/${userId}`,
    };

    public static USER_CREATE: IRoute = {
        route: '/user-new',
        getPath: () => `/user-new`,
    };

    public static ADMIN_CREATE: IRoute = {
        route: '/admin-new',
        getPath: () => `/admin-new`,
    };

    public static PROFILE: IRoute = {
        route: '/profile',
        getPath: () => `/profile`,
    };

    public static AUTH_LOGIN: IRoute = {
        route: '/login',
        getPath: () => `/login`,
    };

    public static AUTH_FORGOT_PASSWORD: IRoute = {
        route: '/forgot-password',
        getPath: () => `/forgot-password`,
    };

    public static AUTH_RESET_PASSWORD: IRoute = {
        route: '/reset-password/:hash',
        getPath: (hash: string) => `/reset-password/${hash}`,
    };

    public static AUTH_ACCEPT_INVITATION: IRoute = {
        route: '/accept-invitation/:hash',
        getPath: (hash: string) => `/accept-invitation/${hash}`,
    };

    public static OPS: IRoute = {
        route: '/ops',
        getPath: () => `/ops`,
    };
}

export default Routing;
