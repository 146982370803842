import IPartModel from './IPartModel';

export type LineItemRequestType = 'parts' | 'service' | 'parts_and_service';

export const LineItemRequestTypes = [
    { value: 'parts', label: 'Spare Parts' },
    { value: 'service', label: 'Service' },
    { value: 'parts_and_service', label: 'Parts and Service' },
];

export const getLabelByValue = (value?: string) => {
    const labelItem = LineItemRequestTypes.find(item => item.value === value);
    return labelItem ? labelItem.label : undefined;
};

interface ILineItem {
    description?: string;
    isWholeComponent?: boolean;
    requestType?: LineItemRequestType;
    title?: string;
    quantity?: string;
    filename?: string;
    pageKey?: string;
    // positionX?: string;
    // positionY?: string;
}

export interface ILineItemCreateModel extends ILineItem {
    basketId?: string;
    partId?: string;
    parentPartId?: string;
    pageClick?: IWord;
}

export interface ILineItemUpdateModel extends ILineItem {
    id: number;
}
export interface IWord {
    text: string;
    boundingBox: any;
    textVariations?: string[];
    __typename?: string;
}
export default interface ILineItemModel extends ILineItem {
    id: number;
    part?: IPartModel;
    parentPart?: IPartModel;
    pageClick: IWord;
    updatedAt: string;
    createdAt: string;
}
