import { Button, Control, Field, Icon, Input, Label } from 'rbx';
import * as React from 'react';

import { IUser } from './../../../models/IUserModel';

interface IProps {
    user: IUser;
    onUpdate: (user: IUser) => void;
    showAddUserButton?: boolean;
    onAddUser: () => void;
    onRemoveUser: () => void;
}

export default class UserCreateFormRow extends React.Component<IProps, any> {
    private nameChange = (context: any) => {
        this.props.onUpdate({
            ...this.props.user,
            name: context.currentTarget.value,
        });
    };

    private emailChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.user,
            email: context.currentTarget.value,
        });
    };

    private renderAddUserbutton = () => {
        const { showAddUserButton, onAddUser, onRemoveUser, user } = this.props;

        return (
            <Field>
                <Control>
                    {showAddUserButton ? (
                        <Button disabled={!user.name || !user.email} onClick={onAddUser}>
                            <Icon>
                                <i className="fa fa-plus" />
                            </Icon>
                        </Button>
                    ) : (
                        <Button onClick={onRemoveUser}>
                            <Icon>
                                <i className="fa fa-minus" />
                            </Icon>
                        </Button>
                    )}
                </Control>
            </Field>
        );
    };

    public render() {
        const { name, email } = this.props.user;

        return (
            <Field horizontal={true}>
                <Field.Label size="normal">
                    <Label>Add User</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control expanded={true} iconLeft={true}>
                            <Input
                                type="string"
                                placeholder="Name"
                                value={name}
                                onChange={this.nameChange}
                            />
                            <Icon size="small" align="left">
                                <i className="fa fa-user" />
                            </Icon>
                        </Control>
                    </Field>
                    <Field>
                        <Control expanded={true} iconLeft={true}>
                            <Input
                                type="email"
                                placeholder="E-Mail"
                                value={email}
                                onChange={this.emailChange}
                            />
                            <Icon size="small" align="left">
                                <i className="fa fa-envelope" />
                            </Icon>
                        </Control>
                    </Field>
                    {this.renderAddUserbutton()}
                </Field.Body>
            </Field>
        );
    }
}
