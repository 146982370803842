import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { USER_DELETE_MUTATION } from './../../../api/GraphQLQueries/User';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    id: number;
    onSuccess: () => void;
}

interface IState {
    error: boolean;
    success: boolean;
}

export default class UserDeleteMutation extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));

        this.props.onSuccess();
    };

    private deleteUser = (doUserDeleteMutation: MutationFn) => (): void => {
        if (window.confirm(`Finally delete user?`)) {
            const { id } = this.props;
            doUserDeleteMutation({ variables: { id } });
        }
    };

    // not needed at the moment, because we reset the list to startpage
    // private updateCache = () => {
    //     return (cache: any, { data: deleteUser }: any) => {
    //         const data = cache.readQuery({ query: USER_LIST_QUERY });
    //         const reducedUsers = data.users.data.filter((user: any) => user.id !== this.props.id);
    //         cache.writeQuery({
    //             query: USER_LIST_QUERY,
    //             data: {
    //                 users: {
    //                     ...data.users,
    //                     data: reducedUsers,
    //                     totalCount: data.users.totalCount - 1,
    //                 },
    //             },
    //         });
    //     };
    // };

    public render() {
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>User delete error</Error>}
                {success && <Message type="success">Successfully deleted user</Message>}
                <Mutation
                    mutation={USER_DELETE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    // update={this.updateCache()}
                    // refetchQueries={() => [{ query: USER_LIST_QUERY, variables: { limit: 5, offset: 15, orderBy: 'id', orderDirection: 'asc' } }]}
                    // awaitRefetchQueries={true}
                >
                    {doUserDelete => (
                        <Button
                            type="button"
                            outlined={true}
                            color="danger"
                            onClick={this.deleteUser(doUserDelete)}
                        >
                            Delete User
                        </Button>
                    )}
                </Mutation>
            </>
        );
    }
}
