import * as React from 'react';

import { getBasketStatusText } from '../../utils/helpers';
import MultiSelect from '../ui/MultiSelect';
import { BasketStates } from './../../models/IBasketModel';

interface IProps {
    defaultSelected?: string;
    onUpdate: (status?: string) => void;
}

export default class StatusSelectMenu extends React.Component<IProps, any> {
    private handleChange = (status?: any) => {
        this.props.onUpdate(status ? status.value : undefined);
    };

    private mapStatusMessage = status => {
        return getBasketStatusText(status);
    };

    private buildSelectObject = () => {
        return BasketStates.map(status => {
            const item = { value: status, label: this.mapStatusMessage(status) };
            return item;
        });
    };

    public render() {
        const { defaultSelected } = this.props;

        const initial = defaultSelected
            ? { value: defaultSelected, label: defaultSelected }
            : undefined;

        return (
            <MultiSelect
                value={initial}
                options={this.buildSelectObject()}
                placeholder="Status"
                isClearable={true}
                onChange={this.handleChange}
            />
        );
    }
}
