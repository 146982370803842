import * as _ from 'lodash';
import { Button, Tag } from 'rbx';
import * as React from 'react';

import PlantListSelectMenu from '../components/plant/PlantListSelectMenu';
import BlurButton from '../components/ui/BlurButton';
import { DEFAULT_PAGE_SIZE } from '../components/ui/paging/Paging';
import TableActions from '../components/ui/table/TableActions';
import TableFilters from '../components/ui/table/TableFilters';
import AdminCreate from '../components/user/AdminCreate';
import GlobalRoleSelectMenu from '../components/user/GlobalRoleSelectMenu';
import UserCreateMultipleMembershipsMutation from '../components/user/queries/UserCreateMultipleMembershipsMutation';
import UserStatusSelectMenu from '../components/user/UserStatusSelectMenu';
import Routing from '../Routing';
import { buildPath, getUrlParams, IUrl } from '../utils/queryString';
import PageHeader from './../components/layout/PageHeader';
import { getPageProps, ITablePageProps } from './../components/ui/paging/PageProps';
import DefaultTable, { ITableColumnModel } from './../components/ui/table/ControlledTable';
import TableActionSortDrowdown, {
    ISortOption,
} from './../components/ui/table/TableActionSortDrowdown';
import withPaging from './../components/ui/table/withPaging';
import UserListQuery from './../components/user/queries/UserListQuery';
import UserCreate from './../components/user/UserCreate';
import UserUpdate from './../components/user/UserUpdate';
import IUserModel, { FriendlyGlobalRole } from './../models/IUserModel';
import { todo } from './../utils/translate';
import { ISorting } from './common/ListQueryContainer';
import withMainNavigation from './withMainNavigation';

const ControlledTable = withPaging(DefaultTable);

const sortOptions: ISortOption[] = [
    {
        optionName: 'Name',
        sortByField: 'name',
    },
    {
        optionName: 'Email',
        sortByField: 'email',
    },
    {
        optionName: 'Job title',
        sortByField: 'jobTitle',
    },
    {
        optionName: 'Status',
        sortByField: 'active',
    },
];

interface IState {
    totalCount: number;
    offset: number;
    limit: number;
    pageIndex: number;
    sortBy?: ISorting;
    forceRerenderKey: number;
    initialRender: boolean;
    showCreateMemberships: boolean;
    createMembershipsIds: number[];
    filter?: any;
    pathinfo: IUrl;
}

class UserListContainer extends React.Component<any, IState> {
    public state: IState = {
        totalCount: 0,
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        sortBy: undefined,
        forceRerenderKey: 0,
        initialRender: true,
        showCreateMemberships: false,
        createMembershipsIds: [],
        filter: {
            searchText: '',
            globalRole: undefined,
            status: undefined,
            jobTitle: undefined,
            plantId: undefined,
        },
        pathinfo: getUrlParams(this.props.location),
    };

    private openUserDetails = (id: string): void => {
        this.props.history.push(Routing.USER_DETAILS.getPath(id));
    };

    private viewDetailsAction = (id: string) => () => {
        this.openUserDetails(id.toString());
    };

    private buildActionButtonsCell = (actionValue: any) => {
        return (
            <Button.Group align="right">
                <BlurButton size="small" onClick={this.viewDetailsAction(actionValue)}>
                    Details
                </BlurButton>
            </Button.Group>
        );
    };

    private buildRoleCell = (role: string) => {
        return role === 'linde_admin' ? (
            <Tag color="success">{FriendlyGlobalRole[role]}</Tag>
        ) : (
            <Tag color="link">{FriendlyGlobalRole[role]}</Tag>
        );
    };

    private buildStatusCell = (active: boolean, invitedAt: number) => {
        return active ? (
            <Tag color="success">Active</Tag>
        ) : (
            <>
                {invitedAt ? <Tag color="warning">Invited</Tag> : <Tag color="light">Inactive</Tag>}
            </>
        );
    };

    private columns: ITableColumnModel[] = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Job title',
            accessor: 'jobTitle',
        },
        {
            Header: 'Global role',
            accessor: (data: any) => this.buildRoleCell(data.globalRole),
        },
        {
            Header: 'Status',
            accessor: (data: any) => this.buildStatusCell(data.active, data.invitedAt),
        },
        {
            accessor: (data: any) => this.buildActionButtonsCell(data.id),
            disableRowHandler: true,
        },
    ];

    private onUserCreate = () => {
        this.props.history.push(Routing.USER_CREATE.getPath());
    };

    private onAdminCreate = () => {
        this.props.history.push(Routing.ADMIN_CREATE.getPath());
    };

    private onClickRow = (id: number) => {
        this.openUserDetails(id.toString());
    };

    private onPageChange = (pageIndex: number) => {
        const offset = this.state.limit * pageIndex;

        this.setState((prevState: IState) => ({
            ...prevState,
            pageIndex,
            offset,
        }));
    };

    private showCreateMemberships = (ids: number[]) => {
        this.setState({
            createMembershipsIds: ids,
            showCreateMemberships: true,
        });
    };

    private hideCreateMemberships = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showCreateMemberships: false,
            forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
        }));
    };

    private toggleForceRerenderKey = (prevKey: number): number => {
        return prevKey < 1 ? 1 : 0;
    };

    private renderUserEditSidebar = () => {
        const { userId } = this.props.match.params;

        const onDeleteUser = () => {
            this.setState(
                (prevState: IState) => ({
                    ...prevState,
                    offset: 0,
                    pageIndex: 0,
                    forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
                }),
                this.hideSidebar,
            );
        };

        return (
            userId && (
                <UserUpdate
                    idUser={parseInt(userId, 10)}
                    onCancel={this.hideSidebar}
                    onDeleteUser={onDeleteUser}
                />
            )
        );
    };

    private renderUserCreateSidebar = () => {
        const onCreateUsers = () => {
            this.setState(
                (prevState: IState) => ({
                    ...prevState,
                    offset: 0,
                    pageIndex: 0,
                    forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
                }),
                this.hideSidebar,
            );
        };

        return <UserCreate onCancel={this.hideSidebar} onSuccess={onCreateUsers} />;
    };

    private renderAdminCreateSidebar = () => {
        const onCreateUsers = () => {
            this.setState(
                (prevState: IState) => ({
                    ...prevState,
                    offset: 0,
                    pageIndex: 0,
                    forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
                }),
                this.hideSidebar,
            );
        };

        return <AdminCreate onCancel={this.hideSidebar} onSuccess={onCreateUsers} />;
    };

    private onSorting = (sortByOptions?: ISorting) => {
        const sortBy = sortByOptions || undefined;

        this.setState((prevState: IState) => ({
            ...prevState,
            offset: 0,
            pageIndex: 0,
            sortBy,
        }));
    };

    private renderSortDropdown = () => {
        return (
            <TableActionSortDrowdown
                sortOptions={sortOptions}
                selected={this.state.sortBy}
                onSelect={this.onSorting}
            />
        );
    };

    private multiSelectActionButtons = (ids: any[], toggleAllFn: any) => {
        const disabled = _.isEmpty(ids);
        const { searchText } = this.state.filter;

        return (
            <>
                <TableActions
                    enableToggleAll={true}
                    onToggleAll={toggleAllFn}
                    renderSortDropdown={this.renderSortDropdown}
                    onCustomFilterChange={this.handleFilter}
                    customFilterValue={searchText}
                >
                    <Button.Group>
                        <BlurButton
                            size="small"
                            disabled={disabled}
                            onClick={this.showCreateMemberships.bind(this, ids)}
                        >
                            Add memberships
                        </BlurButton>
                    </Button.Group>
                </TableActions>
            </>
        );
    };

    private handleFilter = (event: any) => {
        const filter = { searchText: event.currentTarget.value };
        this.setState((prevState: IState) => ({ ...prevState, filter }));
    };

    private handleUserStatusFilter = (selectedStatus?: boolean) => {
        let status;
        if (selectedStatus === true) {
            status = true;
        } else if (selectedStatus === false) {
            status = false;
        }

        const { pathinfo } = this.state;
        pathinfo.params.status = selectedStatus;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, status },
        }));
    };

    private handleGlobalRoleFilter = (globalRoleSelected?: string) => {
        const globalRole = globalRoleSelected ? globalRoleSelected : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.globalRole = globalRole;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, globalRole },
        }));
    };

    private handlePlantFilter = (ids: string[]) => {
        const plantId = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.plantId = plantId;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, plantId },
        }));
    };

    private renderTableFilters = () => {
        let { filter } = this.state;
        filter = {
            status: Boolean(this.state.pathinfo.params.status),
            globalRole: this.state.pathinfo.params.globalRole,
            plantId: this.state.pathinfo.params.plantId,
        };

        return (
            <>
                <TableFilters>
                    <GlobalRoleSelectMenu
                        onUpdate={this.handleGlobalRoleFilter}
                        defaultSelected={filter.globalRole}
                    />
                    <UserStatusSelectMenu
                        onUpdate={this.handleUserStatusFilter}
                        defaultSelected={filter.status}
                    />
                    <PlantListSelectMenu
                        onUpdate={this.handlePlantFilter}
                        defaultSelected={filter.plantId}
                        selectHeader="Plants"
                        isClearable={true}
                    />
                </TableFilters>
            </>
        );
    };

    private renderControlledTable = (
        userList: IUserModel[],
        tablePageProps: ITablePageProps,
    ): JSX.Element => {
        const { pages, currentPageIndex, totalCount } = tablePageProps;
        const { userId } = this.props.match.params;

        return (
            <ControlledTable
                data={userList}
                totalCount={totalCount}
                columns={this.columns}
                pages={pages}
                page={currentPageIndex}
                onClickRow={this.onClickRow}
                onClickRowReturnData="id"
                multiselectDataProp="id"
                selectedRow={parseInt(userId, 10)}
                selectedRowDataProp="id"
                onPageChange={this.onPageChange}
                actionButtons={this.multiSelectActionButtons}
                filters={this.renderTableFilters}
            />
        );
    };

    private renderUserTable = () => {
        return (userList: IUserModel[], totalCount: number, limit: number, offset: number) => {
            const tablePageProps: ITablePageProps = getPageProps(totalCount, limit, offset);
            return this.renderControlledTable(userList, tablePageProps);
        };
    };

    private onResult = (totalCount: number): void => {
        if (this.state.initialRender || totalCount !== this.state.totalCount) {
            this.setState((prevState: IState) => ({
                ...prevState,
                totalCount,
                initialRender: false,
            }));
        }
    };

    private headerButtons = () => {
        return (
            <Button.Group>
                <BlurButton onClick={this.onAdminCreate}>{todo('Add admin')}</BlurButton>
                <BlurButton onClick={this.onUserCreate}>{todo('Add users')}</BlurButton>
            </Button.Group>
        );
    };

    private showCreateUser = () => {
        return this.props.location.pathname === Routing.USER_CREATE.getPath();
    };

    private showCreateAdmin = () => {
        return this.props.location.pathname === Routing.ADMIN_CREATE.getPath();
    };

    private hideSidebar = () => {
        // TODO: Here would be a great place to add a confirmation dialog for unsaved changes. Some day.
        this.props.history.push(Routing.USER_LIST.getPath());
    };

    private showEditUser = () => {
        const { userId } = this.props.match.params;

        return userId ? true : false;
    };

    public render() {
        const {
            offset,
            limit,
            sortBy,
            filter,
            forceRerenderKey,
            totalCount,
            showCreateMemberships,
            createMembershipsIds,
        } = this.state;

        return (
            <div>
                <PageHeader buttons={this.headerButtons()}>
                    {todo('Users')} ({totalCount})
                </PageHeader>
                <UserListQuery
                    key={forceRerenderKey}
                    offset={offset}
                    limit={limit}
                    sortBy={sortBy}
                    filter={!_.isEmpty(filter) ? filter : null}
                    onResult={this.onResult}
                    onResultDataProp="totalCount"
                    renderFetchedUserList={this.renderUserTable()}
                />
                {this.showCreateUser() && this.renderUserCreateSidebar()}
                {this.showCreateAdmin() && this.renderAdminCreateSidebar()}

                {this.showEditUser() && this.renderUserEditSidebar()}
                {showCreateMemberships && (
                    <UserCreateMultipleMembershipsMutation
                        onClose={this.hideCreateMemberships}
                        users={createMembershipsIds}
                    />
                )}
            </div>
        );
    }
}

export default withMainNavigation(UserListContainer);
