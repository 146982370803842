import * as React from 'react';

import { IUser } from './../../../models/IUserModel';
import UserCreateFormRow from './UserCreateFormRow';

interface IProps {
    onUpdate: (user: IUser, key: number) => any;
    users: IUser[];
    onAddUser: () => void;
    onRemoveUser: (key: number) => void;
}

export default class UserCreateForm extends React.Component<IProps, any> {
    private onUpdate = (key: number) => (user: IUser) => {
        this.props.onUpdate(user, key);
    };

    private onRemove = (key: number) => () => {
        this.props.onRemoveUser(key);
    };

    private renderUserFormRow = (user: IUser, key: number): JSX.Element => {
        const showAddUserButton = this.props.users.length === key + 1;

        return (
            <UserCreateFormRow
                user={user}
                key={key}
                onUpdate={this.onUpdate(key)}
                onAddUser={this.props.onAddUser}
                onRemoveUser={this.onRemove(key)}
                showAddUserButton={showAddUserButton}
            />
        );
    };

    private renderUserForms = (): JSX.Element => {
        return (
            <>
                {this.props.users.map((user: IUser, key: number) =>
                    this.renderUserFormRow(user, key),
                )}
            </>
        );
    };

    public render() {
        return this.renderUserForms();
    }
}
