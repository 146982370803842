import React, { Component } from 'react';
import ReactGA from 'react-ga';

export default function(WrappedComponent: any, options = {}) {
    const trackPage = (page: any) => {
        ReactGA.set({
            page,
            ...options,
        });
        ReactGA.pageview(page);
    };

    return class extends Component<any> {
        public componentDidMount() {
            const page = this.props.location.pathname;
            trackPage(page);
        }

        public componentWillReceiveProps(nextProps: any) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;
            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        public render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}
