import { Control, Field, Icon, Input } from 'rbx';
import * as React from 'react';

import { IUser } from './../../../models/IUserModel';

interface IProps {
    onUpdate: (user: IUser) => any;
    user: IUser;
}

class AdminCreateForm extends React.Component<IProps, any> {
    private emailChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.user,
            email: context.currentTarget.value,
        });
    };

    private nameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.user,
            name: context.currentTarget.value,
        });
    };

    public render() {
        const { name, email } = this.props.user;

        return (
            <>
                <Field>
                    <Control expanded={true} iconLeft={true}>
                        <Input
                            type="string"
                            placeholder="Name"
                            value={name}
                            onChange={this.nameChange}
                        />
                        <Icon size="small" align="left">
                            <i className="fa fa-user" />
                        </Icon>
                    </Control>
                </Field>
                <Field>
                    <Control expanded={true} iconLeft={true}>
                        <Input
                            type="email"
                            placeholder="E-Mail"
                            value={email}
                            onChange={this.emailChange}
                        />
                        <Icon size="small" align="left">
                            <i className="fa fa-envelope" />
                        </Icon>
                    </Control>
                </Field>
            </>
        );
    }
}

export default AdminCreateForm;
