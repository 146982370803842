import axios from 'axios';
import { Button, Field, Icon, Label, Table } from 'rbx';
import * as React from 'react';
import Dropzone from 'react-dropzone';

import { getImageUri } from '../../api/s3proxy';
import { notification } from '../../utils/notification';
import { getAuthTokenForHTTP } from './../../api/authorization';
import { API_URI } from './../../api/connection';
import { IBasketFileModel } from './../../models/IBasketModel';
import IsAdminQuery from './../auth/IsAdminQuery';
import Loading from './../layout/Loading';
import BasketFileDeleteMutation from './queries/BasketFileDeleteMutation';
import BasketFilesQuery from './queries/BasketFilesQuery';

interface IProps {
    basketId: string;
}

interface IState {
    uploading: boolean;
    forceRerenderKey: number;
    isQuote: boolean;
    file?: File;
    s3ProxyPath?: string;
}

export default class BasketFiles extends React.Component<IProps, IState> {
    public state: IState = { uploading: false, forceRerenderKey: -1, isQuote: false };

    public componentDidMount() {
        getImageUri().then((imageUri: string) => {
            this.setState({ s3ProxyPath: imageUri });
        });
    }

    private onError = (): void => {
        notification.error('File upload error');
        this.setState({ uploading: false });
    };

    private onCompleted = (): void => {
        notification.success('Successfully uploaded file');
        this.setState({ uploading: false, forceRerenderKey: -1 * this.state.forceRerenderKey });
    };

    private getCustomerFiles = (files: IBasketFileModel[]): IBasketFileModel[] | null => {
        if (files && files.length) {
            return files.filter(file => !file.isQuote);
        }
        return null;
    };

    private getAdminFiles = (files: IBasketFileModel[]): IBasketFileModel[] | null => {
        if (files && files.length) {
            return files.filter(file => file.isQuote);
        }
        return null;
    };

    private renderDeleteFile = (file: IBasketFileModel) => () => {
        return <BasketFileDeleteMutation basketId={this.props.basketId} file={file} />;
    };

    private renderFiles = (files: IBasketFileModel[]) => {
        const { s3ProxyPath } = this.state;

        return files.map((file, index) => (
            <Table.Row key={index}>
                <Table.Cell className="filename">{file.filename}</Table.Cell>
                <Table.Cell className="action">
                    <Button.Group>
                        <Button
                            as="a"
                            size="small"
                            href={`${s3ProxyPath}${encodeURIComponent(file.s3Key)}&download=true`}
                        >
                            <Icon>
                                <i className="fa fa-download" />
                            </Icon>
                        </Button>
                        {file.isQuote ? (
                            <IsAdminQuery renderAdmin={this.renderDeleteFile(file)} />
                        ) : (
                            <>{this.renderDeleteFile(file)()}</>
                        )}
                    </Button.Group>
                </Table.Cell>
            </Table.Row>
        ));
    };

    private renderFileTable = (files: IBasketFileModel[]): JSX.Element => {
        const userFiles = this.getCustomerFiles(files);
        const lindeFiles = this.getAdminFiles(files);

        return (
            <Table fullwidth={true} className="files-table">
                <Table.Body>
                    <Table.Row>
                        <Table.Cell colSpan="2">
                            <strong>Attached Documents</strong>
                        </Table.Cell>
                    </Table.Row>
                    {userFiles && this.renderFiles(userFiles)}
                    <Table.Row>
                        <Table.Cell colSpan="2">
                            <strong>Quotes</strong>
                        </Table.Cell>
                    </Table.Row>
                    {lindeFiles && this.renderFiles(lindeFiles)}
                </Table.Body>
            </Table>
        );
    };

    private uploadFile = async (file: File, isQuote: boolean) => {
        const filename = file.name;
        const token = await getAuthTokenForHTTP();

        const data = new FormData();

        data.set('basketId', this.props.basketId);
        data.set('filename', filename);
        data.set('isQuote', `${isQuote}`);
        data.append('file', file, filename);

        axios
            .post(`${API_URI}/api/s3-proxy/basket`, data, {
                headers: { authorization: `${token ? `bearer ${token}` : ''}` },
            })
            .then(() => this.onCompleted())
            .catch(() => this.onError());
    };

    private onDrop = (acceptedFiles: File[]) => {
        const { isQuote } = this.state;

        if (!acceptedFiles || acceptedFiles.length < 1) {
            return;
        }

        const file = acceptedFiles[0];
        this.setState(
            () => ({ uploading: true }),
            () => this.uploadFile(file, isQuote),
        );
    };

    private toggleQuote = (): void => {
        this.setState({ isQuote: !this.state.isQuote });
    };

    private renderDropSwitch = (): JSX.Element => {
        const { isQuote } = this.state;

        return (
            <Button.Group>
                <Button
                    selected={!isQuote}
                    color={!isQuote ? 'primary' : ''}
                    onClick={this.toggleQuote}
                >
                    Upload attachment
                </Button>
                <Button
                    selected={isQuote}
                    color={isQuote ? 'primary' : ''}
                    onClick={this.toggleQuote}
                >
                    Upload quote
                </Button>
            </Button.Group>
        );
    };

    private renderDropzone = (): JSX.Element => {
        const { uploading } = this.state;

        return (
            <>
                <IsAdminQuery renderAdmin={this.renderDropSwitch} />

                <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone">
                            {uploading && <Loading />}
                            {!uploading && (
                                <>
                                    <i className="drop-icon fa fa-cloud-upload" />
                                    <p>Drag/drop or click here to upload a file.</p>
                                    <input {...getInputProps()} />
                                </>
                            )}
                        </div>
                    )}
                </Dropzone>
            </>
        );
    };

    public render() {
        const { basketId } = this.props;
        const { forceRerenderKey } = this.state;

        return (
            <>
                <Field>
                    <Label>Supporting documentation</Label>
                    {this.renderDropzone()}

                    <BasketFilesQuery
                        key={forceRerenderKey}
                        renderFetchedFiles={this.renderFileTable}
                        basketId={basketId}
                    />
                </Field>
            </>
        );
    }
}
