import gql from 'graphql-tag';

export const ONBOARDING_GETINDEXABLEFILESFORPLANT_QUERY = gql`
    query getIndexableFilesForPlant($plantS3Folder: String!) {
        getIndexableFilesForPlant(plantS3Folder: $plantS3Folder) {
            # number of indexables files in s3
            s3FileCount
            # array
            indexables {
                indexedCount
                indexableCount
                ignoredCount
                errorCount
                # e.g.  green, yellow, red,  spacy, sap_po
                name
                #  csv for spacy and sap_po, all other s3
                type
                # contains the elastic quer to use for indexing (only for s3 type)
                query
            }
            partsCsv {
                indexableCount
                indexedCount
            }
            documentumCsvExists
        }
    }
`;

export const ONBOARDING_COUNTDOCUMENTILESFORPLANT_QUERY = gql`
    query countDocumentFilesForPlant($plantS3Folder: String!) {
        countDocumentFilesForPlant(plantS3Folder: $plantS3Folder)
    }
`;

export const ONBOARDING_SYNCDOCUMENTS_MUTATION = gql`
    mutation syncDocumentsFilesToSpindex($plantS3Folder: String!) {
        syncDocumentsFilesToSpindex(plantS3Folder: $plantS3Folder)
    }
`;

export const COUNT_COMPLETED_PAGES_FOR_PLANT = gql`
    query countIndexedPagesForPlant($plantId: String!) {
        countIndexedPagesForPlant(plantId: $plantId)
    }
`;

export const REINDEX_COMPLETED_PAGES_FOR_PLANT = gql`
    mutation reindexCompletedPagesForPlant(
        $plantId: String!
        $indexFileOptions: AdvancedIndexingOptions!
    ) {
        reindexCompletedPagesForPlant(plantId: $plantId, indexFileOptions: $indexFileOptions)
    }
`;

export const IMPORT_FROM_PARTS_CSV_MUTATION = gql`
    mutation importFromPartsCsv($plantId: String!) {
        importFromPartsCsv(plantId: $plantId)
    }
`;

export const INDEX_DOCUMENTUM_DOCUMENTS_CSV = gql`
    mutation indexDocumentumDocumentsCsv($plantId: String!) {
        indexDocumentumDocumentsCsv(plantId: $plantId) {
            numCsvRows
            numDocuments
        }
    }
`;

export const ONBOARDING_PUBLISHFORINDEXING_MUTATION = gql`
    mutation publishForIndexing($indexFileOptions: IndexFileOptions!) {
        publishForIndexing(indexFileOptions: $indexFileOptions)
    }
`;
