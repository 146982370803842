import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { getToken, logout } from './../../api/authorization';
import UserDetailQuery from './../../components/user/queries/UserDetailQuery';
import IUserModel from './../../models/IUserModel';
import Routing from './../../Routing';

interface IState {
    loggedout: boolean;
}

export default class LogoutContainer extends React.Component<any, any> {
    public state: IState = { loggedout: false };

    private handleLogout = () => {
        this.setState({ loggedout: true });
        logout();
    };

    private renderLogout = () => {
        return (
            <Link to="" onClick={this.handleLogout} data-cy="logoutContainer-link">
                Logout
            </Link>
        );
    };

    private onUserDetailQueryResult = (user: IUserModel) => {
        if (!user) {
            this.setState({ loggedout: true });
        }
    };

    public render = (): JSX.Element => {
        const idUser = getToken().id;

        if (!idUser || this.state.loggedout) {
            return <Redirect to={Routing.AUTH_LOGIN.route} />;
        }

        return (
            <>
                {idUser && (
                    <UserDetailQuery
                        onResult={this.onUserDetailQueryResult}
                        id={idUser}
                        renderFetchedUserData={this.renderLogout}
                    />
                )}
            </>
        );
    };
}
