import { Box, Button, Column, Delete, Level, Modal } from 'rbx';
import * as React from 'react';

import ModalContainer from '../ui/ModalContainer';
import PageHeader from './../../components/layout/PageHeader';
import Accordion from './../../components/ui/accordion/Accordion';
import FormField from './../../components/ui/FormField';

interface IState {
    showEditPart?: boolean;
    showDocument?: boolean;
    partsMock: string[];
}

export default class DevEditTag extends React.Component<any, IState> {
    public state: IState = {
        showEditPart: true,
        showDocument: false,
        partsMock: ['Part Name One', 'Part Name Two', 'Part Name Three'],
    };

    private updateDetails = () => {
        // console.log('Update functionality goes here...');
    };

    private toggleEditPart = () => {
        this.setState({ showEditPart: !this.state.showEditPart });
    };

    private renderEditPart = () => {
        return (
            <ModalContainer isOpen={true} onRequestClose={this.toggleEditPart}>
                <Modal.Background />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Edit Part [[PART NO]]</Modal.Card.Title>
                        <Delete onClick={this.toggleEditPart} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <FormField
                            horizontal={false}
                            label="Tag no."
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Designation"
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Linde Ident no."
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Manufact. Part-no."
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Material"
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Unit of measurement"
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Client No."
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Drawing No."
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Sect.drawing item-no."
                            onChange={this.updateDetails}
                        />
                        <FormField
                            horizontal={false}
                            label="Qty. installed per Unit"
                            onChange={this.updateDetails}
                        />
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button onClick={this.toggleEditPart}>Cancel</Button>
                        <Button color="primary" onClick={this.toggleEditPart}>
                            Save
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };

    private save = () => {
        // console.log('The save function saves all changes to tag/parts')
    };

    private addPart = () => {
        /*
            To illustrate the functionlity in this example we add the part, then immediately open the edit view.
            In the final version we want to open the edit view, but only save the part on the save action.
        */

        const { partsMock } = this.state;
        partsMock.push('NEW PART ' + partsMock.length);
        this.setState({
            partsMock,
            showEditPart: true,
        });
    };

    public render() {
        const { showEditPart, partsMock } = this.state;

        return (
            <>
                <PageHeader>Edit Tag [[Tag Name]]</PageHeader>

                <Column.Group>
                    {/*
                     *** First Column: Basic tag details ***
                     */}

                    <Column narrow={true} className="tag-details">
                        <Accordion title="Tag Details">
                            <FormField label="Tag Number" onChange={this.updateDetails} />
                            <FormField label="Designation" onChange={this.updateDetails} />
                            <FormField label="Client No." onChange={this.updateDetails} />
                            <FormField label="Linde Ident No." onChange={this.updateDetails} />
                            <FormField label="Type/Model No." onChange={this.updateDetails} />
                            <FormField label="Spec No." onChange={this.updateDetails} />
                            <FormField label="Serial No." onChange={this.updateDetails} />
                            <FormField label="Drawing No." onChange={this.updateDetails} />
                            <FormField label="Original Order No." onChange={this.updateDetails} />
                            <hr />
                            <Button color="primary" onClick={this.save}>
                                Save
                            </Button>
                        </Accordion>

                        <Accordion title="Parts">
                            {partsMock.map((part, index) => {
                                return (
                                    <Level key={index}>
                                        <Level.Item align="left">
                                            <strong>{part}</strong>
                                        </Level.Item>
                                        <Level.Item align="right">
                                            <Button
                                                color="primary"
                                                outlined={true}
                                                onClick={this.toggleEditPart}
                                            >
                                                Edit
                                            </Button>
                                        </Level.Item>
                                    </Level>
                                );
                            })}
                            <Button color="info" onClick={this.addPart}>
                                Add part
                            </Button>
                        </Accordion>

                        <Accordion title="Documents">
                            <p>List of related documents.</p>
                            <p>Open in docviewer on click.</p>
                        </Accordion>
                    </Column>

                    {/*
                     *** Last Column: Search and docuemtn viewer ***
                     */}

                    <Column>
                        <Box>
                            <FormField label="Search" onChange={this.updateDetails} />
                            <p>
                                Search results here. When clicked the document viewer with the
                                relevant document will open.
                            </p>
                        </Box>
                    </Column>
                </Column.Group>

                {showEditPart && this.renderEditPart()}
            </>
        );
    }
}
