import * as React from 'react';
import { Query } from 'react-apollo';

import Error from './../../components/layout/Error';
import Loading from './../../components/layout/Loading';

export interface ISorting {
    field: string;
    direction: string;
}

export interface IGraphQLListProps {
    offset: number;
    limit: number;
    filter?: any;
    sortBy?: ISorting;
    fetchPolicy?: any;
    query?: any;
    variables?: any;
    onResult?: (returnData: any) => void;
    onResultDataProp?: string;
    hideLoadingIndicator?: boolean;
}

interface IProps extends IGraphQLListProps {
    dataProp: string;
    query: any;
    renderFetchedList: (
        fetchedData: any[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => JSX.Element;
}

export default class ListQueryContainer extends React.Component<IProps, any> {
    private onCompleted = (data: any) => {
        const { dataProp, onResult, onResultDataProp } = this.props;
        if (onResult) {
            const requestedData = onResultDataProp
                ? data[dataProp][onResultDataProp]
                : data[dataProp];
            onResult(requestedData);
        }
    };

    public render() {
        const {
            renderFetchedList,
            offset,
            limit,
            sortBy,
            query,
            dataProp,
            fetchPolicy,
            filter,
            variables,
            hideLoadingIndicator,
        } = this.props;
        const queryVariables: any = { ...variables, offset, limit };

        if (sortBy) {
            queryVariables.order = { by: sortBy.field, direction: sortBy.direction };
        }

        if (filter) {
            queryVariables.filter = filter;
        }

        return (
            <Query
                query={query}
                variables={queryVariables}
                fetchPolicy={fetchPolicy || 'network-only'}
                onCompleted={this.onCompleted}
            >
                {({ data, loading, error }) => {
                    if (loading && !(data && data[dataProp])) {
                        return !hideLoadingIndicator && <Loading />;
                    }
                    if (error) {
                        return <Error>Error fetching data...</Error>;
                    }

                    const {
                        data: fetchedData,
                        totalCount,
                        limit: fetchedLimit,
                        offset: fetchedOffset,
                    }: {
                        data: any[];
                        totalCount: number;
                        limit: number;
                        offset: number;
                    } = data[dataProp];
                    return renderFetchedList(fetchedData, totalCount, fetchedLimit, fetchedOffset);
                }}
            </Query>
        );
    }
}
