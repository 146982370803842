import classNames from 'classnames';
import * as _ from 'lodash';
import { Checkbox } from 'rbx';
import * as React from 'react';

interface IProps {
    nodes: IFolderNodeFlat[];
    folderPaths: string[];
    onChange: (folderPaths: string[]) => void;
}

interface IFolderNodeFlat {
    id: number;
    name: string;
    childIds: number[];
    root: boolean;
}

export interface IFolderNode {
    id: number;
    name: string;
    parent: IFolderNode;
    children: IFolderNode[];
    active?: boolean;
    expanded?: boolean;
    checked?: boolean;
    indeterminate?: boolean;
    path?: string;
}

interface IState {
    nodes: IFolderNode[];
}

const resetState = {
    nodes: [],
};

export default class FolderSelector extends React.Component<IProps, IState> {
    public state: IState = { ...resetState };
    public flatToNestedHelper = new FlatToNestedHelper();
    public nodeHelper = new NodeHelper();

    public componentDidMount = () => {
        const nodes = this.flatToNestedHelper.convert(this.props.nodes);
        this.nodeHelper.checkFolderPaths(nodes, this.props.folderPaths);
        this.setState({ nodes });
    };

    public render() {
        return (
            <>
                {!this.state.nodes.length && (
                    <div>
                        Cannot display folders, because there are no indexed pages for this plant
                        yet.
                    </div>
                )}
                {this.state.nodes.length > 0 && (
                    <div className="flex-row">{this.renderNodes(this.state.nodes)}</div>
                )}
            </>
        );
    }

    private renderNodes(nodes: IFolderNode[]) {
        const activeNode = nodes.find(node => node.expanded);
        return (
            <>
                <div className="flex-column">
                    {nodes.map(node => {
                        return this.renderNode(node);
                    })}
                </div>
                {activeNode && this.renderNodes(activeNode.children)}
            </>
        );
    }

    private renderNode(node: IFolderNode) {
        const itemClassNames = classNames({ 'folder-node-active': node.active });
        return (
            <div
                key={node.id}
                className={'folder-node ' + itemClassNames}
                onClick={this.exploreNode(node)}
                data-testid={'folder-div-' + node.id}
            >
                <Checkbox
                    checked={node.checked}
                    onChange={this.changeCheckNode(node)}
                    data-testid={'folder-checkbox-' + node.id}
                    ref={el => el && ((el as any).indeterminate = node.indeterminate)}
                />
                <i className="fa fa-folder" />
                &nbsp;
                {node.name}
            </div>
        );
    }

    private exploreNode(node: IFolderNode) {
        return e => {
            if (e.target.checked !== undefined && e.target.checked !== node.checked) {
                // When this event is triggered, it could also be that the checkbox event
                // will also be triggered afterwards.
                // If it looks like that the checkbox was changed, only run the checkbox event.
                return;
            }
            this.nodeHelper.onExplore(this.state.nodes, node);
            this.setState({ nodes: this.state.nodes });
        };
    }

    private changeCheckNode(node: IFolderNode) {
        return (context: React.SyntheticEvent<HTMLInputElement>) => {
            const { checked } = context.currentTarget;
            this.nodeHelper.onChange(node, checked);
            this.setState({ nodes: this.state.nodes });
            const folderPaths = this.nodeHelper.generateFolderPaths(this.state.nodes);
            let newFolderPaths = _.clone(this.props.folderPaths);
            for (const path of folderPaths) {
                if (newFolderPaths.indexOf(path) === -1) {
                    newFolderPaths.push(path);
                }
            }

            newFolderPaths = newFolderPaths.filter(path => folderPaths.indexOf(path) !== -1);

            this.props.onChange(newFolderPaths);
        };
    }
}

class NodeHelper {
    public checkFolderPaths(nodes: IFolderNode[], folderPaths: string[]): void {
        for (const [index, folderPath] of folderPaths.entries()) {
            this.checkFolderPath(nodes, folderPath, index + 1 === folderPaths.length);
        }
    }

    private checkFolderPath(nodes: IFolderNode[], folderPath: string, isLastPath): IFolderNode {
        const names = folderPath.split('/');
        const node = nodes.find(n => n.name === names[0]);
        if (!node) {
            return null;
        }
        if (names.length === 1) {
            if (isLastPath) {
                this.onChange(node, true, true, true);
            } else {
                this.onChange(node, true, false);
            }
            return node;
        }
        return this.checkFolderPath(
            node.children,
            names.slice(1, names.length).join('/'),
            isLastPath,
        );
    }

    public onExplore(nodes: IFolderNode[], node: IFolderNode): void {
        if (node.parent) {
            node.parent.children.forEach(child => {
                child.active = false;
                child.expanded = false;
            });
        } else {
            nodes.forEach(rootNode => {
                rootNode.active = false;
                rootNode.expanded = false;
            });
        }
        node.active = true;
        node.expanded = true;
        node.children.forEach(child => {
            child.active = false;
        });
    }

    public onChange(
        node: IFolderNode,
        checked: boolean,
        active?: boolean,
        expanded?: boolean,
    ): void {
        this.checkNodeDownwards(node, checked);
        this.updateParents(node, active, expanded);
    }

    private checkNodeDownwards(node: IFolderNode, checked: boolean): void {
        node.checked = checked;
        node.indeterminate = false;

        for (const child of node.children) {
            this.checkNodeDownwards(child, checked);
        }
    }

    private updateParents(node: IFolderNode, active?: boolean, expanded?: boolean): void {
        let parent = node.parent;
        while (parent) {
            parent.checked = parent.children.every(child => child.checked);
            if (expanded !== undefined) {
                parent.expanded = expanded;
                parent.active = active;
            }
            parent.indeterminate =
                !parent.checked &&
                parent.children.some(child => child.checked || child.indeterminate);
            parent = parent.parent;
        }
    }

    public generateFolderPaths(nodes: IFolderNode[]): string[] {
        let folderPaths = [];
        for (const node of nodes) {
            folderPaths = folderPaths.concat(this.getAllFolderPaths(node));
        }
        return folderPaths;
    }

    private getAllFolderPaths(node: IFolderNode): string[] {
        if (node.checked) {
            return [node.path];
        }
        let folderPaths = [];
        for (const child of node.children) {
            folderPaths = folderPaths.concat(this.getAllFolderPaths(child));
        }
        return folderPaths;
    }
}

class FlatToNestedHelper {
    public convert(flatNodes: IFolderNodeFlat[]): IFolderNode[] {
        const mapChildren = childIds => {
            return _.sortBy(childIds.map(mapChild), 'name');
        };
        const mapChild = childId => {
            const childNode = flatNodes.find(flatNode => flatNode.id === childId);
            const children = mapChildren(childNode.childIds);
            return {
                id: childNode.id,
                name: childNode.name,
                parent: null,
                children,
            };
        };
        const rootNodes = flatNodes
            .filter(flatNode => flatNode.root === true)
            .map(flatNode => ({
                id: flatNode.id,
                name: flatNode.name,
                parent: null,
                children: mapChildren(flatNode.childIds),
            }));
        for (const rootNode of rootNodes) {
            this.linkParentsToChildren(rootNode);
        }
        for (const rootNode of rootNodes) {
            this.initNode(rootNode);
        }
        return rootNodes;
    }

    private linkParentsToChildren(node: IFolderNode): void {
        for (const childNode of node.children) {
            childNode.parent = node;
            this.linkParentsToChildren(childNode);
        }
    }

    private initNode(node: IFolderNode): IFolderNode {
        node.checked = node.active = node.indeterminate = false;
        node.path = node.parent ? `${node.parent.path}/${node.name}` : node.name;
        for (const child of node.children) {
            this.initNode(child);
        }
        return node;
    }
}
