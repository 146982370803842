import classnames from 'classnames';
import * as _ from 'lodash';
import { Section } from 'rbx';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getImageUri } from './../../api/s3proxy';
import { buildPath, getUrlParams, IUrl } from './../../utils/queryString';
import SearchResultTagGroup from './SearchResultTagGroup';

interface IProps extends RouteComponentProps {
    item: any;
    title: string;
    cy: string;
    hitCount?: number;
}

interface IState {
    s3proxyPath?: string;
    pathinfo: IUrl;
}

class SearchResultDefaultItem extends React.Component<IProps, any> {
    public state: IState = {
        pathinfo: getUrlParams(this.props.location),
    };

    public static getDerivedStateFromProps = (props: any, state: IState) => {
        const pathinfo = getUrlParams(props.location);

        return {
            ...state,
            pathinfo,
        };
    };

    public componentDidMount() {
        getImageUri().then((imageUri: string) => {
            this.setState((prevState: IState) => ({
                ...prevState,
                s3proxyPath: imageUri,
            }));
        });
    }

    private selectDocument = (filekey: string, page: number) => (): void => {
        const { pathinfo } = this.state;
        pathinfo.params.docId = `${filekey}_${page}`;
        pathinfo.params.tagId = undefined;

        const path = buildPath(pathinfo);

        this.props.history.push(path);
    };

    private renderImage = () => {
        const { item, cy } = this.props;
        const { s3proxyPath } = this.state;
        const previewImagePath = `${s3proxyPath}${item._source.image.imageKeyS}`;

        if (s3proxyPath) {
            return <img src={previewImagePath} data-cy={cy} alt="" />;
        }

        return (
            <Section>
                <i className="fa fa-file-image fa-2x" data-cy={cy} />
            </Section>
        );
    };

    public render = () => {
        const { item, title, hitCount } = this.props;
        const { pathinfo } = this.state;

        const filekey: string = item._source.meta.original_filekey;
        const page: number = item._source.meta.page;
        const pageCount = item._source.meta.pageCount;

        const pageStr = `Showing page ${page} of ${pageCount}`;

        let fullText = item._source.content.fullText;
        if (item.highlight && item.highlight['content.fullText']) {
            fullText = item.highlight['content.fullText'];
        }

        let docKey;
        if (pathinfo.params.docId) {
            docKey = pathinfo.params.docId.replace(/(.*)_\d+/, '$1');
        }

        const searchResultItemClasses = classnames({
            'search-result-item': true,
            'is-active': docKey === filekey,
        });

        return (
            <div className={searchResultItemClasses}>
                <div className="result-body">
                    <div className="result-image" onClick={this.selectDocument(filekey, page)}>
                        {this.renderImage()}
                        {hitCount && (
                            <>
                                <small>
                                    Found on {hitCount} page{hitCount > 1 ? 's' : ''}
                                </small>
                                <br />
                            </>
                        )}
                        <small>{pageStr}</small>
                    </div>

                    <div className="result-details">
                        <div className="result-title" onClick={this.selectDocument(filekey, page)}>
                            {title}
                        </div>

                        <SearchResultTagGroup item={item} />

                        <div className="result-path">{item._source.meta.original_filename}</div>
                        <div
                            className="result-text"
                            dangerouslySetInnerHTML={{
                                __html: _.truncate(fullText, {
                                    length: 240,
                                }),
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };
}

export default withRouter(SearchResultDefaultItem);
