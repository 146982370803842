import {
    Button,
    Column,
    Content,
    Control,
    Delete,
    Field,
    Label,
    Level,
    Table,
    Textarea,
} from 'rbx';
import * as React from 'react';

import DatePicker from '../../ui/datePicker/DatePicker';
import FormField from '../../ui/FormField';
import Pane from '../../ui/Pane';

export default class DevBasketContainer extends React.Component {
    private addLineItem = () => {
        // console.log('ADD LINE ITEM');
    };

    private textChange = () => {
        // console.log('Text update');
    };

    private renderLineItems = () => {
        const lineItems = [
            {
                type: 'Spare parts',
                ref: 'QICA.43.101',
                title: 'Pump - Sulzer - SZ131313',
                description: 'Pump needs to be replaced',
                quantity: 1,
            },
            {
                type: 'Service',
                ref: '',
                title: 'Provide quote for turnover support',
                description: 'Need help figuring things out',
                quantity: 1,
            },
            {
                type: 'Spare parts',
                ref: 'BP 4501.66',
                title: 'Main Air Compressor',
                description: 'Gaskets',
                quantity: 2,
            },
            {
                type: 'Service and Spares',
                ref: 'BP 4501.66',
                title: 'Compressor valve',
                description: 'Service compressor valve',
                quantity: 1,
            },
        ];

        return (
            <Table fullwidth={true} className="last-right">
                <Table.Head>
                    <Table.Row>
                        <Table.Cell>Request Type</Table.Cell>
                        <Table.Cell>Reference</Table.Cell>
                        <Table.Cell>Details</Table.Cell>
                        <Table.Cell>Quantity</Table.Cell>
                        <Table.Cell />
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {lineItems.map((item, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>{item.type}</Table.Cell>
                                <Table.Cell>{item.ref}</Table.Cell>
                                <Table.Cell>
                                    <strong>{item.title}</strong>
                                    <br />
                                    {item.description}
                                </Table.Cell>
                                <Table.Cell>{item.quantity}</Table.Cell>
                                <Table.Cell>
                                    <Button size="small" text={true}>
                                        Edit
                                    </Button>
                                    <br />
                                    <Button size="small" text={true}>
                                        Delete
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    };

    private renderFileTable = () => {
        const files = [
            '20180405_Template_GDPR_DPA_controller_EN.DOCX',
            '20180405_Template_GDPR_DPA_processor_EN.DOCX',
            '20180612-Angebot-DVS-Webtracking.pptx',
            '20180619-Angebot-MAN-CampaignTooling.pptx',
            '20190320_Papierkram_Kündigung.pdf',
            'ACFrOgBrft0J824ClxIaqq9vYFNefwMAiIGGmWpK5_RlYCiPN-ggWG_wuIgSVizfBkpDfRpza2BMPLtPKb05zcTm3TjDeBtou-iNAV4AWSqgMlkOoCtP-q3eBPI9haU=.pdf',
            'Add To Basket Flow.pdf',
        ];

        return (
            <Table fullwidth={true} className="files-table">
                <Table.Body>
                    {files.map((file, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell className="filename">{file}</Table.Cell>
                                <Table.Cell className="action">
                                    <Delete />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    };

    public render() {
        return (
            <div className="basket">
                <Column.Group>
                    {/* Left-hand pane */}
                    <Column size="two-thirds">
                        <Pane title="Basket items">
                            {this.renderLineItems()}
                            <hr />
                            <Level>
                                <Level.Item align="left" />
                                <Level.Item align="right">
                                    <Button color="primary" onClick={this.addLineItem}>
                                        Add line item
                                    </Button>
                                </Level.Item>
                            </Level>
                        </Pane>
                    </Column>

                    {/* Right-hand pane */}
                    <Column clipped={true}>
                        <Pane title="Basket details">
                            <FormField
                                onChange={this.textChange}
                                label="Name"
                                placeholder="Name input"
                            />
                            <Field>
                                <Label>Description</Label>
                                <Control expanded={true}>
                                    <Textarea placeholder="Description" />
                                </Control>
                            </Field>
                            <Field>
                                <Label>Delivery Date</Label>
                                <DatePicker />
                            </Field>
                            <FormField
                                onChange={this.textChange}
                                label="Shipping terms"
                                placeholder="Dropdown here"
                            />
                            <Field>
                                <Label>Supporting documentation</Label>
                                <p>Add file picker here, and a list of uploaded files in a table</p>
                            </Field>

                            {this.renderFileTable()}

                            <Content>
                                <Button color="primary">Request Quotation</Button>
                            </Content>
                        </Pane>
                    </Column>
                </Column.Group>
            </div>
        );
    }
}

// add withMainNav
