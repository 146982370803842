import * as React from 'react';

import ILineItemModel from '../../../models/ILineItemModel';
import { LINE_ITEM_QUERY } from './../../../api/GraphQLQueries/LineItem';
import DetailQueryContainer from './../../../containers/common/DetailQueryContainer';

interface IProps {
    id: number;
    renderFetchedItemData: (basket: ILineItemModel) => JSX.Element;
}

export default class LineItemDetailQuery extends React.Component<IProps, any> {
    public render() {
        const { id, renderFetchedItemData } = this.props;

        const variables = { id };

        return (
            <DetailQueryContainer
                query={LINE_ITEM_QUERY}
                variables={variables}
                dataProp="lineItem"
                renderFetchedData={renderFetchedItemData}
            />
        );
    }
}
