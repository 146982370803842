import { Button, Control, Delete, Field, Icon, Input, Modal, Tag } from 'rbx';
import * as React from 'react';
import { Query } from 'react-apollo';

import { FOLDER_STRUCTURE_QUERY } from '../../api/GraphQLQueries/Elastic';
import Loading from '../layout/Loading';
import ModalContainer from '../ui/ModalContainer';
import FolderSelector from './FolderSelector';

interface IProps {
    plantId: string;
    folderPaths: string[];
    onChange: (folderPaths: string[]) => void;
}

interface IState {
    open: boolean;
    folderPaths: string[];
}

export default class FolderSelectorModal extends React.Component<IProps, IState> {
    public state: IState = {
        open: false,
        folderPaths: this.props.folderPaths,
    };

    private closeModal = (folderPaths: string[]): void => {
        this.setState({ open: false, folderPaths });
        this.props.onChange(folderPaths);
    };

    private openModal = (e: React.SyntheticEvent) => {
        e.preventDefault();
        this.setState({ open: true });
    };

    private onFoldersChange = (folderPaths: string[]) => {
        this.setState({ folderPaths });
    };

    private submit = (): void => {
        this.closeModal(this.state.folderPaths);
    };

    private cancel = (): void => {
        this.closeModal(this.props.folderPaths);
    };

    private removePath = (path: string) => () => {
        this.setState(
            (prevState: IState) => ({
                folderPaths: prevState.folderPaths.filter(e => e !== path),
            }),
            () => this.props.onChange(this.state.folderPaths),
        );
    };

    private renderFolderPaths = () => {
        const { folderPaths } = this.props;

        return folderPaths.map(path => {
            return (
                <Tag.Group gapless={true} key={path}>
                    <Tag title={path} color="primary">
                        {path}
                    </Tag>
                    <Tag delete={true} onClick={this.removePath(path)} />
                </Tag.Group>
            );
        });
    };

    public render() {
        const plantId = this.props.plantId;
        const { open, folderPaths } = this.state;
        return (
            <>
                <Field>
                    <Control iconRight={true}>
                        <Input type="text" as={'div'} className={'folder-paths'}>
                            {this.renderFolderPaths()}
                        </Input>
                        <Icon align="right" className={'clickable-icon'} onClick={this.openModal}>
                            <i className="fa fa-folder-plus" />
                        </Icon>
                    </Control>
                </Field>

                <ModalContainer isOpen={open} onRequestClose={this.cancel}>
                    <Modal.Card className="folder-modal-card">
                        <Modal.Card.Head>
                            <Modal.Card.Title>Select Folders</Modal.Card.Title>
                            <Delete onClick={this.cancel} />
                        </Modal.Card.Head>
                        <Modal.Card.Body className="folder-modal-card-body">
                            {open && !plantId && <span>No plant selected</span>}
                            {plantId && plantId && (
                                <Query query={FOLDER_STRUCTURE_QUERY} variables={{ plantId }}>
                                    {({ data, loading, error }) => {
                                        if (loading) {
                                            return <Loading />;
                                        }
                                        if (error) {
                                            return 'Error';
                                        }
                                        return (
                                            <FolderSelector
                                                nodes={data.folderStructureForPlant}
                                                folderPaths={folderPaths}
                                                onChange={this.onFoldersChange}
                                            />
                                        );
                                    }}
                                </Query>
                            )}
                        </Modal.Card.Body>
                        <Modal.Card.Foot align="right">
                            <Button className={'cancel'} onClick={this.cancel}>
                                Cancel
                            </Button>
                            <Button className={'submit'} onClick={this.submit}>
                                Add Folders
                            </Button>
                        </Modal.Card.Foot>
                    </Modal.Card>
                </ModalContainer>
            </>
        );
    }
}
