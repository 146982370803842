import { Button } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { GROUP_ADD_PLANTS_MUTATION } from '../../../api/GraphQLQueries/Group';
import Error from '../../layout/Error';
import Message from '../../layout/Message';

interface IProps {
    groupId?: number | null;
    plantIds: string[];
    onCompleted?: () => void;
}

interface IState {
    error?: boolean;
    success?: boolean;
}

const resetState: IState = { error: false, success: false };

export default class PlantAddToGroupMutation extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private resetState = () => {
        this.setState((prev: IState) => ({ ...prev, ...resetState }));
    };

    private onError = (): void => {
        this.setState(
            (prev: IState) => ({
                ...prev,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prev: IState) => ({
                ...prev,
                error: false,
                success: true,
            }),
            this.resetState,
        );

        if (typeof this.props.onCompleted === 'function') {
            this.props.onCompleted();
        }
    };

    public render() {
        const { error, success } = this.state;
        const { groupId, plantIds } = this.props;

        return (
            <>
                {error && <Error>Plant update error</Error>}
                {success && <Message type="success">Successfully updated plant</Message>}
                <Mutation
                    mutation={GROUP_ADD_PLANTS_MUTATION}
                    variables={{ groupId, plantIds }}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                >
                    {doGroupUpdate => (
                        <Button
                            color="primary"
                            onClick={doGroupUpdate}
                            disabled={!groupId || !plantIds}
                        >
                            Save
                        </Button>
                    )}
                </Mutation>
            </>
        );
    }
}
