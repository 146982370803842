import * as React from 'react';

import { PLANT_QUERY } from '../../../api/GraphQLQueries/Plant';
import DetailQueryContainer from '../../../containers/common/DetailQueryContainer';
import IPlantModel from '../../../models/IPlantModel';

interface IProps {
    id: string;
    renderFetchedPlantData: (plant: IPlantModel, refetch?: () => any) => JSX.Element;
}

export default class PlantDetailQueryContainer extends React.Component<IProps, any> {
    public render = () => {
        const { id, renderFetchedPlantData } = this.props;

        const variables = { id };

        return (
            <DetailQueryContainer
                query={PLANT_QUERY}
                variables={variables}
                dataProp="plant"
                renderFetchedData={renderFetchedPlantData}
            />
        );
    };
}
