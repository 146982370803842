import * as _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ElasticSearchQuery from '../../containers/elastic/ElasticSearchQueryContainer';
import { ISpindexPageHit, ISpindexPageQueryResult } from '../../models/IElasticModel';
import { default as parseQueryString } from '../../utils/queryString';
import Tracker from '../../utils/Tracker';
import { ES_DOCUMENT_PAGE_QUERY } from './../../api/GraphQLQueries/Elastic';
import { getImageUri } from './../../api/s3proxy';
import { getDocumentQuery } from './../../utils/buildSearchQuery';
import { parseDocumentId } from './../../utils/queryString';
import DocumentViewer from './DocumentViewer';
import IDocumentView from './IDocumentView';

interface IProps extends RouteComponentProps {
    onClose?: () => void;
    actionButtons?: any;
}

interface IState {
    s3ProxyPath?: string;
}

let pageHits;
let pages;

class DocumentHandler extends React.Component<IProps, IState> {
    public state: IState = {};

    public componentDidMount() {
        getImageUri().then((s3ProxyPath: string) => this.setState({ s3ProxyPath }));
        this.trackOpenDocument();
    }

    private trackOpenDocument = () => {
        const docId = parseDocumentId(this.props.location);
        Tracker.trackOpenDocument(docId);
    };

    private convertToIDocumentView = (doc: ISpindexPageHit): IDocumentView => {
        const { s3ProxyPath } = this.state;

        return {
            id: doc._id,
            path: s3ProxyPath ? `${s3ProxyPath}${doc._source.image.imageKeyL}` : '',
            downloadPath: s3ProxyPath
                ? `${s3ProxyPath}${encodeURIComponent(doc._source.meta.original_filekey)}`
                : '',
            title:
                doc._source.content.title ||
                _.get(doc, '_source.spindexDocument.title') ||
                doc._id.replace(/.*\//, ''),
            bookKey: doc._source.meta.original_filekey,
            fullText: doc._source.content.fullText,
            page: doc._source.meta.page,
            pages: doc._source.meta.pageCount,
            words: doc._source.content.words,
            detectedTags: doc._source.content.detectedTags,
            skippedOcr: doc._source.content.skippedOcr,
            mergedOcr: doc._source.content.mergedOcr,
            isTextDocument: doc._source.content.isTextDocument,
            processedDate: doc._source.processedDate,
        };
    };

    private buildEsRequestQuery = () => {
        const docId = parseDocumentId(this.props.location);

        return getDocumentQuery(docId);
    };

    private renderDocument = (page: ISpindexPageQueryResult) => {
        if (!page || !page.data || !page.data.length) {
            return null;
        }

        const doc = this.convertToIDocumentView(page.data[0]);
        pages = doc.pages;
        pageHits = page.pageHits;

        return <DocumentViewer {...this.props} pageHits={page.pageHits} data={doc} />;
    };

    private renderDummy = (): JSX.Element => {
        if (typeof pages !== 'number') {
            return null;
        }

        const { s3ProxyPath } = this.state;

        const docId = parseQueryString(this.props.location, 'docId') as string;

        const match = docId.match(/.*\/([^]+_(\d+))$/);
        if (!match) {
            return null;
        }
        const [, title, page] = match;

        const dummy = {
            id: docId,
            path: '',
            downloadPath: s3ProxyPath ? ' ' : null,
            title,
            bookKey: '',
            page: parseInt(page, 10),
            pages,
            words: [],
        };

        return <DocumentViewer {...this.props} pageHits={pageHits} data={dummy} />;
    };

    public render() {
        const esRequest: string = this.buildEsRequestQuery();
        const search = parseQueryString(this.props.location, 'query') as string;

        return (
            <ElasticSearchQuery
                esRequest={esRequest}
                search={search}
                query={ES_DOCUMENT_PAGE_QUERY}
                onLoading={this.renderDummy}
                renderFetchedSearchResults={this.renderDocument as any}
            />
        );
    }
}

export default withRouter(DocumentHandler);
