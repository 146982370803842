import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { BASKET_LINE_ITEMS_QUERY } from './../../../api/GraphQLQueries/Basket';
import { LINE_ITEM_DELETE_MUTATION } from './../../../api/GraphQLQueries/LineItem';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    id: number;
    basketId: string;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

export default class LineItemDeleteMutation extends React.Component<IProps, IState> {
    public state: IState = {
        ...resetState,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    private deleteLineItem = (doDeleteMutation: MutationFn) => (): void => {
        if (window.confirm(`Are you sure? This action can not be undone.`)) {
            const { id } = this.props;
            doDeleteMutation({ variables: { id } });
        }
    };

    public render = () => {
        const { error, success } = this.state;
        const { basketId } = this.props;

        const refetchQueries = basketId
            ? [{ query: BASKET_LINE_ITEMS_QUERY, variables: { id: basketId } }]
            : [];

        return (
            <>
                {error && <Error>Item delete error</Error>}
                {success && <Message type="success">Successfully deleted item</Message>}
                <Mutation
                    mutation={LINE_ITEM_DELETE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={refetchQueries}
                >
                    {doDelete => (
                        <Button size="small" text={true} onClick={this.deleteLineItem(doDelete)}>
                            Delete
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };
}
