import { Title } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import AuthLayout from '../AuthLayout';
import { FORGOT_PASSWORD_MUTATION } from './../../api/GraphQLQueries/Auth';
import AuthForm from './../../components/auth/AuthForm';
import { t } from './../../utils/translate';

interface IState {
    emailSubmitted: boolean;
}

export default class ForgotPasswordContainer extends React.Component<any, IState> {
    public state: IState = {
        emailSubmitted: false,
    };

    private formSubmit = (doForgotPasswordMutation: any) => (email: string): void => {
        doForgotPasswordMutation({ variables: { email }, fetchPolicy: 'no-cache' });
    };

    private onCompleted = (): void => {
        this.setState({ emailSubmitted: true });
    };

    private onError = (): void => {
        return;
    };

    public render() {
        const { emailSubmitted } = this.state;

        return (
            <AuthLayout>
                <Title size="4">{t().forgot_password}</Title>
                {!emailSubmitted ? (
                    <Mutation
                        mutation={FORGOT_PASSWORD_MUTATION}
                        ignoreResults={true}
                        onCompleted={this.onCompleted}
                        onError={this.onError}
                    >
                        {doForgotPassword => (
                            <AuthForm
                                buttonText={t().forgot_password_button}
                                onSubmit={this.formSubmit(doForgotPassword)}
                                isForgotPassword={true}
                            />
                        )}
                    </Mutation>
                ) : (
                    <div className="content">
                        <p>{t().forgot_password_submitted_text}</p>
                    </div>
                )}
            </AuthLayout>
        );
    }
}
