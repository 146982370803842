import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import 'unorm';

import { client } from './api/connection';
import AuthRoute from './components/auth/AuthRoute';
import PlantActions from './components/plant/PlantActions';
import DevContainer from './containers/_dev/DevContainer';
import PlantOnboardingContainer from './containers/admin/onboarding/PlantOnboardingContainer';
import AcceptInvitationContainer from './containers/auth/AcceptInvitationContainer';
import ForgotPasswordContainer from './containers/auth/ForgotPasswordContainer';
import LoginContainer from './containers/auth/LoginContainer';
import PasswordResetContainer from './containers/auth/PasswordResetContainer';
import BasketListContainer from './containers/BasketListContainer';
import BasketUpdateContainer from './containers/BasketUpdateContainer';
import DashboardContainer from './containers/DashboardContainer';
import GroupListContainer from './containers/GroupListContainer';
import NotFoundContainer from './containers/NotFoundContainer';
import OpsContainer from './containers/OpsContainer';
import PlantListContainer from './containers/PlantListContainer';
import ProfileContainer from './containers/ProfileContainer';
import SearchContainer from './containers/SearchContainer';
import SpacyConverterContainer from './containers/SpacyConverterContainer';
import TagListContainer from './containers/TagListContainer';
import TagUpdateContainer from './containers/TagUpdateContainer';
import UserListContainer from './containers/UserListContainer';
import { GlobalRole } from './models/IUserModel';
import Routing from './Routing';
import Tracker from './utils/Tracker';
class App extends React.Component<any, any> {
    public constructor(props) {
        super(props);
        Tracker.init();
    }

    public render() {
        return (
            <ApolloProvider client={client}>
                <Router>
                    <Switch>
                        {/*  PUBLIC ROUTES  */}
                        <Route
                            exact={true}
                            path={Routing.AUTH_LOGIN.route}
                            component={LoginContainer}
                        />
                        <Route
                            exact={true}
                            path={Routing.AUTH_FORGOT_PASSWORD.route}
                            component={ForgotPasswordContainer}
                        />
                        <Route
                            exact={true}
                            path={Routing.AUTH_RESET_PASSWORD.route}
                            component={PasswordResetContainer}
                        />
                        <Route
                            exact={true}
                            path={Routing.AUTH_ACCEPT_INVITATION.route}
                            component={AcceptInvitationContainer}
                        />

                        {/*  ADMIN ROUTES  */}
                        <AuthRoute
                            exact={true}
                            path={Routing.USER_LIST.route}
                            component={UserListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.USER_DETAILS.route}
                            component={UserListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.USER_CREATE.route}
                            component={UserListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.ADMIN_CREATE.route}
                            component={UserListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.SEARCH_PLANT.route}
                            component={SearchContainer}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.GROUPS.route}
                            component={GroupListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.GROUP_CREATE.route}
                            component={GroupListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.GROUP_DETAILS.route}
                            component={GroupListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANTS.route}
                            component={PlantListContainer}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANT_CREATE.route}
                            component={PlantListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANT_STATS.route}
                            component={PlantListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANT_ACTIONS.route}
                            component={PlantActions}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANT_DETAILS.route}
                            component={PlantListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANT_INDEX_STATUS.route}
                            component={PlantOnboardingContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANT_ONBOARDING.route}
                            component={PlantOnboardingContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PLANT_TAGS.route}
                            component={TagListContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.OPS.route}
                            component={OpsContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.TAG_DETAILS.route}
                            component={TagUpdateContainer}
                            whitelistRole={GlobalRole.linde_admin}
                        />

                        {/*  LOGGED IN ROUTES  */}
                        <AuthRoute
                            exact={true}
                            path={Routing.INDEX.route}
                            component={DashboardContainer}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.BASKETS.route}
                            component={BasketListContainer}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.BASKET_DETAILS.route}
                            component={BasketUpdateContainer}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.PROFILE.route}
                            component={ProfileContainer}
                        />
                        <AuthRoute
                            exact={true}
                            path={Routing.SPACY_CONVERTER.route}
                            component={SpacyConverterContainer}
                        />

                        {/*  DEV  */}
                        <Route exact={true} path="/dev/:subcomponent?" component={DevContainer} />

                        {/*  CATCH  */}
                        <AuthRoute component={NotFoundContainer} />
                    </Switch>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;
