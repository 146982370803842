import { Button } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import {
    BASKET_QUERY,
    BASKET_UPDATE_ARCHIVED_MUTATION,
} from './../../../api/GraphQLQueries/Basket';
import Error from './../../layout/Error';
import Message from './../../layout/Message';

interface IProps {
    basketId: string;
    basketArchived: boolean;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

class BasketArchivedMutation extends React.Component<IProps, IState> {
    public state: IState = {
        ...resetState,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    public render = () => {
        const { error, success } = this.state;
        const { basketId, basketArchived } = this.props;

        const refetchQueries = basketId
            ? [{ query: BASKET_QUERY, variables: { id: basketId } }]
            : [];

        return (
            basketId && (
                <>
                    {error && <Error>Basket archive update error</Error>}
                    {success && <Message type="success">Successfully archived basket</Message>}
                    <Mutation
                        mutation={BASKET_UPDATE_ARCHIVED_MUTATION}
                        onCompleted={this.onCompleted}
                        onError={this.onError}
                        variables={{ id: basketId, archived: !basketArchived }}
                        refetchQueries={refetchQueries}
                    >
                        {doUpdate => (
                            <Button color="primary" onClick={doUpdate}>
                                {basketArchived ? 'Unarchive' : 'Archive'} basket
                            </Button>
                        )}
                    </Mutation>
                </>
            )
        );
    };
}

export default BasketArchivedMutation;
