import gql from 'graphql-tag';

const PLANT_LIST_QUERY = gql`
    query Plants($offset: Int, $limit: Int, $order: [PlantOrder], $filter: PlantFilter) {
        plants(offset: $offset, limit: $limit, order: $order, filter: $filter)
            @connection(key: "plants") {
            data {
                id
                name
                s3Folder
                customerDisplayName
                groups {
                    id
                    name
                    description
                }
            }
            limit
            offset
            totalCount
        }
    }
`;

const PLANT_QUERY = gql`
    query Plant($id: String!) {
        plant(id: $id) {
            id
            name
            s3Folder
            customerDisplayName
            invoiceAddress {
                street
                zip
                city
                country
                responsible
                longitude
                latitude
            }
            deliveryAddress {
                street
                zip
                city
                country
                responsible
                longitude
                latitude
            }
            groups {
                id
                name
                description
            }
        }
    }
`;

const PLANT_SAVE_MUTATION = gql`
    mutation SavePlant($plant: PlantSaveInput!) {
        savePlant(plant: $plant) {
            id
            name
            customerDisplayName
            invoiceAddress {
                street
                zip
                city
                country
                responsible
                latitude
                longitude
            }
            deliveryAddress {
                street
                zip
                city
                country
                responsible
                latitude
                longitude
            }
            groups {
                id
                name
                description
            }
        }
    }
`;

const USER_ROLE_FOR_PLANT = gql`
    query UserRolesForPlant($plantId: String!) {
        userRolesForPlant(plantId: $plantId) {
            user {
                id
                name
                email
            }
            role
        }
    }
`;

const CHECK_FOLDER = gql`
    query checkIfFolderExists($plantS3Folder: String!) {
        checkIfFolderExists(plantS3Folder: $plantS3Folder)
    }
`;

const PLANT_STATS = gql`
    query docCounts($offset: Int, $limit: Int) {
        docCounts(offset: $offset, limit: $limit) {
            data {
                plantId
                documentum
                equipment
                customerDocs
                pnid
                indexStates
            }
            limit
            offset
            totalCount
        }
    }
`;

const PLANT_ACTIONS = gql`
    query plantActions(
        $offset: Int
        $limit: Int
        $order: [PlantActionOrder]
        $filter: PlantActionFilter
    ) {
        plantActions(offset: $offset, limit: $limit, order: $order, filter: $filter) {
            data {
                id
                plant {
                    id
                    name
                }
                action
                createdAt
                runOpts {
                    plantId
                    action
                    forceOcr
                    enabled
                    onlyErrored
                    forceIndex
                    forceSplit
                    dryRun
                }
                createdBy {
                    name
                }
            }
            limit
            offset
            totalCount
        }
    }
`;

export {
    PLANT_LIST_QUERY,
    PLANT_QUERY,
    PLANT_SAVE_MUTATION,
    USER_ROLE_FOR_PLANT,
    CHECK_FOLDER,
    PLANT_STATS,
    PLANT_ACTIONS,
};
