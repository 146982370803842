import * as React from 'react';
import { Query } from 'react-apollo';

import { PARTS_BY_TAG_NUMBER_QUERY } from './../../../api/GraphQLQueries/Tag';
import IPartModel from './../../../models/IPartModel';
import Error from './../../layout/Error';
import Loading from './../../layout/Loading';

interface IProps {
    plantId: string;
    tagNumberAsId?: string;
    renderFetchedPartList: (parts: IPartModel[]) => JSX.Element;
}

export default class PartListQuery extends React.Component<IProps, any> {
    public render = () => {
        const { renderFetchedPartList, plantId, tagNumberAsId } = this.props;

        return (
            <Query query={PARTS_BY_TAG_NUMBER_QUERY} variables={{ plantId, tagNumberAsId }}>
                {({ data, loading, error }) => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (error) {
                        return <Error>Error fetching parts...</Error>;
                    }

                    const {
                        tagByNumber: { parts },
                    } = data;

                    return renderFetchedPartList(parts);
                }}
            </Query>
        );
    };
}
