import classNames from 'classnames';
import * as _ from 'lodash';
import { Button, Pagination } from 'rbx';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ILineItemModel, { IWord } from '../../models/ILineItemModel';
import { storage } from '../../utils/storage';
import Tracker from '../../utils/Tracker';
import LineItemDetailQuery from '../basket/queries/LineItemDetailQuery';
import Icon from '../ui/Icon';
import Paging from '../ui/paging/Paging';
import Popover from '../ui/Popover';
import { buildPath, getUrlParams, IUrl } from './../../utils/queryString';
import AddLineItemToBasketModal from './../basket/AddLineItemToBasketModal';
import DocumentViewHandler from './DocumentViewHandler';
import IDocumentView from './IDocumentView';

interface IRouteParams {
    plantId?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    data: IDocumentView;
    onClose?: () => void;
    actionButtons?: any;
    pageHits?: number[];
}

interface IState {
    showPopover: boolean;
    showAddLineItem: boolean;
    popoverTitle?: string;
    popoverParent?: HTMLElement;
    pathinfo: IUrl;
    // TODO: pat - clickedWord: IWord;
    pageClick: IWord;
    isPanning: boolean;
}

class DocumentViewer extends React.Component<IProps, IState> {
    public state: IState = {
        pathinfo: getUrlParams(this.props.location),
        showPopover: false,
        showAddLineItem: false,
        pageClick: { text: '', boundingBox: [] },
        isPanning: false,
    };

    private trackPopoverAction = (action: string) => {
        const { popoverTitle: label } = this.state;
        Tracker.trackPopoverAction(action, label!);
    };

    private onPageChange = (pageIndex: number) => {
        const { bookKey } = this.props.data;
        if (!bookKey) {
            return;
        }
        const page = pageIndex + 1;
        const docId = `${bookKey}_${page}`;

        const pathinfo = getUrlParams(this.props.location);
        pathinfo.params.docId = docId;

        const path = buildPath(pathinfo);

        this.props.history.replace(path);
    };

    private renderSearchResultPaging = () => {
        const { page, pages } = this.props.data;
        const { pageHits } = this.props;

        if (pages < 2 || !pageHits || !pageHits.length) {
            return null;
        }

        let withCurrentPage = pageHits.sort((a, b) => a - b);
        let currentPageIndex = pageHits.indexOf(page);

        if (currentPageIndex === -1) {
            withCurrentPage = pageHits.concat(page).sort((a, b) => a - b);
            currentPageIndex = withCurrentPage.indexOf(page);
        }

        const prevPage =
            withCurrentPage[
                (currentPageIndex + withCurrentPage.length - 1) % withCurrentPage.length
            ];
        const nextPage = withCurrentPage[(currentPageIndex + 1) % withCurrentPage.length];

        const next = () => this.onPageChange(nextPage - 1);
        const prev = () => this.onPageChange(prevPage - 1);

        const onOnlyHit = pageHits.length === 1 && pageHits[0] === page;
        const nextDisabled = currentPageIndex + 1 === withCurrentPage.length || onOnlyHit;
        const prevDisabled = currentPageIndex === 0 || onOnlyHit;

        return (
            <div className="paging search-result-paging">
                <Pagination size="small" align="right">
                    <Pagination.List>
                        <Button
                            size="small"
                            className="is-info"
                            onClick={prev}
                            disabled={prevDisabled}
                        >
                            <i className="fas fa-arrow-left" />
                        </Button>
                        <span className="paging-text">Jump to search result</span>
                        <Button
                            size="small"
                            className="is-info"
                            onClick={next}
                            disabled={nextDisabled}
                        >
                            <i className="fas fa-arrow-right" />
                        </Button>
                    </Pagination.List>
                </Pagination>
            </div>
        );
    };

    private renderPaging = () => {
        const { page, pages } = this.props.data;
        const pageIndex = page - 1;

        if (pages < 2) {
            return null;
        }

        return (
            <>
                <Paging
                    pages={pages}
                    currentPageIndex={pageIndex}
                    pageSize={1}
                    onPageChange={this.onPageChange}
                    disablePageSize={true}
                    showPageJump={true}
                />
            </>
        );
    };

    private filterRenderableWords = (allWords: IWord[]) => {
        return allWords.filter((word: IWord) => Array.isArray(word.boundingBox));
    };

    private handleWordClick = (pageClick: IWord) => (e: React.MouseEvent<HTMLDivElement>) => {
        const eventTarget = e.nativeEvent.target as HTMLDivElement;
        const clickedText = eventTarget.getAttribute('data-text') || '';
        pageClick.boundingBox = pageClick.boundingBox.map(String);
        delete pageClick.__typename;
        delete (pageClick as any).shouldHighlight;

        this.setState({
            showPopover: !this.state.isPanning,
            popoverTitle: clickedText,
            popoverParent: eventTarget,
            pageClick,
        });
    };

    private closePopover = () => {
        this.setState({
            popoverTitle: null,
            popoverParent: null,
            showPopover: false,
        });
    };

    private toggleAddToBasket = () => {
        this.closePopover();
        this.setState({ showAddLineItem: !this.state.showAddLineItem });
    };

    private closeBasketAndPopover = () => {
        this.toggleAddToBasket();
        this.closePopover();
        this.trackPopoverAction('add to basket');
    };

    private search = () => {
        const { popoverTitle, pathinfo } = this.state;
        pathinfo.params = { query: `"${popoverTitle}"` };
        const path = buildPath(pathinfo);
        this.trackPopoverAction('search');
        this.props.history.push(path);
    };

    private popoverRef = React.createRef<Popover>();

    private onPopoverChange = () => {
        this.setState({ popoverTitle: this.popoverRef.current.state.title });
    };

    private renderPopover = () => {
        const { popoverTitle, popoverParent, pageClick } = this.state;

        return (
            <Popover
                title={popoverTitle}
                options={pageClick.textVariations}
                popoverEventTarget={popoverParent}
                onClose={this.closePopover}
                onChange={this.onPopoverChange}
                ref={this.popoverRef}
            >
                <Button.Group>
                    <Button
                        fullwidth={true}
                        onClick={this.toggleAddToBasket}
                        data-cy="documentViewer-addToBasket-btn"
                    >
                        <Icon icon="fa-cart-plus" />
                        <span>Add to basket</span>
                    </Button>
                    <Button fullwidth={true} onClick={this.search}>
                        <Icon icon="fa-search" />
                        <span>Search</span>
                    </Button>
                    <CopyToClipboard text={this.state.popoverTitle!} onCopy={this.onCopy}>
                        <Button fullwidth={true}>
                            <Icon icon="fa-clipboard" />
                            <span>Copy text</span>
                        </Button>
                    </CopyToClipboard>
                </Button.Group>
            </Popover>
        );
    };

    private onCopy = () => {
        this.trackPopoverAction('copy from clipboard');
        this.closePopover();
    };

    private renderBoundingBoxes = (lineItem?: ILineItemModel): JSX.Element => {
        const { data } = this.props;
        let { query } = this.state.pathinfo.params;

        if (!data.words || data.words.length < 1) {
            return <></>;
        }

        const words = this.filterRenderableWords(data.words);

        let singleReg;
        let multiReg;
        let quotedTerms = [];
        let unquotedTerms = [];
        let singleWordTester: RegExp;
        let multiWordTester: RegExp;

        if (query) {
            query = query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

            if (query.match(/"([^"]+)"/g)) {
                quotedTerms = query
                    .match(/"([^"]+)"/g)
                    .map((q: string) => q.substr(1, q.length - 2).toLowerCase());
            }

            unquotedTerms = quotedTerms
                // remove quoted terms from original query
                .reduce((a, v) => a.replace(`"${v}"`, ''), query)
                .split(' ')
                .reduce((terms, term) => {
                    if (!term) {
                        return terms;
                    }
                    return terms.concat(term.toLowerCase());
                }, []);

            // build regex like /(something)|(quoted multi words)|(something else)/i
            singleReg = [...quotedTerms, ...unquotedTerms].map(term => `(${term})`).join('|');

            // need another regex for quoted words, since the regex test should match the entire group
            multiReg = quotedTerms
                .filter(quoted => quoted.split(' ').length > 1)
                .map(term => `(${term})`)
                .join('|');

            singleWordTester = singleReg && new RegExp(singleReg, 'i');
            multiWordTester = multiReg && new RegExp(`\\b(${multiReg})\\b`, 'i');
        }

        const shouldHighlight = (word: IWord, index: number) => {
            if (!query || !word || !word.text) {
                return false;
            }

            if ((word as any).shouldHighlight) {
                return true;
            }

            if (singleWordTester && singleWordTester.test(word.text)) {
                return true;
            }

            if (unquotedTerms.indexOf(word.text) !== -1) {
                return true;
            }

            if (quotedTerms.indexOf(word.text) !== -1) {
                return true;
            }

            for (const quotedTerm of quotedTerms) {
                const multiWordText = [word.text];
                const numWordsToCheck = quotedTerm.split(' ').length;

                if (numWordsToCheck > 1) {
                    for (let i = index + 1; i < index + numWordsToCheck; i++) {
                        if (words[i]) {
                            multiWordText.push(words[i].text);
                        }
                    }
                }

                const wordsText = multiWordText.join(' ').toLowerCase();

                if (
                    quotedTerms.indexOf(wordsText) !== -1 ||
                    (multiWordTester && multiWordTester.test(wordsText))
                ) {
                    for (let i = index; i < index + numWordsToCheck; i++) {
                        if (words[i]) {
                            (words[i] as any).shouldHighlight = true;
                        }
                    }
                    return true;
                }
            }

            return false;
        };

        const highlight = !!storage.getObj('dev.highlightWords');

        return (
            <div className="words-layer">
                {words.map((word: IWord, index: number) => {
                    // transform words bounding box into CSS-friendly positions
                    // first, collect all the x coordinates
                    const xCoords = [
                        word.boundingBox[0],
                        word.boundingBox[2],
                        word.boundingBox[4],
                        word.boundingBox[6],
                    ];
                    // then get all the y coordinates
                    const yCoords: number[] = [
                        word.boundingBox[1],
                        word.boundingBox[3],
                        word.boundingBox[5],
                        word.boundingBox[7],
                    ];

                    // since the order of coordinates can differ depending on rotation,
                    // calculate from max/min values
                    const top = Math.min(...yCoords);
                    const bottom = Math.max(...yCoords);
                    const left = Math.min(...xCoords);
                    const right = Math.max(...xCoords);

                    const classes = classNames({
                        word: true,
                        'line-highlighted': lineItem
                            ? _.isEqual(
                                  lineItem.pageClick.boundingBox,
                                  word.boundingBox.map(String),
                              )
                            : false,
                        'word-highlighted': query && shouldHighlight(word, index),
                        'is-tag': word.textVariations,
                        'has-options': (word.textVariations || []).length > 1,
                        'dev-highlight': highlight,
                    });

                    return (
                        <React.Fragment key={index}>
                            <div
                                data-cy={`documentViewer-word-n${index}`}
                                className={classes}
                                data-text={word.text}
                                onMouseUp={this.handleWordClick(word)}
                                style={{
                                    top: `${top * 100}%`,
                                    left: `${left * 100}%`,
                                    bottom: `${100 - bottom * 100}%`,
                                    right: `${100 - right * 100}%`,
                                }}
                            >
                                {highlight && word.text}
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    private onIsPanning = (isPanning: boolean) => {
        this.setState({ isPanning });
    };

    private renderBoxes = () => {
        const { lineItemId } = getUrlParams(this.props.location).params;
        if (lineItemId) {
            return (
                <LineItemDetailQuery
                    id={parseInt(lineItemId, 10)}
                    renderFetchedItemData={this.renderBoundingBoxes}
                />
            );
        } else {
            return this.renderBoundingBoxes();
        }
    };

    private escapeToClose = () => {
        this.setState({ showPopover: false });
    };

    public render() {
        const { data, onClose, actionButtons } = this.props;
        const { pageClick, showPopover, showAddLineItem, popoverTitle } = this.state;

        return (
            <>
                <DocumentViewHandler
                    title={data.title}
                    key={data.path}
                    downloadKey={data.downloadPath}
                    onClose={onClose}
                    actionButtons={actionButtons}
                    renderPaging={this.renderPaging}
                    page={data.page}
                    data={data}
                    searchResultPaging={this.renderSearchResultPaging}
                    onIsPanning={this.onIsPanning}
                >
                    {data.words && this.renderBoxes()}
                    <img src={data.path} alt="" />
                    <KeyboardEventHandler
                        handleKeys={['esc']}
                        onKeyEvent={this.escapeToClose}
                        isExclusive={showPopover}
                    />
                    {showPopover && this.renderPopover()}
                </DocumentViewHandler>
                {showAddLineItem && (
                    <AddLineItemToBasketModal
                        onClose={this.toggleAddToBasket}
                        onSuccess={this.closeBasketAndPopover}
                        data={{
                            title: popoverTitle || '',
                            filename: this.props.data.bookKey,
                            pageKey: `${this.props.data.page}`,
                            quantity: '1',
                            pageClick,
                        }}
                    />
                )}
            </>
        );
    }
}

export default withRouter(DocumentViewer);
