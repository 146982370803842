import * as React from 'react';

import { PLANT_ACTIONS } from './../../../api/GraphQLQueries/Plant';
import ListQueryContainer, {
    IGraphQLListProps,
} from './../../../containers/common/ListQueryContainer';

interface IProps extends IGraphQLListProps {
    renderFetchedPlantList: (
        plants,
        totalCount: number,
        limit: number,
        offset: number,
    ) => JSX.Element;
}

export default class PlantActionsQuery extends React.Component<IProps, any> {
    public render = () => {
        const { renderFetchedPlantList } = this.props;

        return (
            <ListQueryContainer
                {...this.props}
                query={PLANT_ACTIONS}
                dataProp="plantActions"
                renderFetchedList={renderFetchedPlantList}
            />
        );
    };
}
