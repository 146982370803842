import * as React from 'react';

import { notification } from './../../utils/notification';

export default class Error extends React.Component<any, any> {
    private renderError = (): any => {
        const error = this.props.children ? this.props.children : 'An error occured.';
        notification.error(error as string, 4000);
        return null;
    };

    public render() {
        return this.renderError();
    }
}
