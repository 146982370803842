import classnames from 'classnames';
import * as React from 'react';

import { isMIE } from '../../utils/helpers';
import { storage } from '../../utils/storage';

interface IDragData {
    x: number;
    y: number;
    dx: number;
    dy: number;
}

interface IState {
    dragging: boolean;
    dragData: IDragData;
    transformMatrix: number[];
    disableWordClick: boolean;
}

interface IProps {
    panContainer: React.RefObject<HTMLDivElement>;
    transformMatrix: number[];
    height: string;
    className?: string;
    enablePan?: boolean;
    panable?: boolean;
    onPan?: () => void;
    onIsPanning?: (isPanning: boolean) => void;
}

export default class DocumentView extends React.Component<IProps, IState> {
    public state: IState = {
        dragging: false,
        dragData: { dx: 0, dy: 0, x: 0, y: 0 },
        transformMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
        disableWordClick: false,
    };

    private panWrapper = React.createRef<HTMLDivElement>();

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        const transformMatrix = [...nextProps.transformMatrix];

        return {
            ...prevState,
            transformMatrix,
        };
    }

    private setCursorStyle = (style: string): void => {
        if (this.panWrapper.current) {
            this.panWrapper.current.style.cursor = style;
        }
    };

    private onMouseDown = (e: React.MouseEvent<EventTarget>): void => {
        if (!this.props.panable) {
            return;
        }

        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }

        const { transformMatrix } = this.state;

        const offsetX = transformMatrix[12];
        const offsetY = transformMatrix[13];

        const newDragData: IDragData = {
            dx: offsetX,
            dy: offsetY,
            x: e.pageX,
            y: e.pageY,
        };

        this.setState((prevState: IState) => ({
            ...prevState,
            dragData: newDragData,
            dragging: true,
        }));

        this.setCursorStyle('grabbing');

        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
    };

    private onMouseUp = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            dragging: false,
            dragData: { dx: 0, dy: 0, x: 0, y: 0 },
            disableWordClick: false,
        }));

        this.setCursorStyle('grab');

        if (this.props.onPan) {
            this.props.onPan();
        }

        if (this.props.onIsPanning) {
            this.props.onIsPanning(false);
        }
    };

    private getNewMatrixData = (x: number, y: number): number[] => {
        const { dragData, transformMatrix } = this.state;

        const deltaX = dragData.x - x;
        const deltaY = dragData.y - y;

        transformMatrix[12] = dragData.dx - deltaX;
        transformMatrix[13] = dragData.dy - deltaY;

        return transformMatrix;
    };

    private onMouseMove = (e: React.MouseEvent<EventTarget>): void => {
        const { enablePan, onPan, panable } = this.props;
        const { dragging, disableWordClick, dragData } = this.state;

        if (e.pageX === dragData.x || e.pageY === dragData.y) {
            return;
        }

        if (dragging && enablePan && onPan && panable) {
            if (!disableWordClick) {
                this.setState(
                    (prevState: IState) => ({
                        ...prevState,
                        disableWordClick: true,
                    }),
                    () => {
                        if (this.props.onIsPanning) {
                            this.props.onIsPanning(true);
                        }
                    },
                );
            }

            const transformMatrix = this.getNewMatrixData(e.pageX, e.pageY);

            // only apply new css transform
            // changing the state affects perfomance
            this.applyCssTransform(transformMatrix);
        }
    };

    private applyCssTransform = (transformMatrix: number[]): void => {
        if (this.props.panContainer.current) {
            this.props.panContainer.current.style.transform = `matrix3d(${transformMatrix.toString()})`;
        }
    };

    public render() {
        const { className, height } = this.props;
        const style = { height };

        const applyMatrix = `matrix3d(${this.state.transformMatrix.toString()})`;
        const transformStyle = { ...style, transform: applyMatrix };
        const classes = classnames({
            debug: storage.getObj('dev.highlightWords'),
            'image-container': true,
            'has-transition': !isMIE(),
        });

        return (
            <div
                className={`pan-container ${className || ''}`}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseUp}
                style={style}
                ref={this.panWrapper}
            >
                <div className={classes} ref={this.props.panContainer} style={transformStyle}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
