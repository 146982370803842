import * as React from 'react';

import MultiSelect from '../ui/MultiSelect';

interface IProps {
    defaultSelected?: boolean;
    onUpdate: (status?: boolean) => void;
}

export default class UserStatusSelectMenu extends React.Component<IProps, any> {
    public userStatus = [true, false];

    private handleChange = (status?: any) => {
        this.props.onUpdate(status ? status.value : undefined);
    };

    private mapStatusMessage = status => {
        if (status === true) {
            return 'Active';
        } else if (status === false) {
            return 'Not Active';
        }
    };

    private buildSelectObject = () => {
        return this.userStatus.map(status => {
            const item = { value: status, label: this.mapStatusMessage(status) };
            return item;
        });
    };

    public render() {
        const { defaultSelected } = this.props;
        const initial = defaultSelected
            ? { value: defaultSelected, label: this.mapStatusMessage(defaultSelected) }
            : undefined;

        return (
            <MultiSelect
                value={initial}
                options={this.buildSelectObject()}
                placeholder="Status"
                isClearable={true}
                onChange={this.handleChange}
            />
        );
    }
}
