import queryString from 'query-string';

export interface IUrl {
    pathname: string;
    params: any;
}

interface IMetaData {
    filekey: string;
    page: number;
}

const getUrlParams = (location: any): IUrl => {
    return {
        pathname: location.pathname,
        params: queryString.parse(location.search),
    };
};

const buildPath = (urlParams: IUrl): any => {
    const { pathname, params } = urlParams;
    const search = queryString.stringify(params);

    return {
        pathname,
        search: `?${search}`,
    };
};

const parseQueryString = (location: any, name: string) => {
    const params = queryString.parse(location.search);

    return params[name] || undefined;
};

const parseDocumentId = (location: any) => {
    return parseQueryString(location, 'docId') as string;
};

const parseTagId = (location: any) => {
    return parseQueryString(location, 'tagId') as string;
};

const parseDocumentSource = (filename: string): string => {
    const parts = filename.split('/');

    return parts[parts.length - 2];
};

const parseDocumentFilename = (filename: string): string => {
    const parts = filename.split('/');

    return parts[parts.length - 1];
};

const parseDocumentMeta = (filename: string): IMetaData => {
    const seperatorDocIndex = filename.lastIndexOf('_');

    return {
        filekey: filename.substring(0, seperatorDocIndex),
        page: parseInt(filename.substring(seperatorDocIndex + 1, filename.length), 10),
    };
};

const parseSearchQuery = (location: any) => {
    return parseQueryString(location, 'query') as string;
};

// basically the same code as 'query-string', but it fails on decoded strings...
const parseDecoded = (location: string): { [key: string]: string } => {
    let scheme;
    [scheme, location] = location
        .trim()
        .replace(/^[?#&]/, '')
        .split('?');

    if (scheme && !location) {
        location = scheme;
    }

    if (!location) {
        return {};
    }

    const ret: { [key: string]: string } = {};

    for (const param of location.split('&')) {
        const [key, value] = param.split('=');
        if (typeof key === 'undefined') {
            continue;
        }
        ret[key] = value;
    }

    return ret;
};

export default parseQueryString;

export {
    buildPath,
    getUrlParams,
    parseDocumentFilename,
    parseDocumentId,
    parseDocumentMeta,
    parseDocumentSource,
    parseDecoded,
    parseSearchQuery,
    parseTagId,
};
