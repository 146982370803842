import { Button, Control, Field, Icon } from 'rbx';
import * as React from 'react';
import { ValueType } from 'react-select/lib/types';

import { IBasketFilterParams } from '../../models/IBasketModel';
import MultiSelect from '../ui/MultiSelect';
import IBasketModel from './../../models/IBasketModel';
import BasketCreateModal from './BasketCreateModal';
import BasketListQuery from './queries/BasketListQuery';

interface IProps {
    onUpdate?: (ids: string[]) => void;
    defaultSelected?: string[];
    disabled?: string[];
    selectHeader?: string;
    enableCreateBasket?: boolean;
    filterByPlantId?: string;
    hideArchived?: boolean;
}

interface IState {
    limit: number;
    offset: number;
    baskets: string[];
    showCreateBasket: boolean;
}

export default class BasketListSelectMenu extends React.Component<IProps, IState> {
    public state: IState = {
        offset: 0,
        limit: 100,
        baskets: [],
        showCreateBasket: false,
    };

    public componentDidMount() {
        this.setState({ baskets: this.props.defaultSelected || [] });
    }

    private setBaskets = (baskets: string[]): void => {
        const { onUpdate } = this.props;

        this.setState({ baskets }, () => {
            if (onUpdate) {
                onUpdate(baskets);
            }
        });
    };

    private optionDisabled = (basket: IBasketModel): boolean => {
        const { disabled } = this.props;
        if (!disabled || !disabled.length) {
            return false;
        }
        return disabled.includes(basket.id);
    };

    private handleChange = (basket: ValueType<IBasketModel>): void => {
        if (!basket || (Array.isArray(basket) && basket.length === 0)) {
            return this.setBaskets([]);
        }

        if (Array.isArray(basket)) {
            this.setBaskets(basket.map(({ id }) => id));
        } else {
            this.setBaskets([basket.id]);
        }
    };

    private getOptionType = (index: string): ((basket: IBasketModel) => string) => {
        return (basket: IBasketModel) => (basket as { [key: string]: any })[index];
    };

    private renderSelectMenu() {
        return (baskets: IBasketModel[]) => {
            const { baskets: stateBaskets } = this.state;
            const selected = baskets.filter(({ id }) => stateBaskets.includes(id));

            return (
                <>
                    {baskets && (
                        <Control expanded={true}>
                            <MultiSelect<IBasketModel>
                                cy="BasketListSelectMenu-basketSelect-select"
                                value={selected}
                                isClearable={true}
                                isOptionDisabled={this.optionDisabled}
                                getOptionLabel={this.getOptionType('name')}
                                getOptionValue={this.getOptionType('id')}
                                options={baskets}
                                placeholder={this.props.selectHeader}
                                onChange={this.handleChange}
                            />
                        </Control>
                    )}
                </>
            );
        };
    }

    private toggleCreateBasket = () => {
        this.setState({ showCreateBasket: !this.state.showCreateBasket });
    };

    private renderCreateBasketButton = () => {
        return (
            <Control expanded={false}>
                <Button onClick={this.toggleCreateBasket}>
                    <Icon>
                        <i className="fa fa-plus" />
                    </Icon>
                    <span>New basket</span>
                </Button>
            </Control>
        );
    };

    private selectCreatedBasket = (basketId: string) => {
        this.setBaskets([basketId]);
    };

    private plantIdFilter = () => {
        const { filterByPlantId, hideArchived } = this.props;

        const filter: IBasketFilterParams = {};

        if (filterByPlantId) {
            filter.plantId = filterByPlantId;
        }

        if (hideArchived) {
            filter.archived = false;
        }

        return filter;
    };

    public render() {
        const { limit, offset, showCreateBasket } = this.state;
        const { enableCreateBasket } = this.props;

        return (
            <>
                <Field className="is-grouped">
                    <BasketListQuery
                        limit={limit}
                        filter={this.plantIdFilter()}
                        offset={offset}
                        fetchPolicy="network-only"
                        renderFetchedBasketList={this.renderSelectMenu()}
                    />
                    {enableCreateBasket && this.renderCreateBasketButton()}
                </Field>
                {showCreateBasket && (
                    <BasketCreateModal
                        refetchFilter={this.plantIdFilter()}
                        onClose={this.toggleCreateBasket}
                        onSuccess={this.selectCreatedBasket}
                    />
                )}
            </>
        );
    }
}
