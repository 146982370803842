import gql from 'graphql-tag';

const LINE_ITEM_QUERY = gql`
    query LineItem($id: Int!) {
        lineItem(id: $id) {
            id
            part {
                id
                designation
                tagNumber
                tagNumberAsId
            }
            parentPart {
                id
                designation
                tagNumber
                tagNumberAsId
            }
            pageClick {
                text
                boundingBox
            }
            description
            isWholeComponent
            requestType
            title
            quantity
            filename
            pageKey
            createdAt
            updatedAt
        }
    }
`;

const LINE_ITEM_CREATE_MUTATION = gql`
    mutation CreateLineItem($lineItem: LineItemCreateInput!) {
        createLineItem(lineItem: $lineItem) {
            id
        }
    }
`;

const LINE_ITEM_UPDATE_MUTATION = gql`
    mutation UpdateLineItem($lineItem: LineItemUpdateInput!) {
        updateLineItem(lineItem: $lineItem) {
            id
            part {
                id
                designation
                tagNumber
                tagNumberAsId
            }
            parentPart {
                id
                designation
                tagNumber
                tagNumberAsId
            }
            description
            isWholeComponent
            requestType
            title
            quantity
            filename
            pageKey
            createdAt
            updatedAt
        }
    }
`;

const LINE_ITEM_DELETE_MUTATION = gql`
    mutation DeleteLineItem($id: Int!) {
        deleteLineItem(id: $id)
    }
`;

export {
    LINE_ITEM_QUERY,
    LINE_ITEM_CREATE_MUTATION,
    LINE_ITEM_UPDATE_MUTATION,
    LINE_ITEM_DELETE_MUTATION,
};
