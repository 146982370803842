import { Button, Delete, Modal } from 'rbx';
import * as React from 'react';

import ModalContainer from './../ui/ModalContainer';
import BasketListSelectMenu from './BasketListSelectMenu';

interface IProps {
    onClose: () => void;
    onUpdate: (basketId: string) => void;
    onSubmit: (fn: any) => void;
    defaultSelected?: string[];
    plantId?: string;
}

interface IState {
    disabled: boolean;
}

export default class BasketSelectModal extends React.Component<IProps, IState> {
    public state: IState = { disabled: !this.props.defaultSelected };

    private onUpdate = (baskedIdAsArray: string[]) => {
        if (baskedIdAsArray.length === 1) {
            this.setState({ disabled: false });
            this.props.onUpdate(baskedIdAsArray[0]);
        } else {
            this.setState({ disabled: true });
        }
    };

    public render = () => {
        const { onClose, onSubmit, defaultSelected, plantId } = this.props;

        return (
            <ModalContainer isOpen={true} onRequestClose={onClose}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Select basket</Modal.Card.Title>
                        <Delete onClick={onClose} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <BasketListSelectMenu
                            enableCreateBasket={true}
                            onUpdate={this.onUpdate}
                            defaultSelected={defaultSelected}
                            filterByPlantId={plantId}
                        />
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button onClick={onSubmit} disabled={this.state.disabled}>
                            Add to basket
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };
}
