import axios from 'axios';
import * as React from 'react';

import { getAuthTokenForHTTP } from '../../../api/authorization';
import { API_URI } from '../../../api/connection';
import { notification } from '../../../utils/notification';

interface IState {
    file: File;
    csvErrors: {
        message: string;
        invalidRows: Array<{
            i: number;
            missingCols: string[];
        }>;
    };
}

export default class CsvParser extends React.Component<{}, IState> {
    public state: IState = {
        file: null,
        csvErrors: null,
    };

    private setFile = (synth: React.SyntheticEvent<HTMLInputElement>) => {
        const event = synth.currentTarget;
        if (event && event.files && event.files.length) {
            this.setState({ file: event.files[0] });
        }
    };

    // private setErrors = (csvErrors = null) => {
    //     this.setState({ csvErrors });
    // };

    private handleFormSubmit = async (synth: React.SyntheticEvent) => {
        const { file } = this.state;
        if (!file) {
            return alert('No file!');
        }
        // prevent the browser from submitting the form
        synth.preventDefault();

        const token = await getAuthTokenForHTTP();
        const headers = { Authorization: `Bearer ${token}` };

        const form = new FormData();
        form.append('file', file, file.name);

        axios
            .post(`${API_URI}/api/spacy-csv-to-part-csv`, form, { headers, responseType: 'blob' })
            .then(response => {
                let filename = 'plantserv-parts.csv';
                if (typeof response.headers['content-disposition'] === 'string') {
                    [, filename] = response.headers['content-disposition'].match(
                        /attachment; filename=(.*)$/,
                    );
                }

                const notify = () => {
                    if (filename === 'plantserv-parts.csv') {
                        notification.success('Success! Download will start shortly');
                    } else {
                        notification.warn(
                            'There were errors parsing the csv - please see download.',
                        );
                    }
                };

                // IE "HTML7007:
                if (typeof window.navigator.msSaveBlob === 'function') {
                    window.navigator.msSaveBlob(response.data, filename);
                    return notify();
                }

                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = window.URL.createObjectURL(response.data);
                link.download = filename;

                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);

                return notify();
            })
            .catch(() => notification.error('There were errors parsing the csv'));
    };

    public render() {
        const { file } = this.state;

        return (
            <form onSubmit={this.handleFormSubmit} style={{ position: 'relative' }}>
                <div className="file">
                    <label className="file-label">
                        <input
                            className="file-input"
                            name="file"
                            type="file"
                            accept=".csv"
                            onChange={this.setFile}
                        />
                        <span className="button file-cta">
                            <span className="file-icon">
                                <i className={`fas fa-${file ? 'times' : 'upload'}`} />
                            </span>
                            <span className="file-label">{file ? file.name : 'Select CSV'}</span>
                        </span>
                    </label>
                    <input className="button file-cta is-primary" type="submit" value="Upload" />
                </div>
            </form>
        );
    }
}
