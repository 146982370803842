import gql from 'graphql-tag';

import { BASE_PART_FIELDS, BASE_TAG_FIELDS, EXTENDED_PART_FIELDS } from './Common';

export const PART_CREATE_MUTATION = gql(`
    mutation CreatePart($part: PartCreateInput!) {
        createPart(part: $part) {
            ${BASE_PART_FIELDS}
            parents {
                id
                designation
                tagNumber
                tagNumberAsId
            }
            ${EXTENDED_PART_FIELDS}
        }
    }
`);

export const PART_QUERY = gql(`
    query Part($id: String!) {
        part(id: $id) {
            ${BASE_PART_FIELDS}
            plant {
                id
                name
            }
            parents {
                ${BASE_TAG_FIELDS}
            }
            ${EXTENDED_PART_FIELDS}
        }
    }
`);

export const PART_UPDATE_MUTATION = gql(`
    mutation UpdatePart($part: PartUpdateInput!) {
        updatePart(part: $part) {
            ${BASE_PART_FIELDS}
            plant {
                id
                name
            }
            parents {
                id
                designation
                tagNumber
                tagNumberAsId
            }
            ${EXTENDED_PART_FIELDS}
        }
    }
`);

export const CREATE_PART_DOCUMENT_PAGE_MUTATION = gql`
    mutation CreatePartDocumentPage($document: PartDocumentPageCreateInput!) {
        createPartDocumentPage(document: $document) {
            id
            part {
                id
                designation
                tagNumberAsId
                plant {
                    id
                }
            }
            document {
                s3Key
                customerVisible
            }
            pageNumber
        }
    }
`;

export const UPDATE_PART_DOCUMENT_PAGE_MUTATION = gql`
    mutation UpdatePartDocumentPage($document: PartDocumentPageUpdateInput!) {
        updatePartDocumentPage(document: $document) {
            id
            part {
                id
                designation
                tagNumberAsId
                plant {
                    id
                }
            }
            document {
                s3Key
                customerVisible
            }
            pageNumber
        }
    }
`;

export const REMOVE_PART_DOCUMENT_PAGE_MUTATION = gql`
    mutation RemovePartDocumentPage($id: Int!) {
        deletePartDocumentPage(id: $id)
    }
`;

export const PART_DELETE_MUTATION = gql`
    mutation DeletePart($id: String!) {
        deletePart(id: $id)
    }
`;

export const PART_DOCUMENT_PAGES = gql`
    query PartDocumentPages($plantId: String!, $s3Key: String!) {
        partDocumentPages(plantId: $plantId, s3Key: $s3Key) {
            id
        }
    }
`;
