import { Button } from 'rbx';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as validator from 'validator';

import { PASSWORD_REGEX } from '../../models/IUserModel';
import Routing from '../../Routing';
import { t } from './../../utils/translate';
import FormField from './../ui/FormField';

interface IProps {
    buttonText: string;
    isForgotPassword?: boolean;
    isResetPassword?: boolean;
    isLoginForm?: boolean;
    onSubmit: (email: string, password: string) => void;
}

interface IState {
    email: string;
    password: string;
    inputValid: boolean;
}

export default class AuthForm extends React.Component<IProps, IState> {
    public state: IState = {
        email: '',
        password: '',
        inputValid: false,
    };

    private emailChange = (email: string): void => {
        this.setState({ email }, this.validateInput);
    };

    private passwordChange = (password: string): void => {
        this.setState({ password }, this.validateInput);
    };

    private validateInput = (): void => {
        const { email, password } = this.state;
        const { isResetPassword, isForgotPassword, isLoginForm } = this.props;

        const emailValid: boolean = isResetPassword || validator.isEmail(email);
        const passwordValid: boolean = isLoginForm
            ? true
            : isForgotPassword || PASSWORD_REGEX.test(password);

        this.setState({ inputValid: emailValid && passwordValid });
    };

    private submitAction = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        const { email, password } = this.state;
        this.props.onSubmit(email, password);
    };

    private handleReturn = (e: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.inputValid && (e.nativeEvent as KeyboardEvent).key === 'Enter') {
            this.submitAction(e);
        }
    };

    public render() {
        const { email, password, inputValid } = this.state;
        const { buttonText, isForgotPassword, isResetPassword, isLoginForm } = this.props;
        const showPasswordError = isResetPassword && !inputValid && password;
        const showPasswordHelpMessage = isResetPassword && !inputValid;
        return (
            <>
                {!isResetPassword && (
                    <FormField
                        value={email}
                        onChange={this.emailChange}
                        onKeyDown={this.handleReturn}
                        label={t().email_label}
                        type="email"
                        placeholder={t().email_placeholder}
                        cy="login-email-input"
                    />
                )}

                {!isForgotPassword && (
                    <>
                        <FormField
                            value={password}
                            onChange={this.passwordChange}
                            label={t().password_label}
                            type="password"
                            onKeyDown={this.handleReturn}
                            inputColor={showPasswordError ? 'danger' : ''}
                            placeholder={t().password_placeholder}
                            help={showPasswordHelpMessage ? t().password_complexity : ''}
                            cy="login-password-input"
                        />
                    </>
                )}

                <Button.Group>
                    <Button
                        color="primary"
                        disabled={!inputValid}
                        onClick={this.submitAction}
                        data-cy="login-submit-btn"
                    >
                        {buttonText}
                    </Button>
                    {isLoginForm && (
                        <Button text={true} as={Link} to={Routing.AUTH_FORGOT_PASSWORD.route}>
                            {t().forgot_password}
                        </Button>
                    )}
                </Button.Group>
            </>
        );
    }
}
