import { Control, Field, Icon, Input, Label, Title } from 'rbx';
import * as React from 'react';

import { isRequired } from '../../../utils/helpers';
import { IPlantAddressModel } from './../../../models/IPlantModel';

interface IAddressProps {
    title: string;
    address: IPlantAddressModel;
    onUpdate: (address: IPlantAddressModel) => void;
    hideLatLng?: boolean;
}

export default class PlantAddressForm extends React.Component<IAddressProps, any> {
    private valueChange = (attributeName: string) => (context: any): void => {
        this.props.onUpdate({
            ...this.props.address,
            [attributeName]: context.currentTarget.value,
        });
    };

    public render = () => {
        const { street, zip, city, country, responsible, latitude, longitude } = this.props.address;
        const { hideLatLng } = this.props;

        return (
            <div>
                <Title size="5">{this.props.title}</Title>
                <Field horizontal={true}>
                    <Field.Label>
                        <Label>{isRequired('Street')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Street"
                                    value={street || ''}
                                    onChange={this.valueChange('street')}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-road" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label>
                        <Label>{isRequired('ZIP')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="ZIP"
                                    value={zip || ''}
                                    onChange={this.valueChange('zip')}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-map-pin" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label>
                        <Label>{isRequired('City')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="City"
                                    value={city || ''}
                                    onChange={this.valueChange('city')}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-city" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label>
                        <Label>{isRequired('Country')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Country"
                                    value={country || ''}
                                    onChange={this.valueChange('country')}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-globe" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label>
                        <Label>{isRequired('Responsible')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Responsible"
                                    value={responsible || ''}
                                    onChange={this.valueChange('responsible')}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-user-tie" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>
                {!hideLatLng && (
                    <Field horizontal={true}>
                        <Field.Label>
                            <Label>Latitude</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control expanded={true} iconLeft={true}>
                                    <Input
                                        type="string"
                                        placeholder="latitude"
                                        value={latitude || ''}
                                        onChange={this.valueChange('latitude')}
                                    />
                                    <Icon size="small" align="left">
                                        <i className="fa fa-map-pin" />
                                    </Icon>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                )}
                {!hideLatLng && (
                    <Field horizontal={true}>
                        <Field.Label>
                            <Label>Longitude</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control expanded={true} iconLeft={true}>
                                    <Input
                                        type="string"
                                        placeholder="longitude"
                                        value={longitude || ''}
                                        onChange={this.valueChange('longitude')}
                                    />
                                    <Icon size="small" align="left">
                                        <i className="fa fa-map-pin" />
                                    </Icon>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                )}
            </div>
        );
    };
}
