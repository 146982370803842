import { Button, Column, Icon, Title } from 'rbx';
import React, { Component } from 'react';
import { Query } from 'react-apollo';

import { RABBIT_STATS } from '../../api/GraphQLQueries/Ops';
import ControlledTable, { ITableColumnModel } from './../ui/table/ControlledTable';

const columns: ITableColumnModel[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'messages',
        accessor: 'messages',
    },
    {
        Header: ':ready',
        accessor: 'messages_ready',
    },
    {
        Header: ':unack',
        accessor: 'messages_unacknowledged',
    },
    {
        Header: ':incoming /s',
        accessor: (d: any) =>
            d.message_stats && d.message_stats.publish_details
                ? d.message_stats.publish_details.rate
                : null,
    },
    {
        Header: ':delivered',
        accessor: (d: any) =>
            d.message_stats && d.message_stats.deliver_get_details
                ? d.message_stats.deliver_get_details.rate
                : null,
    },
    {
        Header: ':ack',
        accessor: (d: any) =>
            d.message_stats && d.message_stats.ack_details
                ? d.message_stats.ack_details.rate
                : null,
    },
];

interface IProps {
    pollInterval: number;
}

interface IState {
    isPolling: boolean;
}

export default class RabbitStatsQuery extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        if (/ops$/.test(window.location.href)) {
    
            
            columns.push({
                Header: 'Consumers',
                accessor: 'consumer_count',
            });
        }
    }

    public state: IState = {
        isPolling: true,
    };

    private convertToDisplay = (estimateTime: number): string => {
        const hours = estimateTime >= 3600 ? `${Math.floor(estimateTime / 3600)} h, ` : '';
        const minutes = estimateTime >= 60 ? `${Math.floor((estimateTime % 3600) / 60)} m, ` : '';
        const seconds = estimateTime % 60;

        return `${hours}${minutes}${seconds} s left`;
    };

    private renderEstimateTime = (rabbitEstimate: number) => {
        return rabbitEstimate && rabbitEstimate !== 0 ? (
            <div>ETA {this.convertToDisplay(rabbitEstimate)}</div>
        ) : (
            ''
        );
    };

    private renderTable = (
        rabbitQueues: any,
        rabbitConsumerCount: { [queueName: string]: number },
    ) => {
        if (!rabbitQueues) {
            return;
        }
        rabbitConsumerCount = rabbitConsumerCount || {};

        const { items } = rabbitQueues;
        const filteredItems = items.reduce((queueWithConsumers, queue: any) => {
            if (queue.name.includes('WAITING')) {
                return queueWithConsumers;
            }

            queue.consumer_count = rabbitConsumerCount[queue.name] || 0;

            return queueWithConsumers.concat(queue);
        }, []);

        return rabbitQueues && <ControlledTable data={filteredItems} columns={columns} />;
    };

    private stopPolling = (stopPolling: any) => {
        this.setState({ isPolling: false }, () => stopPolling());
    };

    private startPolling = (startPolling: any) => {
        this.setState({ isPolling: true }, () => startPolling(this.props.pollInterval));
    };

    private renderData = (data: any, refetch: any, startPolling: any, stopPolling: any) => {
        let rabbitQueues;
        let rabbitEstimate;
        let rabbitConsumerCount;
        if (data !== null) {
            rabbitQueues = data.rabbitQueues;
            rabbitEstimate = data.rabbitEstimate;
            rabbitConsumerCount = data.rabbitConsumerCount;
        } else {
            stopPolling();
        }
        const triggerRefetch = () => refetch();
        const { isPolling } = this.state;

        const buttonAction =
            data && isPolling
                ? this.stopPolling.bind(this, stopPolling)
                : this.startPolling.bind(this, startPolling);
        const buttonText = data && isPolling ? 'Stop' : 'Start';

        return (
            <>
                <Column.Group>
                    <Column size={6}>
                        <Title as="h2" subtitle={true}>
                            {this.renderEstimateTime(rabbitEstimate)}
                        </Title>
                    </Column>
                    <Column size={6} align="right">
                        <Button.Group>
                            <Button size="small" onClick={buttonAction}>
                                <span>{buttonText} Auto-refresh</span>
                                {!data && (
                                    <Icon color="danger">
                                        <i className="fa fa-exclamation-triangle" />
                                    </Icon>
                                )}
                            </Button>
                            <Button size="small" onClick={triggerRefetch}>
                                Reload
                            </Button>
                        </Button.Group>
                    </Column>
                </Column.Group>

                {this.renderTable(rabbitQueues, rabbitConsumerCount)}
            </>
        );
    };

    public render() {
        const { isPolling } = this.state;

        return (
            <Query
                query={RABBIT_STATS}
                fetchPolicy="network-only"
                pollInterval={isPolling ? this.props.pollInterval : (null as any)}
            >
                {({ data, loading, error, refetch, startPolling, stopPolling }) => {
                    if (loading) {
                        return null;
                    }
                    if (error) {
                        return this.renderData(null, refetch, startPolling, stopPolling);
                    }

                    return this.renderData(data, refetch, startPolling, stopPolling);
                }}
            </Query>
        );
    }
}
