import * as _ from 'lodash';
import * as React from 'react';

import { getToken } from '../../../api/authorization';
import IPlantModel from '../../../models/IPlantModel';
import IUserModel from '../../../models/IUserModel';
import UserDetailQuery from './UserDetailQuery';

interface IProps {
    userId?: number;
    renderFetchedPlantData: (user: IUserModel, plants: IPlantModel[]) => JSX.Element;
    hideLoadingIndicator?: boolean;
}

export default class UserPlantsQuery extends React.Component<IProps, any> {
    private renderFetchedPlantData = (user: any) => {
        if (!user) {
            return <></>;
        }
        const { renderFetchedPlantData } = this.props;

        const plantsRoles: IPlantModel[] = user.plantRoles || [];

        return renderFetchedPlantData(user, _.map(plantsRoles, 'plant'));
    };

    public render() {
        const { userId, hideLoadingIndicator } = this.props;
        const id = userId || getToken().id;
        return (
            <>
                {id && (
                    <UserDetailQuery
                        hideLoadingIndicator={hideLoadingIndicator}
                        id={id}
                        renderFetchedUserData={this.renderFetchedPlantData}
                    />
                )}
            </>
        );
    }
}
