import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CREATE_PART_DOCUMENT_PAGE_MUTATION } from './../../../api/GraphQLQueries/Part';
import { DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY } from './../../../api/GraphQLQueries/Tag';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';
import { parseDocumentMeta } from './../../../utils/queryString';

interface IRouteParams {
    plantId: string;
    tagNumberAsId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    idTag: string;
    docId: string;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

class CreateTagDocumentMutation extends React.Component<IProps, IState> {
    public state: IState = { ...resetState };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({ ...prevState, ...resetState }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    private createDocument = (doCreateTagDocumentMutation: MutationFn) => (): void => {
        const { idTag, docId } = this.props;
        const metaData = parseDocumentMeta(docId);
        const customerVisible = false;

        const documentData = {
            partId: idTag,
            s3Key: metaData.filekey,
            pageNumber: metaData.page,
            customerVisible,
        };

        doCreateTagDocumentMutation({ variables: { document: documentData } });
    };

    public render = () => {
        const { error, success } = this.state;
        const { tagNumberAsId, plantId } = this.props.match.params;

        return (
            <>
                {error && <Error>document create error</Error>}
                {success && <Message type="success">Successfully linked document to tag</Message>}
                <Mutation
                    mutation={CREATE_PART_DOCUMENT_PAGE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={[
                        {
                            query: DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY,
                            variables: { plantId, tagNumberAsId },
                        },
                    ]}
                >
                    {doCreateTagDocument => (
                        <Button
                            type="button"
                            outlined={true}
                            onClick={this.createDocument(doCreateTagDocument)}
                        >
                            Link to tag
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };
}

export default withRouter(CreateTagDocumentMutation);
