import ILineItemModel from './ILineItemModel';
import IPlantModel from './IPlantModel';
import IUserModel from './IUserModel';

export const BasketLabels = ['repair', 'maintenance', 'turnaround'];
export const BasketStates = ['submitted', 'locked', 'quoted', 'completed'];
export const BasketPurchaseStates = ['purchased', 'not_purchased', 'no_response', 'notset'];

export type BasketStatus = 'draft' | 'submitted' | 'locked' | 'quoted' | 'completed';
export type PurchaseState = 'purchased' | 'not_purchased' | 'no_response' | 'notset';

export interface IBasketFileModel {
    filename: string;
    isQuote: boolean;
    s3Key: string;
}

interface IBasket {
    name?: string;
    description?: string;
    deadline?: string;
    label?: string;
    shippingTerm?: string;
    customerReference?: string;
    status?: BasketStatus;
    purchaseState?: PurchaseState;
    archived?: boolean;
}

export interface IBasketCreateModel extends IBasket {
    plantId?: string;
}

export interface IBasketUpdateModel extends IBasket {
    id: string;
    name: string;
    leNote?: string;
}

export interface IBasketFilterParams {
    plantId?: string;
    status?: BasketStatus;
    purchaseState?: PurchaseState;
    label?: string;
    createdBy?: number;
    archived?: boolean;
}

export default interface IBasketModel extends IBasket {
    id: string;
    name: string;
    plant: IPlantModel;
    lineItems?: ILineItemModel[];
    status: BasketStatus;
    purchaseState: PurchaseState;
    archived: boolean;
    leNote?: string;
    updatedBy: number;
    createdBy: IUserModel;
    updatedAt: string;
    createdAt: string;
}
