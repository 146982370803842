import { Button, Control, Delete, Field, Label, Modal } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';

import { ILineItemCreateModel } from '../../models/ILineItemModel';
import { isRequired } from '../../utils/helpers';
import { notification } from '../../utils/notification';
import { BASKET_LINE_ITEMS_QUERY } from './../../api/GraphQLQueries/Basket';
import { LINE_ITEM_CREATE_MUTATION } from './../../api/GraphQLQueries/LineItem';
import FormField from './../ui/FormField';
import ModalContainer from './../ui/ModalContainer';
import BasketListSelectMenu from './BasketListSelectMenu';
import LineItemRequestTypeSelectMenu from './LineItemRequestTypeSelectMenu';

interface IRouteParams {
    plantId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    onClose: () => void;
    onSuccess: () => void;
    data: ILineItemCreateModel;
    disableBasketSelect?: boolean;
}

interface IState {
    lineItem: ILineItemCreateModel;
}

class AddLineItemToBasketModal extends React.Component<IProps, IState> {
    public state: IState = {
        lineItem: { ...this.props.data, isWholeComponent: false },
    };

    private onError = (): void => {
        notification.error('Some items were not added');
    };

    private onCompleted = (): void => {
        notification.success('Successfully added items to basket');
        if (typeof this.props.onSuccess === 'function') {
            this.props.onSuccess();
        }
    };

    private isDisabled = (): boolean => {
        const { description, requestType, title, basketId, quantity } = this.state.lineItem;

        return !description || !requestType || !title || !basketId || !quantity;
    };

    private updateDetails = (name: string) => (value: string) => {
        const { lineItem } = this.state;
        lineItem[name] = value;

        this.setState({ lineItem });
    };

    private onBasketUpdate = (baskedIdAsArray: string[]) => {
        const { lineItem } = this.state;
        lineItem.basketId = baskedIdAsArray.length === 1 ? baskedIdAsArray[0] : undefined;

        this.setState({ lineItem });
    };

    public render = () => {
        const { onClose, disableBasketSelect } = this.props;
        const { lineItem } = this.state;
        const plantId = this.props.match.params.plantId;

        let refetchQueries;
        if (lineItem.basketId) {
            refetchQueries = [
                {
                    query: BASKET_LINE_ITEMS_QUERY,
                    variables: { id: lineItem.basketId, filter: { plantId } },
                },
            ];
        }

        return (
            <>
                <ModalContainer isOpen={true} onRequestClose={onClose}>
                    <Modal.Card data-cy="addLineItemToBasketModal-modal">
                        <Modal.Card.Head>
                            <Modal.Card.Title>Add to basket</Modal.Card.Title>
                            <Delete onClick={onClose} />
                        </Modal.Card.Head>

                        <Modal.Card.Body>
                            <FormField
                                label={isRequired('Title')}
                                horizontal={true}
                                value={lineItem.title || ''}
                                onChange={this.updateDetails('title')}
                            />
                            <FormField
                                label={isRequired('Spare part description')}
                                cy="addLineItemToBasketModal-sparePartDescription-input"
                                horizontal={true}
                                onChange={this.updateDetails('description')}
                            />
                            <FormField
                                label={isRequired('Quantity')}
                                cy="addLineItemToBasketModal-quantity-input"
                                horizontal={true}
                                value={lineItem.quantity || ''}
                                onChange={this.updateDetails('quantity')}
                            />
                            <Field horizontal={true}>
                                <Field.Label size="normal">
                                    <Label>{isRequired('Type')}</Label>
                                </Field.Label>
                                <Field.Body>
                                    <Field>
                                        <Control expanded={true}>
                                            <LineItemRequestTypeSelectMenu
                                                onUpdate={this.updateDetails('requestType')}
                                            />
                                        </Control>
                                    </Field>
                                </Field.Body>
                            </Field>
                            {!disableBasketSelect && (
                                <Field horizontal={true}>
                                    <Field.Label size="normal">
                                        <Label>{isRequired('Basket')}</Label>
                                    </Field.Label>
                                    <Field.Body>
                                        <Field>
                                            <Control expanded={true}>
                                                <BasketListSelectMenu
                                                    filterByPlantId={plantId}
                                                    hideArchived={true}
                                                    enableCreateBasket={true}
                                                    onUpdate={this.onBasketUpdate}
                                                />
                                            </Control>
                                        </Field>
                                    </Field.Body>
                                </Field>
                            )}
                        </Modal.Card.Body>

                        <Modal.Card.Foot align="right">
                            <Mutation
                                mutation={LINE_ITEM_CREATE_MUTATION}
                                onError={this.onError}
                                onCompleted={this.onCompleted}
                                variables={{ lineItem }}
                                refetchQueries={refetchQueries}
                            >
                                {doLineItemCreate => (
                                    <Button
                                        onClick={doLineItemCreate}
                                        disabled={this.isDisabled()}
                                        data-cy="addLineItemToBasketModal-addToBasket-btn"
                                    >
                                        Add to basket
                                    </Button>
                                )}
                            </Mutation>
                        </Modal.Card.Foot>
                    </Modal.Card>
                </ModalContainer>
            </>
        );
    };
}
export default withRouter(AddLineItemToBasketModal);
