import gql from 'graphql-tag';

import { PART_FIELDS } from '../../utils/fieldHandling';
import { BASE_PART_FIELDS, BASE_TAG_FIELDS, EXTENDED_TAG_FIELDS } from './Common';

export const TAG_LIST_QUERY = gql(`
    query TagsByPlant(
        $plantId: String!
        $offset: Int
        $limit: Int
        $order: [PartOrder]
        $filter: PartFilter
    ) {
        tags(plantId: $plantId, offset: $offset, limit: $limit, order: $order, filter: $filter) {
            data {
                ${BASE_TAG_FIELDS}
                updatedBy {
                    id
                }
                parts {
                    id
                }
                documentPages {
                    id
                    document {
                        customerVisible
                        s3Key
                    }
                }
            }
            limit
            offset
            totalCount
        }
    }
`);

export const TAG_BY_NUMBER_QUERY = gql(`
    query TagByNumber($plantId: String!, $tagNumberAsId: String!) {
        tagByNumber(plantId: $plantId, tagNumberAsId: $tagNumberAsId) {
            ${BASE_TAG_FIELDS}
            plant {
                id
                name
            }
            documentPages {
                id
                document {
                    s3Key
                    customerVisible
                }
                pageNumber
            }
            parts {
                ${BASE_PART_FIELDS}
                ${PART_FIELDS}
            }
            ${EXTENDED_TAG_FIELDS}
        }
    }
`);

export const PARTS_BY_TAG_NUMBER_QUERY = gql(`
    query PartsByTagNumber($plantId: String!, $tagNumberAsId: String!) {
        tagByNumber(plantId: $plantId, tagNumberAsId: $tagNumberAsId) {
            ${BASE_TAG_FIELDS}
            plant {
                id
                name
            }
            parts {
                ${BASE_PART_FIELDS}
                ${PART_FIELDS}
            }
            notes
        }
    }
`);

export const DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY = gql(`
    query DocumentsByTagNumber($plantId: String!, $tagNumberAsId: String!) {
        tagByNumber(plantId: $plantId, tagNumberAsId: $tagNumberAsId) {
            ${BASE_TAG_FIELDS}
            plant {
                id
                name
            }
            documentPages {
                id
                document {
                    s3Key
                    customerVisible
                    title
                }
                pageNumber
            }
        }
    }
`);
