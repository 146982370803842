import * as React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
    onClose: (e: any) => void;
    popoverEventTarget?: HTMLElement | HTMLDivElement;
    title?: string;
    options?: string[];
    onChange?: () => void;
    className?: string;
}

interface IState {
    shouldTransform: boolean;
    style: any;
    clickOptionsVisible?: boolean;
    title?: string;
}

const popoverPortal = document.getElementById('popover-portal')!;

export default class Popover extends React.Component<IProps, IState> {
    public state: IState = {
        shouldTransform: false,
        style: null,
    };

    private calculatePosition(): React.CSSProperties {
        const { popoverEventTarget } = this.props;

        let retObj: React.CSSProperties = {
            top: 0,
            left: 0,
        };

        if (popoverEventTarget && popoverEventTarget instanceof HTMLElement) {
            const parentRect = popoverEventTarget.getBoundingClientRect();
            const top = Math.round(parentRect.top + parentRect.height);
            const left = Math.round(parentRect.left + parentRect.width / 2);

            if (top > window.innerHeight / 2) {
                this.setState({ shouldTransform: true });

                retObj = {
                    top,
                    left,
                    transform: 'rotate(180deg) translateY(200px) translateX(150px)',
                };
            } else {
                retObj = {
                    top,
                    left,
                };
            }
        }

        this.setState({ style: retObj });
        return;
    }

    private windowResize = () => this.forceUpdate();

    public UNSAFE_componentWillMount() {
        this.calculatePosition();
    }

    public componentDidMount() {
        window.addEventListener('resize', this.windowResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
    }

    private renderWordOptions = () => {
        const options = this.props.options;
        if (!options || options.length < 2) {
            return null;
        }

        const { clickOptionsVisible } = this.state;

        const selectHandler = e => {
            this.setState(
                { title: e.currentTarget.innerText, clickOptionsVisible: false },
                this.props.onChange,
            );
        };

        const divhandler = () =>
            this.setState({ clickOptionsVisible: !this.state.clickOptionsVisible });

        return (
            <>
                <span className="click-options" onClick={divhandler}>
                    {clickOptionsVisible ? '▲' : '▼'}
                </span>
                {clickOptionsVisible && (
                    <div className="title-options-container">
                        <strong>Select variation</strong>
                        {options.map(opt => (
                            <div className="title-option" key={opt} onClick={selectHandler}>
                                {opt}
                            </div>
                        ))}
                    </div>
                )}
            </>
        );
    };

    private rendertitle = () => {
        const title = this.state.title || this.props.title;
        const { shouldTransform } = this.state;

        const rotating: React.CSSProperties = {
            transform: 'rotate(180deg)',
        };

        if (title && shouldTransform) {
            return (
                <div className="popover-title" style={rotating}>
                    {title}
                    {this.renderWordOptions()}
                </div>
            );
        } else {
            return (
                <div className="popover-title">
                    {title}
                    {this.renderWordOptions()}
                </div>
            );
        }
    };

    private renderBody = () => {
        const rotating: React.CSSProperties = {
            transform: 'rotate(180deg)',
        };
        const { shouldTransform } = this.state;
        if (shouldTransform) {
            return (
                <div style={rotating}>
                    <div className="popover-body">{this.props.children}</div>
                </div>
            );
        } else {
            return <div className="popover-body">{this.props.children}</div>;
        }
    };

    public render() {
        const { className, onClose } = this.props;
        const { style } = this.state;
        const classes = ['popover', className].join(' ');

        return ReactDOM.createPortal(
            <>
                <div className="popover-background" onClick={onClose} />
                <div className={classes} style={style}>
                    {this.rendertitle()}
                    {this.renderBody()}
                </div>
            </>,
            popoverPortal,
        );
    }
}
