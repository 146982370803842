import _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import withMainNavigation from '../../containers/withMainNavigation';
import { notification } from '../../utils/notification';
import { buildPath, getUrlParams } from '../../utils/queryString';
import { getPageProps, ITablePageProps } from '../ui/paging/PageProps';
import { DEFAULT_PAGE_SIZE } from '../ui/paging/Paging';
import DefaultTable, { ITableColumnModel } from '../ui/table/ControlledTable';
import TableActionSortDrowdown, { ISortOption } from '../ui/table/TableActionSortDrowdown';
import TableFilters from '../ui/table/TableFilters';
import withPaging from '../ui/table/withPaging';
import DateFilterSelectMenu from './DateFilterSelectMenu';
import PlantListSelectMenu from './PlantListSelectMenu';
import PlantActionsQuery from './queries/PlantActionsQuery';

const resetPage = { offset: 0, pageIndex: 0 };

const ControlledTable = withPaging(DefaultTable);

const returnHumanDateformat = (timestamp: string) => {
    const date = new Date(parseInt(timestamp, 10));

    const padLeft = (s: string | number, l: number = 2, p: string = '0') =>
        `${Array(l)
            .fill(p)
            .join('')}${s}`.slice(-l);

    const dateParts = [
        // @prettierignore
        date.getFullYear(),
        padLeft(date.getMonth() + 1),
        padLeft(date.getDate()),
    ].join('-');

    const timeParts = [
        // @prettierignore
        padLeft(date.getHours()),
        padLeft(date.getMinutes()),
        padLeft(date.getSeconds()),
    ].join(':');

    const humanFormat = `${dateParts} ${timeParts}`;
    return humanFormat;
};

const copyDataToClipboard = (data: string) => () => {
    console.info(data);
    const input = document.createElement('input');
    input.type = 'text';
    input.value = data;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 9999);
    document.execCommand('copy');
    notification.info('Copied to clipboard');
    document.body.removeChild(input);
};

const columns: ITableColumnModel[] = [
    {
        Header: 'Date',
        accessor: row => returnHumanDateformat(row.createdAt),
    },
    {
        Header: 'Action',
        accessor: 'action',
    },
    {
        Header: 'User',
        accessor: row => row.createdBy.name,
    },
    {
        Header: 'Plant',
        accessor: row => row.plant.name,
    },
    {
        Header: 'Force Ocr',
        accessor: data => data.runOpts.forceOcr,
    },
    {
        Header: 'Enabled',
        accessor: data => data.runOpts.enabled,
    },
    {
        Header: 'Dry Run',
        accessor: data => data.runOpts.dryRun,
    },
    {
        Header: 'Only Errored',
        accessor: data => data.runOpts.onlyErrored,
    },
    {
        Header: 'Force Index',
        accessor: data => data.runOpts.forceIndex,
    },
    {
        Header: 'Force Split',
        accessor: data => data.runOpts.forceSplit,
    },
    {
        Header: 'Copy',
        accessor: data => {
            const datastring = JSON.stringify(data);
            return (
                <Button onClick={copyDataToClipboard(datastring)} title={datastring}>
                    <i className="fa fa-copy" />
                </Button>
            );
        },
    },
];

class PlantActions extends React.Component<RouteComponentProps<any>> {
    public state = {
        totalCount: 0,
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        forceRerenderKey: 0,
        initialRender: true,
        filter: {
            plantId: undefined,
            date: undefined,
        },
        sortBy: {
            field: 'createdAt',
            direction: 'desc',
        },
        pathinfo: getUrlParams(this.props.location),
    };

    private sortOptions: ISortOption[] = [
        {
            optionName: 'plant',
            sortByField: 'plant',
        },
        {
            optionName: 'user',
            sortByField: 'createdBy',
        },
    ];

    private onSorting = (sortBy?: any) => {
        this.setState({ ...resetPage, sortBy });
    };

    private renderSortDropdown = () => {
        return (
            <TableActionSortDrowdown
                sortOptions={this.sortOptions}
                selected={this.state.sortBy}
                onSelect={this.onSorting}
            />
        );
    };

    private renderPlantActionTable = () => {
        return (plants, totalCount: number, limit: number, offset: number) => {
            const tablePageProps: ITablePageProps = getPageProps(totalCount, limit, offset);
            return this.renderPlantActionsPane(plants, tablePageProps);
        };
    };

    private onResult = (totalCount: number): void => {
        if (this.state.initialRender || totalCount !== this.state.totalCount) {
            this.setState({
                totalCount,
                initialRender: false,
            });
        }
    };

    private onPageChange = (pageIndex: number) => {
        const offset = this.state.limit * pageIndex;

        this.setState({
            pageIndex,
            offset,
        });
    };

    private handlePlantFilter = (ids: string[]) => {
        const plantId = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.plantId = plantId;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: any) => ({
            ...prevState,
            filter: { ...prevState.filter, plantId },
        }));
    };

    private handleDateFilter = (date: string) => {
        const { pathinfo } = this.state;
        pathinfo.params.date = date;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: any) => ({
            ...prevState,
            filter: { ...prevState.filter, date },
        }));
    };

    private renderTableFilters = () => {
        let { filter } = this.state;
        filter = {
            plantId: this.state.pathinfo.params.plantId,
            date: this.state.pathinfo.params.date,
        };
        return (
            <>
                <TableFilters>
                    <PlantListSelectMenu
                        onUpdate={this.handlePlantFilter}
                        selectHeader="Plants"
                        isClearable={true}
                        defaultSelected={filter.plantId}
                    />
                    <DateFilterSelectMenu
                        onUpdate={this.handleDateFilter}
                        isClearable={true}
                        defaultSelected={filter.date}
                    />
                    {this.renderSortDropdown()}
                </TableFilters>
            </>
        );
    };

    private renderPlantActionsPane = (plants: any, tablePageProps) => {
        plants = plants || [];
        const { pages, currentPageIndex, totalCount } = tablePageProps;
        return (
            <ControlledTable
                data={plants}
                columns={columns}
                pages={pages}
                page={currentPageIndex}
                onPageChange={this.onPageChange}
                totalCount={totalCount}
                filters={this.renderTableFilters}
            />
        );
    };

    public render = () => {
        const { offset, limit, forceRerenderKey, sortBy } = this.state;
        let { filter } = this.state;
        filter = {
            plantId: this.state.pathinfo.params.plantId,
            date: this.state.pathinfo.params.date,
        };

        return (
            <>
                <PlantActionsQuery
                    key={forceRerenderKey}
                    offset={offset}
                    limit={limit}
                    onResult={this.onResult}
                    onResultDataProp="totalCount"
                    renderFetchedPlantList={this.renderPlantActionTable()}
                    filter={!_.isEmpty(filter) ? filter : null}
                    sortBy={sortBy}
                />
            </>
        );
    };
}

export default withMainNavigation(PlantActions);
