import { Button } from 'rbx';
import * as React from 'react';

// HOC wrapper to blur button after clicking. Useful for modals...
export default class BlurButton extends React.Component<any> {
    private clickHandler = (e: Event) => {
        if (e && e.currentTarget) {
            (e.currentTarget as HTMLButtonElement).blur();
        }
        if (typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    };

    public render = () => {
        const { onClick, ...rest } = this.props;
        return (
            <Button type="button" onClick={this.clickHandler} {...rest}>
                {this.props.children}
            </Button>
        );
    };
}
