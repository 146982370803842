import { ErrorResponse } from 'apollo-link-error';
import axios from 'axios';

import { API_URI } from './connection';

// Client runtime errors
const errorHandler = (
    event: Event | string,
    source?: string,
    lineno?: number,
    colno?: number,
    error?: Error,
) =>
    sendError(
        JSON.stringify({
            userAgent: navigator.userAgent,
            url: window.location.href,
            event,
            source,
            lineno,
            colno,
            error,
        }),
    );

const rejectionHandler = (event: PromiseRejectionEvent) => errorHandler(event.reason);

export const sendError = (data: any) => {
    if (process.env.NODE_ENV === 'development') {
        const err: any = JSON.parse(data);
        console.error('CLIENT ERROR', err);
    }
    axios
        .post(`${API_URI}/api/client-errors`, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
        .catch(console.error);
};

export const logServerError = (error: ErrorResponse) => {
    if (!error) {
        return;
    }

    if (error.networkError) {
        return;
    }

    if (
        error.graphQLErrors &&
        error.graphQLErrors.find(err => err.extensions && err.extensions.code === 'FORBIDDEN')
    ) {
        return;
    }

    // Ignore requests with only BAD_USER_INPUT (logged in ps.api already)
    if (
        error.graphQLErrors &&
        !error.graphQLErrors.find(err => err.extensions && err.extensions.code !== 'BAD_USER_INPUT')
    ) {
        return;
    }

    const { /*variables, operationName, */ query } = error.operation;
    const data = {
        graphQLErrors: error.graphQLErrors.map(({ extensions, message, path }) => ({
            extensions,
            message,
            path,
        })),
        operation: {
            variables: error.operation.variables,
            operationName: error.operation.operationName,
            query: query.definitions,
        },
    };

    return sendError(JSON.stringify(data));
};

export const addRuntimeErrorListeners = () => {
    window.onerror = errorHandler;
    window.onunhandledrejection = rejectionHandler;
};
