import gql from 'graphql-tag';

export const CLEAR_RABBIT_MUTATION = gql`
    mutation ClearRabbitMutation {
        clearRabbit
    }
`;

export const CLEAR_ELASTIC_MUTATION = gql`
    mutation ClearElasticMutation {
        clearEsLog
    }
`;

export const DELETE_PAGES_FOR_PLANT_MUTATION = gql`
    mutation DeletePagesForPlantMutation($plantId: String!) {
        deletePagesForPlant(plantId: $plantId)
    }
`;

export const DELETE_EQUIPMENT_FOR_PLANT_MUTATION = gql`
    mutation DeletePartsForPlant($plantId: String!) {
        deletePartsForPlant(plantId: $plantId)
    }
`;

export const RABBIT_QUEUES = gql`
    query rabbitQueues($opts: RabbitQueueOptions) {
        rabbitQueues(opts: $opts) {
            page_count
            page_size
            page
            items {
                name
                messages
                messages_ready
                messages_unacknowledged
                message_stats {
                    deliver_get_details {
                        rate
                    }
                    ack_details {
                        rate
                    }
                    publish_details {
                        rate
                    }
                }
                avg_ack_egress_rate
                avg_ack_ingress_rate
                avg_egress_rate
                avg_ingress_rate
            }
        }
    }
`;

export const RABBIT_ESTIMATE = gql`
    query rabbitEstimate {
        rabbitEstimate
    }
`;

export const RABBIT_STATS = gql`
    query RabbitStats($opts: RabbitQueueOptions) {
        rabbitEstimate
        rabbitConsumerCount
        rabbitQueues(opts: $opts) {
            page_count
            page_size
            page
            items {
                name
                messages
                messages_ready
                messages_unacknowledged
                message_stats {
                    deliver_get_details {
                        rate
                    }
                    ack_details {
                        rate
                    }
                    publish_details {
                        rate
                    }
                }
                avg_ack_egress_rate
                avg_ack_ingress_rate
                avg_egress_rate
                avg_ingress_rate
            }
        }
    }
`;

export const QUERY_MAINTENANCE_MODE = gql`
    query maintenanceMode {
        maintenanceMode
    }
`;
