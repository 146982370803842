import gql from 'graphql-tag';

const USER_LIST_ALL_QUERY = gql`
    query Users($offset: Int, $limit: Int, $order: [UserOrder]) {
        users(offset: $offset, limit: $limit, order: $order) {
            data {
                id
                name
                active
            }
            limit
            offset
            totalCount
        }
    }
`;

const USER_LIST_QUERY = gql`
    query Users($offset: Int, $limit: Int, $order: [UserOrder], $filter: UserFilter) {
        users(offset: $offset, limit: $limit, order: $order, filter: $filter)
            @connection(key: "users") {
            data {
                id
                email
                name
                jobTitle
                active
                invitedAt
                globalRole
                memberships {
                    id
                    role
                    group {
                        id
                        name
                    }
                    plant {
                        id
                        name
                    }
                }
            }
            limit
            offset
            totalCount
        }
    }
`;

const USER_QUERY = gql`
    query User($id: Int!) {
        user(id: $id) {
            id
            email
            name
            jobTitle
            active
            invitedAt
            globalRole
            memberships {
                id
                role
                group {
                    id
                    name
                    description
                    parent {
                        id
                        name
                    }
                    groups {
                        id
                        name
                    }
                    plants {
                        id
                        name
                    }
                    memberships {
                        id
                        user {
                            id
                            name
                            email
                        }
                        role
                    }
                }
                plant {
                    id
                    name
                    customerDisplayName
                    invoiceAddress {
                        street
                        zip
                        city
                        country
                        responsible
                    }
                    deliveryAddress {
                        street
                        zip
                        city
                        country
                        responsible
                        latitude
                        longitude
                    }
                }
            }
            plantRoles {
                plant {
                    id
                    name
                    customerDisplayName
                    s3Folder
                    invoiceAddress {
                        street
                        zip
                        city
                        country
                        responsible
                        latitude
                        longitude
                    }
                    deliveryAddress {
                        street
                        zip
                        city
                        country
                        responsible
                        latitude
                        longitude
                    }
                }
            }
        }
    }
`;

const USER_CREATE_MUTATION = gql`
    mutation CreateUser($user: UserCreateInput!) {
        createUser(user: $user) {
            id
            email
            name
            jobTitle
            active
        }
    }
`;

const USER_UPDATE_MUTATION = gql`
    mutation UpdateUser($user: UserUpdateInput!) {
        updateUser(user: $user) {
            id
            email
            name
            jobTitle
            active
            memberships {
                id
                role
                group {
                    id
                    name
                }
                plant {
                    id
                    name
                }
            }
        }
    }
`;

const USER_INVITE_MUTATION = gql`
    mutation InviteUser($id: Int!) {
        inviteUser(id: $id) {
            id
            email
            name
            jobTitle
            active
            invitedAt
        }
    }
`;

const USER_DELETE_MUTATION = gql`
    mutation DeleteUser($id: Int!) {
        deleteUser(id: $id)
    }
`;

export {
    USER_LIST_QUERY,
    USER_QUERY,
    USER_CREATE_MUTATION,
    USER_UPDATE_MUTATION,
    USER_INVITE_MUTATION,
    USER_DELETE_MUTATION,
    USER_LIST_ALL_QUERY,
};
