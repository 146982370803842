// store stuff in local storage

class Storage {
    public get(key: string): any {
        return localStorage.getItem(key);
    }

    public set(key: string, data: string) {
        localStorage.setItem(key, data);
    }

    public remove(key: string) {
        localStorage.removeItem(key);
    }

    public setObj(key: string, data: any) {
        const [name, ...keys] = key.split('.');
        const obj = JSON.parse(localStorage.getItem(name)) || {};
        const length = keys.length - 1;
        let iter = obj;
        keys.forEach((nestedKey, i) => {
            iter[nestedKey] = i === length ? data : {};
            iter = iter[nestedKey];
        });
        localStorage.setItem(name, JSON.stringify(obj));
    }

    public getObj(key: string) {
        const [name, ...keys] = key.split('.');
        let obj = JSON.parse(localStorage.getItem(name));
        keys.forEach(k => {
            obj = (obj || {})[k];
        });
        return obj;
    }
}

export const storage = new Storage();
export { Storage };
