import { Content } from 'rbx';
import * as React from 'react';

import DocumentsSyncMutation from './DocumentsSyncMutation';

interface IProps {
    plantS3Folder: string;
    s3FileCount: number;
}

interface IState {
    pnidCount: number;
    pnidReady: boolean;
    pouploadCount: number;
    pouploadReady: boolean;
}

export default class DocumentsSync extends React.Component<IProps, IState> {
    public state: IState = {
        pnidCount: 0,
        pnidReady: false,
        pouploadCount: 0,
        pouploadReady: false,
    };

    public renderFolderInstruction() {
        return (
            <p>
                For Spindex to properly index a document from s3, it needs to be synced to spindex
                first. Anytime you add new documents to s3 which you want to have spindex index, you
                must sync them first.
            </p>
        );
    }

    public render() {
        const { plantS3Folder, s3FileCount } = this.props;
        const s3FolderName = 'plants/' + plantS3Folder;

        if (s3FileCount > 0) {
            return (
                <Content>
                    <p>
                        There are <strong>{s3FileCount}</strong> files in&nbsp;
                        <strong>{s3FolderName}</strong> which can be synced with spindex.
                    </p>
                    <p>
                        <DocumentsSyncMutation plantS3Folder={this.props.plantS3Folder} />
                    </p>
                    {this.renderFolderInstruction()}
                </Content>
            );
        }
        return (
            <Content>
                <p>
                    There are no indexable files in the S3-Folder {s3FolderName}. (Or the folder
                    does not exist)
                </p>
                <br />
                <p>Please upload files using a S3-Client</p>
                {this.renderFolderInstruction()}
            </Content>
        );
    }
}
