import { Button, Control, Delete, Field, Modal } from 'rbx';
import * as React from 'react';

import GroupListSelectMenu from '../../group/GroupListSelectMenu';
import ModalContainer from '../../ui/ModalContainer';
import PlantAddToGroupMutation from './../queries/PlantAddToGroupMutation';

interface IProps {
    closeModal: () => void;
    onCompleted: () => void;
    plantIds: string[];
}

interface IState {
    selectedGroup?: number | null;
}

export default class PlantsAddToGroupModal extends React.Component<IProps, IState> {
    public state: IState = {
        selectedGroup: null,
    };

    private setSelectedGroup = (ids: number[]) => {
        const [selectedGroup] = ids || [null];
        this.setState((prev: IState) => ({
            ...prev,
            selectedGroup,
        }));
    };

    public render = () => {
        const { closeModal, plantIds, onCompleted } = this.props;
        const { selectedGroup } = this.state;

        return (
            <ModalContainer isOpen={true} onRequestClose={closeModal}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Add plants to group</Modal.Card.Title>
                        <Delete onClick={closeModal} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Field>
                            <Control expanded={true}>
                                <GroupListSelectMenu onUpdate={this.setSelectedGroup} />
                            </Control>
                        </Field>
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button.Group pull="right">
                            <Button onClick={closeModal}>Cancel</Button>
                            <PlantAddToGroupMutation
                                groupId={selectedGroup}
                                plantIds={plantIds}
                                onCompleted={onCompleted}
                            />
                        </Button.Group>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };
}
