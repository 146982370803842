export const EXTENDED_FIELDS = `
    createdAt
    createdBy {
        id
    }
    updatedAt
    updatedBy {
        id
    }
`;

export const BASE_TAG_FIELDS = `
    id
    dataSource
    designation
    isOriginalCsv
    tagNumber
    tagNumberAsId
`;

export const BASE_PART_FIELDS = `
    id
    tagNumber
    tagNumberAsId
`;

export const EXTENDED_PART_FIELDS = `
    ${BASE_PART_FIELDS}
    ${EXTENDED_FIELDS}
    certificate
    clientNo
    contract
    customerMaterialNum
    designation
    drawingNum
    drawingSectNum
    id
    lindeIdentNum
    manufactPartNum
    material
    modelNum
    notes
    originalOrderNum
    originalVendor
    oxygenRelevance
    oxygenRelevance2
    qtyInstalledPerUnit
    qtyUnit
    serialNum
    specNum
    tagNumber
    tagNumberAsId
    type
    unitOfMeasurement
`;

export const EXTENDED_TAG_FIELDS = `
    ${BASE_TAG_FIELDS}
    ${EXTENDED_FIELDS}
    certificate
    clientNo
    contract
    customerMaterialNum
    dataSource
    designation
    drawingNum
    drawingSectNum
    lindeIdentNum
    manufactPartNum
    material
    modelNum
    notes
    originalOrderNum
    originalVendor
    oxygenRelevance
    oxygenRelevance2
    qtyInstalledPerUnit
    qtyUnit
    serialNum
    specNum
    type
    unitOfMeasurement
`;
