import axios from 'axios';
import { Button, Delete, Input, Modal, Textarea } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getAuthTokenForHTTP } from '../../api/authorization';
import { API_URI } from '../../api/connection';
import { FEEDBACK_MUTATION } from '../../api/GraphQLQueries/FeedBack';
import { fileSizeForHumans } from '../../utils/helpers';
import { notification } from '../../utils/notification';
import Icon from '../ui/Icon';
import ModalContainer from '../ui/ModalContainer';

const MAX_COMBINED_UPLOAD_SIZE = 5242880;
const MAX_FILE_UPLOAD_COUNT = 5;
const FEEDBACK_TEXT = `How can we help you?

You can you use this form to ask questions or send us feedback of any kind.

If you have a plant related question, please provide us with plant name.

You can also upload attachments describing your issue.`;

interface IProps extends RouteComponentProps<any> {
    onClose: () => void;
}

interface IState {
    feedbackText: string;
    files?: IMultiUploadResponse[];
    loading?: number;
    fileList?: FileList;
}

interface IMultiUploadResponse {
    s3Key: string;
    filename: string;
}

class FeedBackModal extends React.Component<IProps, IState> {
    private inputElement = React.createRef<HTMLInputElement>();

    public state: IState = {
        feedbackText: '',
        files: null,
        loading: 0,
    };

    private onError = (): void => {
        notification.error('There was an error sending your feedback');
        this.setState({ loading: 0 });
    };

    private onCompleted = (): void => {
        notification.success('Thank you for your feedback');
        this.setState({ loading: 0 });
        this.props.onClose();
    };

    private onFormChange = (event: Event) => {
        this.setState({ feedbackText: (event.target as HTMLTextAreaElement).value });
    };

    private uploadFiles = async (): Promise<IMultiUploadResponse[]> => {
        const { fileList } = this.state;
        const count = fileList.length;

        if (!count) {
            return null;
        }
        if (fileList.length > MAX_FILE_UPLOAD_COUNT) {
            notification.warn(`Max number of files is ${MAX_FILE_UPLOAD_COUNT}`);
            this.setState({ loading: 0 });
            return null;
        }

        const token = await getAuthTokenForHTTP();
        const formData = new FormData();

        let size = 0;
        for (let i = 0; i < count; ++i) {
            formData.append('files[]', fileList[i]);
            size += fileList[i].size;
        }

        if (size > MAX_COMBINED_UPLOAD_SIZE) {
            notification.warn(
                `Max file size must be lower than ${fileSizeForHumans(MAX_COMBINED_UPLOAD_SIZE)}`,
            );
            this.setState({ loading: 0 });
            return null;
        }

        return await axios
            .post(`${API_URI}/api/s3-proxy/upload-feedback-attachment`, formData, {
                headers: { authorization: `${token ? `bearer ${token}` : ''}` },
            })
            .then(({ data }) => data)
            .catch(this.onError);
    };

    private submit = (feedbackMutationFunction: MutationFn<any, any>) => async () => {
        this.setState({ loading: 1 });

        const { fileList } = this.state;
        if (fileList && fileList.length) {
            const uploads = await this.uploadFiles();
            if (!uploads) {
                return;
            }
            this.setState({ files: uploads });
        }

        this.setState({ loading: 2 });
        return await feedbackMutationFunction()
            .then(this.onCompleted)
            .catch(this.onError);
    };

    private updateFiles = () => {
        const fileList = this.inputElement.current.files;
        this.setState({ fileList });
    };

    private handleSelect = () => {
        if (this.inputElement.current) {
            this.inputElement.current.click();
        }
    };

    private removeFiles = () => {
        this.setState({ fileList: null, files: null });
        this.inputElement.current.files = null;
    };

    private renderSelectedFiles = () => {
        const files = this.state.fileList;
        if (!files || !files.length) {
            return null;
        }

        let count = 0;
        let fileSize = 0;
        const names = [];
        Array.from(files).forEach(file => {
            fileSize += file.size;
            count++;
            names.push(file.name);
        });

        return (
            <>
                <Button className="close-button is-danger" onClick={this.removeFiles}>
                    Remove Files
                </Button>
                <span title={names.join(', ')}>
                    {count} Files selected ({fileSizeForHumans(fileSize)})
                </span>
            </>
        );
    };

    private renderFeedBackAttachement() {
        const files = this.state.fileList;
        const suportedFormat = [
            '.doc',
            '.docx',
            '.gif',
            '.jpg',
            '.pdf',
            '.png',
            '.ppt',
            '.pptx',
            '.rtf',
            '.txt',
        ].join(',');

        return (
            <>
                <Input
                    type="file"
                    accept={suportedFormat}
                    hidden={true}
                    multiple={true}
                    ref={this.inputElement}
                    onChange={this.updateFiles}
                />
                {(!files && (
                    <>
                        <Button color="light" onClick={this.handleSelect}>
                            Select File(s)
                        </Button>
                        <span>
                            Add attachments ({MAX_FILE_UPLOAD_COUNT} files,{' '}
                            {fileSizeForHumans(MAX_COMBINED_UPLOAD_SIZE)} total)
                        </span>
                    </>
                )) ||
                    this.renderSelectedFiles()}
            </>
        );
    }

    public render = () => {
        const { onClose } = this.props;
        const { feedbackText, files, loading } = this.state;

        const loadingText = ['Submit', 'Uploading', 'Sending'];

        return (
            <ModalContainer isOpen={true} onRequestClose={onClose}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Contact Us</Modal.Card.Title>
                        <Delete onClick={onClose} />
                    </Modal.Card.Head>

                    <Modal.Card.Body>
                        <Textarea
                            rows={10}
                            label="Send us some feedback"
                            value={feedbackText}
                            onChange={this.onFormChange}
                            placeholder={FEEDBACK_TEXT}
                        />
                    </Modal.Card.Body>
                    <Modal.Card.Foot className="flex-justify-space-between">
                        <section className="left">{this.renderFeedBackAttachement()}</section>
                        <section className="right">
                            <Button color="grey" onClick={onClose}>
                                Cancel
                            </Button>
                            <Mutation
                                mutation={FEEDBACK_MUTATION}
                                variables={{
                                    feedback: {
                                        feedbackText,
                                        files,
                                    },
                                }}
                            >
                                {doSendMutation => (
                                    <Button
                                        color="primary"
                                        onClick={this.submit(doSendMutation)}
                                        disabled={!feedbackText || loading}
                                        data-cy="feedback-send-button"
                                    >
                                        {loading > 0 && (
                                            <Icon iconClass="pl-1 pr-2" icon="fa-cog fa-spin" />
                                        )}
                                        {loadingText[loading]}
                                    </Button>
                                )}
                            </Mutation>
                        </section>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };
}

export default withRouter(FeedBackModal);
