import * as React from 'react';

import { TAG_LIST_QUERY } from '../../../api/GraphQLQueries/Tag';
import ListQueryContainer, {
    IGraphQLListProps,
} from '../../../containers/common/ListQueryContainer';
import IPartModel from '../../../models/IPartModel';

interface IProps extends IGraphQLListProps {
    plantId: string;
    renderFetchedPartList: (
        tags: IPartModel[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => JSX.Element;
}

export default class TagListQuery extends React.Component<IProps, any> {
    public render = () => {
        const { renderFetchedPartList, plantId } = this.props;

        return (
            <ListQueryContainer
                {...this.props}
                query={TAG_LIST_QUERY}
                dataProp="tags"
                fetchPolicy="network-only"
                renderFetchedList={renderFetchedPartList}
                variables={{ plantId }}
            />
        );
    };
}
