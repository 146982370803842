import * as _ from 'lodash';
import { Button, Tag } from 'rbx';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import IsAdminQuery from '../components/auth/IsAdminQuery';
import GroupListSelectMenu from '../components/group/GroupListSelectMenu';
import PlantsAddToGroupModal from '../components/plant/forms/PlantsAddToGroupModal';
import PlantActions from '../components/plant/PlantActions';
import PlantStats from '../components/plant/PlantStats';
import PlantTilesList from '../components/plant/PlantTilesList';
import BlurButton from '../components/ui/BlurButton';
import { DEFAULT_PAGE_SIZE } from '../components/ui/paging/Paging';
import TableFilters from '../components/ui/table/TableFilters';
import UserListSelectMenu from '../components/user/UserListSelectMenu';
import { buildPath, getUrlParams, IUrl } from '../utils/queryString';
import PageHeader from './../components/layout/PageHeader';
import PlantCreateContainer from './../components/plant/PlantCreate';
import PlantUpdate from './../components/plant/PlantUpdate';
import PlantListQuery from './../components/plant/queries/PlantListQuery';
import { getPageProps, ITablePageProps } from './../components/ui/paging/PageProps';
import DefaultTable, { ITableColumnModel } from './../components/ui/table/ControlledTable';
import TableActions from './../components/ui/table/TableActions';
import TableActionSortDrowdown, {
    ISortOption,
} from './../components/ui/table/TableActionSortDrowdown';
import withPaging from './../components/ui/table/withPaging';
import IGroupModel from './../models/IGroupModel';
import IPlantModel from './../models/IPlantModel';
import Routing from './../Routing';
import { todo } from './../utils/translate';
import { ISorting } from './common/ListQueryContainer';
import withMainNavigation from './withMainNavigation';

const ControlledTable = withPaging(DefaultTable);

const sortOptions: ISortOption[] = [
    {
        optionName: 'Name',
        sortByField: 'name',
    },
    {
        optionName: 'Plant Id',
        sortByField: 'id',
    },
];

interface IRouteParams {
    plantId: string;
}

interface IState {
    totalCount: number;
    offset: number;
    limit: number;
    pageIndex: number;
    forceRerenderKey: number;
    initialRender: boolean;
    selectedPlantIds: string[];
    sortBy?: ISorting;
    showAddPlantsToGroupModal?: boolean;
    filter?: any;
    pathinfo: IUrl;
}

class PlantListContainer extends React.Component<RouteComponentProps<IRouteParams>, IState> {
    public state: IState = {
        totalCount: 0,
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        sortBy: undefined,
        forceRerenderKey: 0,
        initialRender: true,
        showAddPlantsToGroupModal: false,
        selectedPlantIds: [],
        filter: {
            searchText: '',
            group: undefined,
            createdBy: undefined,
        },
        pathinfo: getUrlParams(this.props.location),
    };

    private columns: ITableColumnModel[] = [
        {
            Header: 'Name',
            accessor: 'customerDisplayName',
        },
        {
            Header: 'Plant ID',
            accessor: 'id',
        },
        {
            Header: 'Groups',
            accessor: (data: any) => {
                const groups: IGroupModel[] = data.groups || [];
                return (
                    <Tag.Group>
                        {groups.map((group: IGroupModel) => {
                            return <Tag key={group.id}>{group.name}</Tag>;
                        })}
                    </Tag.Group>
                );
            },
        },
        {
            accessor: (data: any) => this.buildActionButtonsCell(data.id),
            disableRowHandler: true,
        },
    ];

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                offset: 0,
                pageIndex: 0,
                forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
            }),
            this.closeAddPlantsModal,
        );
    };

    private buildActionButtonsCell = (actionValue: any) => {
        return (
            <Button.Group align="right">
                <BlurButton size="small" onClick={this.searchAction(actionValue)}>
                    Search
                </BlurButton>
                <BlurButton size="small" onClick={this.viewTagsAction(actionValue)}>
                    View tags
                </BlurButton>
                <BlurButton size="small" onClick={this.viewDetailsAction(actionValue)}>
                    Details
                </BlurButton>
            </Button.Group>
        );
    };

    private openPlantDetails = (id: string): void => {
        this.props.history.push(Routing.PLANT_DETAILS.getPath(id));
    };

    private viewDetailsAction = (id: string) => () => {
        this.openPlantDetails(id);
    };

    private searchAction = (id: string) => () => {
        this.props.history.push(Routing.SEARCH_PLANT.getPath(id));
    };

    private viewTagsAction = (id: string) => () => {
        this.props.history.push(Routing.PLANT_TAGS.getPath(id));
    };

    private onClickRow = (id: string) => {
        this.openPlantDetails(id);
    };

    private onPlantCreate = () => {
        this.props.history.push(Routing.PLANT_CREATE.getPath());
    };

    private onPageChange = (pageIndex: number) => {
        const offset = this.state.limit * pageIndex;

        this.setState((prevState: IState) => ({
            ...prevState,
            pageIndex,
            offset,
        }));
    };

    private toggleForceRerenderKey = (prevKey: number): number => {
        return prevKey < 1 ? 1 : 0;
    };

    private renderPlantEditSidebar = () => {
        const { plantId } = this.props.match.params;
        return plantId && <PlantUpdate idPlant={plantId} onCancel={this.hidePlantSidebar} />;
    };

    private renderPlantCreateSidebar = () => {
        const onCreatePlant = (id: string) => {
            this.setState(
                (prevState: IState) => ({
                    ...prevState,
                    offset: 0,
                    pageIndex: 0,
                    forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
                }),
                () => this.openPlantDetails(id),
            );
        };

        return <PlantCreateContainer onCancel={this.hidePlantSidebar} onSuccess={onCreatePlant} />;
    };

    private renderPlantStatsSidebar = () => {
        return <PlantStats onCancel={this.hidePlantSidebar} />;
    };

    private renderPlantActionsSidebar = () => {
        return <PlantActions onCancel={this.hidePlantSidebar} />;
    };

    private onSorting = (sortByOptions?: ISorting) => {
        const sortBy = sortByOptions || undefined;

        this.setState((prevState: IState) => ({
            ...prevState,
            offset: 0,
            pageIndex: 0,
            sortBy,
        }));
    };

    private renderSortDropdown = () => {
        return (
            <TableActionSortDrowdown
                sortOptions={sortOptions}
                selected={this.state.sortBy}
                onSelect={this.onSorting}
            />
        );
    };

    private closeAddPlantsModal = () => {
        this.setState((prev: IState) => ({ ...prev, showAddPlantsToGroupModal: false }));
    };

    private openAddPlantsModal = (ids: string[]) => () => {
        this.setState((prev: IState) => ({
            ...prev,
            selectedPlantIds: ids,
            showAddPlantsToGroupModal: true,
        }));
    };

    private handleSearchFilter = (event: any) => {
        const filter = { searchText: event.currentTarget.value };
        this.setState((prevState: IState) => ({ ...prevState, filter }));
    };

    private multiSelectActionButtons = (ids: any[], toggleAllFn: any) => {
        const disabled = _.isEmpty(ids);
        const { searchText } = this.state.filter;

        return (
            <>
                <TableActions
                    enableToggleAll={true}
                    onToggleAll={toggleAllFn}
                    renderSortDropdown={this.renderSortDropdown}
                    onCustomFilterChange={this.handleSearchFilter}
                    customFilterValue={searchText}
                >
                    <Button.Group>
                        <Button
                            size="small"
                            disabled={disabled}
                            onClick={this.openAddPlantsModal(ids)}
                        >
                            Add to Group
                        </Button>
                    </Button.Group>
                </TableActions>
            </>
        );
    };

    private handleCreatedByFilter = (ids: number[]) => {
        const createdBy = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.createdBy = createdBy;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, createdBy },
        }));
    };

    private handleGroupFilter = (ids: number[]) => {
        const group = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.group = group;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, group },
        }));
    };

    private renderTableFilters = () => {
        const filter = {
            createdBy: parseInt(this.state.pathinfo.params.createdBy, 10),
            group: parseInt(this.state.pathinfo.params.group, 10),
        };

        return (
            <>
                <TableFilters>
                    <UserListSelectMenu
                        onUpdate={this.handleCreatedByFilter}
                        defaultSelected={[filter.createdBy]}
                        selectHeader="Created by"
                    />
                    <GroupListSelectMenu
                        onUpdate={this.handleGroupFilter}
                        defaultSelected={[filter.group]}
                        selectHeader="Group"
                    />
                </TableFilters>
            </>
        );
    };

    private renderControlledTable = (
        plantList: IPlantModel[],
        tablePageProps: ITablePageProps,
    ): JSX.Element => {
        const { pages, currentPageIndex, totalCount } = tablePageProps;
        const { plantId } = this.props.match.params;
        plantList = _.sortBy(plantList, [
            (plant: IPlantModel) => plant.customerDisplayName.toLocaleLowerCase(),
        ]);

        return (
            <ControlledTable
                data={plantList}
                columns={this.columns}
                onClickRow={this.onClickRow}
                onClickRowReturnData="id"
                actionButtons={this.multiSelectActionButtons}
                multiselectDataProp="id"
                selectedRow={plantId}
                selectedRowDataProp="id"
                totalCount={totalCount}
                pages={pages}
                page={currentPageIndex}
                onPageChange={this.onPageChange}
                filters={this.renderTableFilters}
            />
        );
    };

    private renderPlantTable = (
        plantList: IPlantModel[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => {
        const tablePageProps: ITablePageProps = getPageProps(totalCount, limit, offset);
        return this.renderControlledTable(plantList, tablePageProps);
    };

    private onResult = (totalCount: number): void => {
        if (this.state.initialRender || totalCount !== this.state.totalCount) {
            this.setState((prevState: IState) => ({
                ...prevState,
                totalCount,
                initialRender: false,
            }));
        }
    };

    private headerButtons = () => {
        return (
            <>
                <BlurButton onClick={this.onPlantCreate}>{todo('Create new plant')}</BlurButton>
            </>
        );
    };

    private showCreatePlant = () => {
        return this.props.location.pathname === Routing.PLANT_CREATE.getPath();
    };

    private showStats = () => {
        return this.props.location.pathname === Routing.PLANT_STATS.getPath();
    };

    private showPlantActions = () => {
        return this.props.location.pathname === Routing.PLANT_ACTIONS.getPath();
    };

    private hidePlantSidebar = () => {
        // TODO: Here would be a great place to add a confirmation dialog for unsaved changes. Some day.
        this.props.history.push(Routing.PLANTS.getPath());
    };

    private showEditPlant = () => {
        const { plantId } = this.props.match.params;

        return !!plantId;
    };

    private renderPlantTiles = () => {
        return <PlantTilesList />;
    };

    private renderPlantList = () => {
        const {
            offset,
            limit,
            sortBy,
            totalCount,
            forceRerenderKey,
            showAddPlantsToGroupModal,
        } = this.state;

        const filter = {
            searchText: this.state.filter.searchText,
            createdBy: parseInt(this.state.pathinfo.params.createdBy, 10),
            group: parseInt(this.state.pathinfo.params.group, 10),
        };

        return (
            <div>
                <PageHeader buttons={this.headerButtons()}>Plants ({totalCount}) </PageHeader>
                <PlantListQuery
                    key={forceRerenderKey}
                    offset={offset}
                    limit={limit}
                    sortBy={sortBy}
                    filter={!_.isEmpty(filter) ? filter : null}
                    onResult={this.onResult}
                    onResultDataProp="totalCount"
                    renderFetchedPlantList={this.renderPlantTable}
                />
                {this.showCreatePlant() && this.renderPlantCreateSidebar()}
                {this.showStats() && this.renderPlantStatsSidebar()}
                {this.showPlantActions() && this.renderPlantActionsSidebar()}

                {this.showEditPlant() && this.renderPlantEditSidebar()}
                {showAddPlantsToGroupModal && (
                    <PlantsAddToGroupModal
                        closeModal={this.closeAddPlantsModal}
                        onCompleted={this.onCompleted}
                        plantIds={this.state.selectedPlantIds}
                    />
                )}
            </div>
        );
    };

    public render() {
        return (
            <>
                <IsAdminQuery
                    renderAdmin={this.renderPlantList}
                    renderUser={this.renderPlantTiles}
                />
            </>
        );
    }
}

export default withMainNavigation(PlantListContainer);
