import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import { USER_UPDATE_MUTATION } from './../../api/GraphQLQueries/User';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import Pane from './../../components/ui/Pane';
import IUserModel from './../../models/IUserModel';
import Routing from './../../Routing';
import { todo } from './../../utils/translate';
import UserUpdateForm from './forms/UserUpdateForm';
import UserDeleteMutation from './queries/UserDeleteMutation';
import UserDetailQuery from './queries/UserDetailQuery';

interface IProps {
    idUser: number;
    onCancel: () => void;
    onDeleteUser: () => void;
}

interface IState {
    user?: IUserModel;
    error: boolean;
    success: boolean;
}

export default class UserUpdate extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private resetState = () => {
        this.setState({ error: false, success: false });
    };

    private updateValues = (user: IUserModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            user,
            error: false,
            success: false,
        }));
    };

    private formSubmit = async (doUserUpdateMutation: MutationFn) => {
        const { user } = this.state;

        await doUserUpdateMutation({
            variables: {
                user: _.omit(
                    user,
                    'active',
                    '__typename',
                    'memberships',
                    'plantRoles',
                    'invitedAt',
                    'globalRole',
                ),
            },
        });
        this.resetState();
    };

    private renderUserUpdateForm = (user: IUserModel, doUserUpdate: MutationFn) => {
        const { onCancel } = this.props;

        const updateUser = (
            <Button onClick={this.formSubmit.bind(this, doUserUpdate)} disabled={!this.state.user}>
                Save
            </Button>
        );

        const deleteUser = <UserDeleteMutation id={user.id} onSuccess={this.props.onDeleteUser} />;

        return (
            <Pane
                onClose={onCancel}
                actionButtons={updateUser}
                title={todo('Edit user')}
                sidepane={true}
                dangerButton={deleteUser}
            >
                <UserUpdateForm
                    user={user}
                    hasChanges={!!this.state.user}
                    onUpdate={this.updateValues}
                />
            </Pane>
        );
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));
    };

    private renderUserUpdateMutation = (user: IUserModel) => {
        return (
            <Mutation
                mutation={USER_UPDATE_MUTATION}
                onError={this.onError}
                onCompleted={this.onCompleted}
            >
                {doUserUpdate => this.renderUserUpdateForm(user, doUserUpdate)}
            </Mutation>
        );
    };

    private renderUserForm = (userData: IUserModel) => {
        if (!userData) {
            return <Redirect to={Routing.USER_LIST.getPath()} />;
        }

        const { user } = this.state;
        const { idUser } = this.props;

        if (user && user.id === idUser) {
            return this.renderUserUpdateMutation(user);
        }

        return this.renderUserUpdateMutation(userData);
    };

    public render() {
        const { idUser } = this.props;
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>User update error</Error>}
                {success && <Message type="success">Successfully updated user</Message>}
                {idUser && (
                    <UserDetailQuery id={idUser} renderFetchedUserData={this.renderUserForm} />
                )}
            </>
        );
    }
}
