import { Button, Column, Control, Field, Help, Icon, Input, Label, Table, Title } from 'rbx';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { friendlyRole } from '../../../models/IMembershipModel';
import Routing from '../../../Routing';
import { isRequired } from '../../../utils/helpers';
import { t } from '../../../utils/translate';
import IsAdminQuery from '../../auth/IsAdminQuery';
import NavButton from '../../nav/NavButton';
import UsersForPlantQueryContainer from '../queries/UsersForPlantQuery';
import {
    IPlantAddressModel,
    IPlantUpdateModel,
    IPlantUserRoleModel,
} from './../../../models/IPlantModel';
import GroupListSelectMenu from './../../group/GroupListSelectMenu';
import PlantAddressForm from './PlantAddressForm';

interface IProps {
    onUpdate: (plant: IPlantUpdateModel) => void;
    plant: IPlantUpdateModel;
}

export default class PlantUpdateForm extends React.Component<IProps, any> {
    private nameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.plant,
            name: context.currentTarget.value,
        });
    };

    private customerDisplayNameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.plant,
            customerDisplayName: context.currentTarget.value,
        });
    };

    private deliveryAddressChange = (address: IPlantAddressModel): void => {
        this.props.onUpdate({
            ...this.props.plant,
            deliveryAddress: address,
        });
    };

    private invoiceAddressChange = (address: IPlantAddressModel): void => {
        this.props.onUpdate({
            ...this.props.plant,
            invoiceAddress: address,
        });
    };

    private groupsChange = (groupIds: number[]): void => {
        this.props.onUpdate({
            ...this.props.plant,
            groupIds,
        });
    };

    private renderUser = (iPlantUserRoleModel: IPlantUserRoleModel): JSX.Element => {
        const { user, role } = iPlantUserRoleModel;

        return (
            user && (
                <Table.Row key={user.id}>
                    <Table.Cell>{user.name}</Table.Cell>
                    <Table.Cell className="ellipsis" style={{ maxWidth: '15vw' }}>
                        {user.email}
                    </Table.Cell>
                    <Table.Cell>{friendlyRole(role)}</Table.Cell>
                    <Table.Cell>
                        <NavButton to={Routing.USER_DETAILS.getPath(`${user.id}`)} size="small">
                            View
                        </NavButton>
                    </Table.Cell>
                </Table.Row>
            )
        );
    };

    private renderUsers = (iPlantUserRoleModels: IPlantUserRoleModel[]): JSX.Element => {
        if (!iPlantUserRoleModels || !iPlantUserRoleModels.length) {
            return <p>No users found</p>;
        }
        return (
            <Table fullwidth={true}>
                <Table.Head>
                    <Table.Row>
                        <Table.Heading>Name</Table.Heading>
                        <Table.Heading>Email</Table.Heading>
                        <Table.Heading>Role</Table.Heading>
                        <Table.Heading />
                    </Table.Row>
                </Table.Head>
                <Table.Body>{iPlantUserRoleModels.map(this.renderUser)}</Table.Body>
            </Table>
        );
    };

    private renderAdminActionButtons = () => {
        const { plant } = this.props;
        return (
            <>
                {plant && (
                    <>
                        <Link
                            className="button is-primary"
                            to={Routing.PLANT_ONBOARDING.getPath(plant.id)}
                        >
                            Onboarding
                        </Link>
                        <Link
                            className="button is-primary"
                            to={Routing.PLANT_TAGS.getPath(plant.id)}
                        >
                            View Tags
                        </Link>
                    </>
                )}
            </>
        );
    };

    public render = () => {
        const {
            name,
            id,
            groupIds,
            s3Folder,
            customerDisplayName,
            invoiceAddress,
            deliveryAddress,
        } = this.props.plant;
        const isNameNotValid = name!.length < 3;
        return (
            <>
                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>S3 Folder</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true} iconRight={false}>
                                <Input
                                    type="string"
                                    placeholder="S3 folder"
                                    value={`plants/${s3Folder}`}
                                    disabled={true}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-folder-tree" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>LE Plant ID</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true} iconRight={false}>
                                <Input
                                    type="string"
                                    placeholder="LE Plant ID"
                                    value={id}
                                    disabled={true}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label>
                        <Label>{isRequired('Plant name')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Name"
                                    value={name}
                                    color={isNameNotValid ? 'danger' : ''}
                                    onChange={this.nameChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                                {isNameNotValid && (
                                    <Help color="danger">{t().plant_name_id_complexity}</Help>
                                )}
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label>
                        <Label>Customer Display Name</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Name"
                                    value={customerDisplayName || ''}
                                    onChange={this.customerDisplayNameChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label>
                        <Label>Assign to group</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <GroupListSelectMenu
                                multiple={true}
                                onUpdate={this.groupsChange}
                                defaultSelected={groupIds}
                            />
                        </Field>
                    </Field.Body>
                </Field>

                <hr />

                <Button.Group>
                    <IsAdminQuery renderAdmin={this.renderAdminActionButtons} />

                    <Link className="button is-primary" to={Routing.SEARCH_PLANT.getPath(id)}>
                        Search
                    </Link>
                </Button.Group>

                <hr />

                <Column.Group>
                    <Column>
                        <PlantAddressForm
                            hideLatLng={true}
                            title="Invoice Address"
                            address={invoiceAddress || {}}
                            onUpdate={this.invoiceAddressChange}
                        />
                    </Column>
                    <Column>
                        <PlantAddressForm
                            title="Delivery Address"
                            address={deliveryAddress || {}}
                            onUpdate={this.deliveryAddressChange}
                        />
                    </Column>
                </Column.Group>

                <hr />

                <Title size="5">
                    Users
                    <NavButton to={Routing.USER_LIST.route} pull="right">
                        Add Users
                    </NavButton>
                </Title>
                <UsersForPlantQueryContainer plantId={id} renderFetchedUsers={this.renderUsers} />
            </>
        );
    };
}
