import { Select } from 'rbx';
import * as React from 'react';

import IUserModel from '../../models/IUserModel';
import UserPlantsQuery from '../user/queries/UserPlantsQuery';
import IPlantModel from './../../models/IPlantModel';

interface IProps {
    onUpdate?: (id: string) => void;
    defaultSelected?: string;
    disabled?: boolean;
    showPlaceHolder?: boolean;
}

interface IState {
    plant: string;
}

export default class PlantListDropdown extends React.Component<IProps, IState> {
    public state: IState = {
        plant: '',
    };

    public componentDidMount() {
        this.setState({ plant: this.props.defaultSelected || '' });
    }

    private handleChange = (event: any): void => {
        const { onUpdate } = this.props;
        if (typeof onUpdate === 'function') {
            onUpdate(event.target.value);
        }
    };

    private renderSelectMenuItem(plant: IPlantModel) {
        return (
            <Select.Option key={plant.id} value={plant.id}>
                {plant.name}
            </Select.Option>
        );
    }

    private renderSelectMenu = (user: IUserModel, plants: IPlantModel[]) => {
        void user;
        const { defaultSelected, disabled, showPlaceHolder } = this.props;

        return (
            <>
                <Select.Container>
                    <Select
                        disabled={disabled}
                        defaultValue={defaultSelected || ''}
                        onChange={this.handleChange}
                        className="dropdown-content"
                    >
                        {showPlaceHolder && (
                            <Select.Option disabled={true} value={''}>
                                Select Plant
                            </Select.Option>
                        )}

                        {plants.map((plant: IPlantModel) => {
                            return this.renderSelectMenuItem(plant);
                        })}
                    </Select>
                </Select.Container>
            </>
        );
    };

    public render() {
        return <UserPlantsQuery renderFetchedPlantData={this.renderSelectMenu} />;
    }
}
