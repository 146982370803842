import * as React from 'react';

import MultiSelect from '../ui/MultiSelect';

const ROLES = [
    { value: 'customer_admin', label: 'Customer Admin' },
    { value: 'customer_user', label: 'Customer User' },
];

interface IProps {
    defaultSelected?: string;
    onUpdate: (role: string) => void;
}

export default class RoleSelectMenu extends React.Component<IProps, any> {
    private handleChange = (role: { value: string; label: string } | any) => {
        this.props.onUpdate(role.value);
    };

    public render() {
        const { defaultSelected } = this.props;
        const initial = ROLES.find(r => r.value === defaultSelected);

        return (
            <MultiSelect
                value={initial}
                options={ROLES}
                placeholder="Select user role"
                onChange={this.handleChange}
            />
        );
    }
}
