import * as React from 'react';
import { Mutation } from 'react-apollo';

import { CLEAR_ELASTIC_MUTATION } from '../../api/GraphQLQueries/Ops';
import { notification } from '../../utils/notification';
import OpsModal from './OpsModal';

export default class ClearRabbitMutation extends React.Component {
    private formSubmit = (doClearElasticMutation: any) => (): void => {
        doClearElasticMutation();
    };

    private onError = (): void => {
        notification.error('Error clearing elastic log');
    };

    private onCompleted = (): void => {
        notification.success('Elastic log successfully cleared');
    };

    public render() {
        return (
            <>
                <Mutation
                    mutation={CLEAR_ELASTIC_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {doClearElasticMutation => (
                        <OpsModal
                            title="Clear Elastic Log"
                            onSubmit={this.formSubmit(doClearElasticMutation)}
                            bodyText={`<strong>Warning</strong>: This will remove all log data.<br />Click "Sure" if this is what you want.`}
                        />
                    )}
                </Mutation>
            </>
        );
    }
}
