export const TAG_FIELDS = [
    'dataSource',
    'designation',
    'drawingNum',
    'lindeIdentNum',
    'notes',
    'originalOrderNum',
    'originalVendor',
    'serialNum',
    'specNum',
    'tagNumber',
    'type',
];

export const PART_FIELDS = [
    'certificate',
    'contract',
    'customerMaterialNum',
    'designation',
    'drawingSectNum',
    'lindeIdentNum',
    'manufactPartNum',
    'notes',
    'originalOrderNum',
    'originalVendor',
    'oxygenRelevance',
    'oxygenRelevance2',
    'qtyInstalledPerUnit',
    'qtyUnit',
    'specNum',
    'type',
];
