import * as _ from 'lodash';
import { Button, Column, Control, Field } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import UserUpdatePassword from '../components/user/forms/userUpdatePassword';
import { getToken } from './../api/authorization';
import { USER_UPDATE_MUTATION } from './../api/GraphQLQueries/User';
import Error from './../components/layout/Error';
import Message from './../components/layout/Message';
import PageHeader from './../components/layout/PageHeader';
import UserUpdateBaseFields from './../components/user/forms/UserUpdateBaseFields';
import UserDetailQuery from './../components/user/queries/UserDetailQuery';
import IUserModel from './../models/IUserModel';
import withMainNavigation from './withMainNavigation';

interface IState {
    user?: IUserModel;
    error: boolean;
    success: boolean;
    showUserUpdatePasswordModal: boolean;
}

class ProfileContainer extends React.Component<any, IState> {
    public state: IState = {
        error: false,
        success: false,
        showUserUpdatePasswordModal: false,
    };

    private updateValues = (user: IUserModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            user,
            error: false,
            success: false,
        }));
    };

    private formSubmit = (doUserUpdateMutation: MutationFn) => (): void => {
        const user = _.omit(this.state.user, [
            '__typename',
            'active',
            'memberships',
            'invitedAt',
            'globalRole',
            'email',
        ]);

        doUserUpdateMutation({ variables: { user } });
    };

    private closePasswordChangeModal = () => {
        this.setState({ showUserUpdatePasswordModal: false });
    };

    private openPasswordChangeModal = () => {
        this.setState({ showUserUpdatePasswordModal: true });
    };

    private renderUserUpdateForm = (user: IUserModel, doUserUpdate: MutationFn) => {
        const { showUserUpdatePasswordModal } = this.state;
        return (
            <>
                <Column.Group>
                    <Column size={6}>
                        <UserUpdateBaseFields user={user} onUpdate={this.updateValues} />
                        {showUserUpdatePasswordModal && (
                            <UserUpdatePassword onModalClose={this.closePasswordChangeModal} />
                        )}
                        <Field horizontal={true}>
                            <Field.Label />
                            <Field.Body>
                                <Control>
                                    <Button.Group>
                                        <Button
                                            onClick={this.formSubmit(doUserUpdate)}
                                            disabled={!this.state.user}
                                        >
                                            Save profile
                                        </Button>
                                        <Button onClick={this.openPasswordChangeModal}>
                                            Change Password
                                        </Button>
                                    </Button.Group>
                                </Control>
                            </Field.Body>
                        </Field>
                    </Column>
                </Column.Group>
            </>
        );
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));
    };

    private renderUserUpdateMutation = (user: IUserModel) => {
        return (
            <Mutation
                mutation={USER_UPDATE_MUTATION}
                onError={this.onError}
                onCompleted={this.onCompleted}
            >
                {doUserUpdate =>
                    this.renderUserUpdateForm(_.omit(user, ['plantRoles']), doUserUpdate)
                }
            </Mutation>
        );
    };

    private renderProfileForm = () => {
        return (userData: IUserModel) =>
            this.renderUserUpdateMutation(this.state.user ? this.state.user : userData);
    };

    public render() {
        const { error, success } = this.state;
        const idUser = getToken().id;

        return (
            <>
                <PageHeader>Profile</PageHeader>
                {error && <Error>Profile update error</Error>}
                {success && <Message type="success">Successfully updated profile</Message>}
                {idUser && (
                    <UserDetailQuery id={idUser} renderFetchedUserData={this.renderProfileForm()} />
                )}
            </>
        );
    }
}

export default withMainNavigation(ProfileContainer);
