import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Routing from '../../Routing';
import { notification } from '../../utils/notification';
import { buildPath, getUrlParams } from '../../utils/queryString';
import { PART_DELETE_MUTATION, PART_UPDATE_MUTATION } from './../../api/GraphQLQueries/Part';
import IPartModel, { IPartUpdateModel } from './../../models/IPartModel';
import omit from './../../utils/omit';
import PartEditForm from './../part/forms/PartEditForm';

interface IRouteParams {
    plantId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    tag: IPartModel;
}

interface IState {
    tag?: IPartUpdateModel;
}

class TagDetails extends React.Component<IProps, IState> {
    public state: IState = {};

    private redirectToNewTagNumber = (tag: IPartModel) => {
        const { plantId } = this.props.match.params;
        const qs = getUrlParams(this.props.location);

        qs.params.query = tag.tagNumber;
        const route = buildPath({
            pathname: Routing.TAG_DETAILS.getPath({
                plantId,
                tagNumberAsId: tag.tagNumberAsId as string,
            }),
            params: qs.params,
        });
        this.props.history.replace(route);
    };

    private redirectToTags = () => {
        const { plantId } = this.props.match.params;
        this.props.history.replace(Routing.PLANT_TAGS.getPath(plantId));
    };

    private onError = (message: string) => (): void => {
        notification.error(message);
    };

    private onCompleted = ({ updatePart }: { updatePart: IPartModel }): void => {
        notification.success('Successfully update tag details');
        this.setState({ tag: null }, () => this.redirectToNewTagNumber(updatePart));
    };
    private onDeleteCompleted = (): void => {
        notification.success('Duccessfully delete tag');
        this.setState({ tag: null }, this.redirectToTags);
    };

    private submitTagDetails = (doTagDetailUpdateMutation: MutationFn) => (): void => {
        const tag = omit(this.state.tag, [
            'tagNumber',
            'plant',
            'parents',
            'parts',
            'documents',
            'tagNumberAsId',
            'isOriginalCsv',
            'documentPages',
            'dataSource',
        ]);

        doTagDetailUpdateMutation({ variables: { part: tag } });
    };

    private deleteTag = (doTagDeleteMutation: MutationFn) => (): void => {
        if (window.confirm(`Are you sure? This action can not be undone.`)) {
            const { tag } = this.props;
            doTagDeleteMutation({ variables: { id: tag.id } });
        }
    };

    private updateTagDetails = (tag: IPartUpdateModel) => {
        this.setState({ tag });
    };

    private convertTagToTagUpdateModel = (tag: IPartModel): IPartUpdateModel => {
        const tagDetails: IPartUpdateModel = _.omit(tag, [
            'parents',
            'updatedBy',
            'createdBy',
            'createdAt',
            'updatedAt',
        ]);

        return tagDetails;
    };

    private getTagDetails = () => {
        return this.state.tag || this.convertTagToTagUpdateModel(this.props.tag);
    };

    public render() {
        const { tag } = this.props;
        const tagDetails = this.getTagDetails();
        const deleteDisabled = !_.isEmpty(tag.documentPages) || !_.isEmpty(tag.parts);

        return (
            <>
                <Mutation
                    mutation={PART_UPDATE_MUTATION}
                    onError={this.onError('Tag details update error')}
                    onCompleted={this.onCompleted}
                >
                    {doTagDetailUpate => (
                        <>
                            <PartEditForm
                                part={tagDetails}
                                onUpdate={this.updateTagDetails}
                                tagMode={true}
                            />
                            <hr />
                            <Button.Group>
                                <Mutation
                                    mutation={PART_DELETE_MUTATION}
                                    onError={this.onError('Tag delete error')}
                                    onCompleted={this.onDeleteCompleted}
                                >
                                    {doDeleteTag => (
                                        <Button
                                            color="danger"
                                            onClick={this.deleteTag(doDeleteTag)}
                                            disabled={deleteDisabled}
                                            data-cy="tagDetails-delete-btn"
                                            title={
                                                deleteDisabled
                                                    ? 'Tag with parts or documents cannot be deleted'
                                                    : ''
                                            }
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </Mutation>
                                <Button
                                    color="primary"
                                    disabled={!this.state.tag}
                                    onClick={this.submitTagDetails(doTagDetailUpate)}
                                >
                                    Save
                                </Button>
                            </Button.Group>
                        </>
                    )}
                </Mutation>
            </>
        );
    }
}

export default withRouter(TagDetails);
