import * as _ from 'lodash';
import { Button, Control, Delete, Field, Input, Label, Modal, Table, Tag, Title } from 'rbx';
import * as React from 'react';

import { getToken } from '../../../api/authorization';
import { friendlyRole, IMembershipSaveModel } from '../../../models/IMembershipModel';
import IPlantModel from '../../../models/IPlantModel';
import MembershipDeleteMutation from '../../membership/queries/MembershipDeleteMutation';
import BlurButton from '../../ui/BlurButton';
import ModalContainer from '../../ui/ModalContainer';
import UserSaveMembershipMutation from '../queries/UserSaveMembershipMutation';
import IMembershipModel from './../../../models/IMembershipModel';
import IUserModel, { GlobalRole } from './../../../models/IUserModel';
import UserInviteMutation from './../queries/UserInviteMutation';
import UserUpdateBaseFields from './UserUpdateBaseFields';

interface IProps {
    onUpdate: (user: IUserModel) => void;
    user: IUserModel;
    hasChanges?: boolean;
}

interface IState {
    showCreateMembership: boolean;
    showDeleteMembershipModal: boolean;
    showUpdateMembership: boolean;
    membership?: IMembershipModel;
}

export default class UserUpdateForm extends React.Component<IProps, IState> {
    public state: IState = {
        showCreateMembership: false,
        showDeleteMembershipModal: false,
        showUpdateMembership: false,
    };

    private showDeleteMembershipModal = (membership: IMembershipModel) => {
        this.setState((prev: IState) => ({
            ...prev,
            showDeleteMembershipModal: true,
            membership,
        }));
    };

    private hideDeleteMembershipModal = () => {
        this.setState((prev: IState) => ({
            ...prev,
            showDeleteMembershipModal: false,
            deleteMembershipId: -1,
        }));
    };

    private showCreateMembership = () => {
        this.setState((prev: IState) => ({
            ...prev,
            showCreateMembership: true,
        }));
    };

    private hideCreateMembership = () => {
        this.setState((prev: IState) => ({
            ...prev,
            showCreateMembership: false,
            membership: undefined,
        }));
    };

    private showUpdateMembership = (membership: IMembershipModel) => {
        this.setState((prev: IState) => ({
            ...prev,
            showUpdateMembership: true,
            membership,
        }));
    };

    private hideUpdateMembership = () => {
        this.setState((prev: IState) => ({
            ...prev,
            showUpdateMembership: false,
            membership: undefined,
        }));
    };

    private renderMembershipDeleteModal = () => {
        const { id } = this.props.user;
        const { membership } = this.state;

        return (
            <ModalContainer onRequestClose={this.hideDeleteMembershipModal} isOpen={true}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Are you sure?</Modal.Card.Title>
                        <Delete onClick={this.hideDeleteMembershipModal} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>Permanently delete this memebership?</Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button.Group pull="right">
                            <Button onClick={this.hideDeleteMembershipModal}>Cancel</Button>
                            <MembershipDeleteMutation
                                id={membership ? membership.id : -1}
                                userId={id}
                                onSuccess={this.hideDeleteMembershipModal}
                            />
                        </Button.Group>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };

    private renderMembership = (membership: IMembershipModel): JSX.Element => {
        const groupName = membership.group ? membership.group.name : undefined;
        const groupPlants =
            membership.group && membership.group.plants
                ? membership.group.plants.map((plant: IPlantModel) => (
                      <Tag key={plant.id}>{plant.name}</Tag>
                  ))
                : undefined;
        const plantName = membership.plant ? <Tag>{membership.plant.name} </Tag> : undefined;
        const plantsName = plantName || groupPlants;
        return (
            <Table.Row key={membership.id}>
                <Table.Cell>{friendlyRole(membership.role)}</Table.Cell>
                <Table.Cell>{groupName && <Tag>{groupName}</Tag>}</Table.Cell>
                <Table.Cell>{plantsName && <Tag.Group>{plantsName}</Tag.Group>}</Table.Cell>
                <Table.Cell>
                    <Button.Group pull="right">
                        <BlurButton
                            outlined={true}
                            size="small"
                            onClick={this.showUpdateMembership.bind(this, membership)}
                        >
                            Edit
                        </BlurButton>
                        <BlurButton
                            outlined={true}
                            color="danger"
                            size="small"
                            onClick={this.showDeleteMembershipModal.bind(this, membership)}
                        >
                            Remove
                        </BlurButton>
                    </Button.Group>
                </Table.Cell>
            </Table.Row>
        );
    };

    private renderMemberships = (): JSX.Element => {
        const { memberships } = this.props.user;
        return (
            <>
                {!_.isEmpty(memberships) ? (
                    <Table fullwidth={true}>
                        <Table.Head>
                            <Table.Row>
                                <Table.Heading>Role</Table.Heading>
                                <Table.Heading>Group</Table.Heading>
                                <Table.Heading>Plant(s)</Table.Heading>
                                <Table.Heading />
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>{memberships!.map(this.renderMembership)}</Table.Body>
                    </Table>
                ) : (
                    <p>No memberships found</p>
                )}
            </>
        );
    };

    private mapMembershipToMembershipSaveModel = (): IMembershipSaveModel | undefined => {
        if (this.state.membership) {
            const { group, role, plant } = this.state.membership;
            const { id } = this.props.user;
            const membership: IMembershipSaveModel = {
                groupId: group ? group.id : undefined,
                plantId: plant ? plant.id : undefined,
                role,
                userId: id,
            };
            return membership;
        }
        return {};
    };

    public render() {
        const { id, active, globalRole, invitedAt } = this.props.user;
        const {
            showCreateMembership,
            showUpdateMembership,
            showDeleteMembershipModal,
        } = this.state;
        const isAdmin = globalRole === GlobalRole.linde_admin;
        const token = getToken();

        return (
            <>
                <UserUpdateBaseFields user={this.props.user} onUpdate={this.props.onUpdate} />

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Status</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true} iconRight={true}>
                                <Input
                                    type="string"
                                    placeholder="Active"
                                    value={active ? 'Active' : 'Not active'}
                                    readOnly={true}
                                />
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <hr />

                {!isAdmin && (
                    <>
                        <Title size="5">Memberships</Title>
                        {this.renderMemberships()}
                        <BlurButton type="button" onClick={this.showCreateMembership}>
                            Add membership
                        </BlurButton>
                        <hr />
                    </>
                )}

                {showCreateMembership && (
                    <UserSaveMembershipMutation
                        user={this.props.user}
                        onClose={this.hideCreateMembership}
                        membership={this.mapMembershipToMembershipSaveModel()}
                    />
                )}
                {showUpdateMembership && (
                    <UserSaveMembershipMutation
                        user={this.props.user}
                        onClose={this.hideUpdateMembership}
                        membership={this.mapMembershipToMembershipSaveModel()}
                        showGroupPlantSelectMenu={false}
                    />
                )}
                {token.id !== id && !active && (
                    <>
                        <Title size="5">Actions</Title>

                        <Button.Group>
                            <UserInviteMutation id={id} isAlreadyInvited={invitedAt} />
                        </Button.Group>
                    </>
                )}

                {showDeleteMembershipModal && this.renderMembershipDeleteModal()}
            </>
        );
    }
}
