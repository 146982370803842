import { Button, Delete, Modal } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import ModalContainer from '../ui/ModalContainer';
import { USER_CREATE_MUTATION } from './../../api/GraphQLQueries/User';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import { GlobalRole, IUser } from './../../models/IUserModel';
import { todo } from './../../utils/translate';
import AdminCreateForm from './forms/AdminCreateForm';

interface IProps {
    onCancel: () => void;
    onSuccess: () => void;
}

interface IState {
    user: IUser;
    error: boolean;
    success: boolean;
}
const adminTemplate: IUser = {
    name: '',
    email: '',
    globalRole: GlobalRole.linde_admin,
};
const resetState = {
    error: false,
    success: false,
};
export default class AdminCreate extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
        user: adminTemplate,
    };

    private resetState = () => {
        this.setState((prevState: IState) => ({ ...prevState, ...resetState }));
    };

    private onSuccess = () => {
        this.setState(
            (prevState: IState) => ({ ...prevState, success: true, error: false }),
            () => {
                this.resetState();
                this.props.onSuccess();
            },
        );
    };

    private onError = () => {
        this.setState(
            (prevState: IState) => ({ ...prevState, success: false, error: true }),
            () => this.resetState(),
        );
    };

    private onUpdate = (user: IUser) => {
        this.setState((prevState: IState) => ({ ...prevState, user }));
    };

    private formSubmit = (doUserUpdateMutation: MutationFn) => () => {
        const { user } = this.state;

        doUserUpdateMutation({
            variables: {
                user,
            },
        });
    };

    private renderUserCreateForm = (doUserCreate: MutationFn) => {
        const { onCancel } = this.props;
        const { email, name } = this.state.user;

        return (
            <ModalContainer onRequestClose={onCancel} isOpen={true}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Add admin</Modal.Card.Title>
                        <Delete onClick={onCancel} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <AdminCreateForm onUpdate={this.onUpdate} user={this.state.user} />
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button color="light" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button disabled={!email || !name} onClick={this.formSubmit(doUserCreate)}>
                            {todo('Create Admin')}
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };

    public render() {
        const { error, success } = this.state;
        return (
            <>
                {error && <Error>admin was not created</Error>}
                {success && <Message type="success">Successfully created admin</Message>}
                <Mutation
                    mutation={USER_CREATE_MUTATION}
                    onCompleted={this.onSuccess}
                    onError={this.onError}
                >
                    {doUserCreate => this.renderUserCreateForm(doUserCreate)}
                </Mutation>
            </>
        );
    }
}
