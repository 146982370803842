import IGroupModel from './IGroupModel';
import IUserModel from './IUserModel';

export interface IPlant {
    name?: string;
    id?: string;
    invoiceAddress?: IPlantAddressModel;
    deliveryAddress?: IPlantAddressModel;
}

export interface IPlantCreateModel extends IPlant {
    name?: string;
    customerDisplayName?: string;
    id?: string;
    groupIds?: number[];
}

export interface IPlantUpdateModel extends IPlant {
    id: string;
    name: string;
    s3Folder: string;
    customerDisplayName?: string;
    groupIds?: number[];
}

export default interface IPlantModel extends IPlant {
    id: string;
    name: string;
    s3Folder: string;
    customerDisplayName?: string;
    groups?: IGroupModel[];
}

export interface IPlantAddressModel {
    street?: string;
    zip?: string;
    city?: string;
    country?: string;
    responsible?: string;
    latitude?: string;
    longitude?: string;
}

export interface IPlantUserRoleModel {
    user: IUserModel;
    role: string;
}

/*
This logic is duplicated on the server.
Do not change this unless the server code has changed.
(plant.service.ts)
*/

export const strToAlphanumeric = (str: string) => {
    return str.toLowerCase().replace(/[\W_]+/g, '');
};

export const plantS3Folder = (plantId?: string, plantName?: string) => {
    return plantId && plantName
        ? `${strToAlphanumeric(plantName)}-${strToAlphanumeric(plantId)}`
        : '';
};
