import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { notification } from '../../utils/notification';
import { PART_UPDATE_MUTATION } from './../../api/GraphQLQueries/Part';
import { PARTS_BY_TAG_NUMBER_QUERY } from './../../api/GraphQLQueries/Tag';
import IPartModel, { IPartCreateModel, IPartUpdateModel } from './../../models/IPartModel';
import omit from './../../utils/omit';
import PartEditModal from './PartEditModal';
import PartDeleteMutation from './queries/PartDeleteMutation';
import PartDetailQuery from './queries/PartDetailQuery';

interface IProps {
    partId: string;
    plantId: string;
    tagNumberAsId?: string;
    parentId: string;
    onCancel: () => void;
    onSuccess?: () => void;
}

interface IState {
    part?: IPartUpdateModel;
}

class PartUpdate extends React.Component<IProps, IState> {
    public state: IState = {};

    private onError = (): void => {
        notification.error('Part update error');
    };

    private onCompleted = (): void => {
        notification.success('Successfully updated part');
        this.setState({ part: null });

        if (this.props.onSuccess) {
            this.props.onSuccess();
        }
    };

    private updateValues = (part: IPartUpdateModel | IPartCreateModel) => {
        this.setState({ part } as { part: IPartUpdateModel });
    };

    private formSubmit = (doPartUpdateMutation: MutationFn) => (): void => {
        const part = omit(this.state.part, ['tagNumberAsId', '__typename', 'tagNumber']);

        doPartUpdateMutation({ variables: { part } });
    };

    private removePartFromTag = (
        partData: IPartUpdateModel,
        doPartUpdateMutation: MutationFn,
    ) => (): void => {
        if (window.confirm(`Are you sure? This action can not be undone.`)) {
            const parentIds =
                !partData.parentIds || partData.parentIds.length <= 1
                    ? null
                    : partData.parentIds.filter(parentId => parentId !== this.props.parentId);
            const part = { id: partData.id, parentIds };

            doPartUpdateMutation({ variables: { part } });
        }
    };

    private convertToPartUpdateModel = (fetchedPart: IPartModel): IPartUpdateModel => {
        const parentIds = fetchedPart.parents
            ? fetchedPart.parents.map((parentPart: IPartModel) => parentPart.id)
            : [];
        const part: IPartUpdateModel = _.omit(fetchedPart, [
            'createdAt',
            'createdBy',
            'updatedAt',
            'updatedBy',
            'vendorParts',
            'parents',
            'plant',
        ]);
        part.parentIds = parentIds;
        return part;
    };

    private buildActionButtons = (part: IPartUpdateModel, doPartUpdateMutation: MutationFn) => {
        const { plantId, tagNumberAsId, onCancel } = this.props;

        return (
            <>
                <PartDeleteMutation
                    plantId={plantId}
                    tagNumberAsId={tagNumberAsId}
                    id={part.id}
                    onSuccess={onCancel}
                />
                <Button
                    color="danger"
                    outlined={true}
                    onClick={this.removePartFromTag(part, doPartUpdateMutation)}
                >
                    Remove from tag
                </Button>
            </>
        );
    };

    private renderUpdateMutation = () => {
        const { onCancel, plantId, tagNumberAsId } = this.props;

        return (partData: IPartModel) => {
            const disableSave = !this.state.part;

            const partUpdate = this.state.part
                ? this.state.part
                : this.convertToPartUpdateModel(partData);

            return (
                <Mutation
                    mutation={PART_UPDATE_MUTATION}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                    refetchQueries={[
                        { query: PARTS_BY_TAG_NUMBER_QUERY, variables: { plantId, tagNumberAsId } },
                    ]}
                >
                    {doPartUpdate => (
                        <PartEditModal
                            part={partUpdate}
                            onUpdate={this.updateValues}
                            onSubmit={this.formSubmit(doPartUpdate)}
                            onClose={onCancel}
                            disableSave={disableSave}
                            actionButtons={this.buildActionButtons(partUpdate, doPartUpdate)}
                        />
                    )}
                </Mutation>
            );
        };
    };

    public render = () => {
        const { partId } = this.props;

        return (
            partId && (
                <>
                    {partId && (
                        <PartDetailQuery
                            id={partId}
                            renderFetchedPartData={this.renderUpdateMutation()}
                        />
                    )}
                </>
            )
        );
    };
}

export default PartUpdate;
