import { Icon as RbxIcon } from 'rbx';
import { IconProps } from 'rbx/elements/icon/icon';
import * as React from 'react';

type IRbxIconProps = Partial<IconProps> & Omit<React.HTMLProps<HTMLSpanElement>, 'size'>;

interface IProps extends IRbxIconProps {
    icon: string;
    iconClass?: string;
}

export default class Icon extends React.Component<IProps> {
    public render() {
        const { icon, iconClass, ...defaultProps } = this.props;

        return (
            <RbxIcon {...defaultProps} className={iconClass}>
                <i className={`fa ${icon}`} />
            </RbxIcon>
        );
    }
}
