import * as React from 'react';

import { getCurrentUser, isAdmin } from '../../api/authorization';
import IUserModel from '../../models/IUserModel';

interface IProps {
    renderAdmin?: (user?: IUserModel) => any;
    renderUser?: (user?: IUserModel) => any;
    renderFor?: 'admin' | 'user';
}

interface IState {
    loading: boolean;
    isAdmin: boolean;
    user: IUserModel;
}

export default class IsAdminQuery extends React.Component<IProps, IState> {
    public state = {
        loading: true,
        isAdmin: false,
        user: null,
    };

    public componentDidMount = async () => {
        const user = getCurrentUser();
        const userIsAdmin = isAdmin(user);

        this.setState({
            loading: false,
            isAdmin: userIsAdmin,
            user,
        });
    };

    private doRender = (): React.ReactNode | null => {
        const { renderAdmin, renderUser, renderFor } = this.props;
        const { isAdmin: userIsAdmin, user } = this.state;

        if (userIsAdmin) {
            if (typeof renderAdmin === 'function') {
                return renderAdmin(user);
            } else if (renderFor === 'admin') {
                return this.props.children;
            }
        } else if (!userIsAdmin) {
            if (typeof renderUser === 'function') {
                return renderUser(user);
            } else if (renderFor === 'user') {
                return this.props.children;
            }
        }

        return null;
    };

    public render() {
        const { loading } = this.state;

        if (loading === true) {
            return <></>;
        } else {
            return this.doRender();
        }
    }
}
