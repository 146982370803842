import * as React from 'react';
import { Query } from 'react-apollo';

import { PART_DOCUMENT_PAGES } from './../../../api/GraphQLQueries/Part';
import Error from './../../layout/Error';

interface IProps {
    plantId: string;
    s3Key?: string;
    renderDocumentsExists: (documentsExists: boolean) => JSX.Element;
}

export default class PartDocumentPagesCheckQuery extends React.Component<IProps, any> {
    public render = () => {
        const { renderDocumentsExists, plantId, s3Key } = this.props;

        return (
            <Query
                query={PART_DOCUMENT_PAGES}
                variables={{ plantId, s3Key }}
                fetchPolicy="network-only"
            >
                {({ data, loading, error }) => {
                    if (loading) {
                        return null;
                    }
                    if (error) {
                        return <Error>Error fetching document pages...</Error>;
                    }

                    const documentsExistis =
                        data.partDocumentPages &&
                        Array.isArray(data.partDocumentPages) &&
                        data.partDocumentPages.length > 1;

                    return renderDocumentsExists(documentsExistis);
                }}
            </Query>
        );
    };
}
