import * as React from 'react';

import { IBasketFileModel } from '../../../models/IBasketModel';
import { BASKET_FILES } from './../../../api/GraphQLQueries/Basket';
import DetailQueryContainer from './../../../containers/common/DetailQueryContainer';

interface IProps {
    basketId: string;
    renderFetchedFiles: (basketFiles: IBasketFileModel[]) => JSX.Element;
}

export default class BasketFilesQuery extends React.Component<IProps, any> {
    public render() {
        const { basketId, renderFetchedFiles } = this.props;

        return (
            <DetailQueryContainer
                query={BASKET_FILES}
                variables={{ basketId }}
                dataProp="basketFiles"
                fetchPolicy="network-only"
                renderFetchedData={renderFetchedFiles}
            />
        );
    }
}
