import { Checkbox, Control, Field, Icon, Input, Label, Level } from 'rbx';
import * as React from 'react';

interface IProps {
    enableToggleAll?: boolean;
    onToggleAll?: () => void;
    renderSortDropdown?: () => JSX.Element;
    onCustomFilterChange?: (event: any) => void;
    customFilterValue?: string;
    actionButtons?: any;
}

export default class TableActions extends React.Component<IProps, any> {
    private renderToggleAll = (): JSX.Element | boolean | undefined => {
        const { enableToggleAll, onToggleAll } = this.props;

        return (
            enableToggleAll &&
            onToggleAll && (
                <Label>
                    <Checkbox onClick={onToggleAll} /> Select all
                </Label>
            )
        );
    };

    private renderSortDropDown = (): JSX.Element | boolean | undefined => {
        const { renderSortDropdown } = this.props;

        return renderSortDropdown && renderSortDropdown();
    };

    private renderSearchField = (): JSX.Element => {
        const { onCustomFilterChange, customFilterValue } = this.props;
        if (!onCustomFilterChange || customFilterValue === null) {
            return <></>;
        }
        return (
            <Level.Item>
                <Field kind="addons" className="table-search">
                    <Control expanded={true} iconLeft={true}>
                        <Input
                            size="small"
                            type="string"
                            placeholder="Search"
                            onChange={onCustomFilterChange}
                            value={customFilterValue}
                        />
                        <Icon size="small" align="left">
                            <i className="fa fa-search" />
                        </Icon>
                    </Control>
                </Field>
            </Level.Item>
        );
    };

    private renderActions = () => {
        return <Level.Item>{this.props.actionButtons()}</Level.Item>;
    };

    public render() {
        const { actionButtons } = this.props;

        return (
            <Level marginless={true} className="table-actions">
                <Level.Item align="left">
                    <Level.Item>{this.renderToggleAll()}</Level.Item>
                    <Level.Item>{this.props.children}</Level.Item>
                </Level.Item>
                <Level.Item align="right">
                    {this.renderSearchField()}
                    {actionButtons && this.renderActions()}
                    <Level.Item>{this.renderSortDropDown()}</Level.Item>
                </Level.Item>
            </Level>
        );
    }
}
