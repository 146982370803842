import * as React from 'react';

import { USER_QUERY } from './../../../api/GraphQLQueries/User';
import DetailQueryContainer from './../../../containers/common/DetailQueryContainer';
import IUserModel from './../../../models/IUserModel';

interface IProps {
    id: number;
    renderFetchedUserData: (user: IUserModel) => JSX.Element;
    hideLoadingIndicator?: boolean;
    onResult?: (user: IUserModel) => void;
}

export default class UserDetailQuery extends React.Component<IProps, any> {
    public render() {
        const { id, renderFetchedUserData, hideLoadingIndicator, onResult } = this.props;

        const variables = { id };

        return (
            <DetailQueryContainer
                hideLoadingIndicator={hideLoadingIndicator}
                query={USER_QUERY}
                variables={variables}
                dataProp="user"
                onResultReturnData={true}
                onResult={onResult}
                renderFetchedData={renderFetchedUserData}
            />
        );
    }
}
