import * as React from 'react';

import IBasketModel from '../../../models/IBasketModel';
import { BASKET_QUERY } from './../../../api/GraphQLQueries/Basket';
import DetailQueryContainer from './../../../containers/common/DetailQueryContainer';

interface IProps {
    id: string;
    renderFetchedBasketData: (basket: IBasketModel) => JSX.Element;
}

export default class BasketDetailQuery extends React.Component<IProps, any> {
    public render() {
        const { id, renderFetchedBasketData } = this.props;

        const variables = { id };

        return (
            <DetailQueryContainer
                query={BASKET_QUERY}
                variables={variables}
                dataProp="basket"
                renderFetchedData={renderFetchedBasketData}
            />
        );
    }
}
