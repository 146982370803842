import { Column, Container, Title } from 'rbx';
import * as React from 'react';

export default class AuthLayout extends React.Component<any, any> {
    public render() {
        return (
            <div className="auth-layout">
                <Container>
                    <Column.Group paddingless={true}>
                        <Column size={6} tablet={{ size: 12 }} desktop={{ size: 6 }}>
                            <div className="form-header">
                                <img src="/linde-logo.jpg" alt="Linde PLANTSERV®" />
                                <Title size={3}>LINDE PLANTSERV® portal</Title>
                            </div>
                            <div className="auth-form">{this.props.children}</div>
                        </Column>
                        <Column className="has-key-visual is-hidden-touch" />
                    </Column.Group>
                </Container>
            </div>
        );
    }
}
