import * as React from 'react';
import { Mutation } from 'react-apollo';

import Icon from '../../../../components/ui/Icon';
import { MaintenanceButton } from '../../../../components/ui/MaintenanceButton';
import { notification } from '../../../../utils/notification';
import {
    ONBOARDING_GETINDEXABLEFILESFORPLANT_QUERY,
    ONBOARDING_SYNCDOCUMENTS_MUTATION,
} from './../../../../api/GraphQLQueries/Onboarding';

interface IProps {
    plantS3Folder: string;
    disabled?: boolean;
}

export default class DocumentsSyncMutation extends React.Component<IProps> {
    private onError = (): void => {
        notification.error('Document sync error');
    };

    private onCompleted = (): void => {
        notification.success('Successfully started document sync');
    };

    public render() {
        const { plantS3Folder, disabled } = this.props;

        return (
            <>
                <Mutation
                    mutation={ONBOARDING_SYNCDOCUMENTS_MUTATION}
                    variables={{ plantS3Folder }}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={[
                        {
                            query: ONBOARDING_GETINDEXABLEFILESFORPLANT_QUERY,
                            variables: { plantS3Folder },
                        },
                    ]}
                >
                    {(doDocumentSyncMutation, { loading }) => (
                        <>
                            <MaintenanceButton
                                type="button"
                                color={loading ? 'info' : ''}
                                disabled={disabled}
                                onClick={loading ? () => null : doDocumentSyncMutation}
                            >
                                {loading && <Icon icon="fa-cog fa-spin" />}
                                <span>
                                    {loading
                                        ? 'Started S3 sync, please wait'
                                        : 'Sync S3 Documents to Spindex'}
                                </span>
                            </MaintenanceButton>
                        </>
                    )}
                </Mutation>
            </>
        );
    }
}
