import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import BasketSummary from '../components/basket/BasketSummary';
import PageHeader from '../components/layout/PageHeader';
import IBasketModel from '../models/IBasketModel';
import BasketDetailQuery from './../components/basket/queries/BasketDetailQuery';
import withMainNavigation from './withMainNavigation';

interface IRouteParams {
    basketId: string;
}

class BasketUpdateContainer extends React.Component<RouteComponentProps<IRouteParams>, any> {
    private renderBasketDetails = (basket: IBasketModel) => {
        return (
            basket && (
                <>
                    <PageHeader>{basket.name}</PageHeader>

                    <BasketSummary basket={basket} />
                </>
            )
        );
    };

    public render() {
        const { basketId } = this.props.match.params;

        return (
            basketId && (
                <BasketDetailQuery
                    id={basketId}
                    renderFetchedBasketData={this.renderBasketDetails}
                />
            )
        );
    }
}

export default withMainNavigation(BasketUpdateContainer);
