import * as React from 'react';
import { Query } from 'react-apollo';

import { PLANT_AND_GROUP_QUERY } from '../../api/GraphQLQueries/MultiQueries';
import { IGraphQLListProps } from '../../containers/common/ListQueryContainer';
import IGroupModel from '../../models/IGroupModel';
import IPlantModel from '../../models/IPlantModel';
import Error from '../layout/Error';
import Loading from '../layout/Loading';
import MultiSelect, { IMultiselectOptionType } from '../ui/MultiSelect';
import { IMembershipSaveModel } from './../../models/IMembershipModel';

interface IProps {
    defaultSelected?: IMembershipSaveModel;
    onUpdate: (membership: IMembershipSaveModel) => void;
}

interface IMultiQuery<T> extends IGraphQLListProps {
    data: T[];
}
interface IPlantGroups {
    plants: IMultiQuery<IPlantModel>;
    groups: IMultiQuery<IGroupModel>;
}

const LIMIT = 100;
const OFFSET = 0;

export default class GroupPlantSelectMenu extends React.Component<IProps> {
    private handleChange = ({ value }: { value: string | number }) => {
        const membership: IMembershipSaveModel = {};
        if (typeof value === 'string') {
            membership.plantId = value;
        } else {
            membership.groupId = value;
        }
        this.props.onUpdate(membership);
    };

    private renderMultiSelect = (data: IPlantGroups) => {
        if (!data || !data.groups || !data.plants) {
            return null;
        }

        const options: IMultiselectOptionType[] = [
            {
                label: 'plants',
                options: data.plants.data.map((plant: IPlantModel) => ({
                    value: plant.id,
                    label: `${plant.name} / ${plant.id}`,
                })),
            },
            {
                label: 'groups',
                options: data.groups.data.map((group: IGroupModel) => ({
                    value: group.id,
                    label: group.name,
                })),
            },
        ];

        return (
            <MultiSelect<any>
                options={options}
                placeholder="Select group or plant"
                onChange={this.handleChange}
            />
        );
    };

    public render() {
        return (
            <Query
                query={PLANT_AND_GROUP_QUERY}
                variables={{ limit: LIMIT, offset: OFFSET }}
                fetchPolicy="network-only"
            >
                {({ data, loading, error }) => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (error) {
                        return <Error>Error fetching data...</Error>;
                    }

                    return this.renderMultiSelect(data);
                }}
            </Query>
        );
    }
}
