import IMembershipModel from './IMembershipModel';
import IPlantModel from './IPlantModel';
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[()= #?!@$%^&*-}{]).{8,}$/;

export enum GlobalRole {
    linde_admin = 'linde_admin',
    linde_user = 'linde_user',
    regular_user = 'regular_user',
}

export const globalroles = ['linde_admin', 'regular_user'];

export interface IUser {
    name?: string;
    email?: string;
    jobTitle?: string;
    globalRole?: GlobalRole;
}

export default interface IUserModel extends IUser {
    id: number;
    name: string;
    email: string;
    active: boolean;
    invitedAt: number;
    memberships?: IMembershipModel[];
    plantRoles?: IPlantModel[];
}

export enum FriendlyGlobalRole {
    linde_admin = 'Admin',
    linde_user = 'Admin',
    regular_user = 'User',
}
