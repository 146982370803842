import gql from 'graphql-tag';

// const CURRENT_USER_QUERY = gql`
//     query Me {
//         me {
//             id
//             email
//             globalRole
//             name
//             jobTitle
//             active
//             emailVerified
//             lastLogin
//             loginCount
//             createdAt
//             updatedAt
//             memberships {
//                 id
//             }
//             plantRoles {
//                 role
//                 plant {
//                     id
//                 }
//             }
//         }
//     }
// `;

export const LOGIN_MUTATION = gql`
    mutation LoginMutation($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            id
            accessToken
            refreshToken
        }
    }
`;

export const REFRESH_TOKEN_MUTATION = gql`
    mutation RefreshTokenMutation($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            accessToken
        }
    }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
    mutation changePasswordMutation($currentPassword: String!, $newPassword: String!) {
        changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
    }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
    mutation ForgotPasswordMutation($email: String!) {
        forgotPassword(email: $email)
    }
`;

export const RESET_PASSWORD_MUTATION = gql`
    mutation ResetPasswordMutation($hash: String!, $password: String!) {
        resetPassword(hash: $hash, password: $password)
    }
`;

export const ACCEPT_INVITATION_MUTATION = gql`
    mutation AcceptInvitationMutation($hash: String!, $password: String!) {
        acceptInvitation(hash: $hash, password: $password) {
            id
            accessToken
            refreshToken
        }
    }
`;
