import * as React from 'react';

import MultiSelect from '../ui/MultiSelect';
import { BasketLabels } from './../../models/IBasketModel';

interface IProps {
    defaultSelected?: string;
    onUpdate: (label: string) => void;
    isClearable?: boolean;
}

export default class LabelSelectMenu extends React.Component<IProps, any> {
    private handleChange = (label?: { value: string; label: string } | any) => {
        this.props.onUpdate(label ? label.value : undefined);
    };

    private buildSelectObject = () => {
        return BasketLabels.map(label => {
            const item = { value: label, label };
            return item;
        });
    };

    public render() {
        const { defaultSelected, isClearable } = this.props;

        const initial = defaultSelected
            ? { value: defaultSelected, label: defaultSelected }
            : undefined;

        return (
            <MultiSelect
                value={initial}
                options={this.buildSelectObject()}
                placeholder="Label"
                isClearable={isClearable}
                onChange={this.handleChange}
            />
        );
    }
}
