import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import NotFoundContainer from '../../containers/NotFoundContainer';
import { GlobalRole, IUser } from '../../models/IUserModel';
import Tracker from '../../utils/Tracker';
import { getCurrentUser, isLoggedIn } from './../../api/authorization';
import Routing from './../../Routing';

interface IState {
    working: boolean;
    authenticated: boolean;
    currentUser?: IUser;
}

interface IProps extends RouteProps {
    component: any;
    whitelistRole?: GlobalRole;
}

class AuthRoute extends React.Component<IProps, IState> {
    public state: IState = {
        working: false,
        authenticated: false,
    };

    public UNSAFE_componentWillMount() {
        this.authenticate();
    }

    public authenticate = async (): Promise<void> => {
        this.setState((prevState: IState) => ({
            ...prevState,
            working: true,
        }));

        const authenticated = await isLoggedIn();
        let currentUser;
        if (authenticated) {
            currentUser = await getCurrentUser();
            if (currentUser && currentUser.globalRole) {
                Tracker.setRole(currentUser.globalRole);
            }
        }

        this.setState({ working: false, authenticated, currentUser });
    };

    private renderComponent = (component: any) => (...routeProps: any) => {
        const { currentUser } = this.state;
        const { whitelistRole } = this.props;

        if (whitelistRole && currentUser && whitelistRole !== currentUser.globalRole) {
            component = NotFoundContainer;
        }

        const finalProps = Object.assign({}, ...routeProps, { user: currentUser });
        return React.createElement(component, finalProps);
    };

    public render() {
        const { authenticated, working } = this.state;
        const { component, ...rest } = this.props;

        if (working) {
            return <div />;
        }

        if (authenticated) {
            return <Route {...rest} render={this.renderComponent(component)} />;
        }

        return <Redirect to={Routing.AUTH_LOGIN.route} />;
    }
}

export default AuthRoute;
