import * as React from 'react';

import MultiSelect from '../ui/MultiSelect';
import { LineItemRequestTypes } from './../../models/ILineItemModel';

interface IProps {
    defaultSelected?: string;
    onUpdate: (requestType: string) => void;
}

export default class LineItemRequestTypeSelectMenu extends React.Component<IProps, any> {
    private handleChange = (requestType: { value: string; label: string } | any) => {
        this.props.onUpdate(requestType.value);
    };

    public render() {
        const { defaultSelected } = this.props;

        const initial = LineItemRequestTypes.find(r => r.value === defaultSelected);

        return (
            <MultiSelect
                value={initial}
                options={LineItemRequestTypes}
                placeholder="Request Type"
                onChange={this.handleChange}
                cy="LineItemRequestTypeSelectMenu-requestType-select"
            />
        );
    }
}
