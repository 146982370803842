import { Button, Column, Dropdown, Icon } from 'rbx';
import * as React from 'react';

import { JENKINS_URI, KIBANA_URI, RABBIT_URI, SEARCHKIT_URI, SPINLOG_URI } from '../api/connection';
import PageHeader from '../components/layout/PageHeader';
import ClearElasticMutation from '../components/ops/ClearElasticMutation';
import ClearRabbitMutation from '../components/ops/ClearRabbitMutation';
import DeletePagesForPlantMutation from '../components/ops/DeletePagesForPlantMutation';
import DeletePartsForPlantMutation from '../components/ops/DeletePartsForPlantMutation';
import DevTools from '../components/ops/DevTools';
import OpsLink from '../components/ops/OpsLink';
import RabbitStatsQuery from '../components/ops/RabbitStatsQuery';
import Accordion from '../components/ui/accordion/Accordion';
import withMainNavigation from './withMainNavigation';

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;

export const RABBIT_POLLING_INTERVAL = 1000;

export default withMainNavigation(
    class OpsContainer extends React.Component<{}, {}> {
        private headerButtons = () => {
            return (
                <Column>
                    <Column.Group>
                        <Column>
                            <ClearRabbitMutation />
                        </Column>
                        <Column>
                            <ClearElasticMutation />
                        </Column>
                        <Column>
                            <DeletePagesForPlantMutation />
                        </Column>
                        <Column>
                            <DeletePartsForPlantMutation />
                        </Column>
                        <Column>
                            <Dropdown>
                                <Dropdown.Trigger>
                                    <Button>
                                        <span>Usefull links</span>
                                        <Icon size="small">
                                            <i className="fas fa-arrow-down" />
                                        </Icon>
                                    </Button>
                                </Dropdown.Trigger>
                                <Dropdown.Menu>
                                    <Dropdown.Content>
                                        <OpsLink href={RABBIT_URI} label="Rabbit" />
                                        <OpsLink href={SPINLOG_URI} label="Spindex" />
                                        <OpsLink href={SEARCHKIT_URI} label="Log" />
                                        {KIBANA_URI && <OpsLink href={KIBANA_URI} label="Kibana" />}
                                        <OpsLink
                                            href={JENKINS_URI}
                                            label="Jenkins"
                                            divider={false}
                                        />
                                    </Dropdown.Content>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Column>
                    </Column.Group>
                </Column>
            );
        };

        public render() {
            return (
                <>
                    <PageHeader buttons={this.headerButtons()}>
                        DevOps Dashboard {env ? `(${env})` : ''}
                    </PageHeader>
                    <Accordion title="RABBIT STATS">
                        <RabbitStatsQuery pollInterval={RABBIT_POLLING_INTERVAL} />
                    </Accordion>
                    <br />
                    <DevTools />
                </>
            );
        }
    },
);
