import * as React from 'react';

import { BASKET_LIST_QUERY } from './../../../api/GraphQLQueries/Basket';
import ListQueryContainer, {
    IGraphQLListProps,
} from './../../../containers/common/ListQueryContainer';
import IBasketModel from './../../../models/IBasketModel';

interface IProps extends IGraphQLListProps {
    renderFetchedBasketList: (
        baskets: IBasketModel[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => JSX.Element;
}

export default class BasketListQuery extends React.Component<IProps, any> {
    public render = () => {
        const { renderFetchedBasketList } = this.props;

        return (
            <ListQueryContainer
                {...this.props}
                query={BASKET_LIST_QUERY}
                dataProp="baskets"
                renderFetchedList={renderFetchedBasketList}
            />
        );
    };
}
