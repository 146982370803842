import * as React from 'react';
import { Query } from 'react-apollo';

import { ONBOARDING_GETINDEXABLEFILESFORPLANT_QUERY } from '../../../api/GraphQLQueries/Onboarding';
import Loading from '../../../components/layout/Loading';
import { notification } from '../../../utils/notification';
import PlantOnboarding from './PlantOnboarding';

interface IProps {
    plantS3Folder: string;
    refetch?: () => void;
}

export default class PlantOnboardingQuery extends React.Component<IProps> {
    public render = () => {
        const { plantS3Folder, refetch } = this.props;

        return (
            <Query
                query={ONBOARDING_GETINDEXABLEFILESFORPLANT_QUERY}
                variables={{ plantS3Folder }}
                fetchPolicy="network-only"
            >
                {({ data, loading, error }) => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (error) {
                        notification.error(
                            'Something went wrong while retreiving files to be indexed. Please try again later',
                        );
                        return <></>;
                    }

                    const {
                        s3FileCount,
                        indexables,
                        partsCsv,
                        documentumCsvExists,
                    } = data.getIndexableFilesForPlant;
                    return (
                        <>
                            <PlantOnboarding
                                plantS3Folder={plantS3Folder}
                                s3FileCount={s3FileCount}
                                partsCsv={partsCsv}
                                documentumCsvExists={documentumCsvExists}
                                indexables={indexables}
                                refetch={refetch}
                            />
                        </>
                    );
                }}
            </Query>
        );
    };
}
