import { Button, Input, Pagination } from 'rbx';
import * as React from 'react';

interface IProps {
    disableButtons?: boolean;
    disablePageSize?: boolean;
    showPageJump?: boolean;
    totalCount?: number;
    pages: number;
    currentPageIndex: number;
    pageSize?: number;
    pageSizeOptions?: number[];
    onPageChange: (pageIndex: number) => void;
}

interface IState {
    pageIndicator: number;
}

export const DEFAULT_PAGE_SIZE = 50;

export default class Paging extends React.Component<IProps, IState> {
    public state: IState = {
        pageIndicator: this.props.currentPageIndex + 1,
    };

    private pageNextValid = (pageIndex: number): boolean => {
        return this.props.pages > pageIndex;
    };

    private pagePrevValid = (pageIndex: number): boolean => {
        return pageIndex >= 0;
    };

    private pageIndexValid = (pageIndex: number): boolean => {
        return this.pagePrevValid(pageIndex) && this.pageNextValid(pageIndex);
    };

    private onPageUp = () => {
        const nextPageIndex = this.props.currentPageIndex + 1;

        this.onPageChange(nextPageIndex);
    };

    private onPageDown = () => {
        const prevPageIndex = this.props.currentPageIndex - 1;

        this.onPageChange(prevPageIndex);
    };

    private onPageChange = (pageIndex: number) => {
        const pageIndexValid = this.pageIndexValid(pageIndex);

        if (pageIndexValid) {
            this.props.onPageChange(pageIndex);
        }
    };

    private jumpToPageNumber = () => {
        const { currentPageIndex } = this.props;
        const { pageIndicator } = this.state;
        const pageIndex = pageIndicator - 1;

        if (currentPageIndex === pageIndex) {
            return;
        }

        if (this.pageIndexValid(pageIndex)) {
            this.onPageChange(pageIndex);
        } else {
            this.setState({ pageIndicator: currentPageIndex + 1 });
        }
    };

    private onPageJumpKeyPress = (e: any) => {
        // handle enter key
        if (e.key === 'Enter') {
            this.jumpToPageNumber();
        }
    };

    private onPageJumpChange = (context: any) => {
        this.setState({ pageIndicator: context.currentTarget.value });
    };

    private renderTableResultCounter = () => {
        const { totalCount } = this.props;
        const { pageIndicator } = this.state;

        const from = pageIndicator === 1 ? pageIndicator : (pageIndicator - 1) * DEFAULT_PAGE_SIZE;
        const to =
            pageIndicator * DEFAULT_PAGE_SIZE <= totalCount!
                ? pageIndicator * DEFAULT_PAGE_SIZE
                : totalCount;

        return (
            <>
                {from} - {to} of {totalCount} results
            </>
        );
    };

    public render = () => {
        const { pages, currentPageIndex, disableButtons, showPageJump, totalCount } = this.props;

        const disableNext = !this.pageNextValid(currentPageIndex + 1);
        const disablePrev = !this.pagePrevValid(currentPageIndex - 1);
        const { pageIndicator } = this.state;
        return (
            <>
                <div className="paging">
                    <Pagination size="small" align="right">
                        <Pagination.List>
                            {!disableButtons && showPageJump && (
                                <Button
                                    size="small"
                                    onClick={this.onPageDown}
                                    disabled={disablePrev}
                                >
                                    <i className="fas fa-arrow-left" />
                                </Button>
                            )}
                            &nbsp;
                            {showPageJump && (
                                <>
                                    <Input
                                        size="small"
                                        type="number"
                                        min="1"
                                        max={pages}
                                        value={pageIndicator}
                                        onBlur={this.jumpToPageNumber}
                                        onChange={this.onPageJumpChange}
                                        onKeyPress={this.onPageJumpKeyPress}
                                    />
                                    / {pages}&nbsp;
                                </>
                            )}
                            {!showPageJump && totalCount && this.renderTableResultCounter()}
                            &nbsp;
                            {!disableButtons && (
                                <>
                                    {!showPageJump && (
                                        <Button
                                            size="small"
                                            onClick={this.onPageDown}
                                            disabled={disablePrev}
                                        >
                                            <i className="fas fa-arrow-left" />
                                        </Button>
                                    )}
                                    &nbsp;
                                    <Button
                                        size="small"
                                        onClick={this.onPageUp}
                                        disabled={disableNext}
                                    >
                                        <i className="fas fa-arrow-right" />
                                    </Button>
                                </>
                            )}
                            &nbsp;
                        </Pagination.List>
                    </Pagination>
                </div>
            </>
        );
    };
}
