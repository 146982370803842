import * as _ from 'lodash';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { PART_CREATE_MUTATION } from './../../api/GraphQLQueries/Part';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import IPartModel, { IPartCreateModel } from './../../models/IPartModel';
import PartEditModal from './../part/PartEditModal';

interface IProps {
    onCancel: () => void;
    onSuccess: (part: IPartModel) => void;
    plantId: string;
}

interface IState {
    part: IPartCreateModel;
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

class TagCreate extends React.Component<IProps, any> {
    public state: IState = {
        ...resetState,
        part: {},
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            () => this.resetState(),
        );
    };

    private onCompleted = ({ createPart }: { createPart: IPartModel }): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            () => this.resetState(),
        );

        if (this.props.onSuccess) {
            this.props.onSuccess(createPart);
        }
    };

    private updateValues = (part: IPartCreateModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            part,
            error: false,
            success: false,
        }));
    };

    private formSubmit = (doPartCreateMutation: MutationFn) => (): void => {
        const { tagNumber } = this.state.part;
        const { plantId } = this.props;

        doPartCreateMutation({ variables: { part: { tagNumber, plantId } } });
    };

    public render = () => {
        const { error, success, part } = this.state;
        const { onCancel } = this.props;

        const disableSave = !part || _.isEmpty(part);

        return (
            <>
                {error && <Error>Tag create error</Error>}
                {success && <Message type="success">Successfully created tag</Message>}
                <Mutation
                    mutation={PART_CREATE_MUTATION}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                >
                    {doPartCreate => (
                        <PartEditModal
                            part={part}
                            onUpdate={this.updateValues}
                            onSubmit={this.formSubmit(doPartCreate)}
                            onClose={onCancel}
                            disableSave={disableSave}
                            tagCreateMode={true}
                            tagMode={true}
                            modalTitle="Create Tag"
                        />
                    )}
                </Mutation>
            </>
        );
    };
}

export default TagCreate;
