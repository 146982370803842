import { getAuthTokenForHTTP } from './authorization';
import { S3_PROXY_URI } from './connection';

const getImageUri = async (): Promise<string> => {
    const token = await getAuthTokenForHTTP();

    if (token) {
        return `${S3_PROXY_URI}?accessToken=${token}&key=`;
    }

    return '';
};

export { getImageUri };
