import { Content } from 'rbx';
import * as React from 'react';

import Popover from './../ui/Popover';

interface IState {
    showShippingInfo: boolean;
}

export default class ShippingTermsPopover extends React.Component<any, IState> {
    public state: IState = {
        showShippingInfo: false,
    };

    private shippingInfoIcon = React.createRef<HTMLDivElement>();

    public toggleShippingInfo = () => {
        this.setState({
            showShippingInfo: !this.state.showShippingInfo,
        });
    };

    public render() {
        const { showShippingInfo } = this.state;
        const refElement = this.shippingInfoIcon.current;

        return (
            <>
                <i
                    className="fa fa-info-circle "
                    ref={this.shippingInfoIcon}
                    onClick={this.toggleShippingInfo}
                />
                {showShippingInfo && this.shippingInfoIcon && (
                    <Popover
                        title="Incoterms 2020"
                        className="info-terms"
                        popoverEventTarget={refElement}
                        onClose={this.toggleShippingInfo}
                    >
                        <Content className="mb-2" style={{ textAlign: 'left' }}>
                            <ul>
                                <li>
                                    Most common incoterms are{' '}
                                    <code>FCA (Free Carrier Alongside)</code> and{' '}
                                    <code>CPT (Carriage Paid to)</code>
                                </li>
                                <li>
                                    For more information click{' '}
                                    <a
                                        href="https://en.wikipedia.org/wiki/Incoterms"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here.
                                    </a>
                                </li>
                                <li>
                                    Incoterms can be changed – in alignment with Vendor – at any
                                    time. A new quotation may be needed.
                                </li>
                            </ul>
                        </Content>
                    </Popover>
                )}
            </>
        );
    }
}
