import * as _ from 'lodash';
import { Button, Checkbox, Content, Label, Table } from 'rbx';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import RabbitStatsQuery from '../../../components/ops/RabbitStatsQuery';
import Accordion from '../../../components/ui/accordion/Accordion';
import Routing from '../../../Routing';
import { camelToTitle } from '../../../utils/helpers';
import { notification } from '../../../utils/notification';
import { RABBIT_POLLING_INTERVAL } from '../../OpsContainer';
import Pane from './../../../components/ui/Pane';
import DocumentsSync from './documents/DocumentsSync';
import DocumentsSyncText from './documents/DocumentsSyncText';
import FastReindexMutation from './documents/FastReindexMutation';
import { IIndeaxable, IndexableRow } from './indexables/IndexableRow';
import IndexCsvMutation from './indexables/IndexCsvMutation';
import IndexDocumentumDocumentsCsvMutation from './indexables/IndexDocumentumDocumentsCsvMutation';

interface IRouteParams {
    plantId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    plantS3Folder: string;
    s3FileCount: number;
    indexables: IIndeaxable[];
    partsCsv: { indexableCount: number; indexedCount: number };
    documentumCsvExists: boolean;
    refetch?: () => void;
}

interface IState {
    dryRun?: boolean;
    onlyErrored?: boolean;
    forceSplit?: boolean;
    forceIndex?: boolean;
    forceNormalize?: boolean;
    forceExtract?: boolean;
    forceOcr?: boolean;
}

class PlantOnboarding extends React.Component<IProps, IState> {
    public state: IState = {
        dryRun: false,
        onlyErrored: false,
        forceSplit: false,
        forceIndex: false,
        forceNormalize: false,
        forceExtract: false,
        forceOcr: false,
    };

    private refetchData = async () => {
        const { refetch } = this.props;
        if (refetch) {
            const res: any = await refetch();
            if (res && !res.errors) {
                notification.success('Successfully updated');
            }
        }
    };

    private paneActions = () => {
        const { plantId } = this.props.match.params;
        return (
            <>
                <Link className="button is-primary" to={Routing.PLANT_TAGS.getPath(plantId)}>
                    View Tags
                </Link>
                <Button onClick={this.refetchData}>Reload</Button>
            </>
        );
    };

    private onOptsChange = (context: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, checked } = context.currentTarget;
        const prop: any = {};
        prop[name] = checked;
        this.setState(prop);
    };

    public render() {
        const {
            plantS3Folder,
            s3FileCount,
            indexables,
            partsCsv,
            documentumCsvExists,
        } = this.props;
        const { plantId } = this.props.match.params;
        const title = `Spindex document overview for ${plantS3Folder}`;
        const sortOrder = ['tags', 'pnid', 'spacy', 'sap_po', 'green', 'yellow', 'red'];
        const advancedOptions = [
            'dryRun',
            'onlyErrored',
            'forceSplit',
            'forceIndex',
            'forceNormalize',
            'forceExtract',
            'forceOcr',
        ];

        /// stupid sorting
        _.forEach(indexables, i => {
            i.dryRun = this.state.dryRun;
            i.forceSplit = this.state.forceSplit;
            i.onlyErrored = this.state.onlyErrored;
            i.forceIndex = this.state.forceIndex;
            i.forceNormalize = this.state.forceNormalize;
            i.forceExtract = this.state.forceExtract;
            i.forceOcr = this.state.forceOcr;
            const indx = _.indexOf(sortOrder, i.name);
            if (indx >= 0) {
                i.sort = indx + 1;
            }
        });

        const sortedIndexables = _.sortBy(indexables, 'sort');

        return (
            <Pane actionButtons={this.paneActions()} title={title}>
                <Content>
                    <DocumentsSync plantS3Folder={plantS3Folder} s3FileCount={s3FileCount} />
                    {s3FileCount > 0 && (
                        <>
                            <Table fullwidth={true}>
                                <Table.Head>
                                    <Table.Row>
                                        <Table.Heading>File Type</Table.Heading>
                                        <Table.Heading># Files Indexable</Table.Heading>
                                        <Table.Heading># Files Indexed</Table.Heading>
                                        <Table.Heading>Action</Table.Heading>
                                    </Table.Row>
                                </Table.Head>

                                <Table.Body>
                                    {sortedIndexables.map((indexable: IIndeaxable) => {
                                        return (
                                            <IndexableRow
                                                key={indexable.name}
                                                plantS3Folder={plantS3Folder}
                                                indexable={indexable}
                                            />
                                        );
                                    })}
                                </Table.Body>

                                {partsCsv && (
                                    <>
                                        <Table.Head>
                                            <Table.Row>
                                                <Table.Heading />
                                                <Table.Heading>Rows in CSV</Table.Heading>
                                                <Table.Heading># of tags in plant</Table.Heading>
                                                <Table.Heading />
                                            </Table.Row>
                                        </Table.Head>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>equipment</Table.Cell>
                                                <Table.Cell>{partsCsv.indexableCount} </Table.Cell>
                                                <Table.Cell>{partsCsv.indexedCount}</Table.Cell>
                                                <Table.Cell>
                                                    <IndexCsvMutation plantId={plantId} />
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </>
                                )}

                                {documentumCsvExists && (
                                    <>
                                        <Table.Head>
                                            <Table.Row>
                                                <Table.Heading />
                                                <Table.Heading />
                                                <Table.Heading />
                                                <Table.Heading />
                                            </Table.Row>
                                        </Table.Head>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>documentum csv</Table.Cell>
                                                <Table.Cell />
                                                <Table.Cell />
                                                <Table.Cell>
                                                    <IndexDocumentumDocumentsCsvMutation
                                                        plantId={plantId}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </>
                                )}
                            </Table>

                            <h4>Advanced Indexing Options:</h4>
                            {advancedOptions.map((advOpt: string) => {
                                return (
                                    <Label className="mr-15" key={advOpt}>
                                        <Checkbox
                                            className="mr-05"
                                            name={advOpt}
                                            onChange={this.onOptsChange}
                                            checked={this.state[advOpt]}
                                        />
                                        {camelToTitle(advOpt) +
                                            (advOpt === 'forceOcr' ? ' (costs $$$)' : '')}
                                    </Label>
                                );
                            })}
                            <FastReindexMutation indexFileOptions={this.state} plantId={plantId} />
                            <Accordion title="RABBIT STATS">
                                <RabbitStatsQuery pollInterval={RABBIT_POLLING_INTERVAL} />
                            </Accordion>
                            <DocumentsSyncText plantS3Folder={plantS3Folder} />
                        </>
                    )}
                </Content>
            </Pane>
        );
    }
}

export default withRouter(PlantOnboarding);
