import classnames from 'classnames';
import * as React from 'react';

interface IState {
    filtersOpen: boolean;
}

export default class TableFilters extends React.Component<{}, IState> {
    public state: IState = {
        filtersOpen: false,
    };

    public render = () => {
        const { filtersOpen } = this.state;

        const classes = classnames({
            'table-filters': true,
            'is-open': filtersOpen,
        });

        return (
            <div className={classes}>
                <div className="icon-container">
                    <span>Filter</span>
                </div>
                <div className="filters-container">{this.props.children}</div>
            </div>
        );
    };
}
