import { Button, Icon } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { IBasketFileModel } from '../../../models/IBasketModel';
import { BASKET_FILE_DELETE_MUTATION, BASKET_FILES } from './../../../api/GraphQLQueries/Basket';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    file: IBasketFileModel;
    basketId: string;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

export default class BasketFileDeleteMutation extends React.Component<IProps, IState> {
    public state: IState = {
        ...resetState,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    private deleteFile = (doDeleteMutation: MutationFn) => (): void => {
        if (window.confirm(`Are you sure? This action can not be undone.`)) {
            const { basketId, file } = this.props;
            doDeleteMutation({
                variables: { basketId, filename: file.filename, isQuote: file.isQuote },
            });
        }
    };

    public render = () => {
        const { error, success } = this.state;
        const { basketId } = this.props;

        const refetchQueries = basketId ? [{ query: BASKET_FILES, variables: { basketId } }] : [];

        return (
            <>
                {error && <Error>File delete error</Error>}
                {success && <Message type="success">Successfully deleted file</Message>}
                <Mutation
                    mutation={BASKET_FILE_DELETE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={refetchQueries}
                >
                    {doDelete => (
                        <Button size="small" onClick={this.deleteFile(doDelete)}>
                            <Icon>
                                <i className="fa fa-trash-alt" />
                            </Icon>
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };
}
