import * as _ from 'lodash';
import { Message } from 'rbx';
import * as React from 'react';

import Icon from '../../ui/Icon';
import IPartModel, { IPartCreateModel, IPartUpdateModel } from './../../../models/IPartModel';
import { PART_FIELDS, TAG_FIELDS } from './../../../utils/fieldHandling';
import FormField from './../../ui/FormField';

interface IProps {
    part: IPartModel | IPartCreateModel | IPartUpdateModel;
    onUpdate: (tag: any) => void;
    createMode?: boolean;
    tagMode?: boolean;
    tagCreateMode?: boolean;
}

export default class TagDetailsUpdateForm extends React.Component<IProps, any> {
    private updateDetails = (name: string) => (value: string) => {
        const { part, createMode, tagCreateMode } = this.props;

        if (_.has(part, name) || createMode || tagCreateMode) {
            this.props.onUpdate({
                ...part,
                [name]: value,
            });
        }
    };

    private shouldShowField = (name: string): boolean => {
        return this.props.tagMode ? TAG_FIELDS.includes(name) : PART_FIELDS.includes(name);
    };

    private updateTagNumber = (value: string) => {
        value = value.replace(/\s/g, '');
        this.updateDetails('tagNumber')(value);
    };

    public render() {
        const {
            certificate,
            clientNo,
            contract,
            customerMaterialNum,
            dataSource,
            designation,
            drawingNum,
            drawingSectNum,
            lindeIdentNum,
            manufactPartNum,
            material,
            modelNum,
            notes,
            originalOrderNum,
            originalVendor,
            oxygenRelevance,
            oxygenRelevance2,
            qtyInstalledPerUnit,
            qtyUnit,
            serialNum,
            specNum,
            tagNumber,
            type,
            unitOfMeasurement,
        } = this.props.part;

        const { tagMode, tagCreateMode } = this.props;

        return (
            <>
                {tagMode && (
                    <FormField
                        label="Tag Number"
                        cy="partEditForm-tagNumber-input"
                        value={tagNumber || ''}
                        disabled={!tagCreateMode}
                        onChange={this.updateTagNumber}
                    />
                )}
                {tagCreateMode && (
                    <Message color="warning">
                        <Message.Body>
                            <Icon icon="fa-exclamation-triangle" />
                            Tag Number can not be edited after creation.
                        </Message.Body>
                    </Message>
                )}
                {!tagCreateMode && (
                    <>
                        {this.shouldShowField('dataSource') && (
                            <FormField
                                label="Data Source"
                                value={dataSource || ''}
                                disabled={true}
                            />
                        )}
                        {this.shouldShowField('designation') && (
                            <FormField
                                label="Designation"
                                value={designation || ''}
                                onChange={this.updateDetails('designation')}
                            />
                        )}
                        {this.shouldShowField('type') && (
                            <FormField
                                label="Type"
                                value={type || ''}
                                onChange={this.updateDetails('type')}
                            />
                        )}
                        {this.shouldShowField('clientNo') && (
                            <FormField
                                label="Client No."
                                value={clientNo || ''}
                                onChange={this.updateDetails('clientNo')}
                            />
                        )}
                        {this.shouldShowField('lindeIdentNum') && (
                            <FormField
                                label="Linde Ident No."
                                value={lindeIdentNum || ''}
                                onChange={this.updateDetails('lindeIdentNum')}
                            />
                        )}
                        {this.shouldShowField('manufactPartNum') && (
                            <FormField
                                horizontal={false}
                                label="Manufact. Part-no."
                                value={manufactPartNum || ''}
                                onChange={this.updateDetails('manufactPartNum')}
                            />
                        )}
                        {this.shouldShowField('material') && (
                            <FormField
                                horizontal={false}
                                label="Material"
                                value={material || ''}
                                onChange={this.updateDetails('material')}
                            />
                        )}
                        {this.shouldShowField('customerMaterialNum') && (
                            <FormField
                                horizontal={false}
                                label="Customer Material No."
                                value={customerMaterialNum || ''}
                                onChange={this.updateDetails('customerMaterialNum')}
                            />
                        )}
                        {this.shouldShowField('modelNum') && (
                            <FormField
                                label="Type/Model No."
                                value={modelNum || ''}
                                onChange={this.updateDetails('modelNum')}
                            />
                        )}
                        {this.shouldShowField('unitOfMeasurement') && (
                            <FormField
                                horizontal={false}
                                label="Unit of measurement"
                                value={unitOfMeasurement || ''}
                                onChange={this.updateDetails('unitOfMeasurement')}
                            />
                        )}
                        {this.shouldShowField('specNum') && (
                            <FormField
                                label="Spec No."
                                value={specNum || ''}
                                onChange={this.updateDetails('specNum')}
                            />
                        )}
                        {this.shouldShowField('serialNum') && (
                            <FormField
                                label="Serial No."
                                value={serialNum || ''}
                                onChange={this.updateDetails('serialNum')}
                            />
                        )}
                        {this.shouldShowField('drawingNum') && (
                            <FormField
                                label="Drawing No."
                                value={drawingNum || ''}
                                onChange={this.updateDetails('drawingNum')}
                            />
                        )}
                        {this.shouldShowField('drawingSectNum') && (
                            <FormField
                                label="Drawing Sect. No."
                                value={drawingSectNum || ''}
                                onChange={this.updateDetails('drawingSectNum')}
                            />
                        )}
                        {this.shouldShowField('originalVendor') && (
                            <FormField
                                label="Supplier"
                                value={originalVendor || ''}
                                onChange={this.updateDetails('originalVendor')}
                            />
                        )}
                        {this.shouldShowField('originalOrderNum') && (
                            <FormField
                                label="Original Order No."
                                value={originalOrderNum || ''}
                                onChange={this.updateDetails('originalOrderNum')}
                            />
                        )}
                        {this.shouldShowField('qtyUnit') && (
                            <FormField
                                horizontal={false}
                                label="Measuring Unit"
                                value={qtyUnit || ''}
                                onChange={this.updateDetails('qtyUnit')}
                            />
                        )}
                        {this.shouldShowField('qtyInstalledPerUnit') && (
                            <FormField
                                horizontal={false}
                                label="Qty. installed per Unit"
                                value={qtyInstalledPerUnit || ''}
                                onChange={this.updateDetails('qtyInstalledPerUnit')}
                            />
                        )}
                        {this.shouldShowField('contract') && (
                            <FormField
                                horizontal={false}
                                label="Order No Supplier"
                                value={contract || ''}
                                onChange={this.updateDetails('contract')}
                            />
                        )}
                        {this.shouldShowField('oxygenRelevance') && (
                            <FormField
                                horizontal={false}
                                label="Oxygen Relevance"
                                value={oxygenRelevance || ''}
                                onChange={this.updateDetails('oxygenRelevance')}
                            />
                        )}
                        {this.shouldShowField('oxygenRelevance2') && (
                            <FormField
                                horizontal={false}
                                label="Oxygen Relevance 2"
                                value={oxygenRelevance2 || ''}
                                onChange={this.updateDetails('oxygenRelevance2')}
                            />
                        )}
                        {this.shouldShowField('certificate') && (
                            <FormField
                                horizontal={false}
                                label="Certificate"
                                value={certificate || ''}
                                onChange={this.updateDetails('certificate')}
                            />
                        )}
                        {this.shouldShowField('notes') && (
                            <FormField
                                horizontal={false}
                                label="Additional Notes"
                                value={notes || ''}
                                onChange={this.updateDetails('notes')}
                            />
                        )}
                    </>
                )}
            </>
        );
    }
}
