import * as React from 'react';

import { notification } from './../../utils/notification';

interface IProps {
    type?: 'success' | 'warning';
}

export default class Message extends React.Component<IProps, any> {
    private successMessage = (msg: string): void => {
        notification.success(msg);
    };

    private infoMessage = (msg: string): void => {
        notification.info(msg);
    };

    private warnMessage = (msg: string): void => {
        notification.warn(msg);
    };

    private renderMessage = (): any => {
        const msg = this.props.children ? this.props.children : '';
        const displayMsg = msg as string;

        switch (this.props.type) {
            case 'success':
                this.successMessage(displayMsg);
                break;
            case 'warning':
                this.warnMessage(displayMsg);
                break;

            default:
                this.infoMessage(displayMsg);
        }

        return null;
    };

    public render() {
        return this.renderMessage();
    }
}
