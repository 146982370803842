import * as React from 'react';
import ReactModal from 'react-modal';

interface IProps extends ReactModal.Props {
    onRequestClose: () => void;
}

export default class ModalContainer extends React.Component<IProps, any> {
    public render() {
        ReactModal.setAppElement('#root');

        return (
            <ReactModal
                className="modal-container"
                overlayClassName="modal-overlay"
                {...this.props}
            >
                {this.props.children}
            </ReactModal>
        );
    }
}
