import * as React from 'react';

import { toCapitalCase } from '../../utils/helpers';
import MultiSelect from '../ui/MultiSelect';
import { BasketPurchaseStates } from './../../models/IBasketModel';

interface IProps {
    defaultSelected?: string;
    onUpdate: (purchaseState: string) => void;
    isClearable?: boolean;
}

export default class PurchaseStateSelectMenu extends React.Component<IProps, any> {
    private handleChange = (purchaseState?: { value: string; purchaseState: string } | any) => {
        this.props.onUpdate(purchaseState ? purchaseState.value : undefined);
    };

    private buildSelectObject = () => {
        return BasketPurchaseStates.map(purchaseState => {
            const item = { value: purchaseState, label: toCapitalCase(purchaseState) };
            return item;
        });
    };

    public render() {
        const { defaultSelected, isClearable } = this.props;
        const initial = defaultSelected
            ? { value: defaultSelected, label: toCapitalCase(defaultSelected) }
            : undefined;

        return (
            <MultiSelect
                value={initial}
                options={this.buildSelectObject()}
                placeholder="Purchase State"
                isClearable={isClearable}
                onChange={this.handleChange}
            />
        );
    }
}
