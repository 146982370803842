import * as _ from 'lodash';
import { Button, Control, Delete, Field, Label, Modal } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import ILineItemModel, { ILineItemUpdateModel } from '../../models/ILineItemModel';
import { LINE_ITEM_UPDATE_MUTATION } from './../../api/GraphQLQueries/LineItem';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import FormField from './../ui/FormField';
import ModalContainer from './../ui/ModalContainer';
import LineItemRequestTypeSelectMenu from './LineItemRequestTypeSelectMenu';

interface IProps {
    onClose: () => void;
    onSuccess: () => void;
    data: ILineItemModel;
}

interface IState {
    lineItem?: ILineItemUpdateModel;
    error: boolean;
    success: boolean;
}

export default class AddLineItemToBasketModal extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private resetState = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: false,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
                lineItem: undefined,
            }),
            () => {
                this.resetState();
                this.props.onSuccess();
            },
        );
    };

    private isDisabled = (): boolean => {
        if (!this.state.lineItem) {
            return true;
        }

        const { description, requestType, title } = this.state.lineItem;

        return !description || !requestType || !title;
    };

    private updateDetails = (name: string) => (value: string) => {
        this.setState((prevState: IState) => {
            const lineItem = prevState.lineItem || this.convertToItemUpdateModel(this.props.data);
            return {
                ...prevState,
                lineItem: {
                    ...lineItem,
                    [name]: value,
                },
            };
        });
    };

    private convertToItemUpdateModel = (item: ILineItemModel): ILineItemUpdateModel => {
        const details: ILineItemUpdateModel = _.omit(item, [
            'filename',
            'pageKey',
            // 'positionX',
            // 'positionY',
            'part',
            'updatedAt',
            'createdAt',
            'isWholeComponent',
            'parentPart',
        ]);

        return details;
    };

    private getLineItemsDetails = (): ILineItemUpdateModel => {
        return this.state.lineItem || this.convertToItemUpdateModel(this.props.data);
    };

    public render = () => {
        const { onClose } = this.props;
        const { error, success } = this.state;

        const lineItem = this.getLineItemsDetails();

        return (
            <>
                {error && <Error>Item update error</Error>}
                {success && <Message type="success">Successfully updated item</Message>}
                <ModalContainer isOpen={true} onRequestClose={onClose}>
                    <Modal.Card>
                        <Modal.Card.Head>
                            <Modal.Card.Title>Edit</Modal.Card.Title>
                            <Delete onClick={onClose} />
                        </Modal.Card.Head>
                        <Modal.Card.Body>
                            <FormField
                                label="Title"
                                horizontal={true}
                                value={lineItem.title || ''}
                                onChange={this.updateDetails('title')}
                                placeholder="Pre-Filled with clicked text"
                            />
                            <FormField
                                label="Spare part description"
                                horizontal={true}
                                value={lineItem.description || ''}
                                onChange={this.updateDetails('description')}
                            />
                            <FormField
                                label="Quantity"
                                horizontal={true}
                                value={lineItem.quantity || ''}
                                onChange={this.updateDetails('quantity')}
                            />
                            <Field horizontal={true}>
                                <Field.Label size="normal">
                                    <Label>Type</Label>
                                </Field.Label>
                                <Field.Body>
                                    <Field>
                                        <Control expanded={true}>
                                            <LineItemRequestTypeSelectMenu
                                                defaultSelected={lineItem.requestType}
                                                onUpdate={this.updateDetails('requestType')}
                                            />
                                        </Control>
                                    </Field>
                                </Field.Body>
                            </Field>
                        </Modal.Card.Body>
                        <Modal.Card.Foot align="right">
                            <Mutation
                                mutation={LINE_ITEM_UPDATE_MUTATION}
                                onError={this.onError}
                                onCompleted={this.onCompleted}
                                variables={{
                                    lineItem: _.omit(lineItem, [
                                        '__typename',
                                        'pageClick.__typename',
                                    ]),
                                }}
                            >
                                {doLineItemUpdate => (
                                    <Button onClick={doLineItemUpdate} disabled={this.isDisabled()}>
                                        Save
                                    </Button>
                                )}
                            </Mutation>
                        </Modal.Card.Foot>
                    </Modal.Card>
                </ModalContainer>
            </>
        );
    };
}
