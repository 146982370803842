import * as React from 'react';
import { Query } from 'react-apollo';

import {
    IAggregatedSpindexPageQueryResult,
    ISpindexPageQueryResult,
} from '../../models/IElasticModel';
import { notification } from '../../utils/notification';
import { ES_PAGES_BY_DOCUMENT_QUERY } from './../../api/GraphQLQueries/Elastic';
import Loading from './../../components/layout/Loading';

interface IProps {
    limit?: number;
    offset?: number;
    esRequest: string;
    search?: string;
    query?: any;
    hideLoading?: boolean;
    onLoading?: () => JSX.Element;
    dataProp?: string;
    renderFetchedSearchResults: (
        results: IAggregatedSpindexPageQueryResult | ISpindexPageQueryResult,
        totalCount: number,
        aggregations: any,
    ) => JSX.Element | null;
}

export default class ElasticSearchQueryContainer extends React.Component<IProps, any> {
    public render() {
        const { esRequest, renderFetchedSearchResults, query, hideLoading, search } = this.props;
        const dataProp = this.props.dataProp || 'pages';

        // If the search results are cached, this query needs to be no-cache otherwise it overwrites
        // the previous results (which have the <em> stuff) with the regular query
        return (
            <Query
                query={query || ES_PAGES_BY_DOCUMENT_QUERY}
                variables={{ esRequest, query: search }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, error }) => {
                    if (loading) {
                        if (hideLoading) {
                            return null;
                        }
                        if (typeof this.props.onLoading === 'function') {
                            return this.props.onLoading();
                        }
                        return <Loading />;
                    }
                    if (error) {
                        notification.error('Error fetching search results...');
                        return null;
                    }

                    const { aggregations, totalCount } = data[dataProp];

                    return renderFetchedSearchResults(data[dataProp], totalCount, aggregations);
                }}
            </Query>
        );
    }
}
