import { Section, Title } from 'rbx';
import * as React from 'react';

import CsvParser from './admin/onboarding/CsvParser';
import withMainNavigation from './withMainNavigation';

class SpacyConverterContainer extends React.Component {
    public render() {
        return (
            <Section>
                <Title subtitle={true}>Convert spacy csv to parts csv</Title>
                <p>Select a csv file using the button below.</p>
                <p>After uploading, the converted file will be downloaded.</p>
                <br />
                <p>
                    If there were errors during the conversion, a csv file "error-[uploadname].csv"
                    will be downloaded.
                </p>
                <p>
                    The csv will contain a new error column with descriptions of the problem inline.
                </p>
                <p>
                    Make any changes to the rows that have errors, and re-upload the file (you don't
                    have to remove the error column or header error rows - they are ignored).
                </p>
                <br />
                <p>
                    If the conversion is successful, a csv called "plantserv-parts.csv" will be
                    downloaded.
                </p>
                <br />
                <p>The output is always semicolon-delimited (;).</p>
                <br />
                <CsvParser />
            </Section>
        );
    }
}

export default withMainNavigation(SpacyConverterContainer);
