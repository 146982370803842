import React from 'react';

import { ISorting } from '../../containers/common/ListQueryContainer';
import MultiSelect from '../ui/MultiSelect';

const SORTING_DIRECTION_LABELS = {
    ASC: 'asc',
    DESC: 'desc',
};

export interface ISortOption {
    optionName: string;
    sortByField: string;
    hideAsc?: boolean;
    hideDesc?: boolean;
    hideAscDirLabel?: boolean;
    hideDescDirLabel?: boolean;
    default?: string;
}

interface ISelectItem {
    id?: number;
    text: string;
    optionName: string;
    sortByDirection: string;
    sortByField: string;
    isVisible?: boolean;
    isDefault?: boolean;
}

interface IProps {
    sortOptions: ISortOption[];
    selected?: ISorting;
    onSelect: (sortBy?: ISorting) => void;
}

interface IState {
    selectedOption: ISelectItem | null;
    defaultOption: ISelectItem | null;
    items: ISelectItem[];
}

class SearchSortingSelectMenu extends React.Component<IProps, IState> {
    public state: IState = {
        selectedOption: null,
        defaultOption: null,
        items: [],
    };

    public componentWillMount() {
        const items = this.buildItems();

        this.setState((prevState: IState) => ({
            ...prevState,
            items,
            selectedOption: prevState.defaultOption,
        }));
    }

    private handleChange = (selectedOption: any) => {
        const sortOrder: ISorting = {
            field: selectedOption.sortByField,
            direction: selectedOption.sortByDirection,
        };

        this.props.onSelect(sortOrder);
        this.setState((prevState: IState) => ({
            ...prevState,
            selectedOption,
        }));
    };

    private buildTextString = (name: string, sortByDirection: string): string => {
        return `${name} ${sortByDirection}ending`;
    };

    private buildItem = (option: ISortOption, sortByDirection: string): ISelectItem => {
        const {
            optionName,
            sortByField,
            hideAscDirLabel,
            hideDescDirLabel,
            hideAsc,
            hideDesc,
        } = option;
        const hideDir =
            sortByDirection === SORTING_DIRECTION_LABELS.ASC ? hideAscDirLabel : hideDescDirLabel;
        const text = !hideDir ? this.buildTextString(optionName, sortByDirection) : optionName;
        const isVisible = sortByDirection === SORTING_DIRECTION_LABELS.ASC ? !hideAsc : !hideDesc;
        const isDefault = sortByDirection === option.default;
        return {
            text,
            sortByDirection,
            optionName,
            sortByField,
            isVisible,
            isDefault,
        };
    };

    private buildItems = (): ISelectItem[] => {
        const items: ISelectItem[] = [];
        const { sortOptions } = this.props;
        let index = 0;
        sortOptions.forEach((option: ISortOption) => {
            const ascending: ISelectItem = this.buildItem(option, SORTING_DIRECTION_LABELS.ASC);
            const descending: ISelectItem = this.buildItem(option, SORTING_DIRECTION_LABELS.DESC);

            [ascending, descending].forEach((selectItem: ISelectItem) => {
                selectItem.id = index;
                if (selectItem.isVisible) {
                    items.push(selectItem);
                }
                if (selectItem.isDefault) {
                    this.setState((prevState: IState) => ({
                        ...prevState,
                        defaultOption: selectItem,
                    }));
                }
                index++;
            });
        });

        return items;
    };

    private getOptionType = (index: string): ((sortOption: ISelectItem) => string) => {
        return (sortOption: ISelectItem) => (sortOption as { [key: string]: any })[index];
    };

    public render() {
        const { items, defaultOption, selectedOption } = this.state;

        return (
            <MultiSelect<ISelectItem>
                defaultValue={defaultOption}
                value={selectedOption}
                options={items}
                onChange={this.handleChange}
                getOptionValue={this.getOptionType('id')}
                getOptionLabel={this.getOptionType('text')}
                isClearable={false}
                isSearchable={false}
            />
        );
    }
}

export default SearchSortingSelectMenu;
