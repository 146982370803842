import ReactGA from 'react-ga';

import { isAdmin } from '../api/authorization';

const PAGE_REGEX = /[0-9]*$/;

class Tracker {
    public static loadMoreTimeout: any;

    public static init = () => {
        ReactGA.initialize(
            [
                {
                    trackingId:
                        process.env.NODE_ENV !== 'production' ? null : 'UA-140678383-1',

                },
            ],
            {
                debug: false,
                alwaysSendToDefaultTracker: true,
                testMode: process.env.NODE_ENV !== 'production',

            },
        );
    };

    public static setRole = (role: string) => {
        if (isAdmin()) {
            return;
        }

        ReactGA.set({ dimension1: role });
    };

    public static trackSearch = (searchText: string) => {
        if (isAdmin()) {
            return;
        }

        if (searchText !== '') {
            ReactGA.event({
                category: 'Search',
                action: 'Search Query',
                label: searchText,
            });
        }
    };

    public static trackFilter = (filters: any) => {
        if (isAdmin()) {
            return;
        }

        const filtersNames = Object.keys(filters);
        filtersNames.forEach((_, i) => {
            filters[filtersNames[i]].forEach((filter: string) =>
                ReactGA.event({
                    category: 'Filters',
                    action: filtersNames[i],
                    label: filter,
                }),
            );
        });
    };

    public static trackLoadMore = (index: string) => {
        if (isAdmin()) {
            return;
        }

        if (Tracker.loadMoreTimeout) {
            clearTimeout(Tracker.loadMoreTimeout);
        }
        Tracker.loadMoreTimeout = setTimeout(() => {
            ReactGA.event({
                category: 'Search',
                action: 'LoadMore',
                label: index,
            });
        }, 2000);
    };

    public static trackOpenDocument = (docId: string) => {
        if (isAdmin()) {
            return;
        }

        const page = docId.match(PAGE_REGEX) ? parseInt(docId.match(PAGE_REGEX)![0], 10) : -1;
        if (docId !== '') {
            ReactGA.event({
                category: 'Search',
                action: 'open document',
                label: docId,
                value: page,
            });
        }
    };

    public static trackPopoverAction = (action: string, label: string) => {
        if (isAdmin()) {
            return;
        }

        ReactGA.event({
            category: 'Popover',
            action,
            label,
        });
    };
}

export default Tracker;