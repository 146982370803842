import { Title } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';

import AuthLayout from '../AuthLayout';
import { RESET_PASSWORD_MUTATION } from './../../api/GraphQLQueries/Auth';
import AuthForm from './../../components/auth/AuthForm';
import Routing from './../../Routing';
import { t } from './../../utils/translate';

interface IState {
    title: string;
    text: string;
    submitted: boolean;
    showLoginButton: boolean;
}

export default class PasswordResetContainer extends React.Component<any, IState> {
    public state: IState = {
        title: t().password_reset_title,
        text: t().password_reset_text,
        submitted: false,
        showLoginButton: false,
    };

    public componentDidMount() {
        const {
            match: { params },
        } = this.props;
        if (!params.hash) {
            this.changePasswordFailed();
        }
    }

    private formSubmit = (doResetPasswordMutation: any) => (
        email: string,
        password: string,
    ): void => {
        void email;
        const {
            match: { params },
        } = this.props;
        doResetPasswordMutation({
            variables: { hash: params.hash, password },
            fetchPolicy: 'no-cache',
        });
    };

    private onCompleted = (): void => {
        this.setState({
            title: t().password_reset_success_title,
            text: t().password_reset_success_text,
            submitted: true,
            showLoginButton: true,
        });
    };

    private onError = (): void => {
        this.changePasswordFailed();
    };

    private changePasswordFailed = (): void => {
        this.setState({
            title: t().password_reset_failed_title,
            text: t().password_reset_failed_text,
            submitted: false,
        });
    };

    public render() {
        const { submitted, title, text, showLoginButton } = this.state;

        return (
            <AuthLayout>
                <Title size={3}>{title}</Title>
                <p>{text}</p>
                {!submitted && (
                    <Mutation
                        mutation={RESET_PASSWORD_MUTATION}
                        ignoreResults={true}
                        onCompleted={this.onCompleted}
                        onError={this.onError}
                    >
                        {doResetPassword => (
                            <AuthForm
                                buttonText={t().password_reset_title}
                                onSubmit={this.formSubmit(doResetPassword)}
                                isResetPassword={true}
                            />
                        )}
                    </Mutation>
                )}
                {submitted && showLoginButton && (
                    <Link className="button is-primary" to={Routing.AUTH_LOGIN.route}>
                        {t().login}
                    </Link>
                )}
            </AuthLayout>
        );
    }
}
