import { Button, Icon } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { INDEX_DOCUMENTUM_DOCUMENTS_CSV } from '../../../../api/GraphQLQueries/Onboarding';
import { MaintenanceButton } from '../../../../components/ui/MaintenanceButton';
import { notification } from '../../../../utils/notification';

interface IProps {
    plantId: string;
    disabled?: boolean;
}

interface IState {
    disabled: boolean;
}

export default class IndexDocumentumDocumentsCsvMutation extends React.Component<IProps, IState> {
    public state: IState = {
        disabled: false,
    };

    private onError = (): void => {
        this.setState({ disabled: false });
    };

    private onIndexCompleted = async ({
        indexDocumentumDocumentsCsv: { numCsvRows, numDocuments },
    }): Promise<void> => {
        notification.success(
            `Started processing ${numDocuments} documents (out of ${numCsvRows} csv rows)
            Keep track of the progress by looking at the Rabbit Stats UI below.`,
            0,
        );

        this.setState({ disabled: false });
    };

    public render = () => {
        const { disabled } = this.state;
        const { plantId } = this.props;

        return (
            <>
                <Mutation
                    mutation={INDEX_DOCUMENTUM_DOCUMENTS_CSV}
                    variables={{ plantId }}
                    onCompleted={this.onIndexCompleted}
                    onError={this.onError}
                    awaitRefetchQueries={false}
                >
                    {(doIndexMutation, { loading: loadingIndex }) => (
                        <>
                            {loadingIndex ? (
                                <>
                                    <Button color="info">
                                        <Icon>
                                            <i className="fa fa-cog fa-spin" />
                                        </Icon>
                                        <span>Processing...</span>
                                    </Button>
                                    <div>
                                        <small>Please wait, this could take a few seconds.</small>
                                    </div>
                                </>
                            ) : (
                                <MaintenanceButton
                                    type="button"
                                    disabled={disabled}
                                    onClick={doIndexMutation}
                                >
                                    Index DCTM_Export_Data*.csv
                                </MaintenanceButton>
                            )}
                        </>
                    )}
                </Mutation>
            </>
        );
    };
}
