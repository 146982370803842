import { Button, Icon, Input, Label } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { Part } from '../../../../../backend/src/plantserv/part/part.entity';
import { DELETE_EQUIPMENT_FOR_PLANT_MUTATION } from '../../api/GraphQLQueries/Ops';
import { notification } from '../../utils/notification';
import OpsModal from './OpsModal';

export default class DeletePartsForPlantMutation extends React.Component {
    private formSubmit = (deletePartsForPlantMutation: any) => (plantId: string): void => {
        deletePartsForPlantMutation({ variables: { plantId }, fetchPolicy: 'no-cache' });
    };

    private onError = (): void => {
        notification.error('Error deleting parts for plant');
    };

    private onCompleted = (data: Part[]): void => {
        if (data.length) {
            notification.warn('Some parts could not be deleted at this time.');
        } else {
            notification.success('Success - plant parts will be deleted');
        }
    };

    private onClose = (): void => {
        return;
    };

    private renderBody = (handleChange: any) => {
        return (
            <>
                <Input placeholder="plant id" onChange={handleChange} />
                <hr />
                <Label>
                    After submiting please wait a while for the index to catch-up. Depending on the
                    number of parts, this can take a while. You will see a warning if some of the
                    parts could not be deleted - this will happen when they are referenced by
                    baskets or vendor parts. These must be deleted manually.
                </Label>
            </>
        );
    };

    public render() {
        return (
            <>
                <Mutation
                    mutation={DELETE_EQUIPMENT_FOR_PLANT_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {(deletePartsForPlantMutation, { loading }) =>
                        (loading && (
                            <Button color="info">
                                <Icon>
                                    <i className="fa fa-cog fa-spin" />
                                </Icon>
                                <span>Processing deletion. Please wait</span>
                            </Button>
                        )) || (
                            <OpsModal
                                title="Delete Parts/Equipment For Plant"
                                alertMessage="Are you sure you want to delete plant parts?"
                                onSubmit={this.formSubmit(deletePartsForPlantMutation)}
                                onClose={this.onClose}
                                renderBody={this.renderBody}
                                doubleCheck={true}
                                requireInput={true}
                            />
                        )
                    }
                </Mutation>
            </>
        );
    }
}
