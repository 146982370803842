import * as _ from 'lodash';
import { Button, Control, Dropdown, Icon, Input, Label, Tag } from 'rbx';
import * as React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { generatePath, RouteComponentProps, withRouter } from 'react-router';

import Cache from '../../api/cache';
import { ES_PAGES_BY_DOCUMENT_QUERY } from '../../api/GraphQLQueries/Elastic';
import IPlantModel from '../../models/IPlantModel';
import IUserModel from '../../models/IUserModel';
import Routing from '../../Routing';
import { notification } from '../../utils/notification';
import { buildPath, getUrlParams, IUrl } from '../../utils/queryString';
import IsAdminQuery from '../auth/IsAdminQuery';
import UserPlantsQuery from '../user/queries/UserPlantsQuery';
import FolderSelectorModal from './FolderSelectorModal';

interface IProps extends RouteComponentProps<IRouteParams> {}

interface IRouteParams {
    plantId: string;
}

interface IState {
    pathinfo: IUrl;
    showPlantSelector: boolean;
    termInvalid?: boolean;
    showFilters: boolean;
    documentTitle: string;
    folderPaths: string[];
}

class SearchInputField extends React.Component<IProps, IState> {
    private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    public state: IState = {
        pathinfo: getUrlParams(this.props.location),
        showPlantSelector: false,
        showFilters: false,
        documentTitle: this.extractPropertyFromUrl('documentTitle'),
        folderPaths: this.extractFolderPathsFromUrl(),
    };

    private extractFolderPathsFromUrl() {
        const pathInfo = getUrlParams(this.props.location);
        if (!pathInfo.params.folderPaths) {
            return null;
        }
        if (!Array.isArray(pathInfo.params.folderPaths)) {
            return [pathInfo.params.folderPaths];
        }
        return pathInfo.params.folderPaths;
    }

    private extractPropertyFromUrl(propertyName: string) {
        const pathInfo = getUrlParams(this.props.location);
        const value = pathInfo.params[propertyName];
        return value || '';
    }

    private plantName(plant: IPlantModel) {
        return plant.customerDisplayName || plant.name;
    }

    private togglePlantSelector = () => {
        this.setState({
            showPlantSelector: !this.state.showPlantSelector,
            showFilters: false,
        });
    };

    private closePlantSelector = () => {
        this.setState({
            showPlantSelector: false,
            showFilters: false,
        });
    };

    private selectPlant = (plant: IPlantModel) => {
        const plantId = plant.id;
        const plantPath = generatePath(Routing.SEARCH_PLANT.route, { plantId });
        this.props.history.push(plantPath);

        this.inputRef.current!.focus();
    };

    private toggleShowFilters = () => {
        this.setState({ showFilters: !this.state.showFilters });
    };

    private onFilterPanelSubmit = e => {
        if (e.key === 'Enter') {
            this.handleFormSubmit(e);
        } else if (e.key === 'Escape') {
            this.toggleShowFilters();
        }
    };

    private updateFolderPaths = (selectedPlant: IPlantModel) => (localFolderPaths: string[]) => {
        const folderPaths = localFolderPaths.map(
            folderPath => `/plants/${selectedPlant.s3Folder}/${folderPath}`,
        );
        this.setState({ folderPaths });
    };

    private documentTitleChange = (context: any) => {
        this.setState({ documentTitle: context.currentTarget.value });
    };

    private handleFormSubmit = (e: React.SyntheticEvent) => {
        // prevent the browser from submitting the form
        e.preventDefault();
        const searchValue = (this.inputRef.current!.value || '').trim();

        // just bail if there are an odd number of quotes
        const numQuotes = (searchValue.match(/"/g) || []).length;
        if (numQuotes % 2 !== 0) {
            this.setState({ termInvalid: true });
            notification.warn('Invalid search: unmatched quotation (")');
            return;
        }

        const pathinfo = getUrlParams(this.props.location);
        pathinfo.params.docId = null;
        const currentPath = this.props.match.path;
        let route = Routing.SEARCH_PLANT.route;
        if (
            currentPath === Routing.SEARCH_PLANT.route ||
            currentPath === Routing.TAG_DETAILS.route
        ) {
            route = currentPath;
        }
        pathinfo.pathname = generatePath(route, {
            ...this.props.match.params,
        });

        if (
            /plant\/[^]*\/search/.test(pathinfo.pathname) &&
            pathinfo.params.query !== searchValue
        ) {
            // We're changing the query string on /search, so invalidate the caches.
            // This mitigates the edge case where deleted pages appear in the results due to caching.
            Cache.invalidateQuery(ES_PAGES_BY_DOCUMENT_QUERY);
        }

        pathinfo.params.query = searchValue;
        pathinfo.params.documentTitle = this.state.documentTitle;
        pathinfo.params.folderPaths = this.state.folderPaths;
        const path = buildPath(pathinfo);

        this.setState({ showFilters: false });
        this.props.history.push(path);
    };
    private clearFilters = () => {
        this.setState(
            (prev: IState) => ({
                ...prev,
                showPlantSelector: !this.state.showPlantSelector,
                documentTitle: '',
                folderPaths: [],
            }),
            () => {
                const pathinfo = getUrlParams(this.props.location);
                pathinfo.params.documentTitle = '';
                pathinfo.params.folderPaths = 'this.state.folderPaths';
                const path = buildPath(pathinfo);
                this.props.history.push(path);
            },
        );
    };

    private renderPlantList = (plants: IPlantModel[]) => {
        plants = _.sortBy(plants, [
            (plant: IPlantModel) => plant.customerDisplayName.toLocaleLowerCase(),
        ]);
        return (
            <Dropdown.Content>
                {plants.map((plant: IPlantModel, index: number) => {
                    return (
                        <Dropdown.Item
                            key={plant.id}
                            onClick={this.selectPlant.bind(this, plant)}
                            data-cy={`searchInput-dropDown-plant${index}`}
                        >
                            {this.plantName(plant)}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Content>
        );
    };

    private setValid = () => {
        this.setState({ termInvalid: false });
    };

    private renderFilterToggle = () => {
        const { documentTitle } = this.state;
        const { plantId } = this.props.match.params;
        const filtersActive = documentTitle;

        if (!plantId) {
            return null;
        }

        return (
            <Icon
                className="search-filter-toggle"
                size="small"
                align="right"
                onClick={this.toggleShowFilters}
                title={filtersActive ? 'Filter(s) active' : 'Set filters'}
            >
                {filtersActive ? (
                    <i className="has-text-primary fa fa-filter" />
                ) : (
                    <i className="fa fa-caret-down" />
                )}
            </Icon>
        );
    };

    private renderSearchForm = (user: IUserModel, plants: IPlantModel[]) => {
        void user;
        const { termInvalid, showFilters } = this.state;

        const { plantId } = this.props.match.params;
        const selectedPlant = plants.find(plant => plant.id === plantId);
        if (plants.length === 0) {
            return <></>;
        }

        return (
            <>
                <form
                    onSubmit={this.handleFormSubmit}
                    className="search-form"
                    title={termInvalid ? 'Invalid search: unmatched quotation (")' : null}
                >
                    <KeyboardEventHandler
                        handleKeys={['esc']}
                        onKeyEvent={this.closePlantSelector}
                    />
                    <Control
                        onChange={this.setValid}
                        className="search-control"
                        iconLeft={true}
                        iconRight={true}
                    >
                        <div className="input is-medium search-input ">
                            <Icon size="small" align="left">
                                <i className="fa fa-search" />
                            </Icon>
                            <Dropdown>
                                <Dropdown.Trigger onClick={this.togglePlantSelector}>
                                    {selectedPlant ? (
                                        <Tag
                                            data-cy={`searchInputField-plantSelect-dropDownMenu`}
                                            size="medium"
                                            color="info"
                                        >
                                            {this.plantName(selectedPlant)}
                                        </Tag>
                                    ) : (
                                        <Tag
                                            size="medium"
                                            data-cy={`searchInputField-plantSelect-dropDownMenu`}
                                        >
                                            Select Plant
                                        </Tag>
                                    )}
                                </Dropdown.Trigger>
                                <Dropdown.Menu>{this.renderPlantList(plants)}</Dropdown.Menu>
                            </Dropdown>
                            {this.renderInputField()}
                            {termInvalid && (
                                <Icon size="small" align="right">
                                    <i className="fa fa-exclamation" />
                                </Icon>
                            )}
                            <IsAdminQuery renderAdmin={this.renderFilterToggle} />
                        </div>
                    </Control>

                    {showFilters && this.renderFilterPanel(selectedPlant)}
                </form>
            </>
        );
    };

    private renderInputField = () => {
        const { plantId } = this.props.match.params;
        const pathinfo = getUrlParams(this.props.location);
        const queryText = (pathinfo.params.query || '').trim();

        return (
            <input
                key={queryText}
                ref={this.inputRef}
                type="text"
                defaultValue={queryText}
                onBlur={this.handleFormSubmit}
                placeholder={plantId ? 'Search for something' : 'First select a plant'}
                disabled={plantId ? false : true}
                data-cy={`searchInputField-search-input`}
            />
        );
    };

    private renderFilterPanel = (selectedPlant: IPlantModel) => {
        if (!selectedPlant) {
            return;
        }

        const { documentTitle, folderPaths } = this.state;
        const localFolderPaths = (folderPaths || []).map(folderPath =>
            folderPath
                .split('/')
                .slice(3)
                .join('/'),
        );

        return (
            <>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={this.toggleShowFilters} />
                <div className="card search-filters">
                    <div>
                        <Label>Document Title / Filename</Label>
                        <Input
                            type="text"
                            value={documentTitle}
                            onChange={this.documentTitleChange}
                            onKeyDown={this.onFilterPanelSubmit}
                        />
                    </div>
                    {false && (
                        <div>
                            <Label>Folders</Label>
                            <FolderSelectorModal
                                plantId={selectedPlant.id}
                                folderPaths={localFolderPaths}
                                onChange={this.updateFolderPaths(selectedPlant)}
                            />
                        </div>
                    )}
                    <div className="filter-buttons">
                        <Button className="cancel" onClick={this.toggleShowFilters}>
                            Cancel
                        </Button>
                        <Button.Group>
                            <Button className="cancel" onClick={this.clearFilters}>
                                Clear
                            </Button>
                            <Button className="submit" onClick={this.handleFormSubmit}>
                                Apply filters
                            </Button>
                        </Button.Group>
                    </div>
                </div>
            </>
        );
    };

    public render = () => {
        return (
            <UserPlantsQuery
                hideLoadingIndicator={true}
                renderFetchedPlantData={this.renderSearchForm}
            />
        );
    };
}
export default withRouter(SearchInputField);
