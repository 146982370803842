import * as React from 'react';
import { ValueType } from 'react-select/lib/types';

import { IGrapQLDataResult } from '../../api/GraphQLQueries/GraphQLTypes.d';
import MultiSelect from '../ui/MultiSelect';
import { GROUP_LIST_ALL_QUERY } from './../../api/GraphQLQueries/Group';
import IGroupModel from './../../models/IGroupModel';
import GroupListQuery from './queries/GroupListQuery';

interface IProps {
    multiple?: boolean;
    onUpdate?: (ids: number[]) => void;
    onResult?: (data: IGrapQLDataResult<IGroupModel[]>) => void;
    defaultSelected?: number[];
    disabled?: number[];
    selectHeader?: string;
}

interface IState {
    limit: number;
    offset: number;
    groups: number[];
}

export default class GroupListSelectMenu extends React.Component<IProps, IState> {
    public state: IState = {
        offset: 0,
        limit: 100,
        groups: [],
    };

    public componentDidMount() {
        this.setState({ groups: this.props.defaultSelected || [] });
    }

    private setGroups = (groups: number[]): void => {
        const { onUpdate } = this.props;

        this.setState({ groups }, () => {
            if (typeof onUpdate === 'function') {
                onUpdate(groups);
            }
        });
    };

    private optionDisabled = (group: IGroupModel): boolean => {
        const { disabled } = this.props;
        if (!disabled || !disabled.length) {
            return false;
        }
        return disabled.includes(group.id);
    };

    private handleChange = (groups: ValueType<IGroupModel>): void => {
        if (!groups || (Array.isArray(groups) && groups.length === 0)) {
            return this.setGroups([]);
        }

        // Groups are an array when props.multiple is true, but checking props requires TS type casting
        if (Array.isArray(groups)) {
            this.setGroups(groups.map(({ id }) => id));
        } else {
            this.setGroups([groups.id]);
        }
    };

    private getOptionType = (index: string): ((group: IGroupModel) => string) => {
        return (group: IGroupModel) => (group as { [key: string]: any })[index];
    };

    private renderSelectMenu() {
        return (groups: IGroupModel[]) => {
            const { groups: stateGroups } = this.state;
            const selected = groups.filter(({ id }) => stateGroups.includes(id));

            return (
                groups && (
                    <MultiSelect<IGroupModel>
                        isMulti={this.props.multiple}
                        value={selected}
                        isClearable={true}
                        isOptionDisabled={this.optionDisabled}
                        closeMenuOnSelect={!this.props.multiple}
                        getOptionLabel={this.getOptionType('name')}
                        getOptionValue={this.getOptionType('id')}
                        options={groups}
                        placeholder={this.props.selectHeader}
                        onChange={this.handleChange}
                    />
                )
            );
        };
    }

    public render() {
        const { limit, offset } = this.state;

        return (
            <GroupListQuery
                limit={limit}
                offset={offset}
                fetchPolicy="network-only"
                query={GROUP_LIST_ALL_QUERY}
                onResult={this.props.onResult}
                renderFetchedGroupList={this.renderSelectMenu()}
            />
        );
    }
}
