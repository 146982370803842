import { Button, Delete, Modal } from 'rbx';
import * as React from 'react';

import { IPartCreateModel, IPartUpdateModel } from './../../models/IPartModel';
import ModalContainer from './../ui/ModalContainer';
import PartEditForm from './forms/PartEditForm';

interface IProps {
    part: IPartUpdateModel | IPartCreateModel;
    onClose: () => void;
    onSubmit: () => void;
    onUpdate: (part: IPartUpdateModel | IPartCreateModel) => void;
    disableSave: boolean;
    createMode?: boolean;
    tagMode?: boolean;
    tagCreateMode?: boolean;
    actionButtons?: any;
    modalTitle?: string;
}

export default class PartEditModal extends React.Component<IProps, any> {
    public render() {
        const {
            part,
            onSubmit,
            onUpdate,
            disableSave,
            createMode,
            tagCreateMode,
            tagMode,
            onClose,
            actionButtons,
            modalTitle,
        } = this.props;

        return (
            <ModalContainer isOpen={true} onRequestClose={onClose}>
                <Modal.Background />
                <Modal.Card className="is-scrollable">
                    <Modal.Card.Head>
                        <Modal.Card.Title>
                            {modalTitle ? modalTitle : `Edit part ${part.designation}`}
                        </Modal.Card.Title>
                        <Delete onClick={onClose} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <PartEditForm
                            part={part}
                            onUpdate={onUpdate}
                            createMode={createMode}
                            tagCreateMode={tagCreateMode}
                            tagMode={tagMode}
                        />
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button onClick={onClose}>Close</Button>
                        {actionButtons}
                        <Button
                            color="primary"
                            disabled={disableSave}
                            data-cy="partEditModal-save-btn"
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    }
}
