import { Button, Content } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { getCurrentUser } from '../../api/authorization';
import IBasketModel, { IBasketUpdateModel } from '../../models/IBasketModel';
import { GlobalRole } from '../../models/IUserModel';
import omit from '../../utils/omit';
import IsAdminQuery from '../auth/IsAdminQuery';
import { BASKET_LINE_ITEMS_QUERY, BASKET_UPDATE_MUTATION } from './../../api/GraphQLQueries/Basket';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import BasketFiles from './BasketFiles';
import BasketEditForm from './forms/BasketEditForm';
import BasketArchivedMutation from './queries/BasketArchivedMutation';
import BasketDeleteMutation from './queries/BasketDeleteMutation';
import BasketStatusMutation from './queries/BasketStatusMutation';

interface IProps {
    basket: IBasketModel;
}

interface IState {
    basket?: IBasketModel;
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

export default class BasketDetails extends React.Component<IProps, IState> {
    public state: IState = {
        ...resetState,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
                basket: undefined,
            }),
            this.resetState,
        );
    };

    private convertToBasketUpdateModel = (basket: IBasketModel): IBasketUpdateModel => {
        let basketDetails: IBasketUpdateModel = omit(basket, [
            'updatedBy',
            'createdBy',
            'createdAt',
            'updatedAt',
            'plant',
            'lineItems',
            'status',
            'archived',
        ]);

        if (getCurrentUser().globalRole !== GlobalRole.linde_admin) {
            basketDetails = omit(basketDetails, ['purchaseState']);
        }

        return basketDetails;
    };

    private getBasketDetails = (): IBasketModel => {
        return this.state.basket || this.props.basket;
    };

    private updateBasketData = (basket: IBasketModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            basket,
            error: false,
            success: false,
        }));
    };

    private submitBasket = (doBasketUpdateMutation: MutationFn) => (): void => {
        const basket = this.convertToBasketUpdateModel(this.state.basket);
        doBasketUpdateMutation({ variables: { basket } });
    };

    private renderArchivedMutation = () => {
        const { archived, id } = this.props.basket;
        return id && <BasketArchivedMutation basketId={id} basketArchived={archived} />;
    };
    private renderAdminBasketDeleteMutation = () => {
        return <BasketDeleteMutation basket={this.getBasketDetails()} />;
    };

    private renderUserBasketDeleteMutation = () => {
        const { status } = this.props.basket;
        if (status !== 'draft') {
            return <></>;
        }
        return <BasketDeleteMutation basket={this.getBasketDetails()} />;
    };

    public render() {
        const { error, success } = this.state;
        const { status, id, purchaseState } = this.props.basket;
        const basketDetails = this.getBasketDetails();

        return (
            basketDetails && (
                <>
                    {error && <Error>Basket update error</Error>}
                    {success && <Message type="success">Successfully updated basket</Message>}
                    <Mutation
                        mutation={BASKET_UPDATE_MUTATION}
                        onError={this.onError}
                        onCompleted={this.onCompleted}
                        refetchQueries={[
                            { query: BASKET_LINE_ITEMS_QUERY, variables: { id: basketDetails.id } },
                        ]}
                    >
                        {doBasketUpate => (
                            <>
                                <BasketEditForm
                                    basket={basketDetails}
                                    onUpdate={this.updateBasketData}
                                />

                                <BasketFiles basketId={basketDetails.id} />

                                <Content>
                                    <Button.Group>
                                        <Button
                                            color="primary"
                                            disabled={!this.state.basket}
                                            onClick={this.submitBasket(doBasketUpate)}
                                        >
                                            Save {status !== 'draft' ? 'basket' : 'draft'}
                                        </Button>
                                        {status && id && (
                                            <BasketStatusMutation
                                                basketId={id}
                                                basketStatus={status}
                                            />
                                        )}
                                        {status !== 'draft' && purchaseState !== 'notset' && (
                                            <IsAdminQuery
                                                renderAdmin={this.renderArchivedMutation}
                                            />
                                        )}
                                        <IsAdminQuery
                                            renderAdmin={this.renderAdminBasketDeleteMutation}
                                            renderUser={this.renderUserBasketDeleteMutation}
                                        />
                                    </Button.Group>
                                </Content>
                            </>
                        )}
                    </Mutation>
                </>
            )
        );
    }
}
