import * as React from 'react';
import { ValueType } from 'react-select/lib/types';

import MultiSelect from '../ui/MultiSelect';
import { USER_LIST_ALL_QUERY } from './../../api/GraphQLQueries/User';
import IUserModel from './../../models/IUserModel';
import UserListQuery from './queries/UserListQuery';

interface IProps {
    multiple?: boolean;
    onUpdate?: (ids: number[]) => void;
    defaultSelected?: number[];
    disabled?: number[];
    selectHeader?: string;
}

interface IState {
    limit: number;
    offset: number;
    users: number[];
}

export default class UserListSelectMenu extends React.Component<IProps, IState> {
    public state: IState = {
        offset: 0,
        limit: 1000,
        users: [],
    };

    public componentDidMount() {
        this.setState({ users: this.props.defaultSelected || [] });
    }

    private setUsers = (users: number[]): void => {
        const { onUpdate } = this.props;

        this.setState({ users }, () => {
            if (typeof onUpdate === 'function') {
                onUpdate(users);
            }
        });
    };

    private optionDisabled = (user: IUserModel): boolean => {
        const { disabled } = this.props;
        if (!disabled || !disabled.length) {
            return false;
        }
        return disabled.includes(user.id);
    };

    private handleChange = (users: ValueType<IUserModel>): void => {
        if (!users || (Array.isArray(users) && users.length === 0)) {
            return this.setUsers([]);
        }

        if (Array.isArray(users)) {
            this.setUsers(users.map(({ id }) => id));
        } else {
            this.setUsers([users.id]);
        }
    };

    private getOptionType = (index: string): ((user: IUserModel) => string) => {
        return (user: IUserModel) => (user as { [key: string]: any })[index];
    };

    private renderSelectMenu() {
        return (users: IUserModel[]) => {
            const { users: stateUsers } = this.state;
            const selected = users.filter(({ id }) => stateUsers.includes(id));

            return (
                users && (
                    <MultiSelect<IUserModel>
                        isMulti={this.props.multiple}
                        value={selected}
                        isClearable={true}
                        isOptionDisabled={this.optionDisabled}
                        closeMenuOnSelect={!this.props.multiple}
                        getOptionLabel={this.getOptionType('name')}
                        getOptionValue={this.getOptionType('id')}
                        options={users}
                        placeholder={this.props.selectHeader}
                        onChange={this.handleChange}
                    />
                )
            );
        };
    }

    public render() {
        const { limit, offset } = this.state;

        return (
            <UserListQuery
                hideLoadingIndicator={true}
                limit={limit}
                offset={offset}
                fetchPolicy="network-only"
                query={USER_LIST_ALL_QUERY}
                renderFetchedUserList={this.renderSelectMenu()}
            />
        );
    }
}
