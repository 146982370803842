import * as _ from 'lodash';
import { Button, Dropdown, Icon } from 'rbx';
import * as React from 'react';

import { ISorting } from './../../../containers/common/ListQueryContainer';

export interface ISortOption {
    optionName: string;
    sortByField: string;
}

interface IDrowDownItem {
    text: string;
    optionName: string;
    sortByDirection: string;
    sortByField: string;
    active: boolean;
}

interface IProps {
    sortOptions: ISortOption[];
    selected?: ISorting;
    onSelect: (sortBy?: ISorting) => void;
}

interface IState {
    active: boolean;
}

export default class DropDown extends React.Component<IProps, IState> {
    public state: IState = {
        active: false,
    };

    private isItemActive = (field: string, sortByDirection: string): boolean => {
        const { selected } = this.props;

        if (!selected) {
            return false;
        }

        return field === selected.field && sortByDirection === selected.direction;
    };

    private buildTextString = (name: string, sortByDirection: string): string => {
        return `${name} ${sortByDirection}ending`;
    };

    private buildItem = (option: ISortOption, sortByDirection: string): IDrowDownItem => {
        const { optionName, sortByField } = option;
        const active = this.isItemActive(sortByField, sortByDirection);

        return {
            text: this.buildTextString(optionName, sortByDirection),
            sortByDirection,
            optionName,
            sortByField,
            active,
        };
    };

    private handleSelect = (field: string, direction: string) => (): void => {
        const sorting: ISorting = { field, direction };
        this.props.onSelect(sorting);
        if (this.state.active) {
            this.toggleActive();
        }
    };

    private renderDropDownItem = (item: IDrowDownItem): JSX.Element => {
        return (
            <Dropdown.Item
                key={`${item.sortByField}-${item.sortByDirection}`}
                active={item.active}
                onClick={this.handleSelect(item.sortByField, item.sortByDirection)}
            >
                {item.text}
            </Dropdown.Item>
        );
    };

    private renderDropDownNameGroup = (option: ISortOption): JSX.Element => {
        const ascending: IDrowDownItem = this.buildItem(option, 'asc');
        const descending: IDrowDownItem = this.buildItem(option, 'desc');

        return (
            <>
                {this.renderDropDownItem(ascending)}
                {this.renderDropDownItem(descending)}
            </>
        );
    };

    private renderDropDownItems = (): JSX.Element => {
        const { sortOptions } = this.props;

        return (
            <>
                {sortOptions.map((option: ISortOption, key: number) => {
                    return (
                        <React.Fragment key={key}>
                            {this.renderDropDownNameGroup(option)}
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    private toggleActive = (): void => {
        this.setState((prevState: IState) => ({
            active: !prevState.active,
        }));
    };

    private resetSorting = (): void => {
        this.props.onSelect();
        if (this.state.active) {
            this.toggleActive();
        }
    };

    private renderTrigger = (): string => {
        const { selected, sortOptions } = this.props;
        const defaultTriggerText = 'Sort';

        if (!selected) {
            return defaultTriggerText;
        }

        const selectedItem = _.filter(sortOptions, (option: ISortOption) => {
            return option.sortByField === selected.field;
        }).pop();

        return selectedItem
            ? this.buildTextString(selectedItem.optionName, selected.direction)
            : defaultTriggerText;
    };

    public render = (): JSX.Element => {
        const { active } = this.state;

        return (
            <Dropdown managed={true} active={active} align="right">
                <Dropdown.Trigger>
                    <Button onClick={this.toggleActive} size="small">
                        <span>{this.renderTrigger()}</span>
                        <Icon size="small">
                            <i className="fa fa-sort-alpha-down" />
                        </Icon>
                    </Button>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                    <Dropdown.Content>
                        {this.renderDropDownItems()}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={this.resetSorting}>Reset</Dropdown.Item>
                    </Dropdown.Content>
                </Dropdown.Menu>
            </Dropdown>
        );
    };
}
