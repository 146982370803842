import { Tag } from 'rbx';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import IsAdminQuery from '../auth/IsAdminQuery';
import Icon from '../ui/Icon';

interface IProps extends RouteComponentProps {
    item: any;
}

class SearchResultTagGroup extends React.Component<IProps, any> {
    public render = () => {
        const { item } = this.props;

        return (
            <Tag.Group className="result-tags">
                <IsAdminQuery renderFor="admin">
                    {item._source.customerVisible && (
                        <Tag color="warning">
                            <Icon icon="fa-eye" />
                            Customer Visible
                        </Tag>
                    )}
                    {item._source.indexState && (
                        <Tag>
                            <Icon icon="fa-file-medical" />
                            indexState: {item._source.indexState}
                        </Tag>
                    )}
                </IsAdminQuery>
                {item._source.content.lindeDocTypeText && (
                    <Tag>
                        <Icon icon="fa-book" />
                        {item._source.content.lindeDocTypeText}
                    </Tag>
                )}
                {item._source.content.managedContentType && (
                    <Tag>
                        <Icon icon="fas fa-file" />
                        {item._source.content.managedContentType}
                    </Tag>
                )}

                {item._source.content.lindeDiscplineText && (
                    <Tag>
                        <Icon icon="fa-bookmark" />
                        {item._source.content.lindeDiscplineText}
                    </Tag>
                )}
            </Tag.Group>
        );
    };
}

export default withRouter(SearchResultTagGroup);
