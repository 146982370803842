import * as bodybuilder from 'bodybuilder';

import { isAdmin } from '../api/authorization';
import { ISorting } from '../containers/common/ListQueryContainer';
import { parseDocumentMeta } from './queryString';

export const DEFAULT_PAGING = {
    size: 10,
    from: 0,
};

export const FILTERS = [
    'Content Type',
    'Document Type',
    'Discipline',
    'documentTitle',
    'folderPaths',
    'Index Status',
    'Source',
];

const queryBase = bodybuilder.default;

const getVisibleIndexStates = () => {
    return isAdmin() ? ['completed', 'fileOnly', 'ignored', 'error'] : ['completed'];
};

const addSearchFiltersToQuery = (
    query: bodybuilder.Bodybuilder,
    filters: { [key: string]: any },
): void => {
    const contentTypeFilter = filters[FILTERS[0]];
    const docTypeFilter = filters[FILTERS[1]];
    const lindeDisciplineFilter = filters[FILTERS[2]];
    const documentTitle = filters[FILTERS[3]];
    const folderPathsFilter = filters[FILTERS[4]];
    const indexStatusFilter = filters[FILTERS[5]];
    const sourceFilter = filters[FILTERS[6]];

    if (contentTypeFilter) {
        query.andFilter('terms', 'content.managedContentType', contentTypeFilter);
    }
    if (docTypeFilter) {
        query.andFilter('terms', 'content.lindeDocTypeText.raw', docTypeFilter);
    }
    if (lindeDisciplineFilter) {
        query.andFilter('terms', 'content.lindeDiscplineText.raw', lindeDisciplineFilter);
    }

    if (isAdmin()) {
        if (indexStatusFilter) {
            query.addFilter('terms', 'indexState', indexStatusFilter);
        } else {
            query.addFilter('terms', 'indexState', ['completed', 'fileOnly']);
        }

        if (sourceFilter) {
            if (sourceFilter[0] === 's3') {
                query.andFilter('exists', 'fileMd5').notFilter('exists', 'spindexDocument');
            } else if (sourceFilter[0] === 'dctm') {
                query.notFilter('exists', 'fileMd5').andFilter('exists', 'spindexDocument');
            } else if (sourceFilter[0] === 'both') {
                query.andFilter('exists', 'fileMd5').andFilter('exists', 'spindexDocument');
            }
        }

        if (documentTitle) {
            const title = documentTitle[0].toLowerCase();
            query.orFilter('match', 'content.title.text', title);
            query.orFilter('match', 'spindexDocument.title.text', title);
            query.orFilter('match', 'meta.original_filename', title);
        }
        if (folderPathsFilter) {
            query.andFilter('terms', 'meta.folder', folderPathsFilter);
        }
    }
};

const addSearchTextToQuery = (query: bodybuilder.Bodybuilder, search: string): void => {
    // this needs to be set when search for a specific plant
    // query
    //  .andFilter('term', 'plant.id', 'plantId')

    // server will figure out to search for text
    // if x_search is set
    query.rawOption('x_search', search);
    /*
        please leave the following for quick testing of the search
        // query
        // .filter('term', 'content.lindeDocTypeCode', 'DV')
        // .orQuery('match', 'content.content.words.text', { query: search, boost: 10 })
        // .orQuery('match_phrase_prefix', 'content.words.text', { query: search, boost: 20 })
        // .orQuery('match', 'content.fullText', { query: search, boost: 10 })
        // .orQuery('match', 'content.lindeDiscplineText', { query: search, boost: 5 })
        // .orQuery('match', 'content.lindeDocTypeText', { query: search, boost: 5 })
        // .orQuery('match', 'meta.original_filename', { query: search, boost: 3 })
        // .orQuery('match', 'meta.full_name_parts', { query: search, boost: 1 });
    */
    // query.filter('term', 'content.lindeDocTypeCode', 'DV')
    query.rawOption('highlight', {
        pre_tags: ['<em>'],
        post_tags: ['</em>'],
        fields: {
            'content.fullText': {},
        },
    });
};

const addPlantIdToQuery = (query: bodybuilder.Bodybuilder, plantId: string): void => {
    query.addFilter('term', 'plant.id', plantId);
};

const addSearchSortToQuery = (query: bodybuilder.Bodybuilder, sort: ISorting): void => {
    const { field, direction } = sort;
    query.sort(field, direction);
};

const addSearchPagingToQuery = (query: bodybuilder.Bodybuilder): void => {
    query.size(DEFAULT_PAGING.size);
    query.from(DEFAULT_PAGING.from);
};

export const getSearchQuery = (
    search: string,
    plantId?: string,
    filters?: string[][],
    sort?: ISorting,
): string => {
    const query = queryBase();

    if (search) {
        addSearchTextToQuery(query, search);
    }
    if (plantId) {
        addPlantIdToQuery(query, plantId);
    }
    if (filters) {
        addSearchFiltersToQuery(query, filters);
    } else {
        const indexStates = getVisibleIndexStates();
        query.andFilter('terms', 'indexState', indexStates);
    }
    if (sort) {
        addSearchSortToQuery(query, sort);
    }

    addSearchPagingToQuery(query);

    return JSON.stringify(query.build());
};

export const getDocumentQuery = (docId: string): string => {
    const meta = parseDocumentMeta(docId);
    const query = queryBase();

    query
        .andFilter('term', 'meta.original_filekey', meta.filekey)
        .andFilter('term', 'meta.page', meta.page);

    return JSON.stringify(query.build());
};

export const getFiltersQueries = (plantId: string) => {
    const query = queryBase();
    const indexStates = getVisibleIndexStates();
    query
        .size(0)
        .filter('terms', 'plant.id', plantId)
        .andFilter('terms', 'indexState', indexStates)
        .aggregation('terms', 'content.managedContentType', { size: 1000 }, FILTERS[0])
        .aggregation('terms', 'content.lindeDocTypeText.raw', { size: 1000 }, FILTERS[1])
        .aggregation('terms', 'content.lindeDiscplineText.raw', { size: 1000 }, FILTERS[2])
        .build();

    return JSON.stringify(query.build());
};
