import gql from 'graphql-tag';

const GROUP_LIST_ALL_QUERY = gql`
    query GroupsAll($offset: Int, $limit: Int, $order: [GroupOrder]) {
        groups(offset: $offset, limit: $limit, order: $order) {
            data {
                id
                name
                description
                parent {
                    id
                    name
                }
                groups {
                    id
                    name
                }
                plants {
                    id
                    name
                }
            }
            limit
            offset
            totalCount
        }
    }
`;

const GROUP_LIST_QUERY = gql`
    query Groups($offset: Int, $limit: Int, $order: [GroupOrder], $filter: GroupFilter) {
        groups(offset: $offset, limit: $limit, order: $order, filter: $filter)
            @connection(key: "groups") {
            data {
                id
                name
                description
                parent {
                    id
                    name
                }
                groups {
                    id
                    name
                }
                plants {
                    id
                    name
                }
            }
            limit
            offset
            totalCount
        }
    }
`;

const GROUP_CREATE_MUTATION = gql`
    mutation CreateGroup($group: GroupCreateInput!) {
        createGroup(group: $group) {
            id
            name
            description
            parent {
                id
                name
            }
            groups {
                id
                name
            }
            plants {
                id
                name
            }
        }
    }
`;

const GROUP_UPDATE_MUTATION = gql`
    mutation UpdateGroup($group: GroupUpdateInput!) {
        updateGroup(group: $group) {
            id
            name
            description
            parent {
                id
                name
            }
            groups {
                id
                name
            }
            plants {
                id
                name
            }
        }
    }
`;

const GROUP_QUERY = gql`
    query Group($id: Int!) {
        group(id: $id) {
            id
            name
            description
            parent {
                id
                name
            }
            groups {
                id
                name
            }
            plants {
                id
                name
            }
            memberships {
                id
                user {
                    id
                    name
                    email
                }
                role
            }
        }
    }
`;

const GROUP_MEMBERSHIPS_QUERY = gql`
    query Group($id: Int!) {
        group(id: $id) {
            id
            name
            description
            parent {
                id
                name
            }
            groups {
                id
                name
            }
            plants {
                id
                name
            }
        }
    }
`;

const GROUP_DELETE_MUTATION = gql`
    mutation DeleteGroup($id: Int!) {
        deleteGroup(id: $id)
    }
`;

const GROUP_ADD_PLANTS_MUTATION = gql`
    mutation AddPlantsToGroup($groupId: Int!, $plantIds: [String]!) {
        addPlantsToGroup(groupId: $groupId, plantIds: $plantIds)
    }
`;

export {
    GROUP_ADD_PLANTS_MUTATION,
    GROUP_CREATE_MUTATION,
    GROUP_DELETE_MUTATION,
    GROUP_LIST_ALL_QUERY,
    GROUP_LIST_QUERY,
    GROUP_MEMBERSHIPS_QUERY,
    GROUP_QUERY,
    GROUP_UPDATE_MUTATION,
};
