import gql from 'graphql-tag';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ISpindexPageHit } from '../../models/IElasticModel';
import { notification } from '../../utils/notification';
import Icon from '../ui/Icon';
import { MaintenanceButton } from '../ui/MaintenanceButton';
import SearchResultTagGroup from './SearchResultTagGroup';

interface IProps extends RouteComponentProps {
    item: ISpindexPageHit;
    title: string;
    cy: string;
    folder?: string;
}

class SearchResultNotIndexedItem extends React.Component<IProps, {}> {
    private renderReindexButton = () => {
        let s3Key: string;
        let plantId: string;
        try {
            s3Key = this.props.item._source.meta.original_filekey;
            plantId = this.props.item._source.plant.id;
        } catch (e) {
            return null;
        }

        const query = gql`
            mutation publishRamrodJob($jobName: String!, $opts: JSON!) {
                publishRamrodJob(jobName: $jobName, opts: $opts)
            }
        `;

        const vars = {
            jobName: 'reindexWithIndexOptions',
            opts: JSON.stringify({
                plantId,
                count: '1 hopefully',
                request: {
                    _source: ['meta.original_filekey'],
                    query: {
                        bool: {
                            filter: {
                                bool: {
                                    must: [
                                        {
                                            prefix: {
                                                'meta.original_filekey': s3Key,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
                indexFileOptions: {
                    forceIndex: true,
                },
            }),
        };

        const success = () => notification.success('Indexing started');

        const notSucces = e => {
            notification.error('Indexing not started');
            console.error(e);
        };

        return (
            <>
                <Mutation
                    mutation={query}
                    variables={vars}
                    onCompleted={success}
                    onError={notSucces}
                >
                    {reindexMut => (
                        <MaintenanceButton
                            type="button"
                            onClick={reindexMut}
                            className="is-small"
                            style={{ border: 0 }}
                        >
                            Index
                        </MaintenanceButton>
                    )}
                </Mutation>
            </>
        );
    };

    public render = () => {
        const { item, title, folder } = this.props;
        const documentumText = item._source && item._source.spindexDocument !== null ? 'DCTM' : '';
        const s3Text = item._source && item._source.fileMd5 !== null ? 'S3' : '';

        const sourceText = [documentumText, s3Text].filter(x => x).join(' / ');

        return (
            <div className="search-result-item is-file-only">
                <div className="result-body">
                    <div className="result-image">
                        <span className="fa-stack fa-2x">
                            <Icon icon="fa-circle fa-stack-2x fg-grey" />
                            <Icon icon="fa fa-stack-1x fa-inverse fa-file" />
                        </span>
                        <span className="result-source">{sourceText}</span>
                        {this.renderReindexButton()}
                    </div>

                    <div className="result-details">
                        <div className="result-title">{title}</div>
                        <SearchResultTagGroup item={item} />
                        {folder && (
                            <div className="result-path">
                                <i className="fa fa-folder" /> {folder}
                            </div>
                        )}
                        <div className="result-path">
                            <i className="fa fa-file" /> {item._source.meta.original_filename}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default withRouter(SearchResultNotIndexedItem);
