import { Button, Delete, Modal } from 'rbx';
import * as React from 'react';

import FormField from '../../ui/FormField';
import ModalContainer from '../../ui/ModalContainer';

/*
The spindex reference should be what allows us to find the location the user clicked on again.
Meaning:
Document
Page
X, Y coordinates
Text

In the case of the tage number or part IDs that we have in the DB, we need to add these directly and pass them through to the basket.

*/
interface IProps {
    onClose: () => void;
    spindexReference?: any;
    tagNumber?: any;
    partIds?: any[];
}

export default class UserAddToBasketModal extends React.Component<IProps, any> {
    private onFormChange = () => {
        // console.log('form update');
    };

    public render = () => {
        const { onClose } = this.props;

        return (
            <ModalContainer isOpen={true} onRequestClose={onClose}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Add to basket</Modal.Card.Title>
                        <Delete onClick={onClose} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <FormField
                            label="Title"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Pre-Filled with clicked text"
                        />
                        <FormField
                            label="Description"
                            horizontal={true}
                            onChange={this.onFormChange}
                        />
                        <FormField
                            label="Quantity"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Text input for now"
                        />
                        <FormField
                            label="Type"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Dropdown: Spare parts, service, parts and service"
                        />
                        <FormField
                            label="Basket"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Select basket dropdown"
                        />
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button onClick={onClose}>Add to basket</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };
}
