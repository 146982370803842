import IGroupModel from './IGroupModel';
import IPlantModel from './IPlantModel';
import IUserModel from './IUserModel';

export interface IMembershipSaveModel {
    role?: string;
    groupId?: number;
    plantId?: string;
    userId?: number;
}

export default interface IMembershipModel {
    id: number;
    role: string;
    group?: IGroupModel;
    plant?: IPlantModel;
    user?: IUserModel;
    updatedBy?: number;
    createdBy?: number;
    updatedAt?: string;
    createdAt?: string;
}

export const friendlyRole = (role: string) =>
    role.replace(/_/g, ' ').replace(/\b(.)/g, c => c.toUpperCase());
