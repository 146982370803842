import { Control, Delete, Field, Modal, Textarea } from 'rbx';
import * as React from 'react';

import ILineItemModel, { getLabelByValue } from '../../models/ILineItemModel';
import IPartModel from '../../models/IPartModel';
import { parseDocumentFilename } from './../../utils/queryString';
import ModalContainer from './../ui/ModalContainer';

const LINE_SEPARATOR = '\n';

interface IProps {
    onClose: () => void;
    orderedLineItems: ILineItemModel[];
}

export default class BasketPlainTextCopyModal extends React.Component<IProps, any> {
    // separate items
    private buildSeparator = () => {
        return `${LINE_SEPARATOR}---------------------------${LINE_SEPARATOR}`;
    };

    // default information from the add line item dialog
    // is called for every item in the basket
    private renderLineItemInfo = (
        title: string = '',
        quantity: string = '',
        type: string = '',
        description: string = '',
    ) => {
        const quantityValue = quantity || '1';

        return this.renderLine('Title', title).concat(
            this.renderLine('Quantity', quantityValue),
            this.renderLine('Type', getLabelByValue(type)),
            this.renderLine('Description', description),
        );
    };

    // render line item information
    // for items which are manually added on basket detail page
    private renderManuallyAdded = (lineItem: ILineItemModel) => {
        const { title, quantity, requestType, description } = lineItem;

        return this.buildSeparator().concat(
            'MANUALLY ADDED',
            LINE_SEPARATOR,
            this.renderLineItemInfo(title, quantity, requestType, description),
        );
    };

    // render line item information and document filename (pagenumber)
    // for items which are added from doc viewer popover
    private renderFromDocument = (lineItem: ILineItemModel) => {
        const { title, quantity, requestType, description } = lineItem;
        const filename = lineItem.filename ? parseDocumentFilename(lineItem.filename) : '';

        return this.buildSeparator().concat(
            'FROM DOCUMENT',
            LINE_SEPARATOR,
            this.renderLineItemInfo(title, quantity, requestType, description),
            this.renderLine(
                'Document',
                `${filename} (${lineItem.pageKey ? lineItem.pageKey : '1'})`,
            ),
        );
    };

    // render equipment information for spare part with quantity 0
    // and line item information with spare part details
    private renderSingleSparePart = (lineItem: ILineItemModel) => {
        const { title, quantity, requestType, description } = lineItem;
        const equipmentTitle = lineItem.parentPart
            ? `${lineItem.parentPart.designation} (${lineItem.parentPart.tagNumber})`
            : '';

        return lineItem.part
            ? this.renderEquipmentContent(equipmentTitle, '0', '', '', lineItem.parentPart).concat(
                  LINE_SEPARATOR,
                  this.renderLineItemInfo(title, quantity, requestType, description),
                  this.renderSparePartDetails(lineItem.part),
              )
            : '';
    };

    // spare part key value pair information
    private renderSparePartDetails = (part: IPartModel) => {
        return this.renderLine('Designation', part.designation).concat(
            this.renderLine('Customer Material Number', part.customerMaterialNum),
            this.renderLine('Measuring Unit', part.qtyUnit),
            this.renderLine('Quantity Installed', part.qtyInstalledPerUnit),
            this.renderLine('Linde ID', part.lindeIdentNum),
            this.renderLine('Manufacturer Part No', part.manufactPartNum),
            this.renderLine('Order No Supplier', part.contract),
            this.renderLine('Drawing Part No', part.drawingSectNum),
            this.renderLine('Type', part.type),
            this.renderLine('Original Order Nr', part.originalOrderNum),
            this.renderLine('Oxygen Relevance 1', part.oxygenRelevance),
            this.renderLine('Oxygen Relevance 2', part.oxygenRelevance2),
            this.renderLine('Certificates', part.certificate),
        );
    };

    // equipment/tag key value pair information
    private renderEquipmentDetails = (tag: IPartModel) => {
        return this.renderLine('Designation', tag.designation).concat(
            this.renderLine('Tag Number', tag.tagNumber),
            this.renderLine('Spec Number', tag.specNum),
            this.renderLine('Linde ID', tag.lindeIdentNum),
            this.renderLine('Type', tag.type),
            this.renderLine('Serial Number', tag.serialNum),
            this.renderLine('Drawing Number', tag.drawingNum),
            this.renderLine('Original Order Nr', tag.originalOrderNum),
        );
    };

    // default case: render line item info and spare part details
    private renderSparePart = (lineItem: ILineItemModel) => {
        const { title, quantity, requestType, description } = lineItem;

        return lineItem.part
            ? this.renderLineItemInfo(title, quantity, requestType, description).concat(
                  this.renderSparePartDetails(lineItem.part),
              )
            : '';
    };

    // render line item info and equipment/tag details
    private renderEquipmentContent = (
        title: string = '',
        quantity: string = '',
        type: string = '',
        description: string = '',
        tag?: IPartModel,
    ) => {
        const quantityValue = quantity || '1';

        return tag
            ? this.buildSeparator().concat(
                  'EQUIPMENT',
                  LINE_SEPARATOR,
                  this.renderLineItemInfo(title, quantityValue, type, description),
                  this.renderEquipmentDetails(tag),
              )
            : '';
    };

    // equipment wrapper
    private renderEquipment = (lineItem: ILineItemModel) => {
        const { title, quantity, requestType, description } = lineItem;

        return this.renderEquipmentContent(
            title,
            quantity,
            requestType,
            description,
            lineItem.part,
        );
    };

    // map over all line items and return combined content
    private buildPlainTextContent = (): string => {
        const { orderedLineItems } = this.props;

        const wholeComponentIdsInBasket = orderedLineItems
            .filter(item => item.isWholeComponent)
            .map(item => item.part!.id);

        // for checking if equipment is already shown for spare parts without related equipment basket item
        let lastSingleSparePartEquipmentId: string = '0';

        const plainTextContent = orderedLineItems.map(lineItem => {
            const isEquipment = lineItem.isWholeComponent;
            const isSingleSparePart =
                lineItem.part &&
                lineItem.parentPart &&
                !wholeComponentIdsInBasket.includes(lineItem.parentPart!.id);
            const isFromDocumentPopover =
                typeof lineItem.filename === 'string' && lineItem.filename !== '';
            const isManuallyAddedFromBasket = !lineItem.part;

            switch (true) {
                // equipment/tag
                case isEquipment:
                    return this.renderEquipment(lineItem);

                // added from doc viewer popover
                case isFromDocumentPopover:
                    return this.renderFromDocument(lineItem);

                // manually added on basket detail page
                case isManuallyAddedFromBasket:
                    return this.renderManuallyAdded(lineItem);

                // spare part, for which the related equipment is not in the basket
                // and the equipment has to be shown, too
                case isSingleSparePart &&
                    lastSingleSparePartEquipmentId !== lineItem.parentPart!.id:
                    const res = this.renderSingleSparePart(lineItem);
                    lastSingleSparePartEquipmentId = lineItem.parentPart!.id;
                    return res;

                // spare part
                default:
                    return this.renderSparePart(lineItem);
            }
        });

        return plainTextContent.join(LINE_SEPARATOR);
    };

    // key/value pair display
    private renderLine = (name: string, value?: string) => {
        if (value && value !== '') {
            return `${name}: ${value}${LINE_SEPARATOR}`;
        }

        return '';
    };

    public render = () => {
        const { onClose } = this.props;

        const plainTextContent = this.buildPlainTextContent();

        return (
            <ModalContainer isOpen={true} onRequestClose={onClose}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Plain Text Basket</Modal.Card.Title>
                        <Delete onClick={onClose} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Field>
                            <Control expanded={true}>
                                <Textarea defaultValue={plainTextContent} rows={20} />
                            </Control>
                        </Field>
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right" />
                </Modal.Card>
            </ModalContainer>
        );
    };
}
