import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { GROUP_DELETE_MUTATION } from './../../../api/GraphQLQueries/Group';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    id: number;
    onSuccess: () => void;
}

interface IState {
    error: boolean;
    success: boolean;
}

export default class GroupDeleteMutation extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));

        this.props.onSuccess();
    };

    private deleteGroup = (doDeleteMutation: MutationFn) => (): void => {
        if (window.confirm(`Are you sure? This action can not be undone.`)) {
            const { id } = this.props;
            doDeleteMutation({ variables: { id } });
        }
    };

    public render = () => {
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>Group delete error</Error>}
                {success && <Message type="success">Successfully deleted group</Message>}
                <Mutation
                    mutation={GROUP_DELETE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {doDelete => (
                        <Button
                            type="button"
                            outlined={true}
                            color="danger"
                            onClick={this.deleteGroup(doDelete)}
                        >
                            Delete Group
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };
}
