import * as _ from 'lodash';
import { Button, Tag } from 'rbx';
import * as React from 'react';

import GroupListSelectMenu from '../components/group/GroupListSelectMenu';
import GroupUpdate from '../components/group/GroupUpdate';
import PlantListSelectMenu from '../components/plant/PlantListSelectMenu';
import BlurButton from '../components/ui/BlurButton';
import { DEFAULT_PAGE_SIZE } from '../components/ui/paging/Paging';
import TableFilters from '../components/ui/table/TableFilters';
import UserListSelectMenu from '../components/user/UserListSelectMenu';
import Routing from '../Routing';
import { buildPath, getUrlParams, IUrl } from '../utils/queryString';
import GroupCreateContainer from './../components/group/GroupCreate';
import GroupListQuery from './../components/group/queries/GroupListQuery';
import PageHeader from './../components/layout/PageHeader';
import { getPageProps, ITablePageProps } from './../components/ui/paging/PageProps';
import DefaultTable, { ITableColumnModel } from './../components/ui/table/ControlledTable';
import TableActions from './../components/ui/table/TableActions';
import TableActionSortDrowdown, {
    ISortOption,
} from './../components/ui/table/TableActionSortDrowdown';
import withPaging from './../components/ui/table/withPaging';
import IGroupModel from './../models/IGroupModel';
import IPlantModel from './../models/IPlantModel';
import { todo } from './../utils/translate';
import { ISorting } from './common/ListQueryContainer';
import withMainNavigation from './withMainNavigation';

const ControlledTable = withPaging(DefaultTable);

const sortOptions: ISortOption[] = [
    {
        optionName: 'Name',
        sortByField: 'name',
    },
    {
        optionName: 'ID / Create date',
        sortByField: 'id',
    },
    {
        optionName: 'Description',
        sortByField: 'description',
    },
];

interface IState {
    totalCount: number;
    offset: number;
    limit: number;
    pageIndex: number;
    sortBy?: ISorting;
    forceRerenderKey: number;
    initialRender: boolean;
    filter?: any;
    pathinfo: IUrl;
}

class GroupListContainer extends React.Component<any, IState> {
    public state: IState = {
        totalCount: 0,
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        sortBy: undefined,
        forceRerenderKey: 0,
        initialRender: true,
        filter: {
            searchText: '',
            createdBy: undefined,
            plantId: undefined,
            parent: undefined,
            group: undefined,
        },
        pathinfo: getUrlParams(this.props.location),
    };

    private columns: ITableColumnModel[] = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Plants',
            accessor: (data: any) => {
                const plants: IPlantModel[] = data.plants || [];
                return (
                    <Tag.Group>
                        {plants.map((plant: IPlantModel) => {
                            return <Tag key={plant.id}>{plant.name}</Tag>;
                        })}
                    </Tag.Group>
                );
            },
        },
        {
            Header: 'Parent Group',
            accessor: (data: any) => {
                const { parent }: IGroupModel = data;
                if (!_.isEmpty(parent)) {
                    return <Tag>{parent!.name}</Tag>;
                }

                return null;
            },
        },
        {
            Header: 'Groups',
            accessor: (data: any) => {
                const groups: IGroupModel[] = data.groups || [];
                return (
                    <Tag.Group>
                        {groups.map((group: IGroupModel) => {
                            return <Tag key={group.id}>{group.name}</Tag>;
                        })}
                    </Tag.Group>
                );
            },
        },
        {
            accessor: (data: any) => this.buildActionButtonsCell(data.id),
            disableRowHandler: true,
        },
    ];

    private buildActionButtonsCell = (actionValue: any) => {
        return (
            <Button.Group pull="right">
                <BlurButton size="small" onClick={this.viewDetailsAction(actionValue)}>
                    Details
                </BlurButton>
            </Button.Group>
        );
    };

    private openGroupDetails = (id: string) => {
        this.props.history.push(Routing.GROUP_DETAILS.getPath(id));
    };

    private viewDetailsAction = (id: string) => () => {
        this.openGroupDetails(id);
    };

    private onGroupCreate = () => {
        this.props.history.push(Routing.GROUP_CREATE.getPath());
    };

    private onClickRow = (id: string) => {
        this.openGroupDetails(id);
    };

    private onPageChange = (pageIndex: number) => {
        const offset = this.state.limit * pageIndex;

        this.setState((prevState: IState) => ({
            ...prevState,
            pageIndex,
            offset,
        }));
    };

    private toggleForceRerenderKey = (prevKey: number): number => {
        return prevKey < 1 ? 1 : 0;
    };

    private renderGroupEditSidebar = () => {
        const { groupId } = this.props.match.params;

        const onDeleteGroup = () => {
            this.setState(
                (prevState: IState) => ({
                    ...prevState,
                    offset: 0,
                    pageIndex: 0,
                    forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
                }),
                this.hideGroupSidebar,
            );
        };

        return (
            groupId && (
                <GroupUpdate
                    idGroup={parseInt(groupId, 10)}
                    onCancel={this.hideGroupSidebar}
                    onDeleteGroup={onDeleteGroup}
                />
            )
        );
    };

    private renderGroupCreateSidebar = () => {
        const onCreateGroup = (id: string) => {
            this.setState(
                (prevState: IState) => ({
                    ...prevState,
                    offset: 0,
                    pageIndex: 0,
                    forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
                }),
                () => this.openGroupDetails(id),
            );
        };

        return <GroupCreateContainer onCancel={this.hideGroupSidebar} onSuccess={onCreateGroup} />;
    };

    private onSorting = (sortByOptions?: ISorting) => {
        const sortBy = sortByOptions || undefined;

        this.setState((prevState: IState) => ({
            ...prevState,
            offset: 0,
            pageIndex: 0,
            sortBy,
        }));
    };

    private renderSortDropdown = () => {
        return (
            <TableActionSortDrowdown
                sortOptions={sortOptions}
                selected={this.state.sortBy}
                onSelect={this.onSorting}
            />
        );
    };

    private multiSelectActionButtons = () => {
        const { searchText } = this.state.filter;

        return (
            <>
                <TableActions
                    renderSortDropdown={this.renderSortDropdown}
                    onCustomFilterChange={this.handleSearchFilter}
                    customFilterValue={searchText}
                />
            </>
        );
    };

    private handleSearchFilter = (event: any) => {
        const filter = { searchText: event.currentTarget.value };
        this.setState((prevState: IState) => ({ ...prevState, filter }));
    };

    private handleCreatedByFilter = (ids: number[]) => {
        const createdBy = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.createdBy = createdBy;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, createdBy },
        }));
    };

    private handleGroupFilter = (ids: number[]) => {
        const group = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.group = group;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, group },
        }));
    };

    private handlePlantFilter = (ids: string[]) => {
        const plantId = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.plantId = plantId;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, plantId },
        }));
    };

    private handleParentFilter = (ids: number[]) => {
        const parent = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.parent = parent;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, parent },
        }));
    };

    private renderTableFilters = () => {
        const { pathinfo } = this.state;

        const filter = {
            searchText: this.state.filter.searchText,
            plantId: pathinfo.params.plantId,
            createdBy: parseInt(pathinfo.params.createdBy, 10),
            group: parseInt(pathinfo.params.group, 10),
            parent: parseInt(pathinfo.params.parent, 10),
        };

        return (
            <>
                <TableFilters>
                    <UserListSelectMenu
                        onUpdate={this.handleCreatedByFilter}
                        defaultSelected={[filter.createdBy]}
                        selectHeader="Created by"
                    />
                    <PlantListSelectMenu
                        onUpdate={this.handlePlantFilter}
                        selectHeader="Plants"
                        isClearable={true}
                        defaultSelected={filter.plantId}
                    />
                    <GroupListSelectMenu
                        onUpdate={this.handleParentFilter}
                        defaultSelected={[filter.parent]}
                        selectHeader="Parent Group"
                    />
                    <GroupListSelectMenu
                        onUpdate={this.handleGroupFilter}
                        defaultSelected={[filter.group]}
                        selectHeader="Group"
                    />
                </TableFilters>
            </>
        );
    };

    private renderControlledTable = (
        groupList: IGroupModel[],
        tablePageProps: ITablePageProps,
    ): JSX.Element => {
        const { pages, currentPageIndex, totalCount } = tablePageProps;
        const { groupId } = this.props.match.params;

        return (
            <ControlledTable
                data={groupList}
                columns={this.columns}
                totalCount={totalCount}
                pages={pages}
                page={currentPageIndex}
                onClickRow={this.onClickRow}
                onClickRowReturnData="id"
                onPageChange={this.onPageChange}
                actionButtons={this.multiSelectActionButtons}
                selectedRow={parseInt(groupId, 10)}
                selectedRowDataProp="id"
                filters={this.renderTableFilters}
            />
        );
    };

    private renderGroupTable = (
        groupList: IGroupModel[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => {
        const tablePageProps: ITablePageProps = getPageProps(totalCount, limit, offset);

        return this.renderControlledTable(groupList, tablePageProps);
    };

    private onResult = (totalCount: number): void => {
        if (this.state.initialRender || totalCount !== this.state.totalCount) {
            this.setState((prevState: IState) => ({
                ...prevState,
                totalCount,
                initialRender: false,
            }));
        }
    };

    private headerButtons = () => {
        return <BlurButton onClick={this.onGroupCreate}>{todo('Create new group')}</BlurButton>;
    };

    private showCreateGroup = () => {
        return this.props.location.pathname === Routing.GROUP_CREATE.getPath();
    };

    private hideGroupSidebar = () => {
        // TODO: Here would be a great place to add a confirmation dialog for unsaved changes. Some day.
        this.props.history.push(Routing.GROUPS.getPath());
    };

    private showEditGroup = () => {
        const { groupId } = this.props.match.params;

        return !!groupId;
    };

    public render() {
        const { pathinfo, offset, limit, sortBy, forceRerenderKey, totalCount } = this.state;

        const filter = {
            searchText: this.state.filter.searchText,
            plantId: pathinfo.params.plantId,
            createdBy: parseInt(pathinfo.params.createdBy, 10),
            group: parseInt(pathinfo.params.group, 10),
            parent: parseInt(pathinfo.params.parent, 10),
        };

        return (
            <div>
                <PageHeader buttons={this.headerButtons()}>
                    {todo('Groups')} ({totalCount})
                </PageHeader>
                <GroupListQuery
                    key={forceRerenderKey}
                    offset={offset}
                    limit={limit}
                    sortBy={sortBy}
                    filter={!_.isEmpty(filter) ? filter : null}
                    onResult={this.onResult}
                    onResultDataProp="totalCount"
                    renderFetchedGroupList={this.renderGroupTable}
                />
                {this.showEditGroup() && this.renderGroupEditSidebar()}
                {this.showCreateGroup() && this.renderGroupCreateSidebar()}
            </div>
        );
    }
}

export default withMainNavigation(GroupListContainer);
