import { Button } from 'rbx';
import { ButtonProps } from 'rbx/elements/button/button';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps extends ButtonProps {
    to: string;
    exact?: boolean;
}

export default class NavButton extends React.Component<IProps, any> {
    public render() {
        return (
            <Button as={NavLink} {...this.props}>
                {this.props.children}
            </Button>
        );
    }
}
