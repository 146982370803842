import * as React from 'react';

import { getPageProps, ITablePageProps } from '../ui/paging/PageProps';
import { DEFAULT_PAGE_SIZE } from '../ui/paging/Paging';
import DefaultTable, { ITableColumnModel } from '../ui/table/ControlledTable';
import withPaging from '../ui/table/withPaging';
import Pane from './../../components/ui/Pane';
import { todo } from './../../utils/translate';
import PlantStatsListQuery from './queries/PlantStatsListQuery';

interface IProps {
    onCancel: () => void;
}
const ControlledTable = withPaging(DefaultTable);

const columns: ITableColumnModel[] = [
    {
        Header: 'ID',
        accessor: 'plantId',
    },
    {
        Header: 'DOCUMENTUM',
        accessor: 'documentum',
    },
    {
        Header: 'EQUIPMENT',
        accessor: 'equipment',
    },
    {
        Header: 'PNID',
        accessor: 'pnid',
    },
    {
        Header: 'CUSTOMER DOCS',
        accessor: 'customerDocs',
        className: 'nowrap',
    },
    {
        Header: 'INDEX STATE (C/F/I/E)',
        className: 'nowrap',
        accessor: ({ indexStates }) => {
            return (
                [
                    indexStates.completed,
                    indexStates.fileOnly,
                    indexStates.ignored,
                    indexStates.error,
                ].join(' / ') || 0
            );
        },
    },
];

class PlantStats extends React.Component<IProps> {
    public state = {
        totalCount: 0,
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        forceRerenderKey: 0,
        initialRender: true,
    };

    private renderPlantStatsTable = (plants, totalCount: number, limit: number, offset: number) => {
        if (this.state.initialRender || totalCount !== this.state.totalCount) {
            this.setState({ totalCount, initialRender: false });
        }

        const tablePageProps: ITablePageProps = getPageProps(totalCount, limit, offset);
        const { onCancel } = this.props;
        const { pages, currentPageIndex } = tablePageProps;
        plants = plants || [];

        return (
            <Pane onClose={onCancel} title={todo('Plant Stats')} sidepane={true}>
                <ControlledTable
                    data={plants}
                    columns={columns}
                    pages={pages}
                    page={currentPageIndex}
                    onPageChange={this.onPageChange}
                    totalCount={totalCount}
                />
            </Pane>
        );
    };

    // private onResult = (totalCount: number): void => {
    //     if (this.state.initialRender || totalCount !== this.state.totalCount) {
    //         this.setState({
    //             totalCount,
    //             initialRender: false,
    //         });
    //     }
    // };

    private onPageChange = (pageIndex: number) => {
        const offset = this.state.limit * pageIndex;

        this.setState({
            pageIndex,
            offset,
        });
    };

    public render = () => {
        const { offset, limit, forceRerenderKey } = this.state;

        return (
            <>
                <PlantStatsListQuery
                    key={forceRerenderKey}
                    offset={offset}
                    limit={limit}
                    onResultDataProp="totalCount"
                    renderFetchedPlantList={this.renderPlantStatsTable}
                />
            </>
        );
    };
}

export default PlantStats;
