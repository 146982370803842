import * as React from 'react';

import IUserModel from '../models/IUserModel';
import TopMenu from './../components/nav/TopMenu';

interface IProps {
    user: IUserModel;
}

export default class MainLayout extends React.Component<IProps> {
    public render() {
        return (
            <div className="main-layout">
                <div className="content-layout">
                    <TopMenu {...this.props} />
                    <div className="content-container">{this.props.children}</div>
                </div>
            </div>
        );
    }
}
