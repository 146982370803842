import * as React from 'react';

import { globalroles } from '../../models/IUserModel';
import MultiSelect from '../ui/MultiSelect';

interface IProps {
    defaultSelected?: string;
    onUpdate: (role?: string) => void;
}

export default class GlobalRoleSelectMenu extends React.Component<IProps, any> {
    private handleChange = (role?: any) => {
        this.props.onUpdate(role ? role.value : undefined);
    };

    private mapRole = role => {
        if (role === 'linde_admin') {
            return 'Admin';
        } else if (role === 'regular_user') {
            return 'User';
        }
    };

    private buildSelectObject = () => {
        return globalroles.map(role => {
            const item = { value: role, label: this.mapRole(role) };
            return item;
        });
    };

    public render() {
        const { defaultSelected } = this.props;
        const initial = defaultSelected
            ? { value: defaultSelected, label: this.mapRole(defaultSelected) }
            : undefined;

        return (
            <MultiSelect
                value={initial}
                options={this.buildSelectObject()}
                placeholder="Global Role"
                isClearable={true}
                onChange={this.handleChange}
            />
        );
    }
}
