import * as _ from 'lodash';
import { Button, Control, Delete, Field, Label, Modal } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IBasketFilterParams } from '../../models/IBasketModel';
import { isRequired } from '../../utils/helpers';
import { notification } from '../../utils/notification';
import PlantListDropdown from '../plant/PlantListDropdown';
import { BASKET_CREATE_MUTATION, BASKET_LIST_QUERY } from './../../api/GraphQLQueries/Basket';
import IBasketModel, { IBasketCreateModel } from './../../models/IBasketModel';
import DatePicker from './../ui/datePicker/DatePicker';
import FormField from './../ui/FormField';
import ModalContainer from './../ui/ModalContainer';
import LabelSelectMenu from './LabelSelectMenu';
import ShippingTermsPopover from './ShippingTermsPopover';
import ShippingTermSelectMenu from './ShippingTermsSelectMenu';

interface IRouteParams {
    plantId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    onClose: () => void;
    onSuccess: (basket: any) => void;
    refetchFilter?: IBasketFilterParams;
}

interface IState {
    basket: IBasketCreateModel;
    plantId: string;
}

class BasketCreateModal extends React.Component<IProps, IState> {
    public state: IState = {
        basket: {
            name: '',
            description: '',
            deadline: '',
            label: '',
            shippingTerm: '',
            customerReference: '',
        },
        plantId: this.props.match.params.plantId,
    };

    private onError = (): void => {
        notification.error('There was an error creating the basket');
    };

    private onCompleted = ({ createBasket }: { createBasket: IBasketModel }): void => {
        notification.success('Successfully created basket');

        this.props.onSuccess(createBasket.id);
        this.props.onClose();
    };

    private updateDetails = (name: string) => (value: string) => {
        this.setState({
            basket: {
                ...this.state.basket,
                [name]: value,
            },
        });
    };

    private onSelectPlant = (plantId: string) => {
        this.setState({ plantId });
    };

    public render = () => {
        const { onClose, refetchFilter } = this.props;
        const { plantId } = this.state;
        const routePlant = this.props.match.params.plantId;
        const {
            name,
            description,
            deadline,
            label,
            shippingTerm,
            customerReference,
        } = this.state.basket;

        return (
            <>
                <ModalContainer isOpen={true} onRequestClose={onClose}>
                    <Modal.Card data-cy="basket-create-modal">
                        <Modal.Card.Head>
                            <Modal.Card.Title>Create basket</Modal.Card.Title>
                            <Delete onClick={onClose} />
                        </Modal.Card.Head>

                        <Modal.Card.Body>
                            {!routePlant && (
                                <Field horizontal={true}>
                                    <Field.Label size="normal">
                                        <Label>{isRequired('Plant')}</Label>
                                    </Field.Label>
                                    <Field.Body>
                                        <Field>
                                            <Control
                                                expanded={true}
                                                data-cy="BasketCreateModal-plantList-select"
                                            >
                                                <PlantListDropdown
                                                    onUpdate={this.onSelectPlant}
                                                    showPlaceHolder={true}
                                                />
                                            </Control>
                                        </Field>
                                    </Field.Body>
                                </Field>
                            )}
                            <>
                                <FormField
                                    label={isRequired('Name')}
                                    value={name}
                                    horizontal={true}
                                    onChange={this.updateDetails('name')}
                                    placeholder="Basket name"
                                    cy="basketCreateModal-basketName-input"
                                />
                                <FormField
                                    label="Description"
                                    value={description}
                                    horizontal={true}
                                    onChange={this.updateDetails('description')}
                                    placeholder="What is this basket for?"
                                    cy="basketCreateModal-description-input"
                                />

                                <Field horizontal={true}>
                                    <Field.Label size="normal">
                                        <Label>Delivery Date</Label>
                                    </Field.Label>
                                    <Field.Body>
                                        <Field>
                                            <Control
                                                expanded={true}
                                                data-cy="basketCreateModal-deadline-input"
                                            >
                                                <DatePicker
                                                    onChange={this.updateDetails('deadline')}
                                                    defaultDate={deadline}
                                                />
                                            </Control>
                                        </Field>
                                    </Field.Body>
                                </Field>
                                <Field horizontal={true}>
                                    <Field.Label size="normal">
                                        <Label>Label</Label>
                                    </Field.Label>
                                    <Field.Body>
                                        <Field>
                                            <Control expanded={true} data-cy="basket-label-input">
                                                <LabelSelectMenu
                                                    defaultSelected={label}
                                                    onUpdate={this.updateDetails('label')}
                                                />
                                            </Control>
                                        </Field>
                                    </Field.Body>
                                </Field>
                                <Field horizontal={true}>
                                    <Field.Label size="normal">
                                        <Label>
                                            Shipping Terms <ShippingTermsPopover />
                                        </Label>
                                    </Field.Label>
                                    <Field.Body>
                                        <Field>
                                            <Control
                                                expanded={true}
                                                data-cy="basket-shipping-input"
                                            >
                                                <ShippingTermSelectMenu
                                                    defaultSelected={shippingTerm}
                                                    onUpdate={this.updateDetails('shippingTerm')}
                                                />
                                            </Control>
                                        </Field>
                                    </Field.Body>
                                </Field>
                                <FormField
                                    label="Customer Reference number"
                                    value={customerReference}
                                    horizontal={true}
                                    onChange={this.updateDetails('customerReference')}
                                    placeholder="Reference"
                                    cy="basket-reference-input"
                                />
                            </>
                        </Modal.Card.Body>
                        <Modal.Card.Foot align="right">
                            <Mutation
                                mutation={BASKET_CREATE_MUTATION}
                                variables={{
                                    basket: {
                                        ...this.state.basket,
                                        deadline: !_.isEmpty(deadline) ? deadline : null,
                                        plantId,
                                    },
                                }}
                                onError={this.onError}
                                onCompleted={this.onCompleted}
                                refetchQueries={[
                                    {
                                        query: BASKET_LIST_QUERY,
                                        variables: {
                                            filter: refetchFilter,
                                            order: { by: 'createdAt', direction: 'asc' },
                                        },
                                    },
                                ]}
                            >
                                {doCreateMutation => (
                                    <Button
                                        onClick={doCreateMutation}
                                        disabled={!name || !plantId}
                                        data-cy="basket-create-button"
                                    >
                                        Create basket
                                    </Button>
                                )}
                            </Mutation>
                        </Modal.Card.Foot>
                    </Modal.Card>
                </ModalContainer>
            </>
        );
    };
}

export default withRouter(BasketCreateModal);
