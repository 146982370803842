import Noty from 'noty';

const defaultTimeout = 5000;

class Notification {
    public success = (msg: string, timeout?: number) => {
        this.triggerNotification('success', msg, timeout);
    };

    public info = (msg: string) => {
        console.info({
            type: 'info',
            text: msg,
            defaultTimeout,
        });

        this.triggerNotification('info', msg);
    };

    public warn = (msg: string) => {
        console.info({
            type: 'warning',
            text: msg,
            defaultTimeout,
        });

        this.triggerNotification('warning', msg);
    };

    public error = (msg: string, timeout: number = 0) => {
        console.info({
            type: 'error',
            text: msg,
            defaultTimeout: timeout,
        });

        this.triggerNotification('error', msg, timeout);
    };

    private triggerNotification = (type: Noty.Type, text: string, timeout: number = 5000) => {
        new Noty({
            text: text.replace(/\n/g, '<br/>'),
            type,
            timeout: timeout > 0 ? timeout : false,
            layout: 'bottomRight',
        }).show();
    };
}

export const notification = new Notification();
