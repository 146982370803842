import classnames from 'classnames';
import { Checkbox, Table } from 'rbx';
import * as React from 'react';

export interface ITableColumnModel {
    accessor: any;
    Header?: any;
    className?: string;
    disableRowHandler?: boolean;
}

export interface IProps {
    data: any[];
    columns: ITableColumnModel[];
    actionButtons?: any;
    filters?: any;
    multiselectDataProp?: string;
    onClickRow?: (onClickRowReturnProp: any) => void;
    onClickRowReturnData?: string[] | string;
    selectedRow?: string;
    selectedRowDataProp?: string;
}

interface IState {
    selection: any[];
    selectAll: boolean;
}

export default class ControlledTable extends React.Component<IProps, IState> {
    public state: IState = {
        selection: [],
        selectAll: false,
    };

    private getAllKeys = (): any[] => {
        const { data, multiselectDataProp } = this.props;
        return multiselectDataProp ? data.map((item: any) => item[multiselectDataProp]) : [];
    };

    private toggleAll = (): void => {
        const selectAll = !this.state.selectAll;
        const selection = selectAll ? this.getAllKeys() : [];

        this.setState((prevState: IState) => ({
            ...prevState,
            selectAll,
            selection,
        }));
    };

    private isSelected = (key: any): boolean => {
        return this.state.selection.includes(key);
    };

    private setSelection = (selection: any[]): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            selection,
        }));
    };

    private addToSelection = (key: any): void => {
        const selection = [...this.state.selection, key];

        this.setSelection(selection);
    };

    private removeFromSelection = (key: any): void => {
        const selection = this.state.selection.filter((item: any) => item !== key);

        this.setSelection(selection);
    };

    private toggleSelect = (key: any) => () => {
        this.isSelected(key) ? this.removeFromSelection(key) : this.addToSelection(key);
    };

    private renderMultiSelectCell = (value: any) => {
        const checked = this.isSelected(value);

        return (
            <Table.Cell onClick={this.toggleSelect(value)} className="is-checkbox-cell">
                <Checkbox checked={checked} readOnly={true} />
            </Table.Cell>
        );
    };

    private renderTableActions = () => {
        const mulitSelectRow = this.props.multiselectDataProp ? 1 : 0;
        const colSpan = this.props.columns.length + mulitSelectRow;
        return (
            <Table.Row className="table-actions-row">
                <Table.Cell colSpan={colSpan}>
                    {this.props.actionButtons(this.state.selection, this.toggleAll)}
                </Table.Cell>
            </Table.Row>
        );
    };

    private renderTableFilters = () => {
        const mulitSelectRow = this.props.multiselectDataProp ? 1 : 0;
        const colSpan = this.props.columns.length + mulitSelectRow;

        return (
            <Table.Row className="table-filters-row">
                <Table.Cell colSpan={colSpan}>
                    {this.props.filters(this.state.selection, this.toggleAll)}
                </Table.Cell>
            </Table.Row>
        );
    };

    private getCellDisplayValue = (row: any, column: ITableColumnModel) => {
        const { accessor } = column;
        switch (typeof accessor) {
            case 'string':
                return row[accessor];
            case 'function':
                return accessor(row);
            default:
                return;
        }
    };

    private renderTableHeadItem = (header: ITableColumnModel, index: number) => {
        return (
            <Table.Heading key={index} className={header.className}>
                {header.Header}
            </Table.Heading>
        );
    };

    private renderTableHead = () => {
        const { columns, multiselectDataProp } = this.props;
        const mulitSelectRow = multiselectDataProp ? <Table.Heading /> : null;

        return (
            <Table.Head>
                <Table.Row>
                    {mulitSelectRow}
                    {columns.map((header, i: number) => this.renderTableHeadItem(header, i))}
                </Table.Row>
            </Table.Head>
        );
    };

    private renderTableCell = (row: any, column: ITableColumnModel, index: number) => {
        return (
            <Table.Cell
                key={index}
                onClick={!column.disableRowHandler ? this.onClickRowHandler(row) : undefined}
            >
                {typeof this.getCellDisplayValue(row, column) === 'boolean' &&
                    String(this.getCellDisplayValue(row, column))}
                {this.getCellDisplayValue(row, column)}
            </Table.Cell>
        );
    };

    private onClickRowHandler = (row: any) => () => {
        const { onClickRow, onClickRowReturnData } = this.props;
        if (!onClickRow || !onClickRowReturnData) {
            return;
        }
        const returnData = Array.isArray(onClickRowReturnData)
            ? onClickRowReturnData.map(selector => row[selector])
            : row[onClickRowReturnData];

        onClickRow(returnData);
    };

    private getRowClass = (dataRow: any) => {
        const { onClickRow, selectedRow, selectedRowDataProp } = this.props;

        let ret: string = !!onClickRow ? 'is-clickable' : '';

        if (selectedRow && selectedRowDataProp && dataRow[selectedRowDataProp] === selectedRow) {
            ret += ' is-active';
        }

        return ret;
    };

    private renderTableBody = () => {
        const { columns, data, actionButtons, multiselectDataProp, filters } = this.props;

        return (
            <Table.Body>
                {filters && this.renderTableFilters()}
                {actionButtons && this.renderTableActions()}
                {data.map((row: any, key: number) => (
                    <Table.Row key={key} className={this.getRowClass(row)}>
                        {multiselectDataProp &&
                            this.renderMultiSelectCell(row[multiselectDataProp])}
                        {columns.map((column: ITableColumnModel, i: number) =>
                            this.renderTableCell(row, column, i),
                        )}
                    </Table.Row>
                ))}
            </Table.Body>
        );
    };

    public render() {
        const { multiselectDataProp, actionButtons, filters } = this.props;

        const classes = classnames([
            { 'is-multiselect': multiselectDataProp },
            { 'has-actions': actionButtons },
            { 'has-filters': filters },
        ]);

        return (
            <>
                <Table className={classes}>
                    {this.renderTableHead()}
                    {this.renderTableBody()}
                </Table>
            </>
        );
    }
}
