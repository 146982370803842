import * as React from 'react';
import { Redirect } from 'react-router-dom';

import PlantDetailQueryContainer from '../../../components/plant/queries/PlantDetailQuery';
import IPlantModel from '../../../models/IPlantModel';
import Routing from '../../../Routing';
import withMainNavigation from '../../withMainNavigation';
import PlantOnboardingQuery from './PlantOnboardingQuery';

class PlantOnboardingContainer extends React.Component<any, any> {
    public renderOnboarding = (plantData: IPlantModel, refetch?: () => void) => {
        return plantData ? (
            <PlantOnboardingQuery refetch={refetch} plantS3Folder={plantData.s3Folder} />
        ) : (
            <Redirect to={Routing.PLANTS.route} />
        );
    };

    public render() {
        const { plantId } = this.props.match.params;

        return (
            <>
                {plantId && (
                    <PlantDetailQueryContainer
                        id={plantId}
                        renderFetchedPlantData={this.renderOnboarding}
                    />
                )}
            </>
        );
    }
}

export default withMainNavigation(PlantOnboardingContainer);
