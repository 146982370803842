import IUserModel, { GlobalRole } from '../models/IUserModel';
import AccessTokenModel from './../models/AccessTokenModel';
import { storage } from './../utils/storage';
import { client, unauthorizedClient } from './connection';
import * as AUTH from './GraphQLQueries/Auth';

// Token
export const getToken = (): AccessTokenModel => {
    const token: AccessTokenModel = {
        accessToken: storage.get('accessToken'),
        refreshToken: storage.get('refreshToken'),
        id: storage.get('id') ? parseInt(storage.get('id'), 10) : null,
    };
    return token;
};

export const isTokenExpired = (): boolean => {
    const expires = getTokenExpiration();
    const currentTime = Math.ceil(Date.now() / 1000);
    const isExpired = (expires || 0) <= currentTime;

    return isExpired;
};

export const getAuthTokenForHTTP = async (): Promise<string> => {
    if (isTokenExpired()) {
        await doRefreshMutation();
    }

    return getToken().accessToken;
};

const getTokenExpiration = () => {
    const parsed = parseJwtToken();
    if (parsed) {
        return parsed.exp;
    }
};

export const parseJwtToken = () => {
    const { accessToken } = getToken();
    if (accessToken) {
        const [, payload] = accessToken.split('.');
        if (payload) {
            return JSON.parse(window.atob(payload));
        }
    }
    return null;
};

const setToken = (token: AccessTokenModel): void => {
    storage.set('accessToken', token.accessToken);
    storage.set('refreshToken', token.refreshToken);
    storage.set('id', `${token.id}`);
};

const renewToken = (token: AccessTokenModel): void => {
    storage.set('accessToken', token.accessToken);
};

export const doRefreshMutation = (): Promise<void> => {
    const existingRefreshToken = getToken().refreshToken;
    if (!existingRefreshToken) {
        return logout(true);
    }

    return unauthorizedClient
        .mutate({
            mutation: AUTH.REFRESH_TOKEN_MUTATION,
            variables: { refreshToken: existingRefreshToken },
            fetchPolicy: 'no-cache',
        })
        .then(({ data }) => renewToken(data.refreshToken))
        .catch(() => {
            logout(true);
        });
};

// User and login
export const isAdmin = (user?: IUserModel) => {
    if (!user) {
        user = parseJwtToken();
    }
    return user && user.globalRole === GlobalRole.linde_admin;
};

export const getCurrentUser = (): IUserModel => {
    return parseJwtToken();
};

export const logout = async (redirect = false) => {
    storage.remove('accessToken');
    storage.remove('refreshToken');
    storage.remove('id');
    storage.remove('dev');

    await clearCache();
    if (redirect) {
        window.location.href = '/';
    }
};

export const isLoggedIn = (): boolean => {
    const token = getToken();
    return !!(token && token.accessToken);
};

export const doUserLogin = async (loginResponse: AccessTokenModel): Promise<void> => {
    setToken(loginResponse);
    return await clearCache();
};

// Apollo
const clearCache = async () => {
    // same cache now
    await Promise.all([
        client.cache.reset().catch(() => null),
        unauthorizedClient.cache.reset().catch(() => null),
        unauthorizedClient.clearStore().catch(() => null),
        client.clearStore().catch(() => null),
    ]);
};
