import * as _ from 'lodash';
import { Button, Tag } from 'rbx';
import * as React from 'react';
import { Link } from 'react-router-dom';

import BasketCreateModal from '../components/basket/BasketCreateModal';
import PurchaseStateSelectMenu from '../components/basket/PurchaseStateSelectMenu';
import BasketListQuery from '../components/basket/queries/BasketListQuery';
import PageHeader from '../components/layout/PageHeader';
import BlurButton from '../components/ui/BlurButton';
import { getPageProps, ITablePageProps } from '../components/ui/paging/PageProps';
import { DEFAULT_PAGE_SIZE } from '../components/ui/paging/Paging';
import TableActions from '../components/ui/table/TableActions';
import TableActionSortDrowdown, {
    ISortOption,
} from '../components/ui/table/TableActionSortDrowdown';
import TableFilters from '../components/ui/table/TableFilters';
import IBasketModel from '../models/IBasketModel';
import Routing from '../Routing';
import { getBasketStatusText } from '../utils/helpers';
import { buildPath, getUrlParams, IUrl } from '../utils/queryString';
import { todo } from '../utils/translate';
import ArchivedSelectMenu from './../components/basket/ArchivedSelectMenu';
import StatusSelectMenu from './../components/basket/StatusSelectMenu';
import PlantListSelectMenu from './../components/plant/PlantListSelectMenu';
import DefaultTable, { ITableColumnModel } from './../components/ui/table/ControlledTable';
import withPaging from './../components/ui/table/withPaging';
import UserListSelectMenu from './../components/user/UserListSelectMenu';
import { ISorting } from './common/ListQueryContainer';
import withMainNavigation from './withMainNavigation';

const ControlledTable = withPaging(DefaultTable);

const resetPage = { offset: 0, pageIndex: 0 };

interface IState {
    totalCount: number;
    offset: number;
    limit: number;
    pageIndex: number;
    sortBy?: ISorting;
    filter?: any;
    showCreateBasket: boolean;
    pathinfo: IUrl;
}

class BasketListContainer extends React.Component<any, IState> {
    public state: IState = {
        totalCount: 0,
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        filter: {
            plantId: undefined,
            status: undefined,
            createdBy: undefined,
            purchaseState: undefined,
            archived: false,
        },
        sortBy: {
            field: 'createdAt',
            direction: 'desc',
        },
        showCreateBasket: false,
        pathinfo: getUrlParams(this.props.location),
    };

    private sortOptions: ISortOption[] = [
        {
            optionName: 'Reference',
            sortByField: 'id',
        },
        {
            optionName: 'Name',
            sortByField: 'name',
        },
    ];

    private textToTag(text: string, color?: string) {
        return <Tag color={color}>{this.mapStatusMessage(text)}</Tag>;
    }

    private mapStatusToColor(status: string) {
        switch (status.toLowerCase()) {
            case 'draft': {
                return 'danger';
            }
            case 'submitted': {
                return 'info';
            }
            case 'locked': {
                return 'warning';
            }
            case 'quoted': {
                return 'success';
            }
            case 'completed': {
                return 'dark';
            }
            default:
                return '';
        }
    }

    private mapStatusMessage = status => {
        return getBasketStatusText(status);
    };

    private columns: ITableColumnModel[] = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Submitted Date ',
            accessor: 'submitDate',
        },
        {
            Header: 'Reference',
            accessor: (data: any) => (data.id ? this.textToTag(data.id.toUpperCase()) : null),
        },
        {
            Header: 'Plant',
            accessor: (data: any) =>
                data.plant && data.plant.name ? this.textToTag(data.plant.name) : null,
        },
        {
            Header: 'Delivery Date ',
            accessor: 'deadline',
        },
        {
            Header: 'Status',
            accessor: (data: any) =>
                data.status
                    ? this.textToTag(data.status, this.mapStatusToColor(data.status))
                    : null,
        },
        {
            accessor: (data: any) => (data.archived ? this.textToTag('archived') : null),
        },
        {
            accessor: (data: any) => this.buildActionButtonsCell(data.id),
            disableRowHandler: true,
        },
    ];

    private headerButtons = () => {
        return (
            <BlurButton
                onClick={this.toggleCreateBasket}
                data-cy="basketListContainer-addBasket-btn"
            >
                {todo('Add Basket')}
            </BlurButton>
        );
    };

    private toggleCreateBasket = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showCreateBasket: !prevState.showCreateBasket,
        }));
    };

    private buildActionButtonsCell = (id: string) => {
        const detailRoute = this.getDetailRoute(id);

        return (
            detailRoute && (
                <Button.Group align="right">
                    <Link className="button is-small" to={detailRoute}>
                        Details
                    </Link>
                </Button.Group>
            )
        );
    };

    private redirectToTagDetail = (id: string) => {
        const route = this.getDetailRoute(id);
        if (route) {
            this.props.history.push(route);
        }
    };

    private onClickRow = (id: string) => {
        this.redirectToTagDetail(id);
    };

    private getDetailRoute = (id: string) => {
        return Routing.BASKET_DETAILS.getPath(id);
    };

    private onPageChange = (pageIndex: number) => {
        const offset = this.state.limit * pageIndex;
        this.setState({ pageIndex, offset });
    };

    private onSorting = (sortBy?: ISorting) => {
        this.setState({ ...resetPage, sortBy });
    };

    private renderSortDropdown = () => {
        return (
            <TableActionSortDrowdown
                sortOptions={this.sortOptions}
                selected={this.state.sortBy}
                onSelect={this.onSorting}
            />
        );
    };

    private handlePlantFilter = (ids: string[]) => {
        const plantId = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.plantId = plantId;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, plantId },
        }));
    };

    private handleCreatedByFilter = (ids: number[]) => {
        const createdBy = ids.length > 0 ? ids[0] : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.createdBy = createdBy;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, createdBy },
        }));
    };

    private handleStatusFilter = (selectedStatus?: string) => {
        const status = selectedStatus ? selectedStatus : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.status = selectedStatus;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, status },
        }));
    };

    private handlePurchaseStatusFilter = (selectedPurchasedStatus?: string) => {
        const purchaseState = selectedPurchasedStatus ? selectedPurchasedStatus : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.purchaseState = selectedPurchasedStatus;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, purchaseState },
        }));
    };

    private handleArchivedFilter = (archivedValue?: string) => {
        const archived =
            archivedValue === 'true' ? true : archivedValue === 'false' ? false : undefined;

        const { pathinfo } = this.state;
        pathinfo.params.archived = archived;
        const path = buildPath(pathinfo);
        this.props.history.push(path);

        this.setState((prevState: IState) => ({
            ...prevState,
            filter: { ...prevState.filter, archived },
        }));
    };

    private renderTableFilters = () => {
        let { filter } = this.state;
        filter = {
            plantId: this.state.pathinfo.params.plantId,
            status: this.state.pathinfo.params.status,
            createdBy: parseInt(this.state.pathinfo.params.createdBy, 10),
            purchaseState: this.state.pathinfo.params.purchaseState,
            archived: this.state.pathinfo.params.archived,
        };

        return (
            <>
                <TableFilters>
                    <PlantListSelectMenu
                        onUpdate={this.handlePlantFilter}
                        selectHeader="Plants"
                        isClearable={true}
                        defaultSelected={filter.plantId}
                    />
                    <UserListSelectMenu
                        onUpdate={this.handleCreatedByFilter}
                        defaultSelected={[filter.createdBy]}
                        selectHeader="Created by"
                    />
                    <StatusSelectMenu
                        onUpdate={this.handleStatusFilter}
                        defaultSelected={filter.status}
                    />
                    <PurchaseStateSelectMenu
                        onUpdate={this.handlePurchaseStatusFilter}
                        isClearable={true}
                        defaultSelected={filter.purchaseState}
                    />
                    <ArchivedSelectMenu
                        defaultSelected={filter.archived}
                        onUpdate={this.handleArchivedFilter}
                    />
                </TableFilters>
            </>
        );
    };

    private renderTableActions = () => {
        return <TableActions renderSortDropdown={this.renderSortDropdown} />;
    };

    private renderBasketTable = () => {
        return (baskets: IBasketModel[], totalCount: number, limit: number, offset: number) => {
            const tablePageProps: ITablePageProps = getPageProps(totalCount, limit, offset);
            return this.renderControlledTable(baskets, tablePageProps);
        };
    };

    private renderControlledTable = (
        basketList: IBasketModel[],
        tablePageProps: ITablePageProps,
    ): JSX.Element => {
        const { pages, currentPageIndex, totalCount } = tablePageProps;

        return (
            <ControlledTable
                data={basketList}
                totalCount={totalCount}
                columns={this.columns}
                onClickRow={this.onClickRow}
                onClickRowReturnData="id"
                pages={pages}
                page={currentPageIndex}
                onPageChange={this.onPageChange}
                actionButtons={this.renderTableActions}
                filters={this.renderTableFilters}
            />
        );
    };

    private onResult = (totalCount: number): void => {
        if (totalCount !== this.state.totalCount) {
            this.setState({ totalCount });
        }
    };

    private selectCreatedBasket = (basketId: string) => {
        if (basketId) {
            this.props.history.push(`${Routing.BASKET_DETAILS.getPath(basketId)}`);
        }
    };

    public render() {
        const { offset, limit, sortBy, totalCount, showCreateBasket } = this.state;
        let { filter } = this.state;
        filter = {
            plantId: this.state.pathinfo.params.plantId,
            status: this.state.pathinfo.params.status,
            createdBy: parseInt(this.state.pathinfo.params.createdBy, 10),
            purchaseState: this.state.pathinfo.params.purchaseState,
            archived: Boolean(this.state.pathinfo.params.archived),
        };

        return (
            <>
                <PageHeader buttons={this.headerButtons()}>
                    {todo('Baskets')} ({totalCount})
                </PageHeader>
                {showCreateBasket && (
                    <BasketCreateModal
                        onClose={this.toggleCreateBasket}
                        onSuccess={this.selectCreatedBasket}
                    />
                )}
                <BasketListQuery
                    offset={offset}
                    limit={limit}
                    sortBy={sortBy}
                    onResult={this.onResult}
                    filter={!_.isEmpty(filter) ? filter : null}
                    onResultDataProp="totalCount"
                    renderFetchedBasketList={this.renderBasketTable()}
                />
            </>
        );
    }
}

export default withMainNavigation(BasketListContainer);
