import * as React from 'react';

import { GROUP_QUERY } from './../../../api/GraphQLQueries/Group';
import DetailQueryContainer from './../../../containers/common/DetailQueryContainer';
import IGroupModel from './../../../models/IGroupModel';

interface IProps {
    id: number;
    renderFetchedGroupData: (group: IGroupModel) => JSX.Element;
}

export default class GroupDetailQuery extends React.Component<IProps, any> {
    public render() {
        const { id, renderFetchedGroupData } = this.props;

        const variables = { id };

        return (
            <DetailQueryContainer
                query={GROUP_QUERY}
                variables={variables}
                dataProp="group"
                renderFetchedData={renderFetchedGroupData}
            >
                {(groupData: IGroupModel) => {
                    return renderFetchedGroupData(groupData);
                }}
            </DetailQueryContainer>
        );
    }
}
