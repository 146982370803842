import * as React from 'react';
import { ValueType } from 'react-select/lib/types';

import { IGrapQLDataResult } from '../../api/GraphQLQueries/GraphQLTypes.d';
import { PLANT_LIST_QUERY } from '../../api/GraphQLQueries/Plant';
import MultiSelect from '../ui/MultiSelect';
import IPlantModel from './../../models/IPlantModel';
import PlantListQuery from './queries/PlantListQuery';

interface IProps {
    rows?: number;
    multiple?: boolean;
    onUpdate?: (ids: string[]) => void;
    onResult?: (data: IGrapQLDataResult<IPlantModel[]>) => void;
    defaultSelected?: string[];
    disabled?: string[];
    selectHeader?: string;
    isClearable?: boolean;
}

interface IState {
    limit: number;
    offset: number;
    plants: string[];
}

export default class PlantListSelectMenu extends React.Component<IProps, IState> {
    public state: IState = {
        offset: 0,
        limit: 100,
        plants: [],
    };

    public componentDidMount() {
        this.setState({ plants: this.props.defaultSelected || [] });
    }

    private setPlants = (plants: string[]): void => {
        const { onUpdate } = this.props;

        this.setState({ plants }, () => {
            if (onUpdate) {
                onUpdate(plants);
            }
        });
    };

    private optionDisabled = (plant: IPlantModel): boolean => {
        const { disabled } = this.props;
        if (!disabled || !disabled.length) {
            return false;
        }
        return disabled.includes(plant.id);
    };

    private handleChange = (plants: ValueType<IPlantModel>): void => {
        if (!plants || (Array.isArray(plants) && plants.length === 0)) {
            return this.setPlants([]);
        }

        // Plants are an array when props.multiple is true, but checking props requires TS type casting
        if (Array.isArray(plants)) {
            this.setPlants(plants.map(({ id }) => id));
        } else {
            this.setPlants([plants.id]);
        }
    };

    private getOptionType = (index: string): ((plant: IPlantModel) => string) => {
        return (plant: IPlantModel) => (plant as { [key: string]: any })[index];
    };

    private renderSelectMenu() {
        return (plants: IPlantModel[]) => {
            const { plants: statePlants } = this.state;
            const selected = plants.filter(({ id }) => statePlants.includes(id));
            const { isClearable } = this.props;
            return (
                plants && (
                    <MultiSelect<IPlantModel>
                        cy="plantListSelectMenu-plantListSelect-select"
                        isMulti={this.props.multiple}
                        value={selected}
                        isClearable={isClearable || undefined}
                        isOptionDisabled={this.optionDisabled}
                        closeMenuOnSelect={!this.props.multiple}
                        getOptionLabel={this.getOptionType('name')}
                        getOptionValue={this.getOptionType('id')}
                        options={plants}
                        placeholder={this.props.selectHeader}
                        onChange={this.handleChange}
                    />
                )
            );
        };
    }

    public render() {
        const { limit, offset } = this.state;

        return (
            <PlantListQuery
                hideLoadingIndicator={true}
                limit={limit}
                offset={offset}
                fetchPolicy="network-only"
                query={PLANT_LIST_QUERY}
                onResult={this.props.onResult}
                renderFetchedPlantList={this.renderSelectMenu()}
            />
        );
    }
}
