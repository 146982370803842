const lang = {
    key: 'en',
    lang: 'English',

    login: 'Login',
    register: 'Register',

    page_not_found_title: 'Page not found...',

    email_label: 'E-Mail Address',
    email_placeholder: 'Your E-Mail',

    password_label: 'Password',
    password_placeholder: 'Your password',

    button_register: 'Register now',
    button_login: 'Login',

    password_complexity: 'At least 8 characters long, contain uppercase and special characters.',
    password_mismatch: 'Password confirmation does not match new password',

    forgot_password: 'Reset your password',
    forgot_password_button: 'Email me',
    forgot_password_submitted_text:
        'If the E-Mail is registered with PLANTSERV®, a message containing instructions on how to reset your password will have been sent to you.',

    password_reset_title: 'Reset your password',
    password_reset_text: 'Enter your new password',
    password_reset_success_title: 'Password changed',
    password_reset_success_text: 'You can now log in with your new password',
    password_reset_failed_title: 'Password reset failed',
    password_reset_failed_text: 'Unable to reset password',

    register_text: 'Register text.. Enter data...',
    register_success_title: 'Registration successful',
    register_success_text: 'You can now log in.',
    register_failed_title: 'Registration Failed',
    register_failed_text: 'Perhaps the register link is out of date.',

    plant_name_id_complexity: 'At least 3 characters long',
    plant_s3folder_notExist: 's3 folder do not exist',
};

export default lang;
