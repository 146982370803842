import { Control, Field, Icon, Input, Label } from 'rbx';
import * as React from 'react';

import { isRequired } from '../../../utils/helpers';
import { IGroupCreateModel } from './../../../models/IGroupModel';
import GroupListSelectMenu from './../../group/GroupListSelectMenu';

interface IProps {
    onUpdate: (group: IGroupCreateModel) => void;
    group: IGroupCreateModel;
}

export default class GroupCreateForm extends React.Component<IProps, any> {
    private nameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.group,
            name: context.currentTarget.value,
        });
    };

    private descriptonChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.group,
            description: context.currentTarget.value,
        });
    };

    private parentIdChange = (parentIds: number[]): void => {
        const parentId = parentIds.length > 0 ? parentIds[0] : undefined;

        this.props.onUpdate({
            ...this.props.group,
            parentId,
        });
    };

    public render() {
        const { name, description } = this.props.group;

        return (
            <>
                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>{isRequired('Group name')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Name"
                                    value={name}
                                    onChange={this.nameChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Description</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Description"
                                    value={description}
                                    onChange={this.descriptonChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Parent group (optional)</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <GroupListSelectMenu
                                selectHeader="Name"
                                onUpdate={this.parentIdChange}
                            />
                        </Field>
                    </Field.Body>
                </Field>
            </>
        );
    }
}
