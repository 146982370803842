import { Container } from 'rbx';
import * as React from 'react';
import { Link } from 'react-router-dom';

import Routing from './../Routing';
import { t } from './../utils/translate';
import withMainNavigation from './withMainNavigation';

class NotFoundContainer extends React.Component<any, any> {
    public render() {
        return (
            <>
                <Container>
                    <h1 className="title">{t().page_not_found_title}</h1>
                    <hr />
                    <Link className="button is-primary" to={Routing.INDEX.getPath()}>
                        Back to dashboard
                    </Link>
                </Container>
            </>
        );
    }
}

export default withMainNavigation(NotFoundContainer);
