import { Table } from 'rbx';
import * as React from 'react';

import IndexDocumentMutation from './IndexDocumentMutation';

export interface IIndeaxable {
    indexedCount: number;
    indexableCount: number;
    ignoredCount: number;
    errorCount: number;
    name: string;
    type: string;
    query: string;
    sort: number;
    dryRun: boolean;
    forceSplit: boolean;
    onlyErrored: boolean;
    forceOcr: boolean;
    forceIndex: boolean;
    forceNormalize: boolean;
    forceExtract: boolean;
}

interface IProps {
    plantS3Folder: string;
    indexable: IIndeaxable;
}

export class IndexableRow extends React.Component<IProps, any> {
    public render() {
        const { plantS3Folder, indexable } = this.props;
        const hasAction = indexable.name !== 'ignored' && indexable.name !== 'error';

        return (
            <Table.Row>
                <Table.Cell>{indexable.name} </Table.Cell>
                <Table.Cell>{indexable.indexableCount} </Table.Cell>
                <Table.Cell>
                    {indexable.indexedCount}
                    {indexable.type === 's3' && (
                        <small>
                            &nbsp;({indexable.ignoredCount} ignored / {indexable.errorCount}&nbsp;
                            errored)
                        </small>
                    )}
                </Table.Cell>
                <Table.Cell>
                    {hasAction && (
                        <>
                            {indexable.type === 's3' && (
                                <IndexDocumentMutation
                                    indexable={indexable}
                                    plantS3Folder={plantS3Folder}
                                />
                            )}
                        </>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}
