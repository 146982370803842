import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Search from './elastic/Search';
import withMainNavigation from './withMainNavigation';

interface IProps extends RouteComponentProps<{}> {
    documentHandlerActionButtons?: any;
}

class SearchContainer extends React.Component<IProps> {
    public render() {
        return <Search />;
    }
}

export default withMainNavigation(SearchContainer);
