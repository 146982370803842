import { Content } from 'rbx';
import * as React from 'react';
import { Link } from 'react-router-dom';

import CreateBasketModal from './basket/CreateBasketModal';
import UserAddToBasketModal from './basket/UserAddToBasketModal';

interface IState {
    addModalOpen: boolean;
    createModalOpen: boolean;
}

export default class DevBasket extends React.Component<any, IState> {
    public state: IState = {
        addModalOpen: false,
        createModalOpen: true,
    };

    private toggleAddToBasketModal = () => {
        this.setState({ addModalOpen: !this.state.addModalOpen });
    };

    private toggleCreateBasketModal = () => {
        this.setState({ createModalOpen: !this.state.createModalOpen });
    };

    public render() {
        const { addModalOpen, createModalOpen } = this.state;
        return (
            <Content>
                <p>
                    The basket flow starts from the search screen. When the user is viewing a
                    document of any type, he can add the current document as a supporting document
                    to a basket line item.
                </p>
                <p>
                    The "Add to basket modal" gets triggered from the document viewer, when a user
                    clicks on "Add to basket".
                </p>

                <strong>Layouts:</strong>
                <ul>
                    <li>
                        <button className="button-link" onClick={this.toggleAddToBasketModal}>
                            Create basket modal
                        </button>
                    </li>
                    <li>
                        <button className="button-link" onClick={this.toggleAddToBasketModal}>
                            Add to basket modal
                        </button>
                    </li>
                    <li>
                        <Link to="/dev/basket-basket">Basket</Link>
                    </li>
                </ul>
                {addModalOpen && <UserAddToBasketModal onClose={this.toggleAddToBasketModal} />}
                {createModalOpen && <CreateBasketModal onClose={this.toggleCreateBasketModal} />}
            </Content>
        );
    }
}
