import * as _ from 'lodash';

export interface ITablePageProps {
    totalCount: number;
    pages: number;
    currentPageIndex: number;
    pageSize: number;
}

const getPages = (totalCount: number, limit: number): number => {
    return _.ceil(totalCount / limit);
};

// returns the page index, first page index is 0
const getCurrentPageIndex = (offset: number, limit: number): number => {
    return offset > 0 ? offset / limit : 0;
};

const getPageProps = (totalCount: number, limit: number, offset: number): ITablePageProps => {
    return {
        totalCount,
        pages: getPages(totalCount, limit),
        currentPageIndex: getCurrentPageIndex(offset, limit),
        pageSize: limit,
    };
};

export { getPageProps };
