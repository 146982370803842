import gql from 'graphql-tag';

const BASKET_LIST_QUERY = gql`
    query Baskets($offset: Int, $limit: Int, $order: [BasketOrder], $filter: BasketFilter) {
        baskets(offset: $offset, limit: $limit, order: $order, filter: $filter)
            @connection(key: "baskets") {
            data {
                id
                name
                plant {
                    id
                    name
                }
                description
                deadline
                label
                shippingTerm
                status
                archived
                leNote
                customerReference
                updatedAt
                createdAt
                submitDate
            }
            limit
            offset
            totalCount
        }
    }
`;

const BASKET_QUERY = gql`
    query Basket($id: String!) {
        basket(id: $id) {
            id
            name
            plant {
                id
                name
            }
            description
            deadline
            label
            shippingTerm
            status
            purchaseState
            archived
            leNote
            customerReference
            updatedAt
            createdAt
            createdBy {
                name
            }
        }
    }
`;

const BASKET_LINE_ITEMS_QUERY = gql`
    query BasketLineItems($id: String!) {
        basket(id: $id) {
            id
            name
            status
            archived
            lineItems {
                id
                part {
                    id
                    tagNumber
                    tagNumberAsId
                    type
                    specNum
                    serialNum
                    originalOrderNum
                    designation
                    lindeIdentNum
                    manufactPartNum
                    material
                    customerMaterialNum
                    modelNum
                    unitOfMeasurement
                    clientNo
                    drawingNum
                    drawingSectNum
                    qtyUnit
                    qtyInstalledPerUnit
                    contract
                    oxygenRelevance
                    oxygenRelevance2
                    certificate
                }
                parentPart {
                    id
                    tagNumber
                    tagNumberAsId
                    type
                    specNum
                    serialNum
                    originalOrderNum
                    designation
                    lindeIdentNum
                    manufactPartNum
                    material
                    customerMaterialNum
                    modelNum
                    unitOfMeasurement
                    clientNo
                    drawingNum
                    drawingSectNum
                    qtyUnit
                    qtyInstalledPerUnit
                    contract
                    oxygenRelevance
                    oxygenRelevance2
                    certificate
                }
                description
                isWholeComponent
                requestType
                title
                quantity
                filename
                pageKey
                createdAt
                updatedAt
            }
        }
    }
`;

const BASKET_CREATE_MUTATION = gql`
    mutation BasketCreateMutation($basket: BasketCreateInput!) {
        createBasket(basket: $basket) {
            id
            name
            plant {
                id
                name
            }
            description
            deadline
            label
            shippingTerm
            status
            archived
            customerReference
            lineItems {
                id
                part {
                    id
                    designation
                    tagNumber
                    tagNumberAsId
                }
                parentPart {
                    id
                    designation
                    tagNumber
                    tagNumberAsId
                }
                description
                isWholeComponent
                requestType
                title
                quantity
                filename
                pageKey
                createdAt
                updatedAt
            }
        }
    }
`;

const BASKET_UPDATE_MUTATION = gql`
    mutation BasketUpdateMutation($basket: BasketUpdateInput!) {
        updateBasket(basket: $basket) {
            id
            name
            description
            deadline
            label
            shippingTerm
            status
            archived
            leNote
            purchaseState
            customerReference
            updatedAt
            createdAt
            lineItems {
                id
                part {
                    id
                    designation
                    tagNumber
                    tagNumberAsId
                }
                parentPart {
                    id
                    designation
                    tagNumber
                    tagNumberAsId
                }
                description
                isWholeComponent
                requestType
                title
                quantity
                filename
                pageKey
                createdAt
                updatedAt
            }
        }
    }
`;

const BASKET_DELETE_MUTATION = gql`
    mutation DeleteBasket($id: String!) {
        deleteBasket(id: $id)
    }
`;

const BASKET_UPDATE_STATUS_MUTATION = gql`
    mutation UpdateBasketStatus($id: String!, $status: BasketStatus!) {
        updateBasketStatus(id: $id, status: $status)
    }
`;

const BASKET_FILES = gql`
    query BasketFiles($basketId: String!) {
        basketFiles(basketId: $basketId) {
            filename
            isQuote
            s3Key
        }
    }
`;

const BASKET_FILE_DELETE_MUTATION = gql`
    mutation DeleteBasketFile($basketId: String!, $filename: String!, $isQuote: Boolean) {
        deleteBasketFile(basketId: $basketId, filename: $filename, isQuote: $isQuote)
    }
`;

const BASKET_UPDATE_ARCHIVED_MUTATION = gql`
    mutation UpdateBasketArchived($id: String!, $archived: Boolean!) {
        updateBasketArchived(id: $id, archived: $archived)
    }
`;

export {
    BASKET_LIST_QUERY,
    BASKET_QUERY,
    BASKET_CREATE_MUTATION,
    BASKET_UPDATE_MUTATION,
    BASKET_LINE_ITEMS_QUERY,
    BASKET_DELETE_MUTATION,
    BASKET_UPDATE_STATUS_MUTATION,
    BASKET_FILES,
    BASKET_FILE_DELETE_MUTATION,
    BASKET_UPDATE_ARCHIVED_MUTATION,
};
