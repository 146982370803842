import { Button, Control, Delete, Field, Label, Modal } from 'rbx';
import * as React from 'react';

import DatePicker from '../../ui/datePicker/DatePicker';
import FormField from '../../ui/FormField';
import ModalContainer from '../../ui/ModalContainer';

interface IProps {
    onClose: () => void;
}

export default class CreateBasketModal extends React.Component<IProps, any> {
    private onFormChange = () => {
        // console.log('form update');
    };

    public render = () => {
        const { onClose } = this.props;

        return (
            <ModalContainer isOpen={true} onRequestClose={onClose}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Create basket</Modal.Card.Title>
                        <Delete onClick={onClose} />
                    </Modal.Card.Head>

                    <Modal.Card.Body>
                        <FormField
                            label="Title"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Basket name"
                        />
                        <FormField
                            label="Description"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="What is this basket for?"
                        />

                        <Field horizontal={true}>
                            <Field.Label>
                                <Label>Delivery Date</Label>
                            </Field.Label>
                            <Field.Body>
                                <Field>
                                    <Control expanded={true}>
                                        <DatePicker />
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>

                        <FormField
                            label="Label"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Single select with values [repair, maintenance, turnaround]"
                        />
                        <FormField
                            label="Shipping terms"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Single select: EXW, FCA, CPT, CIP, DAT, DAP, DDP, FAS, FOB, CFR, CIF"
                        />
                        <FormField
                            label="Customer Reference number"
                            horizontal={true}
                            onChange={this.onFormChange}
                            placeholder="Reference"
                        />
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button onClick={onClose}>Create basket</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };
}
