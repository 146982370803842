import en from './../i18n/en';

class Translate {
    public todo(str: string) {
        // console.warn('Missing transtation: ', str);
        return str;
    }

    public t() {
        return en;
    }
}

const instance = new Translate();
const { todo, t } = instance;

export { todo, t };
