import * as React from 'react';
import { Route } from 'react-router-dom';

import DevBasketContainer from '../../components/_dev/basket/DevBasketContainer';
import DevBasket from '../../components/_dev/DevBasket';
import TagUpdateContainer from '../TagUpdateContainer';
import DevEditTag from './../../components/_dev/DevEditTag';
import DevIndex from './../../components/_dev/DevIndex';
import withMainNavigation from './../withMainNavigation';

class DevContainer extends React.Component {
    public render() {
        return (
            <>
                <Route exact={true} path="/dev" component={DevIndex} />
                <Route exact={true} path="/dev/edittag" component={DevEditTag} />
                <Route exact={true} path="/dev/tag" component={TagUpdateContainer} />
                <Route exact={true} path="/dev/basket" component={DevBasket} />
                <Route exact={true} path="/dev/basket-basket" component={DevBasketContainer} />
            </>
        );
    }
}

export default withMainNavigation(DevContainer);
