import * as React from 'react';

import { TAG_BY_NUMBER_QUERY } from '../../../api/GraphQLQueries/Tag';
import DetailQueryContainer, {
    IGraphQLQueryProps,
} from '../../../containers/common/DetailQueryContainer';
import IPartModel from './../../../models/IPartModel';

interface IProps extends IGraphQLQueryProps {
    tagNumberAsId: string;
    plantId: string;
    renderFetchedTagData: (tag: IPartModel) => JSX.Element;
}

export default class TagDetailQuery extends React.Component<IProps, any> {
    public render = () => {
        const { tagNumberAsId, plantId, renderFetchedTagData } = this.props;

        const variables = { plantId, tagNumberAsId };

        return (
            <DetailQueryContainer
                {...this.props}
                query={TAG_BY_NUMBER_QUERY}
                variables={variables}
                dataProp="tagByNumber"
                renderFetchedData={renderFetchedTagData}
            />
        );
    };
}
