import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { GROUP_QUERY } from '../../../api/GraphQLQueries/Group';
import { PLANT_QUERY } from '../../../api/GraphQLQueries/Plant';
import { IMembershipSaveModel } from '../../../models/IMembershipModel';
import { SAVE_MEMBERSHIP_MUTATION } from './../../../api/GraphQLQueries/Membership';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';
import UserSaveMembership from './../UserSaveMembership';

interface IProps {
    users: number[];
    onClose: () => void;
}

interface IState {
    error: boolean;
    success: boolean;
    membershipData?: IMembershipSaveModel;
    failed?: number[];
}

export default class UserCreateMultipleMembershipsMutation extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private formSubmit = async (doCreateMembershipMutation: MutationFn) => {
        const { users } = this.props;
        const { membershipData } = this.state;

        const failed: number[] = [];

        for (const userId of users) {
            const variables = {
                membership: {
                    ...membershipData,
                    userId,
                },
            };

            await doCreateMembershipMutation({ variables }).catch(() => failed.push(userId));
        }

        if (failed.length) {
            this.onError(failed);
        } else {
            this.onCompleted();
        }

        this.props.onClose();
    };

    // This kinda sucks, but otherwise it means giving this component the user details
    private renderFailed = (): string => {
        const { failed } = this.state;

        if (failed) {
            return `Memberships could not be created for user ids: <br />
                ${failed.join(', ')}`;
        }
        return '';
    };

    private onUpdate = (membershipData: IMembershipSaveModel) => {
        this.setState({ membershipData });
    };

    private onError = (failed: number[]): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            failed,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));
    };

    private renderCreateMembershipForm = (doCreateMembershipMutation: MutationFn) => {
        const { membershipData: membership } = this.state;

        return (
            <>
                <UserSaveMembership
                    name="users"
                    showGroupPlantSelectMenu={true}
                    membership={membership}
                    onUpdate={this.onUpdate}
                    onSubmit={this.formSubmit.bind(this, doCreateMembershipMutation)}
                    onClose={this.props.onClose}
                />
            </>
        );
    };

    public render() {
        const { error, success } = this.state;
        const membershipData = this.state.membershipData || {};
        const { groupId, plantId } = membershipData;
        const refetch = {
            query: plantId ? PLANT_QUERY : GROUP_QUERY,
            variables: { id: plantId || groupId },
        };

        return (
            <>
                {error && <Error>{this.renderFailed()}</Error>}
                {success && <Message type="success">Successfully created memberships</Message>}
                <Mutation
                    mutation={SAVE_MEMBERSHIP_MUTATION}
                    refetchQueries={[refetch]}
                    awaitRefetchQueries={true}
                >
                    {doCreateMembership => this.renderCreateMembershipForm(doCreateMembership)}
                </Mutation>
            </>
        );
    }
}
