import React, { Component } from 'react';

interface IProps {
    className?: string;
    position?: string;
    zoom?: number;
    size?: { height: string; width: string };
}
const API_KEY = 'AIzaSyDtqo0OrMEw2pp6fORXtL9xEJZCSx4Texg';
const BASE_URL = `https://maps.googleapis.com/maps/api/staticmap`;
const DEFAULT_POS = ` Poccistraße 15, 80336 München`;
const DEFAULT_ZOOM = `17`;
const DEFAULT_SIZE = { width: 300, height: 600 };
const DEFAULT_MAPTYPE = `roadmap`;

class GmapsStaticImg extends Component<IProps> {
    public render() {
        const { className, position, zoom, size } = {
            position: DEFAULT_POS,
            zoom: DEFAULT_ZOOM,
            size: { ...DEFAULT_SIZE, ...this.props?.size },
            ...this.props,
        };

        const imgUrl =
            `${BASE_URL}?` +
            [
                `size=${size.height}x${size.width}`,
                `center=${encodeURIComponent(position)}`,
                `zoom=${zoom}`,
                `maptype=${DEFAULT_MAPTYPE}`,
                `key=${API_KEY}`,
            ].join('&');

        return (
            <>
                <img className={className} width={size.width} src={imgUrl} alt="" />
            </>
        );
    }
}

export default GmapsStaticImg;
