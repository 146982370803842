import React from 'react';

import { CHECK_FOLDER } from '../../../api/GraphQLQueries/Plant';
import DetailQueryContainer from '../../../containers/common/DetailQueryContainer';

interface IProps {
    plantS3Folder: string;
    renderFetchedFolderData: (checkIfFolderExists: boolean) => JSX.Element;
    onResult: (checkIfFolderExists: boolean) => void;
}

export default class FolderDetailQuery extends React.Component<IProps, any> {
    public render() {
        const { plantS3Folder, renderFetchedFolderData } = this.props;

        const variables = { plantS3Folder };

        return (
            <DetailQueryContainer
                query={CHECK_FOLDER}
                variables={variables}
                dataProp="checkIfFolderExists"
                onResult={this.props.onResult}
                onResultReturnData={true}
                renderFetchedData={renderFetchedFolderData}
            >
                {(checkIfFolderExists: boolean) => {
                    return renderFetchedFolderData(checkIfFolderExists);
                }}
            </DetailQueryContainer>
        );
    }
}
