export const isMIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    return (
        msie > 0 ||
        !!navigator.userAgent.match(/Trident.*rv:11\./) ||
        navigator.userAgent.indexOf('Edge') > -1
    );
};

export const isRequired = (str: string) => {
    return `${str} *`;
};

export const normalize = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getTopLevelPlantsFolder = (folder: string) => {
    const regex = /(plants)\/(.*?)\/([^]*)\/*./g;
    folder = folder + '/'; // so that the regex can handle the top level case
    const match = regex.exec(folder);
    if (!match) {
        return '';
    }
    return match[3];
};

export const toCapitalCase = (s: string) => {
    return s.replace(/_/g, ' ').replace(/\b(.)/g, c => c.toUpperCase());
};

export const fileSizeForHumans = (bytes: number, fmtWithSpace = true) => {
    const space = fmtWithSpace ? ' ' : '';
    // https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
    if (bytes < 1024) {
        return bytes + `${space}B`;
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const num = bytes / Math.pow(1024, i);
    const round = Math.round(num);
    const text = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;

    return `${text}${space}${'KMGTPEZY'[i - 1]}B`;
};

export const camelToTitle = (s: string) =>
    s.replace(/(^.|[A-Z])/g, (you, match, i) => (you && i ? ' ' : '') + match.toUpperCase());

export const getBasketStatusText = (serverText: string): string => {
    let statusText = serverText;
    switch (statusText) {
        case 'submitted':
            statusText = 'Submitted to portal team';
            break;
        case 'locked':
            statusText = 'Locked - portal team is working';
            break;
        case 'quoted':
            statusText = 'RFQ with vendor';
            break;
        case 'completed':
            statusText = 'Quotation uploaded (Completed)';
            break;
    }

    return statusText;
};
