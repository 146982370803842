import { Control, Field, Icon, Input, Label } from 'rbx';
import * as React from 'react';

import { isRequired } from '../../../utils/helpers';
import IUserModel, { FriendlyGlobalRole, GlobalRole } from './../../../models/IUserModel';

interface IProps {
    onUpdate: (user: IUserModel) => void;
    user: IUserModel;
}

export default class UserUpdateBaseFields extends React.Component<IProps, any> {
    private nameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.user,
            name: context.currentTarget.value,
        });
    };

    private jobTitleChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.user,
            jobTitle: context.currentTarget.value,
        });
    };

    private emailChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.user,
            email: context.currentTarget.value,
        });
    };

    public render() {
        const { name, email, jobTitle, globalRole } = this.props.user;
        const disabled = globalRole !== GlobalRole.linde_admin;
        return (
            <>
                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>{isRequired('Name')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Name"
                                    value={name}
                                    onChange={this.nameChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-user" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>{disabled ? 'Email' : isRequired('Email')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={this.emailChange}
                                    disabled={disabled}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-envelope" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Job title</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="job title"
                                    value={jobTitle || ''}
                                    onChange={this.jobTitleChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-user" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Global role</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true}>
                                <Input
                                    type="text"
                                    disabled={true}
                                    value={FriendlyGlobalRole[globalRole]}
                                />
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>
            </>
        );
    }
}
