import gql from 'graphql-tag';

const SAVE_MEMBERSHIP_MUTATION = gql`
    mutation SaveMembership($membership: SaveMembershipInput!) {
        saveMembership(membership: $membership) {
            id
            role
            group {
                id
                name
            }
            plant {
                id
                name
            }
        }
    }
`;

const DELETE_MEMBERSHIP_MUTATION = gql`
    mutation DeleteMembership($id: Int!) {
        deleteMembership(id: $id)
    }
`;

export { SAVE_MEMBERSHIP_MUTATION, DELETE_MEMBERSHIP_MUTATION };
