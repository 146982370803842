import { Button, Control, Delete, Field, Help, Icon, Input, Modal } from 'rbx';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

import { CHANGE_PASSWORD_MUTATION } from '../../../api/GraphQLQueries/Auth';
import { PASSWORD_REGEX } from '../../../models/IUserModel';
import { t } from '../../../utils/translate';
import Error from '../../layout/Error';
import Message from '../../layout/Message';
import ModalContainer from '../../ui/ModalContainer';

interface IProps {
    onModalClose: () => void;
}
interface IState {
    currentPassword: string;
    newPasswordConfirm: string;
    newPassword: string;
    isValid: boolean;
    error: boolean;
    success: boolean;
}

class UserUpdatePassword extends Component<IProps, IState> {
    public state = {
        currentPassword: '',
        newPasswordConfirm: '',
        newPassword: '',
        isValid: false,
        error: false,
        success: false,
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.props.onModalClose,
        );
    };

    private onPasswordChange = (context: any) => {
        const target: any = context.target;
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                [target.name]: target.value,
            }),
            () => {
                this.validatePasswords();
            },
        );
    };

    private validatePasswords = () => {
        const { currentPassword, newPasswordConfirm, newPassword } = this.state;
        if (
            currentPassword &&
            PASSWORD_REGEX.test(newPassword) &&
            newPassword !== newPasswordConfirm
        ) {
            this.setState({ isValid: true });
        }
    };

    private renderModal = (doUserChangePasswordMutation: any) => {
        const { onModalClose } = this.props;
        const { newPassword, newPasswordConfirm } = this.state;
        const showMissMatchPasswordError =
            newPasswordConfirm &&
            newPassword !== newPasswordConfirm &&
            PASSWORD_REGEX.test(newPassword);
        return (
            <ModalContainer isOpen={true} onRequestClose={onModalClose}>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Change Password</Modal.Card.Title>
                        <Delete onClick={onModalClose} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Field horizontal={true}>
                            <Field.Body>
                                <Field>
                                    <Control expanded={true} iconLeft={true}>
                                        <Input
                                            type="password"
                                            placeholder="current password"
                                            name="currentPassword"
                                            onChange={this.onPasswordChange}
                                        />
                                        <Icon size="small" align="left">
                                            <i className="fa fa-user" />
                                        </Icon>
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                        <Field horizontal={true}>
                            <Field.Body>
                                <Field>
                                    <Control expanded={true} iconLeft={true}>
                                        <Input
                                            type="password"
                                            placeholder="new password"
                                            color={
                                                !PASSWORD_REGEX.test(newPassword) && newPassword
                                                    ? 'danger'
                                                    : ''
                                            }
                                            name="newPassword"
                                            onChange={this.onPasswordChange}
                                        />

                                        <Icon size="small" align="left">
                                            <i className="fa fa-user" />
                                        </Icon>
                                        {!PASSWORD_REGEX.test(newPassword) && (
                                            <Help color="danger">{t().password_complexity}</Help>
                                        )}
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                        <Field horizontal={true}>
                            <Field.Body>
                                <Field>
                                    <Control expanded={true} iconLeft={true}>
                                        <Input
                                            type="password"
                                            placeholder="confirm new password"
                                            color={showMissMatchPasswordError ? 'danger' : ''}
                                            name="newPasswordConfirm"
                                            onChange={this.onPasswordChange}
                                        />
                                        <Icon size="small" align="left">
                                            <i className="fa fa-user" />
                                        </Icon>

                                        {showMissMatchPasswordError && (
                                            <Help color="danger">{t().password_mismatch}</Help>
                                        )}
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button onClick={onModalClose}>Cancel</Button>
                        <Button
                            onClick={doUserChangePasswordMutation}
                            disabled={!this.state.isValid}
                        >
                            Change Password
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    };

    public render() {
        const { currentPassword, newPassword, error, success } = this.state;
        return (
            <>
                {error && <Error>Password update error</Error>}
                {success && <Message type="success">Successfully updated password</Message>}
                <Mutation
                    mutation={CHANGE_PASSWORD_MUTATION}
                    variables={{ currentPassword, newPassword }}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                >
                    {doUserChangePasswordMutation => this.renderModal(doUserChangePasswordMutation)}
                </Mutation>
            </>
        );
    }
}

export default UserUpdatePassword;
