import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { PART_DELETE_MUTATION } from './../../../api/GraphQLQueries/Part';
import { PARTS_BY_TAG_NUMBER_QUERY } from './../../../api/GraphQLQueries/Tag';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    id: string;
    tagNumberAsId?: string;
    plantId?: string;
    onSuccess: () => void;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

export default class PartDeleteMutation extends React.Component<IProps, IState> {
    public state: IState = {
        ...resetState,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );

        this.props.onSuccess();
    };

    private deletePart = (doDeleteMutation: MutationFn) => (): void => {
        if (window.confirm(`Are you sure? This action can not be undone.`)) {
            const { id } = this.props;
            doDeleteMutation({ variables: { id } });
        }
    };

    public render = () => {
        const { error, success } = this.state;
        const { plantId, tagNumberAsId } = this.props;

        const refetchQueries =
            plantId && tagNumberAsId
                ? [{ query: PARTS_BY_TAG_NUMBER_QUERY, variables: { plantId, tagNumberAsId } }]
                : [];

        return (
            <>
                {error && <Error>Part delete error</Error>}
                {success && <Message type="success">Successfully deleted part</Message>}
                <Mutation
                    mutation={PART_DELETE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={refetchQueries}
                >
                    {doDelete => (
                        <Button
                            type="button"
                            outlined={true}
                            color="danger"
                            onClick={this.deletePart(doDelete)}
                        >
                            Delete Part
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };
}
