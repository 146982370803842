import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import Icon from '../../../../components/ui/Icon';
import { MaintenanceButton } from '../../../../components/ui/MaintenanceButton';
import { notification } from '../../../../utils/notification';
import { REINDEX_COMPLETED_PAGES_FOR_PLANT } from './../../../../api/GraphQLQueries/Onboarding';

export interface IAdvancedIndexingOptions {
    dryRun?: boolean;
    onlyErrored?: boolean;
    forceSplit?: boolean;
    forceOcr?: boolean;
    forceIndex?: boolean;
    forceNormalize?: boolean;
    forceExtract?: boolean;
}

interface IProps {
    plantId: string;
    indexFileOptions: IAdvancedIndexingOptions;
}

type IReindexMutationFn = MutationFn<
    { reindexCompletedPagesForPlant: number },
    { plantId: string; indexFileOptions: IAdvancedIndexingOptions }
>;

export default class FastReindexMutation extends React.Component<IProps> {
    private onError = (): void => {
        notification.error('Failed to complete index action');
    };

    private onCompleted = (data: { reindexCompletedPagesForPlant: number }): void => {
        if (data.reindexCompletedPagesForPlant === 0) {
            notification.info(`No files which can be re-indexed were found`);
            return;
        }
        notification.success(`Re-index for ${data.reindexCompletedPagesForPlant} files started`);
    };

    private confirmReindex = (loading: boolean, reindexMutation: IReindexMutationFn) => () => {
        if (loading) {
            return;
        }
        if (window.confirm('Reindexing may take a long time. Are you sure?')) {
            return reindexMutation();
        }
    };

    public render() {
        const { plantId, indexFileOptions } = this.props;
        const options: IAdvancedIndexingOptions = {
            ...indexFileOptions,
            forceIndex: true,
            forceNormalize: true,
        };

        return (
            <>
                <Mutation
                    mutation={REINDEX_COMPLETED_PAGES_FOR_PLANT}
                    variables={{ plantId, indexFileOptions: options }}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {(reindexMutation, { loading }) => (
                        <div className="my-3 flex-align-center">
                            <MaintenanceButton
                                type="button"
                                className="mr-1"
                                color={loading ? 'info' : ''}
                                onClick={this.confirmReindex(loading, reindexMutation)}
                            >
                                {loading && <Icon icon="fa-cog fa-spin" />}
                                <span>Re-Index Completed Documents</span>
                            </MaintenanceButton>
                            <Icon
                                icon="fa-info-circle fg-grey"
                                title="This button will re-index with 'Advanced Indexing Options' for&nbsp;
                                files which have already been indexed before.&nbsp;
                                This applies to all file types for this plant."
                            />
                        </div>
                    )}
                </Mutation>
            </>
        );
    }
}
