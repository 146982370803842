import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ErrorResponse } from 'apollo-link-error';

import { transformApolloErr } from '../utils/apolloErrorsTransformer';
import { notification } from './../utils/notification';
import {
    doRefreshMutation,
    getToken,
    isLoggedIn,
    isTokenExpired,
    logout,
    parseJwtToken,
} from './authorization';
import { addRuntimeErrorListeners, logServerError } from './logging';

const env = process.env.REACT_APP_ENV;

let BASE_URI = `${window.location.protocol}//${window.location.hostname}`;
let API_URI = BASE_URI;
let GRAPHQL_URI;
let RABBIT_URI = `${BASE_URI}:15672`;
let SEARCHKIT_URI = `${BASE_URI}:4340`;
let KIBANA_URI = `${BASE_URI}:9200/_plugin/kibana/app/kibana`;

switch (env) {
    case 'integration':
    case 'staging':
    case 'devtest':
    case 'devtest2':
        API_URI = `https://ps-${env}.isarbits.net`;
        BASE_URI = `https://ps-${env}.isarbits.net`;
        RABBIT_URI = `https://ps-dev-rabbit.isarbits.net`;
        SEARCHKIT_URI = `https://ps-${env}-logs.isarbits.net`;
        KIBANA_URI = 'https://ps-dev-es.isarbits.net/_plugin/kibana';
        break;
    case 'serverhosted':
    case 'production':
        KIBANA_URI = '';
        if (/\.isarbits\.net/.test(window.location.hostname)) {
            RABBIT_URI = `${window.location.protocol}//ps-prod-rabbit.${window.location.hostname}`;
            SEARCHKIT_URI = `${window.location.protocol}//ps-prod-logs.${window.location.hostname}`;
        } else {
            RABBIT_URI = `https://ps-prod-rabbit.isarbits.net`;
            SEARCHKIT_URI = `https://ps-prod-logs.isarbits.net`;
            KIBANA_URI = 'https://ps-dev-es.isarbits.net/_plugin/kibana';
        }

        break;
    default:
        API_URI = `${BASE_URI}`;
        break;
}

GRAPHQL_URI = `${API_URI}/graphql`;

const JENKINS_URI = 'https://ps-j.isarbits.net';
const S3_PROXY_URI = `${API_URI}/api/s3-proxy`;
const SPINLOG_URI = `${SEARCHKIT_URI}/#/spindex`;

if (process.env.NODE_ENV === 'development') {
    console.info('Using GraphQL server at ', GRAPHQL_URI);
}

addRuntimeErrorListeners();

const onError = (e: ErrorResponse) => {
    if (process.env.NODE_ENV === 'production') {
        logServerError(e);
    }

    transformApolloErr(e).forEach(({ message, code }) => {
        notification.error(message);
        if (code === 'UNAUTHENTICATED') {
            logout(true);
        }
    });
};

const cache = new InMemoryCache();

export const unauthorizedClient = new ApolloClient({
    uri: GRAPHQL_URI,
    onError,
    cache,
});

let refreshingToken = null;

export const client = new ApolloClient({
    uri: GRAPHQL_URI,
    onError,
    cache,
    request: async operation => {
        if (!isLoggedIn()) {
            return;
        }

        if (isTokenExpired()) {
            if (!refreshingToken) {
                refreshingToken = doRefreshMutation();
            }
            await refreshingToken;
            refreshingToken = null;
        }

        const token = getToken();
        const user = parseJwtToken();
        if (typeof user.id === 'undefined') {
            await logout(true);
        }
        operation.setContext({
            headers: { Authorization: `Bearer ${token.accessToken}` },
        });
    },
});

export {
    BASE_URI,
    API_URI,
    JENKINS_URI,
    S3_PROXY_URI,
    RABBIT_URI,
    SEARCHKIT_URI,
    SPINLOG_URI,
    KIBANA_URI,
};
