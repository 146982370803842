import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';

interface IState {
    focused: boolean;
    date: Moment;
}

interface IProps {
    defaultDate?: string;
    onChange?: (date: string) => void;
}

const DATE_FORMAT = 'YYYY-MM-DD';

export default class DatePicker extends Component<IProps, IState> {
    public state: IState = {
        focused: false,
        date: moment(),
    };

    private convertToString = (date: Moment): string => {
        return date?.format(DATE_FORMAT);
    };

    private convertToDate = (dateString: string): Moment => {
        return moment(dateString, DATE_FORMAT);
    };

    private onChange = (date: any) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            date,
        }));
        if (this.props.onChange) {
            this.props.onChange(this.convertToString(date));
        }
    };

    private onFocusChange = ({ focused }: any) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            focused,
        }));
    };

    private isOutsideRange = (): boolean => {
        return false;
    };

    public render() {
        const { defaultDate } = this.props;

        return (
            <SingleDatePicker
                id="datePicker"
                numberOfMonths={1}
                onDateChange={this.onChange}
                onFocusChange={this.onFocusChange}
                focused={this.state.focused}
                date={defaultDate ? this.convertToDate(defaultDate) : this.state.date}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={this.isOutsideRange}
                displayFormat="YYYY-MM-DD"
                small={true}
            />
        );
    }
}
