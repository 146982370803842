import * as React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import PageHeader from '../components/layout/PageHeader';
import TagDetailQuery from '../components/tag/queries/TagDetailQuery';
import TagSummary from '../components/tag/TagSummary';
import IPartModel from '../models/IPartModel';
import Routing from '../Routing';
import withMainNavigation from './withMainNavigation';

interface IRouteParams {
    plantId: string;
    tagNumberAsId: string;
}

export class TagUpdateContainer extends React.Component<RouteComponentProps<IRouteParams>> {
    private headerButtons = () => {
        const { plantId } = this.props.match.params;
        return (
            <NavLink className="button" to={Routing.PLANT_TAGS.getPath(plantId)}>
                Back to tags
            </NavLink>
        );
    };

    private renderTagUpdate = (tag: IPartModel) => {
        return (
            tag && (
                <>
                    <PageHeader buttons={this.headerButtons()}>Edit Tag {tag.tagNumber}</PageHeader>
                    <TagSummary tag={tag} />
                </>
            )
        );
    };

    public render() {
        const { plantId, tagNumberAsId } = this.props.match.params;

        return (
            plantId &&
            tagNumberAsId && (
                <TagDetailQuery
                    tagNumberAsId={tagNumberAsId}
                    plantId={plantId}
                    renderFetchedTagData={this.renderTagUpdate}
                />
            )
        );
    }
}

export default withMainNavigation(TagUpdateContainer);
