import * as React from 'react';

interface IProps {
    plantS3Folder: string;
}

export default class DocumentsSyncText extends React.Component<IProps, any> {
    public render() {
        const { plantS3Folder } = this.props;

        return (
            <>
                <h3>File Description / Checklist:</h3>
                <strong>Folder: plants/{plantS3Folder}/csvs_to_import</strong>
                <p>
                    Note: All file names must be lowercase as indicated. Otherwise Spindex will not
                    find the file.
                </p>
                <br />

                <strong>plantserv-parts.csv</strong>
                <br />
                <ul>
                    <li>must be uploaded to plants/{plantS3Folder}/csvs_to_import </li>
                    <li>Part must have a dataSource row</li>
                    <li>
                        The Allowed DataSources are:
                        <br />
                        "spacy"
                        <br />
                        "sap_po" <br />
                        "sap_bw" <br />
                        "stl" <br />
                        "pdp"
                        <br />
                        "ped"
                        <br />
                        "spir"
                        <br />
                        "smart_plant"
                        <br />
                        "manual"
                        <br />
                    </li>
                    <li> Part must either have 'tagNumber' or 'parents.tagNumber' set </li>
                    <li>Part cannot have both 'tagNumber' and 'parents.tagNumber'</li>
                    <li>tagNumber should not be duplicated</li>
                </ul>
                <br />

                <strong>Folder: plants/{plantS3Folder}/documentum</strong>
                <br />
                <ul>
                    <li>must be uploaded to plants/{plantS3Folder}/documentum </li>
                    <li>Contains the full documtum export (including the forlder structure)</li>
                    <li>
                        Documentum files are classified into green/yellow/red according to their
                        relevance to PLANTSERV®. blue means the document could not be classified.
                    </li>
                    <li>
                        Spindex can currently not handle the following cases for documentum files:
                        <ul>
                            <li>Document removal</li>
                            <li>Document moving</li>
                        </ul>
                    </li>
                </ul>
                <br />

                <strong>Folder: plants/{plantS3Folder}/pnid</strong>
                <br />
                <ul>
                    <li>Contains one or many pnid files</li>
                </ul>
                <br />

                <strong>Folder: plants/{plantS3Folder}/po_uploads</strong>
                <br />
                <ul>
                    <li>Contains one or many po documents</li>
                    <li>This folder should be when there is no sap export available</li>
                </ul>
                <br />

                {/*
                <strong>tag-patterns.txt</strong>

                <ul>
                    <li>Must be uploaded to plants/{plantS3Folder}/settings/</li>
                    <li>Contains plant-specific tag matching pattern(s) (used when parsing and grouping tags in documents)</li>
                    <li>Acceptable formats are:
                        <ul>
                            <li><strong>Regex string</strong> (eg [ABCD]\\.[0-9]\\.[0-9]&#x7B;4&#x7D;)</li>
                            <li><strong>List of tags</strong> (eg ["A.1.0123", "B.2.4567", "C.3.8910"])</li>
                            // this is acceptable and handled by the API, but not a concept everyone is familiar with
                            <li><strong>JSON tag list</strong> (eg '&#x7B; "A.1.0123": true, "B.2.4567": true, "C.3.8910": true &#x7D;')</li>
                        </ul>
                    </li>
                </ul>

                <br />
                */}

                <strong>plantserv-parts.csv</strong>
                <br />
                <ul>
                    <li>must be uploaded to plants/{plantS3Folder}/csvs_to_import </li>
                    <li>Part must have a dataSource row</li>
                    <li>
                        The Allowed DataSources are:
                        <br />
                        "spacy"
                        <br />
                        "sap_po" <br />
                        "sap_bw" <br />
                        "stl" <br />
                        "pdp"
                        <br />
                        "ped"
                        <br />
                        "spir"
                        <br />
                        "smart_plant"
                        <br />
                        "manual"
                        <br />
                    </li>
                    <li> Part must either have 'tagNumber' or 'parents.tagNumber' set </li>
                    <li>Part cannot have both 'tagNumber' and 'parents.tagNumber'</li>
                    <li>tagNumber should not be duplicated</li>
                </ul>
                <br />

                <strong>Top Level Folder: plants/{plantS3Folder}</strong>
                <br />
                <ul>
                    <li>Content in the top level folder will not be be indexed.</li>
                </ul>
                <br />
                <strong>Systems Folders</strong>
                <br />
                <ul>
                    <li>The following folders are created and maintend by PLANTSERV®.</li>
                    <li>Please do not touch the following folders:</li>
                    <li>
                        <ul>
                            <li>
                                <strong>plants/{plantS3Folder}/</strong>
                                <ul>
                                    <li>baskets</li>
                                    <li>equipment</li>
                                    <li>generated_content</li>
                                    <li>overrides</li>
                                </ul>
                            </li>
                            <li>
                                <strong>shadow</strong>/plants/{plantS3Folder}
                            </li>
                        </ul>
                    </li>
                </ul>
            </>
        );
    }
}
