import { Button, Control, Field, Icon, Input } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { LINE_ITEM_UPDATE_MUTATION } from './../../../api/GraphQLQueries/LineItem';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    lineItemId: number;
    quantity?: string;
}

interface IState {
    error: boolean;
    success: boolean;
    value: string;
    quantityChanged: boolean;
}

const resetState = {
    error: false,
    success: false,
    quantityChanged: false,
};

class BasketUpdateQuatntiyMutation extends React.Component<IProps, IState> {
    public state: IState = {
        ...resetState,
        value: this.props.quantity || '',
        quantityChanged: false,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    private updateQuantity = (doUpdateMutation: MutationFn) => (e: any): void => {
        const { lineItemId } = this.props;
        const { value } = this.state;

        if (e.key === 'Enter') {
            doUpdateMutation({ variables: { lineItem: { id: lineItemId, quantity: value } } });
            e.currentTarget.blur();
        }
    };

    private handleButton = (doUpdateMutation: MutationFn) => (): void => {
        const { lineItemId } = this.props;
        const { value } = this.state;
        doUpdateMutation({ variables: { lineItem: { id: lineItemId, quantity: value } } });
    };

    private updateDetails = (context: any) => {
        const { value } = context.currentTarget;

        this.setState((prevState: IState) => ({
            ...prevState,
            value,
            quantityChanged: true,
        }));
    };

    public render = () => {
        const { error, success, value, quantityChanged } = this.state;

        return (
            <>
                {error && <Error>Quantity update error</Error>}
                {success && <Message type="success">Successfully updated quantity</Message>}
                <Mutation
                    mutation={LINE_ITEM_UPDATE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {doUpdate => (
                        <Field kind={quantityChanged ? 'addons' : ''} className="is-narrow">
                            <Control expanded={true}>
                                <Input
                                    type="text"
                                    value={value}
                                    onChange={this.updateDetails}
                                    onKeyPress={this.updateQuantity(doUpdate)}
                                />
                            </Control>
                            {quantityChanged && (
                                <Control>
                                    <Button
                                        color="primary"
                                        disabled={!quantityChanged}
                                        onClick={this.handleButton(doUpdate)}
                                    >
                                        <Icon size="small">
                                            <i className="fa fa-arrow-alt-right" />
                                        </Icon>
                                    </Button>
                                </Control>
                            )}
                        </Field>
                    )}
                </Mutation>
            </>
        );
    };
}

export default BasketUpdateQuatntiyMutation;
