import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import IPartModel, { IPartUpdateModel } from '../../../models/IPartModel';
import { PART_UPDATE_MUTATION } from './../../../api/GraphQLQueries/Part';
import { TAG_BY_NUMBER_QUERY } from './../../../api/GraphQLQueries/Tag';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IRouteParams {
    plantId: string;
    tagNumberAsId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    source: string;
    tag: IPartModel;
    filekey: string;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

class TagFillFromSourceMutation extends React.Component<IProps, IState> {
    public state: IState = { ...resetState };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({ ...prevState, ...resetState }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    private convertTagToTagUpdateModel = (): IPartUpdateModel => {
        const { tag, source } = this.props;

        const tagDetails: IPartUpdateModel = _.omit(tag, [
            'parents',
            'plant',
            'parts',
            'documentPages',
            'tagNumberAsId',
            'updatedBy',
            'createdBy',
            'createdAt',
            'updatedAt',
        ]);

        if (source === 'foobarbaz') {
            // TODO: add final sap documentSource, waiting for backend
            // tagDetails.foobarbaz = filekey;
        }

        return tagDetails;
    };

    public render() {
        const { error, success } = this.state;
        const { plantId, tagNumberAsId } = this.props.match.params;
        const part = this.convertTagToTagUpdateModel();

        return (
            plantId &&
            tagNumberAsId &&
            part && (
                <>
                    {error && <Error>get source data error</Error>}
                    {success && <Message type="success">Successfully added source data</Message>}
                    <Mutation
                        mutation={PART_UPDATE_MUTATION}
                        variables={{ part: _.omit(part, ['__typename']) }}
                        onCompleted={this.onCompleted}
                        onError={this.onError}
                        refetchQueries={[
                            {
                                query: TAG_BY_NUMBER_QUERY,
                                variables: { plantId, tagNumberAsId },
                            },
                        ]}
                    >
                        {doFillFromSource => (
                            <Button type="button" outlined={true} onClick={doFillFromSource}>
                                Fill fields
                            </Button>
                        )}
                    </Mutation>
                </>
            )
        );
    }
}

export default withRouter(TagFillFromSourceMutation);
