import * as React from 'react';

import IBasketModel from '../../../models/IBasketModel';
import ILineItemModel from '../../../models/ILineItemModel';
import { BASKET_LINE_ITEMS_QUERY } from './../../../api/GraphQLQueries/Basket';
import DetailQueryContainer, {
    IGraphQLQueryProps,
} from './../../../containers/common/DetailQueryContainer';

interface IProps extends IGraphQLQueryProps {
    id: string;
    renderFetchedLineItems: (lineItems: ILineItemModel[]) => JSX.Element;
}

export default class BasketLineItemListQuery extends React.Component<IProps, any> {
    private renderBasketLineItems = (basket: IBasketModel) => {
        const { lineItems } = basket;

        return lineItems ? this.props.renderFetchedLineItems(lineItems) : null;
    };

    private onResult = (basket: IBasketModel) => {
        if (this.props.onResult && this.props.onResultReturnData) {
            const { lineItems } = basket;

            this.props.onResult(lineItems);
        }
    };

    public render() {
        const { id, onResultReturnData } = this.props;

        const variables = { id };

        return (
            <DetailQueryContainer
                query={BASKET_LINE_ITEMS_QUERY}
                variables={variables}
                dataProp="basket"
                onResult={this.onResult}
                onResultReturnData={onResultReturnData}
                fetchPolicy="network-only"
                renderFetchedData={this.renderBasketLineItems}
            />
        );
    }
}
