import gql from 'graphql-tag';

export const ES_PAGES_QUERY = gql`
    query Pages($esRequest: JSON) {
        pages(filter: { esRequest: $esRequest }) {
            data {
                _id
                _source {
                    customerVisible
                    content {
                        title
                        wordCount
                        fullText
                        isTextDocument
                        managedContentType
                        lindeDocTypeText
                        lindeDiscplineText
                        lindeDocColor
                    }
                    equipment {
                        tag
                        tagNumberAsId
                    }
                    image {
                        imageKeyL
                        imageKeyS
                    }
                    plant {
                        id
                        name
                        s3Folder
                    }
                    meta {
                        original_filename
                        original_filekey
                        page
                        pageCount
                    }
                }
                _score
                highlight
            }
            totalCount
            from
            size
        }
    }
`;

export const ES_PAGES_BY_DOCUMENT_QUERY = gql`
    query PagesByDocument($esRequest: JSON) {
        pagesByDocument(filter: { esRequest: $esRequest }) {
            data {
                key
                pageHits
                totalCount
                pages {
                    _id
                    _source {
                        indexState
                        customerVisible
                        fileMd5
                        content {
                            title
                            wordCount
                            fullText
                            isTextDocument
                            managedContentType
                            lindeDocTypeText
                            lindeDiscplineText
                            lindeDocColor
                        }
                        equipment {
                            tag
                            tagNumberAsId
                            designation
                        }
                        image {
                            imageKeyL
                            imageKeyS
                        }
                        plant {
                            id
                            name
                            s3Folder
                        }
                        meta {
                            original_filename
                            original_filekey
                            page
                            pageCount
                            folder
                        }
                        spindexDocument {
                            s3Key
                            title
                            folder
                            lindeDisciplineCode
                            lindeDocTypeCode
                            csvRowIndex
                        }
                    }
                    _score
                    highlight
                }
            }
            aggregations
            totalCount
            totalPageHits
            from
            size
        }
    }
`;

export const ES_DOCUMENT_PAGE_QUERY = gql`
    query PagesDocument($esRequest: JSON, $query: String) {
        pages(filter: { esRequest: $esRequest }, query: $query) {
            data {
                _id
                _source {
                    processedDate
                    customerVisible
                    content {
                        title
                        # added isStandardDocument this to bypass errors when there is no title, resulting in no content from es
                        isStandardDocument
                        isTextDocument
                        fullText
                        words {
                            text
                            boundingBox
                            textVariations
                        }
                        skippedOcr
                        mergedOcr
                        detectedTags {
                            text
                            boundingBox
                            textVariations
                        }
                    }
                    equipment {
                        tag
                        tagNumberAsId
                    }
                    image {
                        imageKeyL
                    }
                    meta {
                        original_filename
                        original_filekey
                        page
                        pageCount
                    }
                    spindexDocument {
                        s3Key
                        title
                        folder
                        lindeDisciplineCode
                        lindeDocTypeCode
                        csvRowIndex
                    }
                }
                highlight
            }
            totalCount
            pageHits
        }
    }
`;

export const ES_PAGES_FILTERS_QUERY = gql`
    query PagesFilter($esRequest: JSON) {
        pages(filter: { esRequest: $esRequest }) {
            aggregations
        }
    }
`;

export const ES_PAGES_QUERY_COUNT = gql`
    query PagesCount($esRequest: JSON) {
        pages(filter: { esRequest: $esRequest }) {
            totalCount
        }
    }
`;

export const FOLDER_STRUCTURE_QUERY = gql`
    query FolderStructureForPlant($plantId: String!) {
        folderStructureForPlant(plantId: $plantId) {
            id
            name
            childIds
            root
        }
    }
`;

export const GET_LINKED_PARTS_FOR_DOCUMENT_QUERY = gql`
    query GetLinkedPartsForDocument($plantId: String!, $s3Key: String!) {
        getLinkedPartsForDocument(plantId: $plantId, s3Key: $s3Key) {
            id
            part {
                id
                tagNumber
                designation
                tagNumberAsId
            }
            document {
                s3Key
            }
            pageNumber
            createdBy {
                id
            }
            updatedBy {
                id
            }
            createdAt
            updatedAt
        }
    }
`;

export const DELETE_DOCUMENT_MUTATION = gql`
    mutation DeleteDocument($plantId: String!, $s3Key: String!) {
        deleteDocument(plantId: $plantId, s3Key: $s3Key)
    }
`;
