import React from 'react';

interface IProps {
    onLoadMore: () => void;
    resizeIfNotOverFlow?: boolean;
    fitInnerHeight?: boolean;
}

interface IState {
    height?: string;
}

const OFFSET = 50;

class InfinityScroller extends React.Component<IProps, IState> {
    private ref: React.RefObject<HTMLDivElement> = React.createRef();
    public state: IState = {};

    public componentDidMount = () => {
        if (this.ref.current) {
            this.checkIfNeedsToLoadMore();
        }
    };

    private checkIfNeedsToLoadMore = () => {
        // if the thing is full height, try to load more
        if (this.ref.current.clientHeight === this.ref.current.scrollHeight) {
            this.props.onLoadMore();
        }
    };

    private onScroll = ({ currentTarget }: any) => {
        if (
            currentTarget.scrollTop + currentTarget.clientHeight >=
            currentTarget.scrollHeight - OFFSET
        ) {
            this.props.onLoadMore();
        }
    };

    public render() {
        return (
            <div
                className="infinity-scroller mt-2"
                ref={this.ref}
                onScroll={this.onScroll}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default InfinityScroller;
