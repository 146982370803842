import { Control, Field, Label, Textarea } from 'rbx';
import * as React from 'react';

import IBasketModel from '../../../models/IBasketModel';
import IsAdminQuery from '../../auth/IsAdminQuery';
import PurchaseStateSelectMenu from '../PurchaseStateSelectMenu';
import ShippingTermsPopover from '../ShippingTermsPopover';
import DatePicker from './../../ui/datePicker/DatePicker';
import FormField from './../../ui/FormField';
import LabelSelectMenu from './../LabelSelectMenu';
import ShippingTermSelectMenu from './../ShippingTermsSelectMenu';

interface IProps {
    basket: IBasketModel;
    onUpdate: (basket: IBasketModel) => void;
}

export default class BasketEditForm extends React.Component<IProps, any> {
    private updateDetails = (name: string) => (value: string) => {
        const { basket } = this.props;

        this.props.onUpdate({
            ...basket,
            [name]: value,
        });
    };

    private updateTextarea = (name: string) => (context: any) => {
        const { basket } = this.props;

        this.props.onUpdate({
            ...basket,
            [name]: context.currentTarget.value,
        });
    };

    private renderNotes = () => {
        const { leNote } = this.props.basket;

        return (
            <Field>
                <Label>Notes</Label>
                <Control expanded={true}>
                    <Textarea
                        value={leNote || ''}
                        placeholder="Notes"
                        onChange={this.updateTextarea('leNote')}
                    />
                </Control>
            </Field>
        );
    };

    private renderPurchaseStateSelect = () => {
        const { purchaseState } = this.props.basket;
        return (
            <Field>
                <Label>PurchaseState</Label>

                <Control expanded={true}>
                    <PurchaseStateSelectMenu
                        defaultSelected={purchaseState}
                        onUpdate={this.updateDetails('purchaseState')}
                    />
                </Control>
            </Field>
        );
    };

    public render() {
        const {
            name,
            description,
            deadline,
            label,
            shippingTerm,
            customerReference,
            id,
            createdBy,
        } = this.props.basket;

        const createdName: string = createdBy === null ? 'UNKNOWN' : createdBy.name;

        return (
            <>
                <FormField label="Reference" value={id.toUpperCase()} disabled={true} />
                <FormField label="Created By" value={createdName} disabled={true} />

                <FormField
                    label="Name"
                    value={name}
                    onChange={this.updateDetails('name')}
                    placeholder="Basket name"
                />
                <Field>
                    <Label>Basket Description</Label>
                    <Control expanded={true}>
                        <Textarea
                            value={description || ''}
                            placeholder="What is this basket for?"
                            onChange={this.updateTextarea('description')}
                        />
                    </Control>
                </Field>
                <Field>
                    <Label>Delivery Date</Label>
                    <Control expanded={true}>
                        <DatePicker
                            onChange={this.updateDetails('deadline')}
                            defaultDate={deadline}
                        />
                    </Control>
                </Field>
                <Field>
                    <Label>Label</Label>
                    <Control expanded={true}>
                        <LabelSelectMenu
                            defaultSelected={label}
                            onUpdate={this.updateDetails('label')}
                        />
                    </Control>
                </Field>

                <IsAdminQuery renderAdmin={this.renderPurchaseStateSelect} />

                <Field>
                    <Label>
                        Shipping Terms <ShippingTermsPopover />
                    </Label>
                    <Control expanded={true}>
                        <ShippingTermSelectMenu
                            defaultSelected={shippingTerm}
                            onUpdate={this.updateDetails('shippingTerm')}
                        />
                    </Control>
                </Field>
                <FormField
                    label="Customer Reference number"
                    value={customerReference || ''}
                    onChange={this.updateDetails('customerReference')}
                    placeholder="Reference"
                />
                <IsAdminQuery renderAdmin={this.renderNotes} />
            </>
        );
    }
}
