import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

import { UPDATE_PART_DOCUMENT_PAGE_MUTATION } from '../../../api/GraphQLQueries/Part';
import { DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY } from '../../../api/GraphQLQueries/Tag';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    renderButtons: any;
    plantId: string;
    tagNumberAsId?: string;
}
interface IState {
    error: boolean;
    success: boolean;
}
const resetState = {
    error: false,
    success: false,
};

class ToggleCustomerVisibleMutation extends Component<IProps, IState> {
    public state: IState = {
        ...resetState,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({ ...prevState, ...resetState }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = () => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    public render() {
        const { renderButtons, plantId, tagNumberAsId } = this.props;
        const { error, success } = this.state;

        const refetchQueries =
            plantId && tagNumberAsId
                ? [
                      {
                          query: DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY,
                          variables: { plantId, tagNumberAsId },
                      },
                  ]
                : [];

        return (
            <>
                {error && <Error>Error Updating Document Visibility</Error>}
                {success && (
                    <Message type="success">Successfully Updating Document Visibility</Message>
                )}
                <Mutation
                    mutation={UPDATE_PART_DOCUMENT_PAGE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={refetchQueries}
                >
                    {doToggle => <>{renderButtons(doToggle)}</>}
                </Mutation>
            </>
        );
    }
}

export default ToggleCustomerVisibleMutation;
