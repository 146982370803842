import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import Routing from '../../Routing';
import { GROUP_UPDATE_MUTATION } from './../../api/GraphQLQueries/Group';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import Pane from './../../components/ui/Pane';
import IGroupModel, { IGroupUpdateModel } from './../../models/IGroupModel';
import { todo } from './../../utils/translate';
import GroupUpdateForm from './forms/GroupUpdateForm';
import GroupDeleteMutation from './queries/GroupDeleteMutation';
import GroupDetailQuery from './queries/GroupDetailQuery';

interface IProps {
    idGroup: number;
    onCancel: () => void;
    onDeleteGroup: () => void;
    onSuccess?: () => void;
}

interface IState {
    group?: IGroupUpdateModel;
    error: boolean;
    success: boolean;
}

export default class GroupUpdate extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
        }));

        if (typeof this.props.onSuccess === 'function') {
            this.props.onSuccess();
        }
    };

    private updateValues = (group: IGroupModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            group,
            error: false,
            success: false,
        }));
    };

    private formSubmit = (doGroupUpdateMutation: MutationFn) => (): void => {
        if (!this.state.group) {
            return;
        }
        const {
            __typename,
            parent,
            parentId,
            groupIds,
            plantIds,
            memberships,
            groups,
            plants,
            ...group
        } = this.state.group as any;

        if (Array.isArray(groupIds)) {
            group.groupIds = groupIds;
        }
        if (Array.isArray(plantIds)) {
            group.plantIds = plantIds;
        }
        if (typeof parentId !== 'undefined') {
            group.parentId = parentId;
        }

        doGroupUpdateMutation({ variables: { group } });
    };

    private buildGroupUpdateModel = (group: IGroupModel): IGroupUpdateModel => {
        const parentId = group.parent ? group.parent.id : null;

        return {
            ...group,
            parentId: parentId as any,
        };
    };

    private renderGroupUpdateForm = (group: IGroupModel, doGroupUpdate: MutationFn) => {
        const { onDeleteGroup, onCancel } = this.props;

        const updateGroup = (
            <Button onClick={this.formSubmit(doGroupUpdate)} disabled={!this.state.group}>
                Save
            </Button>
        );

        const deleteGroup = <GroupDeleteMutation id={group.id} onSuccess={onDeleteGroup} />;

        const groupValues = this.buildGroupUpdateModel(group);

        return (
            <Pane
                onClose={onCancel}
                actionButtons={updateGroup}
                title={todo('Edit group')}
                sidepane={true}
                dangerButton={deleteGroup}
            >
                <GroupUpdateForm group={groupValues} onUpdate={this.updateValues} />
            </Pane>
        );
    };

    private renderGroupUpdateMutation = (group: IGroupModel) => {
        return (
            <Mutation
                mutation={GROUP_UPDATE_MUTATION}
                onError={this.onError}
                onCompleted={this.onCompleted}
            >
                {doGroupUpdate => this.renderGroupUpdateForm(group, doGroupUpdate)}
            </Mutation>
        );
    };

    private renderGroupForm = () => {
        return (groupData: IGroupModel) =>
            groupData ? (
                this.renderGroupUpdateMutation(this.state.group ? this.state.group : groupData)
            ) : (
                <Redirect to={Routing.GROUPS.route} />
            );
    };

    public render() {
        const { idGroup } = this.props;
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>Group update error</Error>}
                {success && <Message type="success">Successfully updated group</Message>}
                {idGroup && (
                    <GroupDetailQuery
                        id={idGroup}
                        renderFetchedGroupData={this.renderGroupForm()}
                    />
                )}
            </>
        );
    }
}
