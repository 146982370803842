import { Button, Control, Delete, Field, Label, Modal } from 'rbx';
import * as React from 'react';

import { IMembershipSaveModel } from '../../models/IMembershipModel';
import GroupPlantSelectMenu from '../group/GroupPlantSelectMenu';
import RoleSelectMenu from '../role/RoleSelectMenu';
import ModalContainer from '../ui/ModalContainer';

interface IProps {
    name: string;
    membership?: IMembershipSaveModel;
    onUpdate: (membership: IMembershipSaveModel) => void;
    onSubmit: (fn: any) => void;
    onClose: () => void;
    showGroupPlantSelectMenu?: boolean;
}

const overflowable = {
    overflow: 'visible',
};

export default class UserSaveMembership extends React.Component<IProps, any> {
    private closeModal = () => {
        this.props.onClose();
    };

    private groupPlantChanged = (membership: IMembershipSaveModel) => {
        const { plantId, groupId } = membership;

        const newMembership = {
            ...this.props.membership,
            groupId,
            plantId,
        };

        this.props.onUpdate(newMembership);
    };

    private roleChanged = (role: string) => {
        const newMembership = {
            ...this.props.membership,
            role,
        };

        this.props.onUpdate(newMembership);
    };

    private isSubmitable = () => {
        const { membership } = this.props;

        if (!membership) {
            return false;
        }

        const { role, groupId, plantId } = membership;

        return !!role && (!!groupId || !!plantId);
    };

    public render() {
        const { name, onSubmit, showGroupPlantSelectMenu, membership } = this.props;
        const disabled = !this.isSubmitable();
        return (
            <ModalContainer isOpen={true} onRequestClose={this.closeModal}>
                <Modal.Card style={overflowable}>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Save membership for {name}</Modal.Card.Title>
                        <Delete onClick={this.closeModal} />
                    </Modal.Card.Head>
                    <Modal.Card.Body style={overflowable}>
                        {showGroupPlantSelectMenu && (
                            <Field horizontal={true}>
                                <Field.Label size="normal">
                                    <Label>Group/Plant</Label>
                                </Field.Label>
                                <Field.Body>
                                    <Field>
                                        <Control expanded={true}>
                                            <GroupPlantSelectMenu
                                                onUpdate={this.groupPlantChanged}
                                            />
                                        </Control>
                                    </Field>
                                </Field.Body>
                            </Field>
                        )}
                        <Field horizontal={true}>
                            <Field.Label size="normal">
                                <Label>Role</Label>
                            </Field.Label>
                            <Field.Body>
                                <Field>
                                    <Control expanded={true}>
                                        <RoleSelectMenu
                                            onUpdate={this.roleChanged}
                                            defaultSelected={
                                                membership ? membership.role : undefined
                                            }
                                        />
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                    </Modal.Card.Body>
                    <Modal.Card.Foot align="right">
                        <Button onClick={this.closeModal}>Cancel</Button>
                        <Button color="primary" onClick={onSubmit} disabled={disabled}>
                            Save
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </ModalContainer>
        );
    }
}
