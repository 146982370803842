import { Table } from 'rbx';
import * as React from 'react';

const LOADING = 'Loading...';

export default class Loading extends React.Component<any, any> {
    private renderLoading = () => {
        const { renderAs } = this.props;

        if (renderAs === 'Table.Cell') {
            return <Table.Cell>{LOADING}</Table.Cell>;
        }

        return (
            <div className="fa-3x has-text-centered">
                <i className="fas fa-spinner fa-spin" />
            </div>
        );
    };

    public render() {
        return this.renderLoading();
    }
}
