import * as _ from 'lodash';
import { Button, Column, Icon, Level, Message, Table, Tag } from 'rbx';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ILineItemModel, { getLabelByValue } from '../../models/ILineItemModel';
import Routing from '../../Routing';
import { getBasketStatusText } from '../../utils/helpers';
import NavButton from '../nav/NavButton';
import IBasketModel from './../../models/IBasketModel';
import { buildPath, getUrlParams, parseDocumentId, parseTagId } from './../../utils/queryString';
import DocumentHandler from './../documentviewer/DocumentHandler';
import TagViewer from './../tag/TagViewer';
import Pane from './../ui/Pane';
import AddLineItemToBasketModal from './AddLineItemToBasketModal';
import BasketDetails from './BasketDetails';
import BasketPlainTextCopyModal from './BasketPlainTextCopyModal';
import EditLineItemModal from './EditLineItemModal';
import BasketLineItemListQuery from './queries/BasketLineItemListQuery';
import BasketLineItemQuantityUpdateMutation from './queries/BasketLineItemQuantityUpdateMutation';
import LineItemDeleteMutation from './queries/LineItemDeleteMutation';
import LineItemDetailQuery from './queries/LineItemDetailQuery';

interface ILineItemReference {
    onClick: any;
}

interface IRouteParams {
    basketId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    basket: IBasketModel;
}

interface IState {
    showEditLineItem: boolean;
    showAddLineItem: boolean;
    showPlainTextModal: boolean;
    selectedLineItemId?: number;
    partIdsInBasket: string[];
    forceRerenderKey: number;
    lineItems: ILineItemModel[];
}

class BasketSummary extends React.Component<IProps, IState> {
    public state: IState = {
        showEditLineItem: false,
        showAddLineItem: false,
        showPlainTextModal: false,
        partIdsInBasket: [],
        forceRerenderKey: 0,
        lineItems: [],
    };

    private showEditLineItem = (selectedLineItemId: number) => () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showEditLineItem: true,
            selectedLineItemId,
        }));
    };

    private showDocument = (link: string) => () => {
        if (link) {
            this.props.history.push(link);
        }
    };

    private closeDocumentViewerPane = () => {
        const pathInfo = getUrlParams(this.props.location);
        pathInfo.params.docId = undefined;

        const path = buildPath(pathInfo);

        this.props.history.push(path);
    };

    private closeTagViewerPane = () => {
        const pathInfo = getUrlParams(this.props.location);
        pathInfo.params.tagId = undefined;

        const path = buildPath(pathInfo);

        this.props.history.push(path);
    };

    private renderDocumentViewerPane = () => {
        const docId = parseDocumentId(this.props.location);

        return docId && <DocumentHandler key={docId} onClose={this.closeDocumentViewerPane} />;
    };

    private renderTagViewerPane = () => {
        const tagId = parseTagId(this.props.location);
        const { basket } = this.props;
        const { basketId } = this.props.match.params;
        const { partIdsInBasket } = this.state;

        return (
            tagId &&
            basket &&
            basket.plant.id && (
                <TagViewer
                    defaultSelectedBasketId={basketId}
                    defaultSelected={partIdsInBasket}
                    tagNumberAsId={tagId}
                    plantId={basket.plant.id}
                    onClose={this.closeTagViewerPane}
                />
            )
        );
    };

    private getRelatedTagNumberAsId = (item: ILineItemModel) => {
        if (item.isWholeComponent && item.part && item.part.tagNumberAsId) {
            return item.part.tagNumberAsId;
        } else if (item.parentPart && item.parentPart.tagNumberAsId) {
            return item.parentPart.tagNumberAsId;
        }

        return;
    };

    private getLineItemReference = (item: ILineItemModel): ILineItemReference | undefined => {
        if (item.filename && item.pageKey) {
            const pathInfo = getUrlParams(this.props.location);
            pathInfo.params.docId = `${item.filename}_${item.pageKey}`;
            pathInfo.params.tagId = undefined;
            pathInfo.params.lineItemId = item.id;

            return {
                onClick: this.showDocument(buildPath(pathInfo)),
            };
        } else if (item.part) {
            const pathInfo = getUrlParams(this.props.location);
            pathInfo.params.docId = undefined;
            pathInfo.params.tagId = this.getRelatedTagNumberAsId(item);

            return {
                onClick: this.showDocument(buildPath(pathInfo)),
            };
        }

        return;
    };

    private renderLineItem = (item: ILineItemModel) => {
        const { basketId } = this.props.match.params;
        const reference = this.getLineItemReference(item);

        return (
            <Table.Row key={item.id}>
                <Table.Cell>{getLabelByValue(item.requestType)}</Table.Cell>
                <Table.Cell onClick={reference ? reference.onClick : undefined}>
                    {reference && (
                        <Button color="primary" size="small">
                            View
                        </Button>
                    )}
                </Table.Cell>
                <Table.Cell>
                    {item.isWholeComponent && (
                        <div className="icon">
                            <i className="fa fa-tools" />
                        </div>
                    )}
                    <strong>{item.title}</strong>
                    <br />
                    {item.description}
                </Table.Cell>
                <Table.Cell>
                    <BasketLineItemQuantityUpdateMutation
                        lineItemId={item.id}
                        quantity={item.quantity}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Button size="small" text={true} onClick={this.showEditLineItem(item.id)}>
                        Edit
                    </Button>
                    <br />
                    <LineItemDeleteMutation id={item.id} basketId={basketId} />
                </Table.Cell>
            </Table.Row>
        );
    };

    private getLineItemPartIds = (lineItems: ILineItemModel[]) => {
        const partLineItems = lineItems.filter(item => item.part);
        return partLineItems.map(item => item.part!.id);
    };

    private setLineItemsPartIds = (lineItems: ILineItemModel[]) => {
        if (!Array.isArray(lineItems) || lineItems.length < 1) {
            return;
        }

        const basketPartIds = this.getLineItemPartIds(lineItems) || [];

        if (!_.isEqual(this.state.partIdsInBasket, basketPartIds)) {
            this.setState((prevState: IState) => ({
                ...prevState,
                partIdsInBasket: basketPartIds,
            }));
        }
        if (!_.isEqual(this.state.lineItems, lineItems)) {
            this.setState((prevState: IState) => ({
                ...prevState,
                lineItems,
            }));
        }
    };

    private getWholeComponentItems = (lineItems: ILineItemModel[]) => {
        return lineItems.filter(i => i.part && !i.parentPart) || [];
    };

    private getSparePartsForWholeComponentItems = (
        lineItems: ILineItemModel[],
        wholeComponentItems: ILineItemModel[],
    ) => {
        return lineItems.filter(
            i => i.parentPart && wholeComponentItems.find(w => i.parentPart!.id === w.part!.id),
        );
    };

    private getSparePartsWithoutWholeComponentItems = (
        lineItems: ILineItemModel[],
        wholeComponentItems: ILineItemModel[],
    ) => {
        return lineItems.filter(
            i => i.parentPart && !wholeComponentItems.find(w => i.parentPart!.id === w.part!.id),
        );
    };

    private getManuallyAddedItems = (lineItems: ILineItemModel[]) => {
        return lineItems.filter(i => !i.part);
    };

    private getSparePartsForComponent = (
        componentLineItem: ILineItemModel,
        partBelongsToComponentsItems: ILineItemModel[],
    ) => {
        return partBelongsToComponentsItems.filter(
            i => i.parentPart!.id === componentLineItem.part!.id,
        );
    };

    private buildWholeComponentOrder = (
        wholeComponentItems: ILineItemModel[],
        partBelongsToComponentsItems: ILineItemModel[],
    ) => {
        const orderedComponentsAndChildItems: ILineItemModel[] = [];

        wholeComponentItems.forEach(item => {
            orderedComponentsAndChildItems.push(item);
            const childParts = this.getSparePartsForComponent(item, partBelongsToComponentsItems);
            childParts.forEach(part => orderedComponentsAndChildItems.push(part));
        });

        return orderedComponentsAndChildItems;
    };

    private orderLineItems = (lineItems: ILineItemModel[]) => {
        const wholeComponentItems = this.getWholeComponentItems(lineItems);
        const partBelongsToComponentsItems = this.getSparePartsForWholeComponentItems(
            lineItems,
            wholeComponentItems,
        );
        const partWithoutComponentsItems = this.getSparePartsWithoutWholeComponentItems(
            lineItems,
            wholeComponentItems,
        );
        const manuallyAddedItems = this.getManuallyAddedItems(lineItems);

        const orderedComponentsAndChildItems = this.buildWholeComponentOrder(
            wholeComponentItems,
            partBelongsToComponentsItems,
        );

        return orderedComponentsAndChildItems.concat(
            partWithoutComponentsItems,
            manuallyAddedItems,
        );
    };

    private renderLineItems = (lineItems: ILineItemModel[]) => {
        const { basket } = this.props;
        if (!Array.isArray(lineItems) || lineItems.length < 1) {
            return (
                <div className="has-text-centered">
                    <p>Search your plant to add items to the basket.</p>
                    <br />
                    <NavButton
                        color="primary"
                        size="normal"
                        to={Routing.SEARCH_PLANT.getPath(`${basket.plant.id}`)}
                    >
                        Search Now
                    </NavButton>
                    &nbsp;&nbsp;&nbsp;
                    <Button color="primary" onClick={this.toggleAddLineItem}>
                        Add line item
                    </Button>
                </div>
            );
        }

        const orderedItems = this.orderLineItems(lineItems);
        const { showPlainTextModal } = this.state;

        return (
            <>
                <Table fullwidth={true}>
                    <Table.Head>
                        <Table.Row>
                            <Table.Heading>Request Type</Table.Heading>
                            <Table.Heading>Reference</Table.Heading>
                            <Table.Heading>Details</Table.Heading>
                            <Table.Heading>Quantity</Table.Heading>
                            <Table.Heading onClick={this.togglePlainTextModal}>
                                <div className="icon">
                                    <i className="fa fa-clipboard" />
                                </div>
                            </Table.Heading>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>{orderedItems.map(item => this.renderLineItem(item))}</Table.Body>
                </Table>
                {showPlainTextModal && this.renderPlainTextModal(orderedItems)}
            </>
        );
    };

    private toggleAddLineItem = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showAddLineItem: !prevState.showAddLineItem,
        }));
    };

    private hideEditLineItem = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showEditLineItem: false,
            selectedLineItemId: undefined,
        }));
    };

    private toggleForceRerenderKey = (prevKey: number): number => {
        return prevKey < 1 ? 1 : 0;
    };

    private editLineItemSuccess = () => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                forceRerenderKey: this.toggleForceRerenderKey(prevState.forceRerenderKey),
            }),
            this.hideEditLineItem,
        );
    };

    private renderEditLineItem = (item: ILineItemModel) => {
        return (
            item && (
                <EditLineItemModal
                    data={item}
                    onClose={this.hideEditLineItem}
                    onSuccess={this.editLineItemSuccess}
                />
            )
        );
    };

    private togglePlainTextModal = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showPlainTextModal: !prevState.showPlainTextModal,
        }));
    };

    private renderPlainTextModal = (orderedLineItems: ILineItemModel[]) => {
        return (
            orderedLineItems && (
                <BasketPlainTextCopyModal
                    onClose={this.togglePlainTextModal}
                    orderedLineItems={orderedLineItems}
                />
            )
        );
    };

    private renderPaneHeader = () => {
        const { basket } = this.props;
        return (
            <>
                <span className="pane-title">Basket items</span>
                <Tag color="info" size="medium">
                    <Icon>
                        <i className="fa fa-industry" />
                    </Icon>
                    <span>{basket.plant.customerDisplayName || basket.plant.name}</span>
                </Tag>
            </>
        );
    };

    private mapStatusMessage = () => {
        const { status } = this.props.basket;
        return getBasketStatusText(status);
    };

    public render() {
        const { basketId } = this.props.match.params;
        const { basket } = this.props;
        const {
            showAddLineItem,
            showEditLineItem,
            forceRerenderKey,
            selectedLineItemId,
            lineItems,
        } = this.state;

        return (
            <>
                <Column.Group clipped={true}>
                    <Column size="two-thirds">
                        <Pane headerContent={this.renderPaneHeader}>
                            <BasketLineItemListQuery
                                key={forceRerenderKey}
                                id={basketId}
                                onResult={this.setLineItemsPartIds}
                                onResultReturnData={true}
                                renderFetchedLineItems={this.renderLineItems}
                            />
                            <hr />
                            <Level>
                                <Level.Item align="left" />
                                <Level.Item align="right">
                                    {!_.isEmpty(lineItems) && (
                                        <Button.Group>
                                            {basket && basket.plant.id && (
                                                <NavButton
                                                    color="primary"
                                                    to={Routing.SEARCH_PLANT.getPath(
                                                        `${basket.plant.id}`,
                                                    )}
                                                >
                                                    Search
                                                </NavButton>
                                            )}

                                            <Button
                                                color="primary"
                                                onClick={this.toggleAddLineItem}
                                            >
                                                Add line item
                                            </Button>
                                        </Button.Group>
                                    )}
                                </Level.Item>
                            </Level>
                        </Pane>
                    </Column>

                    <Column clipped={true}>
                        <Pane title={`Basket details (${this.mapStatusMessage()})`}>
                            {this.props.basket.status === 'draft' && (
                                <Message>
                                    <Message.Body>
                                        While the basket is in "draft" status, only your team can
                                        see it. Click on "Submit basket" to submit it to the
                                        PLANTSERV® Team.
                                    </Message.Body>
                                </Message>
                            )}
                            <BasketDetails basket={this.props.basket} />
                        </Pane>
                    </Column>
                </Column.Group>

                {showEditLineItem && selectedLineItemId && (
                    <LineItemDetailQuery
                        id={selectedLineItemId}
                        renderFetchedItemData={this.renderEditLineItem}
                    />
                )}
                {showAddLineItem && (
                    <AddLineItemToBasketModal
                        data={{ basketId }}
                        onClose={this.toggleAddLineItem}
                        onSuccess={this.toggleAddLineItem}
                        disableBasketSelect={true}
                    />
                )}
                {/* IMPORTANT: don't change the order of the render stuff below! */}
                {/* It's possible to open doc viewer from inside the tag viewer */}
                {/* and the tag viewer should be under the doc viewer. */}
                {this.renderTagViewerPane()}
                {this.renderDocumentViewerPane()}
            </>
        );
    }
}

export default withRouter(BasketSummary);
