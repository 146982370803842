import * as _ from 'lodash';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { PART_CREATE_MUTATION } from './../../api/GraphQLQueries/Part';
import { PARTS_BY_TAG_NUMBER_QUERY } from './../../api/GraphQLQueries/Tag';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import IPartModel, { IPartCreateModel } from './../../models/IPartModel';
import PartEditModal from './PartEditModal';

interface IProps {
    parent: IPartModel;
    onCancel: () => void;
    onSuccess: () => void;
}

interface IState {
    part: IPartCreateModel;
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

class PartCreate extends React.Component<IProps, any> {
    public state: IState = {
        ...resetState,
        part: {},
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            () => this.resetState(),
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            () => {
                this.resetState();
                this.props.onSuccess();
            },
        );
    };

    private updateValues = (part: IPartCreateModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            part,
            error: false,
            success: false,
        }));
    };

    private formSubmit = (doPartCreateMutation: MutationFn) => (): void => {
        const part = {
            ...this.state.part,
            parentIds: [this.props.parent.id],
        };

        doPartCreateMutation({ variables: { part } });
    };

    public render = () => {
        const { error, success, part } = this.state;
        const { onCancel, parent } = this.props;

        const disableSave = !part || _.isEmpty(part);

        return (
            <>
                {error && <Error>Part create error</Error>}
                {success && <Message type="success">Successfully created part</Message>}
                <Mutation
                    mutation={PART_CREATE_MUTATION}
                    onError={this.onError}
                    onCompleted={this.onCompleted}
                    refetchQueries={[
                        {
                            query: PARTS_BY_TAG_NUMBER_QUERY,
                            variables: {
                                plantId: parent.plant.id,
                                tagNumberAsId: parent.tagNumberAsId,
                            },
                        },
                    ]}
                    awaitRefetchQueries={true}
                >
                    {doPartCreate => (
                        <PartEditModal
                            part={part}
                            onUpdate={this.updateValues}
                            onSubmit={this.formSubmit(doPartCreate)}
                            onClose={onCancel}
                            disableSave={disableSave}
                            createMode={true}
                            modalTitle="Create Part"
                        />
                    )}
                </Mutation>
            </>
        );
    };
}

export default PartCreate;
