import * as React from 'react';

import { PART_QUERY } from '../../../api/GraphQLQueries/Part';
import DetailQueryContainer from '../../../containers/common/DetailQueryContainer';
import IPartModel from './../../../models/IPartModel';

interface IProps {
    id: string;
    renderFetchedPartData: (part: IPartModel) => JSX.Element;
}

export default class PartDetailQuery extends React.Component<IProps, any> {
    public render = () => {
        const { id, renderFetchedPartData } = this.props;

        const variables = { id };

        return (
            <DetailQueryContainer
                query={PART_QUERY}
                variables={variables}
                dataProp="part"
                renderFetchedData={renderFetchedPartData}
            />
        );
    };
}
