import { Button, Delete, Modal } from 'rbx';
import * as React from 'react';

import ModalContainer from '../ui/ModalContainer';

interface IProps {
    title: string;
    onSubmit: (plantId: string) => void;
    onClose?: () => void;
    renderBody?: (handleChange: any) => void;
    doubleCheck?: boolean;
    bodyText?: string;
    alertMessage?: string;
    requireInput?: boolean;
}

interface IState {
    open: boolean;
    hasInput: boolean;
    plantId: string;
}

const resetState = {
    open: false,
    hasInput: false,
    plantId: null,
};

export default class OpsModal extends React.Component<IProps, IState> {
    public state: IState = { ...resetState };

    private closeModal = (): void => {
        this.setState({ open: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    private openModal = (): void => {
        this.setState({ open: true });
    };

    private submit = (): void => {
        const { onSubmit, doubleCheck, alertMessage } = this.props;
        const { plantId } = this.state;

        if (
            doubleCheck &&
            !window.confirm(alertMessage || 'Are you sure you want to delete plant pages?')
        ) {
            return this.closeModal();
        }

        onSubmit(plantId);
        this.closeModal();
    };

    private handleChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        this.setState({
            plantId: event.currentTarget.value,
            hasInput: !!event.currentTarget.value,
        });
    };

    public render() {
        const { title, renderBody, bodyText, requireInput } = this.props;
        const { open, hasInput } = this.state;

        return (
            <>
                <Button onClick={this.openModal}>{title}</Button>
                <ModalContainer isOpen={open} onRequestClose={this.closeModal}>
                    <Modal.Card>
                        <Modal.Card.Head>
                            <Modal.Card.Title>{title}</Modal.Card.Title>
                            <Delete onClick={this.closeModal} />
                        </Modal.Card.Head>
                        {(renderBody && (
                            <Modal.Card.Body>{renderBody(this.handleChange)}</Modal.Card.Body>
                        )) ||
                            (bodyText && (
                                <Modal.Card.Body dangerouslySetInnerHTML={{ __html: bodyText }} />
                            ))}
                        <Modal.Card.Foot align="right">
                            <Button onClick={this.closeModal}>Cancel</Button>
                            <Button
                                color="primary"
                                disabled={requireInput && !hasInput}
                                onClick={this.submit}
                            >
                                Sure
                            </Button>
                        </Modal.Card.Foot>
                    </Modal.Card>
                </ModalContainer>
            </>
        );
    }
}
