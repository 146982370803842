import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { BASKET_QUERY, BASKET_UPDATE_STATUS_MUTATION } from './../../../api/GraphQLQueries/Basket';
import IsAdminQuery from './../../auth/IsAdminQuery';
import Error from './../../layout/Error';
import Message from './../../layout/Message';

interface IProps {
    basketId: string;
    basketStatus: string;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

const BASKET_STATES = [
    {
        status: 'draft',
        nextStatus: 'submitted',
        text: 'Submit Basket',
    },
    {
        status: 'submitted',
        nextStatus: 'locked',
        text: 'Lock Basket',
    },
    {
        status: 'locked',
        nextStatus: 'quoted',
        text: 'Request Quotation',
    },
    {
        status: 'quoted',
        nextStatus: 'completed',
        text: 'Complete',
    },
];

class BasketStatusMutation extends React.Component<IProps, IState> {
    public state: IState = {
        ...resetState,
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    private getNextStatus = () => {
        return BASKET_STATES.find(states => states.status === this.props.basketStatus);
    };

    private updateBasketStatus = (newStatus: string, doUpdate: MutationFn) => (): void => {
        const { basketId } = this.props;
        doUpdate({ variables: { id: basketId, status: newStatus } });
    };

    private renderMutation = () => {
        const { basketId } = this.props;

        const refetchQueries = basketId
            ? [{ query: BASKET_QUERY, variables: { id: basketId } }]
            : [];

        return (
            basketId && (
                <Mutation
                    mutation={BASKET_UPDATE_STATUS_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={refetchQueries}
                >
                    {doUpdate => {
                        const statusHandler = this.getNextStatus();
                        return (
                            statusHandler && (
                                <Button
                                    color="primary"
                                    data-cy="basketStatusMutation-submit-btn"
                                    onClick={this.updateBasketStatus(
                                        statusHandler.nextStatus,
                                        doUpdate,
                                    )}
                                >
                                    {statusHandler.text}
                                </Button>
                            )
                        );
                    }}
                </Mutation>
            )
        );
    };

    private renderAdminStatusUpdateMutation = () => {
        const { basketStatus } = this.props;
        return basketStatus !== 'completed' && this.renderMutation();
    };

    private renderUserStatusUpdateMutation = () => {
        const { basketStatus } = this.props;
        return basketStatus === 'draft' && this.renderMutation();
    };

    public render = () => {
        const { error, success } = this.state;
        const { basketId, basketStatus } = this.props;

        return (
            basketId &&
            basketStatus && (
                <>
                    {error && <Error>Basket status update error</Error>}
                    {success && (
                        <Message type="success">Successfully updated basket status</Message>
                    )}
                    <IsAdminQuery
                        renderAdmin={this.renderAdminStatusUpdateMutation}
                        renderUser={this.renderUserStatusUpdateMutation}
                    />
                </>
            )
        );
    };
}

export default BasketStatusMutation;
