import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { USER_CREATE_MUTATION } from './../../api/GraphQLQueries/User';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import Pane from './../../components/ui/Pane';
import { IUser } from './../../models/IUserModel';
import { todo } from './../../utils/translate';
import UserCreateForm from './forms/UserCreateForm';

interface IProps {
    onCancel: () => void;
    onSuccess: () => void;
}

interface IState {
    users: IUser[];
    error: boolean;
    success: boolean;
}

const userTemplate: IUser = {
    name: '',
    email: '',
};
const resetState = {
    error: false,
    success: false,
};

export default class UserCreate extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
        users: [{ ...userTemplate }],
    };

    private onError = () => {
        return;
    };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            ...resetState,
        }));
    };

    private setUsers = (users: IUser[]): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            users,
        }));
    };

    private addUserRow = (): void => {
        const users = [...this.state.users, { ...userTemplate }];

        this.setUsers(users);
    };

    private removeUserRow = (key: number): void => {
        const users = [...this.state.users];
        users.splice(key, 1);

        this.setUsers(users);
    };

    private updateValues = (user: IUser, key: number) => {
        const { users } = this.state;
        const { name, email } = user;

        users[key] = {
            name,
            email,
        };

        this.setUsers(users);
    };

    private formSubmit = async (doUserCreateMutation: MutationFn) => {
        const users = this.state.users.filter(({ name, email }) => name && email);
        const failedUsers: IUser[] = [];
        let success = false;

        for (const user of users) {
            const res = await this.doSingleMutation(user, doUserCreateMutation);
            if (!res) {
                failedUsers.push(user);
            } else {
                success = true;
            }
        }

        const failed = failedUsers.length > 0;

        this.setState(
            (prevState: IState) => ({
                ...prevState,
                users: failedUsers,
                success,
                error: failedUsers.length > 0,
            }),
            () => {
                this.resetState();
                if (!failed) {
                    this.props.onSuccess();
                }
            },
        );
    };

    private doSingleMutation = async (
        user: IUser,
        doUserCreateMutation: MutationFn,
    ): Promise<boolean> => {
        const res = await doUserCreateMutation({ variables: { user } });

        if (!res || !res.data || !res.data.createUser) {
            return false;
        }
        return true;
    };

    private renderUserCreateForm = (doUserCreate: MutationFn) => {
        const { onCancel } = this.props;
        const users = this.state.users.filter(user => user.name && user.email);

        const createUser = (
            <Button disabled={!users.length} onClick={this.formSubmit.bind(this, doUserCreate)}>
                {todo('Create user(s)')}
            </Button>
        );

        return (
            <Pane
                onClose={onCancel}
                actionButtons={createUser}
                title={todo('Add users')}
                sidepane={true}
            >
                <UserCreateForm
                    users={this.state.users}
                    onUpdate={this.updateValues}
                    onAddUser={this.addUserRow}
                    onRemoveUser={this.removeUserRow}
                />
            </Pane>
        );
    };

    public render() {
        const { error, success } = this.state;
        return (
            <>
                {error && <Error>Some users were not created</Error>}
                {success && <Message type="success">Successfully created users</Message>}
                <Mutation mutation={USER_CREATE_MUTATION} onError={this.onError}>
                    {doUserCreate => this.renderUserCreateForm(doUserCreate)}
                </Mutation>
            </>
        );
    }
}
