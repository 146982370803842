import classnames from 'classnames';
import * as _ from 'lodash';
import { Tag } from 'rbx';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import IsAdminQuery from '../auth/IsAdminQuery';
import { buildPath, getUrlParams } from './../../utils/queryString';

interface IProps extends RouteComponentProps {
    item: any;
    cy: string;
    hitCount?: number;
}

class SearchResultEquimentItem extends React.Component<IProps, any> {
    private selectDocument = (tagNumberAsId: string) => (): void => {
        const pathinfo = getUrlParams(this.props.location);
        pathinfo.params.tagId = tagNumberAsId;
        pathinfo.params.docId = undefined;

        const path = buildPath(pathinfo);
        this.props.history.push(path);
    };

    private customerVisibleTag = () => {
        const { item } = this.props;

        return (
            <Tag.Group className="result-tags">
                {item._source.customerVisible && (
                    <Tag>
                        <i className="fa fa-eye" />
                        Customer Visible
                    </Tag>
                )}
            </Tag.Group>
        );
    };

    public render() {
        const { item, cy } = this.props;
        const pathinfo = getUrlParams(this.props.location);

        let fullText = item._source.content.fullText;
        if (item.highlight && item.highlight['content.fullText']) {
            fullText = item.highlight['content.fullText'];
        }
        const { tagNumberAsId, tag, designation } = item._source.equipment;

        const onClick = tagNumberAsId ? this.selectDocument(tagNumberAsId) : undefined;

        const searchResultItemClasses = classnames({
            'search-result-item': true,
            'is-equipment': true,
            'is-active': pathinfo.params.tagId === tagNumberAsId,
        });

        return (
            <div className={searchResultItemClasses}>
                <div className="result-body">
                    <div className="result-image" onClick={onClick} data-cy={cy}>
                        <span className="fa-stack fa-2x">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-tools fa-stack-1x fa-inverse" />
                        </span>
                    </div>

                    <div className="result-details">
                        <div className="result-title mb-05" onClick={onClick}>
                            {tag.toUpperCase()} {designation && ` - ${designation}`}
                        </div>

                        <Tag.Group className="result-tags">
                            {item._source.content.lindeDocTypeText && (
                                <Tag>
                                    <i className="fa fa-book" />
                                    {item._source.content.lindeDocTypeText}
                                </Tag>
                            )}
                            <Tag color="link">
                                <i className="fa fa-tools" />
                                Equipment
                            </Tag>
                            {item._source.content.lindeDiscplineText && (
                                <Tag>
                                    <i className="fa fa-bookmark" />
                                    {item._source.content.lindeDiscplineText}
                                </Tag>
                            )}
                        </Tag.Group>
                        <IsAdminQuery renderAdmin={this.customerVisibleTag} />

                        <div
                            className="result-text"
                            dangerouslySetInnerHTML={{
                                __html: _.truncate(fullText, {
                                    length: 240,
                                }),
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SearchResultEquimentItem);
