import { Button, Icon } from 'rbx';
import * as React from 'react';

interface IProps {
    title: string;
}
interface IState {
    opened: boolean;
}

export default class Accordion extends React.Component<IProps, IState> {
    public state: IState = {
        opened: true,
    };

    private toggleCollapse = () => {
        this.setState({ opened: !this.state.opened });
    };

    public render() {
        const { title } = this.props;
        const { opened } = this.state;

        return (
            <div className="accordion">
                <div className="accordion-title" onClick={this.toggleCollapse}>
                    {title}

                    <Button size="small" outlined={true}>
                        <Icon>
                            <i className={`fa fa-caret-${opened ? 'down' : 'up'}`} />
                        </Icon>
                    </Button>
                </div>

                {opened && <div className="accordion-content">{this.props.children}</div>}
            </div>
        );
    }
}
