import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { DELETE_MEMBERSHIP_MUTATION } from '../../../api/GraphQLQueries/Membership';
import { USER_QUERY } from '../../../api/GraphQLQueries/User';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    id: number;
    userId?: number;
    buttonText?: string;
    onSuccess: () => void;
}

interface IState {
    error: boolean;
    success: boolean;
}

export default class MembershipDeleteMutation extends React.Component<IProps, IState> {
    public state: IState = {
        error: false,
        success: false,
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.props.onSuccess,
        );
    };

    private deleteMembership = (doMembershipDelete: MutationFn) => (): void => {
        const { id } = this.props;
        doMembershipDelete({ variables: { id } });
    };

    public render() {
        const { error, success } = this.state;
        const { buttonText, userId } = this.props;

        return (
            <>
                {error && <Error>User delete error</Error>}
                {success && <Message type="success">Successfully deleted membership</Message>}
                <Mutation
                    mutation={DELETE_MEMBERSHIP_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    awaitRefetchQueries={true}
                    refetchQueries={
                        userId ? [{ query: USER_QUERY, variables: { id: userId } }] : []
                    }
                >
                    {doMembershipDelete => (
                        <Button
                            type="button"
                            outlined={true}
                            color="danger"
                            onClick={this.deleteMembership(doMembershipDelete)}
                        >
                            {buttonText || 'Delete Membership'}
                        </Button>
                    )}
                </Mutation>
            </>
        );
    }
}
