import { Button, Delete, Modal, Table } from 'rbx';
import * as React from 'react';

import IPartModel from '../../models/IPartModel';
import PartDetailQuery from './../part/queries/PartDetailQuery';
import ModalContainer from './../ui/ModalContainer';

interface IProps {
    partId: string;
    type?: 'equipment' | 'part';
    isMarked?: boolean;
    disabled?: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export default class TagViewerPartModal extends React.Component<IProps, any> {
    private renderEquipment = (tag: IPartModel) => {
        const { onClose } = this.props;
        return (
            <>
                <Modal.Card.Head>
                    <Modal.Card.Title>Equipment Details</Modal.Card.Title>
                    <Delete onClick={onClose} />
                </Modal.Card.Head>
                <Modal.Card.Body>
                    <Table fullwidth={true}>
                        <Table.Head>
                            <Table.Row>
                                <Table.Heading>Property</Table.Heading>
                                <Table.Heading>Value</Table.Heading>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {this.renderRow('Tag Number', tag.tagNumber)}
                            {this.renderRow('Designation', tag.designation)}
                            {this.renderRow('Spec Number', tag.specNum)}
                            {this.renderRow('Linde ID', tag.lindeIdentNum)}
                            {this.renderRow('Type', tag.type)}
                            {this.renderRow('Serial Number', tag.serialNum)}
                            {this.renderRow('Drawing Number', tag.drawingNum)}
                            {this.renderRow('Original Order Nr', tag.originalOrderNum)}
                            {this.renderRow('Notes', tag.notes)}
                        </Table.Body>
                    </Table>
                </Modal.Card.Body>
            </>
        );
    };

    private renderPart = (tag: IPartModel) => {
        const { onClose } = this.props;
        return (
            <>
                <Modal.Card.Head>
                    <Modal.Card.Title>Part Details</Modal.Card.Title>
                    <Delete onClick={onClose} />
                </Modal.Card.Head>
                <Modal.Card.Body>
                    <Table fullwidth={true}>
                        <Table.Head>
                            <Table.Row>
                                <Table.Cell>Property</Table.Cell>
                                <Table.Cell>Value</Table.Cell>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Designation</Table.Cell>
                                <Table.Cell>{tag.designation}</Table.Cell>
                            </Table.Row>
                            {this.renderRow('Customer Material Number', tag.customerMaterialNum)}
                            {this.renderRow('Measuring Unit', tag.qtyUnit)}
                            {this.renderRow('Quantity Installed', tag.qtyInstalledPerUnit)}
                            {this.renderRow('Linde ID', tag.lindeIdentNum)}
                            {this.renderRow('Manufacturer Part No', tag.manufactPartNum)}
                            {this.renderRow('Order No Supplier', tag.contract)}
                            {this.renderRow('Drawing Part No', tag.drawingSectNum)}
                            {this.renderRow('Type', tag.type)}
                            {this.renderRow('Original Order Nr', tag.originalOrderNum)}
                            {this.renderRow('Oxygen Relevance 1', tag.oxygenRelevance)}
                            {this.renderRow('Oxygen Relevance 2', tag.oxygenRelevance2)}
                            {this.renderRow('Certificates', tag.certificate)}
                            {this.renderRow('Notes', tag.notes)}
                        </Table.Body>
                    </Table>
                </Modal.Card.Body>
            </>
        );
    };

    private renderRow = (name: string, value?: string) => {
        if (value) {
            return (
                <Table.Row>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{value}</Table.Cell>
                </Table.Row>
            );
        }
    };

    public renderDetails = (tag: IPartModel) => {
        const { type } = this.props;

        return tag && (type === 'equipment' ? this.renderEquipment(tag) : this.renderPart(tag));
    };

    public render() {
        const { onClose, onSubmit, partId, isMarked, disabled } = this.props;

        return (
            partId && (
                <ModalContainer isOpen={true} onRequestClose={onClose}>
                    <Modal.Background />
                    <Modal.Card className="is-scrollable">
                        <PartDetailQuery id={partId} renderFetchedPartData={this.renderDetails} />
                        <Modal.Card.Foot align="right">
                            <Button onClick={onClose}>Close</Button>
                            <Button color="primary" onClick={onSubmit} disabled={disabled}>
                                {isMarked ? 'Unmark for order' : 'Mark for order'}
                            </Button>
                        </Modal.Card.Foot>
                    </Modal.Card>
                </ModalContainer>
            )
        );
    }
}
