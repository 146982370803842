import { Button } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import IPartDocumentPageModel from '../../../models/IPartDocumentPageModel';
import { REMOVE_PART_DOCUMENT_PAGE_MUTATION } from './../../../api/GraphQLQueries/Part';
import { DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY } from './../../../api/GraphQLQueries/Tag';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IRouteParams {
    plantId: string;
    tagNumberAsId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    doc: IPartDocumentPageModel;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

class RemoveTagDocumentPageMutation extends React.Component<IProps, IState> {
    public state: IState = { ...resetState };

    private resetState = (): void => {
        this.setState((prevState: IState) => ({ ...prevState, ...resetState }));
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    public render = () => {
        const { error, success } = this.state;

        const { tagNumberAsId, plantId } = this.props.match.params;
        const { doc } = this.props;

        return (
            <>
                {error && <Error>document remove error</Error>}
                {success && <Message type="success">Successfully removed document</Message>}
                <Mutation
                    mutation={REMOVE_PART_DOCUMENT_PAGE_MUTATION}
                    variables={{ id: doc.id }}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={[
                        {
                            query: DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY,
                            variables: { plantId, tagNumberAsId },
                        },
                    ]}
                >
                    {doRemoveTagDocument => (
                        <Button type="button" outlined={true} onClick={doRemoveTagDocument}>
                            Remove from tag
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };
}

export default withRouter(RemoveTagDocumentPageMutation);
