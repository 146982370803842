import * as React from 'react';

import withTracker from '../components/google-analytics/withTracker';
import IUserModel from '../models/IUserModel';
import MainLayout from './MainLayout';

interface IProps {
    user: IUserModel;
}

const withMainNavigation = (WrappedComponent: any, data: any = {}): any => {
    // We need to store this tracker component outside of render - since
    // the withTracker() is a function, it returns a new instance each call
    // and react's shallow compare will re-render each time
    const WithTracker = withTracker(WrappedComponent);

    return class extends React.Component<IProps> {
        public render() {
            return (
                <MainLayout {...this.props}>
                    <WithTracker {...this.props} data={data} />
                </MainLayout>
            );
        }
    };
};

export default withMainNavigation;
