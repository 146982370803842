import { FetchPolicy } from 'apollo-client';
import { DocumentNode } from 'graphql';
import * as _ from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';

import Loading from '../../components/layout/Loading';
import {
    IAggregatedSpindexPageHit,
    IAggregatedSpindexPageQueryResult,
} from '../../models/IElasticModel';
import { DEFAULT_PAGING } from '../../utils/buildSearchQuery';
import InfinityScroller from '../../utils/InfinityScroller';
import { notification } from '../../utils/notification';
import Tracker from '../../utils/Tracker';
import { ES_PAGES_BY_DOCUMENT_QUERY } from './../../api/GraphQLQueries/Elastic';

interface IProps {
    esRequest: string;
    renderFetchedSearchResults: (
        results: IAggregatedSpindexPageHit[],
        totalPageHits: number,
        totalCount: number,
        canFetchMore: boolean,
    ) => JSX.Element | null;
    query?: DocumentNode;
    dataProp?: string;
    fetchPolicy?: FetchPolicy;
}

interface IState {
    canFetchMore: boolean;
}

export default class ElasticSearchQueryFetchMoreContainer extends React.Component<IProps, IState> {
    public state: IState = { canFetchMore: true };

    private getUpdatedFetchMoreQuery = (fetchMore: any, data: any) => () => {
        let { esRequest } = this.props;
        const esRequestParsed = JSON.parse(esRequest);
        const size = esRequestParsed.size || DEFAULT_PAGING.size;
        esRequestParsed.from = data.pagesByDocument.from + size;
        esRequest = JSON.stringify(esRequestParsed);

        if (esRequestParsed.from < data.pagesByDocument.totalCount) {
            Tracker.trackLoadMore(esRequestParsed.from.toString());
            fetchMore({
                variables: {
                    esRequest,
                },
                updateQuery: (prev: any, { fetchMoreResult }: any) => {
                    if (!fetchMoreResult) {
                        return prev;
                    }

                    return Object.assign({}, prev, {
                        pagesByDocument: {
                            ...fetchMoreResult.pagesByDocument,
                            data: _.uniqBy(
                                [
                                    ...prev.pagesByDocument.data,
                                    ...fetchMoreResult.pagesByDocument.data,
                                ],
                                ({ key }) => key,
                            ),
                        },
                    });
                },
            });
        } else {
            this.setState({ canFetchMore: false });
        }
    };

    private resultsDoneTrailer: IAggregatedSpindexPageHit = {
        key: '__DONE__',
        pageHits: [],
        pages: [],
        totalCount: 0,
    };

    public render() {
        const { esRequest, query, dataProp, renderFetchedSearchResults, fetchPolicy } = this.props;

        return (
            <Query
                query={query || ES_PAGES_BY_DOCUMENT_QUERY}
                variables={{ esRequest }}
                fetchPolicy={fetchPolicy || 'network-only'}
            >
                {({ data, fetchMore, error, loading }) => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (error) {
                        notification.error('Error fetching search results...');
                        return null;
                    }

                    const results: IAggregatedSpindexPageQueryResult =
                        data[dataProp || 'pagesByDocument'];

                    return (
                        <InfinityScroller
                            fitInnerHeight={true}
                            onLoadMore={this.getUpdatedFetchMoreQuery(fetchMore, data)}
                        >
                            {renderFetchedSearchResults(
                                this.state.canFetchMore
                                    ? results.data
                                    : results.data.concat(this.resultsDoneTrailer),
                                results.totalPageHits,
                                results.totalCount,
                                this.state.canFetchMore,
                            )}
                        </InfinityScroller>
                    );
                }}
            </Query>
        );
    }
}
