import * as React from 'react';

import { USER_ROLE_FOR_PLANT } from '../../../api/GraphQLQueries/Plant';
import DetailQueryContainer from '../../../containers/common/DetailQueryContainer';

interface IProps {
    plantId: string;
    renderFetchedUsers: (users: any) => JSX.Element;
}

export default class UsersForPlantQueryContainer extends React.Component<IProps, any> {
    public render = () => {
        const { plantId, renderFetchedUsers } = this.props;

        const variables = { plantId };

        return (
            <DetailQueryContainer
                query={USER_ROLE_FOR_PLANT}
                variables={variables}
                dataProp="userRolesForPlant"
                renderFetchedData={renderFetchedUsers}
            />
        );
    };
}
