import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

import PageHeader from '../components/layout/PageHeader';
import TagListQuery from '../components/tag/queries/TagListQuery';
import { getPageProps, ITablePageProps } from '../components/ui/paging/PageProps';
import { DEFAULT_PAGE_SIZE } from '../components/ui/paging/Paging';
import TableActions from '../components/ui/table/TableActions';
import TableActionSortDrowdown, {
    ISortOption,
} from '../components/ui/table/TableActionSortDrowdown';
import IPartModel from '../models/IPartModel';
import Routing from '../Routing';
import { todo } from '../utils/translate';
import TagCreate from './../components/tag/TagCreate';
import BlurButton from './../components/ui/BlurButton';
import DefaultTable, { ITableColumnModel } from './../components/ui/table/ControlledTable';
import withPaging from './../components/ui/table/withPaging';
import { ISorting } from './common/ListQueryContainer';
import withMainNavigation from './withMainNavigation';

const ControlledTable = withPaging(DefaultTable);

const resetPage = { offset: 0, pageIndex: 0 };

interface IRouteParams {
    plantId: string;
}

interface IState {
    totalCount: number;
    offset: number;
    limit: number;
    pageIndex: number;
    sortBy?: ISorting;
    showTagCreate: boolean;
    tableSearchText?: string;
}

class TagListContainer extends React.Component<RouteComponentProps<IRouteParams>, IState> {
    public state: IState = {
        totalCount: 0,
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        showTagCreate: false,
        tableSearchText: '',
    };

    private toggleTagCreate = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showTagCreate: !prevState.showTagCreate,
        }));
    };

    private onCreatePart = (part: IPartModel): void => {
        this.redirectToTagDetail(part);
    };

    private renderEdited = (data: any) => {
        return data.isOriginalCsv === false ? <i className="fa fa-certificate" /> : null;
    };

    private sortOptions: ISortOption[] = [
        {
            optionName: 'Tag Number',
            sortByField: 'tagNumber',
        },
        {
            optionName: 'Designation',
            sortByField: 'designation',
        },
    ];

    private columns: ITableColumnModel[] = [
        {
            Header: 'Tag Number',
            accessor: 'tagNumber',
        },
        {
            Header: 'Designation',
            accessor: 'designation',
        },
        {
            Header: 'Data Source',
            accessor: 'dataSource',
        },
        {
            Header: 'Checked',
            accessor: (data: any) => this.renderEdited(data),
        },
        {
            Header: 'Part count',
            accessor: (data: any) =>
                Array.isArray(data.parts) && data.parts.length > 0 ? data.parts.length : '0',
        },
        {
            Header: 'Documents',
            accessor: (data: any) => {
                return _.uniqBy(data.documentPages, 'document.s3Key').length;
            },
        },
        {
            Header: 'Cust. Vis',
            accessor: (data: any) => {
                data = _.uniqBy(data.documentPages, 'document.s3Key');
                return data.filter(el => el.document.customerVisible).length;
            },
        },
        {
            accessor: (data: any) => this.buildActionButtonsCell(data),
            disableRowHandler: true,
        },
    ];

    private buildActionButtonsCell = (tag: IPartModel) => {
        const detailRoute = this.getDetailRoute(tag);

        return (
            detailRoute && (
                <Button.Group align="right">
                    <Link className="button is-small" to={detailRoute}>
                        Details
                    </Link>
                </Button.Group>
            )
        );
    };

    private redirectToTagDetail = (tag: Partial<IPartModel>) => {
        const route = this.getDetailRoute(tag);
        if (route) {
            this.props.history.push(route);
        }
    };

    private onClickRow = (data: any) => {
        if (Array.isArray(data)) {
            this.redirectToTagDetail({ tagNumberAsId: data[0], tagNumber: data[1] });
        }
    };

    private getDetailRoute = ({ tagNumberAsId, tagNumber }: Partial<IPartModel>) => {
        const { plantId } = this.props.match.params;
        if (!plantId || !tagNumberAsId) {
            return;
        }
        return `${Routing.TAG_DETAILS.getPath({ tagNumberAsId, plantId })}?query=${tagNumber}`;
    };

    private onPageChange = (pageIndex: number) => {
        const offset = this.state.limit * pageIndex;
        this.setState({ pageIndex, offset });
    };

    private onSorting = (sortBy?: ISorting) => {
        this.setState({ ...resetPage, sortBy });
    };

    private renderSortDropdown = () => {
        return (
            <TableActionSortDrowdown
                sortOptions={this.sortOptions}
                selected={this.state.sortBy}
                onSelect={this.onSorting}
            />
        );
    };

    private handleFilter = (event: any) => {
        const tableSearchText = event.currentTarget.value;
        this.setState({ tableSearchText });
    };

    private renderTableActions = () => {
        const { tableSearchText } = this.state;

        return (
            <TableActions
                renderSortDropdown={this.renderSortDropdown}
                onCustomFilterChange={this.handleFilter}
                customFilterValue={tableSearchText}
            />
        );
    };

    private renderTagTable = () => {
        return (tags: IPartModel[], totalCount: number, limit: number, offset: number) => {
            const tablePageProps: ITablePageProps = getPageProps(totalCount, limit, offset);
            return this.renderControlledTable(tags, tablePageProps);
        };
    };

    private renderControlledTable = (
        tagList: IPartModel[],
        tablePageProps: ITablePageProps,
    ): JSX.Element => {
        const { pages, currentPageIndex, totalCount } = tablePageProps;

        return (
            <ControlledTable
                data={tagList}
                totalCount={totalCount}
                columns={this.columns}
                onClickRow={this.onClickRow}
                onClickRowReturnData={['tagNumberAsId', 'tagNumber']}
                pages={pages}
                page={currentPageIndex}
                onPageChange={this.onPageChange}
                actionButtons={this.renderTableActions}
            />
        );
    };

    private onResult = (totalCount: number): void => {
        if (totalCount !== this.state.totalCount) {
            this.setState({ totalCount });
        }
    };

    private headerButtons = () => {
        const { plantId } = this.props.match.params;
        return (
            <>
                <NavLink className="button" to={Routing.PLANT_DETAILS.getPath(plantId)}>
                    Back to plant
                </NavLink>
                <BlurButton data-cy="tagListContainer-createTag-btn" onClick={this.toggleTagCreate}>
                    Create Tag
                </BlurButton>
            </>
        );
    };

    public render() {
        const {
            offset,
            limit,
            sortBy,
            totalCount,
            showTagCreate,
            tableSearchText: searchText,
        } = this.state;
        const { plantId } = this.props.match.params;
        const filter = (searchText && { searchText }) || null;

        return (
            <div>
                <PageHeader buttons={this.headerButtons()}>
                    {todo('Tags')} ({totalCount})
                </PageHeader>
                <TagListQuery
                    plantId={plantId}
                    offset={offset}
                    limit={limit}
                    sortBy={sortBy}
                    onResult={this.onResult}
                    onResultDataProp="totalCount"
                    filter={filter}
                    renderFetchedPartList={this.renderTagTable()}
                />

                {showTagCreate && (
                    <TagCreate
                        plantId={plantId}
                        onCancel={this.toggleTagCreate}
                        onSuccess={this.onCreatePart}
                    />
                )}
            </div>
        );
    }
}

export default withMainNavigation(TagListContainer);
