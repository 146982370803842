import { Button, Delete, Modal } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import IBasketModel from '../../../models/IBasketModel';
import { notification } from '../../../utils/notification';
import FormField from '../../ui/FormField';
import ModalContainer from '../../ui/ModalContainer';
import { BASKET_DELETE_MUTATION, BASKET_LIST_QUERY } from './../../../api/GraphQLQueries/Basket';
import Routing from './../../../Routing';

interface IProps extends RouteComponentProps {
    basket: IBasketModel;
}

interface IState {
    showBasketDeleteModal: boolean;
    basketNameConfirm: string;
    deleting: boolean;
}

class BasketDeleteMutation extends React.Component<IProps, IState> {
    public state: IState = {
        showBasketDeleteModal: false,
        basketNameConfirm: '',
        deleting: false,
    };

    private onError = (): void => {
        this.setState({ deleting: false });
        notification.error('Basket delete error');
    };

    private onCompleted = (): void => {
        notification.success('Successfully deleted basket');
        this.props.history.replace(Routing.BASKETS.getPath());
    };

    private deleteBasket = (doDeleteMutation: MutationFn) => (): void => {
        const { basket } = this.props;
        this.setState({ deleting: true });
        doDeleteMutation({ variables: { id: basket.id } });
    };

    private toggleDeleteBasketModal = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showBasketDeleteModal: !prevState.showBasketDeleteModal,
            basketNameConfirm: '',
        }));
    };

    private onBasketTitleChange = (basketNameConfirm: string) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            basketNameConfirm,
        }));
    };

    public render = () => {
        const { showBasketDeleteModal, basketNameConfirm, deleting } = this.state;
        const { basket } = this.props;

        return (
            <>
                <Mutation
                    mutation={BASKET_DELETE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    refetchQueries={[{ query: BASKET_LIST_QUERY }]}
                >
                    {doDelete => (
                        <>
                            <Button
                                color="danger"
                                onClick={this.toggleDeleteBasketModal}
                                data-cy="basketDeleteMutation-delete-btn"
                            >
                                Delete Basket
                            </Button>

                            {showBasketDeleteModal && (
                                <ModalContainer
                                    isOpen={true}
                                    onRequestClose={this.toggleDeleteBasketModal}
                                >
                                    <Modal.Card>
                                        <Modal.Card.Head>
                                            <Modal.Card.Title>Delete basket</Modal.Card.Title>
                                            <Delete onClick={this.toggleDeleteBasketModal} />
                                        </Modal.Card.Head>

                                        <Modal.Card.Body>
                                            <FormField
                                                label="Confirm"
                                                horizontal={true}
                                                placeholder="Basket name"
                                                value={basketNameConfirm}
                                                onChange={this.onBasketTitleChange}
                                            />
                                        </Modal.Card.Body>
                                        <Modal.Card.Foot align="right">
                                            <Button
                                                color="danger"
                                                disabled={
                                                    basketNameConfirm.trim() !==
                                                        basket.name.trim() || deleting
                                                }
                                                onClick={this.deleteBasket(doDelete)}
                                            >
                                                Delete basket
                                            </Button>
                                        </Modal.Card.Foot>
                                    </Modal.Card>
                                </ModalContainer>
                            )}
                        </>
                    )}
                </Mutation>
            </>
        );
    };
}

export default withRouter(BasketDeleteMutation);
