import { Control, Field, Help, Input, Label } from 'rbx';
import * as React from 'react';

interface IProps {
    type?: string;
    label?: string;
    help?: string;
    placeholder?: string;
    value?: string;
    inputColor?: string;
    readOnly?: boolean;
    disabled?: boolean;
    horizontal?: boolean;
    cy?: string;
    onChange?: (e: any) => void;
    onKeyDown?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

export default class FormField extends React.Component<IProps, {}> {
    private updateContent = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event.target.value);
        }
    };

    public render() {
        const {
            value,
            type,
            label,
            help,
            placeholder,
            readOnly,
            disabled,
            horizontal,
            inputColor,
            onKeyDown,
            cy,
        } = this.props;

        return (
            <>
                {horizontal === true ? (
                    <Field horizontal={horizontal}>
                        {label && (
                            <Field.Label size="normal">
                                <Label>{label}</Label>
                            </Field.Label>
                        )}
                        <Field.Body>
                            <Field>
                                <Control expanded={true} data-cy={cy}>
                                    <Input
                                        value={value}
                                        onChange={this.updateContent}
                                        onKeyDown={onKeyDown}
                                        type={type ? type : 'text'}
                                        className="input"
                                        color={inputColor}
                                        placeholder={placeholder}
                                        readOnly={readOnly}
                                    />
                                </Control>
                            </Field>
                        </Field.Body>

                        {help && <Help>{help}</Help>}
                    </Field>
                ) : (
                    <Field>
                        {label && <Label>{label}</Label>}
                        <Control expanded={true} data-cy={cy}>
                            <Input
                                value={value}
                                onChange={this.updateContent}
                                onKeyDown={onKeyDown}
                                color={inputColor}
                                type={type ? type : 'text'}
                                className="input"
                                placeholder={placeholder}
                                readOnly={readOnly}
                                disabled={disabled}
                            />
                        </Control>
                        {help && <Help>{help}</Help>}
                    </Field>
                )}
            </>
        );
    }
}
