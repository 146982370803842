import { Box, Column, Title } from 'rbx';
import React from 'react';

import IPlantModel from '../../models/IPlantModel';
import IUserModel from '../../models/IUserModel';
import Routing from '../../Routing';
import PageHeader from '../layout/PageHeader';
import NavButton from '../nav/NavButton';
import GmapsStaticImg from '../ui/gmaps/GmapsStaticImg';
import UserPlantsQuery from '../user/queries/UserPlantsQuery';

class PlantTilesList extends React.Component {
    private renderTileItem(plant: IPlantModel) {
        const position =
            plant.deliveryAddress.latitude && plant.deliveryAddress.longitude
                ? `${plant.deliveryAddress!.latitude},${plant.deliveryAddress!.longitude}`
                : `${plant.deliveryAddress!.country} ${plant.deliveryAddress!.city} ${
                      plant.deliveryAddress!.zip
                  } ${plant.deliveryAddress!.street}`;
        return (
            <Column size="one-quarter" key={plant.id} className="plant-tile">
                <Box className="plant-tile">
                    <Title size={5} align="center">
                        {plant.customerDisplayName || plant.name}
                    </Title>
                    <GmapsStaticImg className="plant-tile-img" position={position} />
                    <NavButton
                        className="is-fullwidth"
                        color="primary"
                        to={Routing.SEARCH_PLANT.getPath(`${plant.id}`)}
                    >
                        Search
                    </NavButton>
                </Box>
            </Column>
        );
    }

    private renderPlantTiles = (user: IUserModel, plants: IPlantModel[]) => {
        void user;
        if (plants.length === 0) {
            return <p>No plants found</p>;
        }

        return (
            <>
                <PageHeader>Plants ({plants.length})</PageHeader>

                <Column.Group multiline={true}>
                    {plants.map((plant: IPlantModel) => {
                        return this.renderTileItem(plant);
                    })}
                </Column.Group>
            </>
        );
    };

    public render() {
        return (
            <>
                <UserPlantsQuery renderFetchedPlantData={this.renderPlantTiles} />
            </>
        );
    }
}

export default PlantTilesList;
