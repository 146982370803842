import { Button, Checkbox, Label } from 'rbx';
import * as React from 'react';

import { storage } from '../../utils/storage';

interface IState {
    highlightWords: boolean;
}

export default class DevTools extends React.Component<{}, IState> {
    public state: IState = {
        highlightWords: storage.getObj('dev.highlightWords'),
    };

    private change = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const { name } = e.currentTarget;
        const { highlightWords } = this.state;

        switch (name) {
            case 'highlightWords':
                storage.setObj('dev.highlightWords', !highlightWords);
                this.setState({ highlightWords: !highlightWords });
                break;
        }
    };

    private testThrow = () => {
        throw new Error('Test page error - this should be logged');
    };

    private testReject = () => {
        return Promise.reject('Test promise rejected - this should be logged');
    };

    public render() {
        const { highlightWords } = this.state;

        return (
            <div className="flex-column">
                <hr />
                <h2 className="subtitle underline">Developer Settings</h2>
                <Label>
                    <Checkbox
                        name="highlightWords"
                        onChange={this.change}
                        checked={highlightWords}
                    />
                    &nbsp;Highlight Document Viewer words
                </Label>
                <br />
                <Button.Group>
                    <Button name="testThrow" onClick={this.testThrow}>
                        Test page error
                    </Button>
                    <Button name="testReject" onClick={this.testReject}>
                        Test promise rejection
                    </Button>
                </Button.Group>
            </div>
        );
    }
}
