import * as React from 'react';
import { Query } from 'react-apollo';

import { DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY } from './../../../api/GraphQLQueries/Tag';
import IPartDocumentPageModel from './../../../models/IPartDocumentPageModel';
import Error from './../../layout/Error';
import Loading from './../../layout/Loading';

interface IProps {
    plantId: string;
    tagNumberAsId?: string;
    onResult?: (data?: any) => void;
    renderFetchedDocumentList: (parts: IPartDocumentPageModel[]) => JSX.Element;
}

export default class PartDocumentPageListQuery extends React.Component<IProps, any> {
    private onCompleted = (data: any) => {
        const { onResult } = this.props;

        if (onResult) {
            onResult(data.tagByNumber.documentPages);
        }
    };

    public render = () => {
        const { renderFetchedDocumentList, plantId, tagNumberAsId } = this.props;

        return (
            <Query
                query={DOCUMENT_PAGES_BY_TAG_NUMBER_QUERY}
                variables={{ plantId, tagNumberAsId }}
                fetchPolicy="network-only"
                onCompleted={this.onCompleted}
            >
                {({ data, loading, error }) => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (error) {
                        return <Error>Error fetching parts...</Error>;
                    }

                    const {
                        tagByNumber: { documentPages },
                    } = data;

                    return renderFetchedDocumentList(documentPages);
                }}
            </Query>
        );
    };
}
