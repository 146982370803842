import * as _ from 'lodash';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import { PLANT_SAVE_MUTATION } from './../../api/GraphQLQueries/Plant';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import Pane from './../../components/ui/Pane';
import IGroupModel from './../../models/IGroupModel';
import IPlantModel, { IPlantUpdateModel } from './../../models/IPlantModel';
import Routing from './../../Routing';
import omit from './../../utils/omit';
import { todo } from './../../utils/translate';
import PlantUpdateForm from './forms/PlantUpdateForm';
import PlantDetailQueryContainer from './queries/PlantDetailQuery';

interface IProps {
    idPlant: string;
    onCancel: () => void;
}

interface IState {
    plant?: IPlantUpdateModel;
    error: boolean;
    success: boolean;
}

class PlantUpdate extends React.Component<IProps, any> {
    public state: IState = {
        error: false,
        success: false,
    };

    private onError = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: true,
            success: false,
        }));
    };

    private onCompleted = (): void => {
        this.setState((prevState: IState) => ({
            ...prevState,
            error: false,
            success: true,
            plant: null,
        }));
    };

    private updateValues = (plant: IPlantUpdateModel) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            plant,
            error: false,
            success: false,
        }));
    };

    private formSubmit = (doPlantUpdateMutation: MutationFn) => (): void => {
        const plant = omit(this.state.plant, ['s3Folder']);

        doPlantUpdateMutation({ variables: { plant } });
    };

    private getGroupIdsFromPlantGroups = (fetchedPlant: IPlantModel): number[] | undefined => {
        const { groups } = fetchedPlant;

        if (_.isEmpty(groups)) {
            return undefined;
        }

        return groups!.map((group: IGroupModel) => group.id);
    };

    private convertPlantToPlantUpdateModel = (fetchedPlant: IPlantModel): IPlantUpdateModel => {
        const plant: IPlantUpdateModel = _.pick(fetchedPlant, [
            'id',
            'name',
            's3Folder',
            'customerDisplayName',
            'invoiceAddress',
            'deliveryAddress',
        ]);
        plant.groupIds = this.getGroupIdsFromPlantGroups(fetchedPlant);

        return plant;
    };

    private isPlantInValid = () => {
        const { plant } = this.state;
        return (
            !plant ||
            plant.name!.length < 3 ||
            _.some(
                [
                    ..._.values(_.omit(plant.invoiceAddress, ['latitude', 'longitude'])),
                    ..._.values(_.omit(plant.deliveryAddress, ['latitude', 'longitude'])),
                ],
                _.isEmpty,
            )
        );
    };

    private renderPlantUpdateForm = (plant: IPlantUpdateModel, doPlantUpdate: MutationFn) => {
        const { onCancel } = this.props;

        const updatePlant = (
            <Button onClick={this.formSubmit(doPlantUpdate)} disabled={this.isPlantInValid()}>
                Save
            </Button>
        );

        return (
            <Pane
                onClose={onCancel}
                actionButtons={updatePlant}
                title={todo('Edit plant')}
                sidepane={true}
            >
                <PlantUpdateForm plant={plant} onUpdate={this.updateValues} />
            </Pane>
        );
    };

    private renderPlantUpdateMutation = (plant: IPlantUpdateModel) => {
        return (
            <Mutation
                mutation={PLANT_SAVE_MUTATION}
                onError={this.onError}
                onCompleted={this.onCompleted}
            >
                {doPlantCreate => this.renderPlantUpdateForm(plant, doPlantCreate)}
            </Mutation>
        );
    };

    private renderPlantForm = (plantData: IPlantModel) => {
        if (!plantData) {
            return <Redirect to={Routing.PLANTS.route} />;
        }

        return this.renderPlantUpdateMutation(
            this.state.plant || this.convertPlantToPlantUpdateModel(plantData),
        );
    };

    public render = () => {
        const { idPlant } = this.props;
        const { error, success } = this.state;

        return (
            <>
                {error && <Error>Plant update error</Error>}
                {success && <Message type="success">Successfully updated plant</Message>}
                {idPlant && (
                    <PlantDetailQueryContainer
                        id={idPlant}
                        renderFetchedPlantData={this.renderPlantForm}
                    />
                )}
            </>
        );
    };
}

export default PlantUpdate;
