import moment from 'moment';
import { Button } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { USER_INVITE_MUTATION } from './../../../api/GraphQLQueries/User';
import Error from './../../../components/layout/Error';
import Message from './../../../components/layout/Message';

interface IProps {
    id: number;
    isAlreadyInvited: number;
}

interface IState {
    error: boolean;
    success: boolean;
}

const resetState = {
    error: false,
    success: false,
};

export default class UserInviteMutation extends React.Component<IProps, IState> {
    public state: IState = { ...resetState };

    private resetState = (): void => {
        this.setState({ ...resetState });
    };

    private onError = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: true,
                success: false,
            }),
            this.resetState,
        );
    };

    private onCompleted = (): void => {
        this.setState(
            (prevState: IState) => ({
                ...prevState,
                error: false,
                success: true,
            }),
            this.resetState,
        );
    };

    public render() {
        const { error, success } = this.state;
        const { id, isAlreadyInvited } = this.props;

        return (
            <>
                {error && <Error>User invite error</Error>}
                {success && <Message type="success">Successfully invited user</Message>}
                <Mutation
                    mutation={USER_INVITE_MUTATION}
                    variables={{ id }}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {(doUserInviteMutation, { data }) => (
                        <>
                            <Button type="button" onClick={doUserInviteMutation}>
                                Send {((data && data.invitedAt) || isAlreadyInvited) && 'another '}
                                email invitation
                            </Button>
                            {isAlreadyInvited && (
                                <small>
                                    Invited {moment(isAlreadyInvited).format('YYYY-MM-DD')}
                                </small>
                            )}
                        </>
                    )}
                </Mutation>
            </>
        );
    }
}
