import { Column, Control, Field, Help, Icon, Input, Label } from 'rbx';
import * as React from 'react';

import { normalize } from '../../../utils/helpers';
import { isRequired } from '../../../utils/helpers';
import { t } from '../../../utils/translate';
import {
    IPlantAddressModel,
    IPlantCreateModel,
    plantS3Folder,
} from './../../../models/IPlantModel';
import GroupListSelectMenu from './../../group/GroupListSelectMenu';
import PlantAddressForm from './PlantAddressForm';

interface IProps {
    onUpdate: (plant: IPlantCreateModel) => void;
    plant: IPlantCreateModel;
    s3FolderExists: boolean;
}

export default class PlantCreateForm extends React.Component<IProps, any> {
    private nameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.plant,
            name: normalize(context.currentTarget.value),
        });
    };

    private plantIdChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.plant,
            id: normalize(context.currentTarget.value),
        });
    };

    private groupsChange = (groupIds: number[]): void => {
        this.props.onUpdate({
            ...this.props.plant,
            groupIds,
        });
    };

    private customerDisplayNameChange = (context: any): void => {
        this.props.onUpdate({
            ...this.props.plant,
            customerDisplayName: context.currentTarget.value,
        });
    };

    private deliveryAddressChange = (address: IPlantAddressModel): void => {
        this.props.onUpdate({
            ...this.props.plant,
            deliveryAddress: address,
        });
    };

    private invoiceAddressChange = (address: IPlantAddressModel): void => {
        this.props.onUpdate({
            ...this.props.plant,
            invoiceAddress: address,
        });
    };

    private showErrorForNameId = (target: string, dependent: string) => {
        return (
            (!this.props.s3FolderExists && target.length >= 3 && dependent!.length >= 3) ||
            (target !== '' && target.length < 3)
        );
    };

    private getHelperMsgForNameId = (target: string, dependent: string) => {
        return target!.length < 3
            ? t().plant_name_id_complexity
            : !this.props.s3FolderExists && dependent.length >= 3
            ? t().plant_s3folder_notExist
            : '';
    };

    public render = () => {
        const { name, id, customerDisplayName, invoiceAddress, deliveryAddress } = this.props.plant;
        const plantS3Key = plantS3Folder(id, name);

        return (
            <>
                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>{isRequired('Linde Plant ID')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="LE Plant ID"
                                    value={id}
                                    onChange={this.plantIdChange}
                                    color={this.showErrorForNameId(id!, name!) ? 'danger' : ''}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                                {id !== '' && (
                                    <Help color="danger">
                                        {this.getHelperMsgForNameId(id!, name!)}
                                    </Help>
                                )}
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>{isRequired('Plant name')}</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Name"
                                    value={name}
                                    onChange={this.nameChange}
                                    color={this.showErrorForNameId(name!, id!) ? 'danger' : ''}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                                {name !== '' && (
                                    <Help color="danger">
                                        {this.getHelperMsgForNameId(name!, id!)}
                                    </Help>
                                )}
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>S3 Folder</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true}>
                                <Input
                                    type="string"
                                    disabled={true}
                                    placeholder="Generated from name and ID"
                                    value={plantS3Key ? `plants/${plantS3Key}` : ''}
                                />
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Customer Display Name</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded={true} iconLeft={true}>
                                <Input
                                    type="string"
                                    placeholder="Customer Display Name"
                                    value={customerDisplayName}
                                    onChange={this.customerDisplayNameChange}
                                />
                                <Icon size="small" align="left">
                                    <i className="fa fa-building" />
                                </Icon>
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal={true}>
                    <Field.Label size="normal">
                        <Label>Group name</Label>
                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <GroupListSelectMenu multiple={true} onUpdate={this.groupsChange} />
                        </Field>
                    </Field.Body>
                </Field>

                <hr />

                <Column.Group>
                    <Column>
                        <PlantAddressForm
                            hideLatLng={true}
                            title="Invoice Address"
                            address={invoiceAddress || {}}
                            onUpdate={this.invoiceAddressChange}
                        />
                    </Column>
                    <Column>
                        <PlantAddressForm
                            title="Delivery Address"
                            address={deliveryAddress || {}}
                            onUpdate={this.deliveryAddressChange}
                        />
                    </Column>
                </Column.Group>
            </>
        );
    };
}
