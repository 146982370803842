const baseMatrix = (): number[] => {
    return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
};

const parse = (source: string | null | undefined): number[] => {
    if (typeof source === 'string') {
        const match = source.match(/matrix(3d)?\(([^)]+)\)/);
        if (match) {
            const raw = match[2].split(', ').map(parseFloat);
            return format(raw);
        }
    }

    return baseMatrix();
};

const format = (source: number[]): number[] => {
    if (source.constructor !== Array) {
        throw new TypeError('Expected array.');
    }
    if (source.length === 16) {
        return source;
    }
    if (source.length === 6) {
        const matrix = baseMatrix();
        matrix[0] = source[0];
        matrix[1] = source[1];
        matrix[4] = source[2];
        matrix[5] = source[3];
        matrix[12] = source[4];
        matrix[13] = source[5];
        return matrix;
    }
    throw new RangeError('Expected array with either 6 or 16 values.');
};

const scale = (scalar: number): number[] => {
    const matrix = baseMatrix();

    matrix[0] = scalar;
    matrix[5] = scalar;

    return matrix;
};

const rotate = (angle: number): number[] => {
    const theta = (Math.PI / 180) * angle;
    const matrix = baseMatrix();

    matrix[0] = matrix[5] = Math.cos(theta);
    matrix[1] = matrix[4] = Math.sin(theta);
    matrix[4] *= -1;

    return matrix;
};

const translate = (distanceX: number, distanceY: number): number[] => {
    const matrix = baseMatrix();
    matrix[12] = distanceX;
    matrix[13] = distanceY;

    return matrix;
};

const multiply = (matrix1: number[], matrix2: number[]): number[] => {
    const matrix3d1 = format(matrix1);
    const matrix3d2 = format(matrix2);
    const resultMatrix: number[] = [];

    for (const i of [0, 1, 2, 3]) {
        const row = [matrix3d1[i], matrix3d1[i + 4], matrix3d1[i + 8], matrix3d1[i + 12]];
        for (const j of [0, 1, 2, 3]) {
            const k = j * 4;
            const col = [matrix3d2[k], matrix3d2[k + 1], matrix3d2[k + 2], matrix3d2[k + 3]];
            const result = row[0] * col[0] + row[1] * col[1] + row[2] * col[2] + row[3] * col[3];

            resultMatrix[i + k] = result;
        }
    }

    return resultMatrix;
};

export { baseMatrix, scale, rotate, translate, multiply, parse };
