import * as React from 'react';

import Paging from './../paging/Paging';
import { IProps as ControlledTableProps } from './ControlledTable';

const withPaging = (WrappedComponent: any): any => {
    interface IProps extends ControlledTableProps {
        totalCount?: number;
        pages: number;
        page: number;
        showPageJump?: boolean;
        onPageChange: (pageIndex: number) => void;
    }

    return class extends React.Component<IProps, any> {
        public render() {
            const { page, pages, onPageChange, showPageJump, totalCount } = this.props;

            return (
                <>
                    <WrappedComponent {...this.props} />
                    <Paging
                        key={page}
                        totalCount={totalCount}
                        pages={pages}
                        currentPageIndex={page}
                        onPageChange={onPageChange}
                        showPageJump={showPageJump}
                    />
                </>
            );
        }
    };
};

export default withPaging;
