import * as React from 'react';
import { Mutation } from 'react-apollo';

import { CLEAR_RABBIT_MUTATION } from '../../api/GraphQLQueries/Ops';
import { notification } from '../../utils/notification';
import OpsModal from './OpsModal';

export default class ClearRabbitMutation extends React.Component<{}, {}> {
    private formSubmit = (doClearRabiitMutation: any) => (): void => {
        doClearRabiitMutation();
    };

    private onError = (): void => {
        notification.error('Error clearing rabbit queue');
    };

    private onCompleted = (): void => {
        notification.success('Rabbit queue successfully cleared');
    };

    private onClose = (): void => {
        return;
    };

    public render() {
        return (
            <>
                <Mutation
                    mutation={CLEAR_RABBIT_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {doClearRabiitMutation => (
                        <OpsModal
                            title="Clear Rabbit Queue"
                            onSubmit={this.formSubmit(doClearRabiitMutation)}
                            onClose={this.onClose}
                            bodyText={`<strong>Warning</strong>: This will remove all currently processing jobs.<br />Click "Sure" if this is what you want.`}
                        />
                    )}
                </Mutation>
            </>
        );
    }
}
