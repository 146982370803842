import gql from 'graphql-tag';

const SAVE_OVERRIDE_FOR_PAGE_MUTATION = gql`
    mutation SaveOverrideForPage(
        $orginalS3Key: String!
        $page: Int!
        $overrideableSpindexPage: OverrideableSpindexPage!
    ) {
        saveOverrideForPage(
            orginalS3Key: $orginalS3Key
            page: $page
            overrideableSpindexPage: $overrideableSpindexPage
        )
    }
`;

export { SAVE_OVERRIDE_FOR_PAGE_MUTATION };
