import { DataProxy } from 'apollo-cache';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { DocumentNode } from 'graphql';

import { client } from './connection';

type ICacheProxy = DataProxy | InMemoryCache | any;

// import * as jquery2 from 'lodash'; Object.assign(jquery2, { get: safeNavigator });
const safeNavigator = (obj: { [key: string]: any }, index: string, or: any = null): any => {
    let value = obj;
    const keys = index.split('.');

    for (const key of keys) {
        if (!value || !value.hasOwnProperty(key)) {
            return or;
        }
        value = value[key];
    }

    return value;
};

/*
 * Apollo cache can be found in inspector with:
 * __APOLLO_CLIENT__.cache.data.data
 *
 * Inspiration:
 * https://github.com/apollographql/apollo-feature-requests/issues/4
 */

export default class Cache {
    /**
     * Extract query name from DocumentNode and apollo's dataId method
     */
    public static getCacheQueryName = (node: DocumentNode): string[] => {
        return [
            defaultDataIdFromObject(node),
            safeNavigator(node, 'definitions.0.selectionSet.selections.0.name.value'),
            safeNavigator(node, 'definitions.0.name.value'),
        ].filter(s => s);
    };

    /**
     * Delete cached queries
     */
    public static removeCacheItems = (
        cacheProxy: ICacheProxy | any = client.cache,
        queryNames: string[],
    ): number => {
        const cacheKeys = new RegExp(`^(${queryNames.join('|')})\\(`);

        let nKeysDeleted = 0;

        Object.keys(cacheProxy.data.data).forEach(key => {
            if (cacheKeys.test(key)) {
                cacheProxy.data.delete(key);
                nKeysDeleted++;
            }
        });
        Object.keys(cacheProxy.data.data.ROOT_QUERY).forEach(key => {
            if (cacheKeys.test(key)) {
                cacheProxy.data.delete(key);
                nKeysDeleted++;
            }
        });

        return nKeysDeleted;
    };

    /**
     * Deletes cache keys
     *
     * Use with Mutation component update prop:
     * update={Cache.invalidateQuery(GROUP_QUERY, 'saveMembership.group.id')}
     *
     * Will delete cached queries. Accepts query DocumentNode and optional
     * nested key to extract from the mutation return result
     */
    public static invalidateQueryComponent = (toRemove: DocumentNode, dataIndex?: string) => (
        cacheProxy: ICacheProxy | any = client.cache,
        queryResult: any = {},
    ) => {
        let queryNames: string[];

        queryNames = Cache.getCacheQueryName(toRemove);
        let reducer;
        if (dataIndex) {
            const index = safeNavigator(queryResult.data, dataIndex);
            if (index === null) {
                return false;
            }
            reducer = (a, n) => a.concat([`${n}:${index}`, `ROOT_QUERY.${n}:${index}`]);
        } else {
            reducer = (a, n) => a.concat([n, `ROOT_QUERY.${n}`]);
        }

        queryNames = queryNames.reduce(reducer, []);

        return Cache.removeCacheItems(cacheProxy, queryNames);
    };

    public static invalidateQuery = (toRemove: DocumentNode, dataIndex?: string) =>
        Cache.invalidateQueryComponent(toRemove, dataIndex)();

    /**
     * Deletes cache keys
     *
     * Use with Mutation component update prop:
     * update={Cache.invalidateKeys(['user:11', 'groups'])}
     *
     * Will delete cached queries. Accepts string or string array of cache keys
     * in the format <query>[:<queryId>]
     */
    public static invalidateKeysComponent = (toRemove: string | string[]) => (
        cacheProxy: ICacheProxy | any = client.cache,
        /*queryResult: any = {},*/
    ) => {
        const capitalize = (s: string) => `${s[0].toUpperCase()}${s.slice(1)}`;

        if (typeof toRemove === 'string') {
            toRemove = [toRemove];
        }
        toRemove.concat(
            ...toRemove.map(n => [`ROOT_QUERY.${n}`, `ROOT_QUERY.${capitalize(n)}`, capitalize(n)]),
        );

        return Cache.removeCacheItems(cacheProxy, toRemove);
    };

    public static invalidateKeys = (toRemove: string | string[]) =>
        Cache.invalidateKeysComponent(toRemove)();
}
