import classnames from 'classnames';
import { Button, Icon } from 'rbx';
import * as React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

interface IProps {
    onClose?: () => void;
    dangerButton?: JSX.Element;
    actionButtons?: any;
    headerContent?: any;
    title?: string;
    sidepane?: boolean;
    cy?: string;
    classNames?: string;
}

export default class Pane extends React.Component<IProps, any> {
    private escapeToClose = () => {
        if (typeof this.props.onClose !== 'function') {
            return;
        }
        this.props.onClose();
    };

    private renderActionButtons = () => {
        const { onClose, actionButtons, dangerButton } = this.props;

        return (
            <>
                {dangerButton}
                {actionButtons && (
                    <Button.Group>
                        {onClose && (
                            <Button color="light" onClick={onClose}>
                                Cancel
                            </Button>
                        )}
                        {actionButtons}
                    </Button.Group>
                )}
            </>
        );
    };

    private renderHeaderContent = () => {
        const { title, headerContent } = this.props;

        if (headerContent) {
            return headerContent();
        }

        return <span className="pane-title">{title}</span>;
    };

    public render = () => {
        const {
            title,
            onClose,
            actionButtons,
            dangerButton,
            sidepane,
            headerContent,
            cy,
            classNames,
        } = this.props;

        const paneClasses = classnames({
            pane: true,
            'side-pane': sidepane,
            [classNames]: true,
        });
        const footerClasses = classnames({
            'pane-footer': true,
            'with-action': actionButtons,
            'with-danger': dangerButton,
        });

        return (
            <div className={paneClasses} data-cy={cy}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={this.escapeToClose} />
                {(title || onClose || headerContent) && (
                    <div className="pane-header">
                        {this.renderHeaderContent()}
                        {onClose && (
                            <Button size="small" onClick={onClose}>
                                <Icon size="small">
                                    <i className="fa fa-times" />
                                </Icon>
                            </Button>
                        )}
                    </div>
                )}

                <div className="pane-content">{this.props.children}</div>

                {(actionButtons || dangerButton) && (
                    <div className={footerClasses}>{this.renderActionButtons()}</div>
                )}
            </div>
        );
    };
}
