import * as _ from 'lodash';
import { Button, Control, Field, Icon, Input, Label, Level, Title } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import IPartModel from '../../models/IPartModel';
import Pane from '../ui/Pane';
import { BASKET_LINE_ITEMS_QUERY } from './../../api/GraphQLQueries/Basket';
import { LINE_ITEM_CREATE_MUTATION } from './../../api/GraphQLQueries/LineItem';
import Error from './../../components/layout/Error';
import Message from './../../components/layout/Message';
import { ILineItemCreateModel } from './../../models/ILineItemModel';
import IPartDocumentPageModel from './../../models/IPartDocumentPageModel';
import { buildPath, getUrlParams, parseDocumentFilename } from './../../utils/queryString';
import BasketSelectModal from './../basket/BasketSelectModal';
import PartDocumentPageListQuery from './../part/queries/PartDocumentPageListQuery';
import TagDetailQuery from './queries/TagDetailQuery';
import TagViewerPartModal from './TagViewerPartModal';

interface IProps extends RouteComponentProps {
    tagNumberAsId: string;
    plantId: string;
    onClose: () => void;
    actionButtons?: any;
    defaultSelected?: string[];
    defaultSelectedBasketId?: string;
}

interface IState {
    modalOpen: boolean;
    showBasketSelect: boolean;
    modalPart?: IPartModel;
    modalType?: 'equipment' | 'part';
    markedItems: IPartModel[];
    selectedBasketId?: string;
    tagId?: string;
    error: boolean;
    success: boolean;
}

class TagViewer extends React.Component<IProps, IState> {
    public state: IState = {
        modalOpen: false,
        showBasketSelect: false,
        markedItems: [],
        error: false,
        success: false,
        selectedBasketId: this.props.defaultSelectedBasketId || undefined,
    };

    private setSelectedBasket = (selectedBasketId: string) => {
        this.setState((prevState: IState) => ({
            ...prevState,
            selectedBasketId,
        }));
    };

    private toggleShowBasketSelect = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showBasketSelect: !prevState.showBasketSelect,
        }));
    };

    private isItemMarked = (id: string) => {
        const markedItem = this.state.markedItems.find(item => item.id === id);

        return markedItem ? true : false;
    };

    private isDefaultSelected = (id: string) => {
        const { defaultSelected } = this.props;

        return defaultSelected && defaultSelected.length > 0 && defaultSelected.includes(id);
    };

    private toggleItem = (part: IPartModel) => () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            markedItems: prevState.markedItems.find(item => item.id === part.id)
                ? prevState.markedItems.filter(item => item.id !== part.id)
                : [...prevState.markedItems, part],
        }));
    };

    private getCartIcon = (id: string): string => {
        return this.isItemMarked(id) || this.isDefaultSelected(id)
            ? 'fa fa-cart-arrow-down'
            : 'fa fa-shopping-cart';
    };

    private getButtonColor = (id: string): string => {
        return this.isItemMarked(id) || this.isDefaultSelected(id) ? 'primary' : '';
    };

    private getCartButton = (part: IPartModel) => {
        const disabled = this.isDefaultSelected(part.id);

        return (
            <Button
                color={this.getButtonColor(part.id)}
                onClick={this.toggleItem(part)}
                disabled={disabled}
            >
                <Icon>
                    <i className={this.getCartIcon(part.id)} />
                </Icon>
            </Button>
        );
    };

    private renderEquipmentField = (tag: IPartModel) => {
        return (
            <Field horizontal={true}>
                <Field.Label>
                    <Label>Equipment</Label>
                </Field.Label>
                <Field.Body>
                    <Field kind="group">
                        <Control expanded={true}>
                            <Input defaultValue={tag.designation} disabled={true} />
                        </Control>
                        <Control>
                            <Button onClick={this.showEquipmentDetail(tag)}>
                                <Icon>
                                    <i className="fa fa-info" />
                                </Icon>
                            </Button>
                        </Control>
                        <Control>{this.getCartButton(tag)}</Control>
                    </Field>
                </Field.Body>
            </Field>
        );
    };

    private renderPartField = (part: IPartModel) => {
        const { type, customerMaterialNum, contract, designation, manufactPartNum } = part;
        const display = type || customerMaterialNum || contract || manufactPartNum;
        const name = display ? `${designation} (${display})` : designation;

        return (
            <Field key={part.id} horizontal={true}>
                <Field.Label>
                    <Label>Spare part</Label>
                </Field.Label>
                <Field.Body>
                    <Field kind="group">
                        <Control expanded={true}>
                            <Input defaultValue={name} disabled={true} />
                        </Control>
                        <Control>
                            <Button onClick={this.showPartDetail(part)}>
                                <Icon>
                                    <i className="fa fa-info" />
                                </Icon>
                            </Button>
                        </Control>
                        <Control>{this.getCartButton(part)}</Control>
                    </Field>
                </Field.Body>
            </Field>
        );
    };

    private renderActionButtons = () => {
        const itemCount = this.state.markedItems.length;
        return (
            <Button disabled={itemCount === 0} onClick={this.toggleShowBasketSelect}>
                Add {itemCount} items to basket
            </Button>
        );
    };

    private showDocument = (link: string) => () => {
        this.props.history.push(link);
    };

    private renderDocument = (documentPage: IPartDocumentPageModel) => {
        const text = parseDocumentFilename(documentPage.document.s3Key);
        const pathInfo = getUrlParams(this.props.location);
        pathInfo.params.docId = `${documentPage.document.s3Key}_${documentPage.pageNumber}`;
        const linkTo = buildPath(pathInfo);

        return (
            <Level key={documentPage.id}>
                <Level.Item
                    align="left"
                    className="is-clickable is-shrink is-clipped"
                    onClick={this.showDocument(linkTo)}
                >
                    <div className="ellipsis">
                        <Icon size="small">
                            <i className="fa fa-file-image" />
                        </Icon>
                        &nbsp;
                        <strong>{text}&nbsp;</strong>
                        {documentPage.pageNumber > 1 && <>({documentPage.pageNumber})&nbsp;</>}
                    </div>
                </Level.Item>
                <Level.Item align="right">
                    <Button size="small" color="primary" onClick={this.showDocument(linkTo)}>
                        View
                    </Button>
                </Level.Item>
            </Level>
        );
    };

    private renderDocuments = (documents: IPartDocumentPageModel[]) => {
        if (!documents || !documents.length) {
            return <span>No documents found</span>;
        }

        return (
            <>
                <Title size="5">Related documents</Title>
                {documents.map(documentPage => this.renderDocument(documentPage))}
            </>
        );
    };

    private renderTagDetails = (tag: IPartModel) => {
        const { onClose } = this.props;

        return (
            tag && (
                <div className="tag-viewer-pane">
                    <Pane
                        cy="tagViewer-pane"
                        sidepane={true}
                        actionButtons={this.renderActionButtons()}
                        onClose={onClose}
                        title={`${tag.designation} (${tag.tagNumber})`}
                    >
                        {this.renderEquipmentField(tag)}
                        <hr />
                        {tag.parts && tag.parts.length > 0 && (
                            <div className="parts-list">
                                {tag.parts.map(part => this.renderPartField(part))}
                            </div>
                        )}
                        <hr />
                        <PartDocumentPageListQuery
                            plantId={tag.plant.id}
                            tagNumberAsId={tag.tagNumberAsId}
                            renderFetchedDocumentList={this.renderDocuments}
                        />
                    </Pane>
                </div>
            )
        );
    };

    private showEquipmentDetail = (part: IPartModel) => () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            modalOpen: true,
            modalPart: part,
            modalType: 'equipment',
        }));
    };

    private showPartDetail = (part: IPartModel) => () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            modalOpen: true,
            modalPart: part,
            modalType: 'part',
        }));
    };

    private closeModal = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            modalOpen: false,
            modalPart: undefined,
            modalType: undefined,
        }));
    };

    private showModal = () => {
        const { modalOpen, modalPart, modalType } = this.state;

        return (
            modalOpen &&
            modalPart && (
                <TagViewerPartModal
                    partId={modalPart.id}
                    onClose={this.closeModal}
                    onSubmit={this.toggleItem(modalPart)}
                    isMarked={this.isItemMarked(modalPart.id)}
                    disabled={this.isDefaultSelected(modalPart.id)}
                    type={modalType}
                />
            )
        );
    };

    private convertPartToLineItem = (part: IPartModel): ILineItemCreateModel => {
        const isWholeComponent = !_.isEmpty(part.tagNumber);
        const title = part!.designation + (isWholeComponent ? ` (${part.tagNumber})` : '');
        const parentPartId = !isWholeComponent ? this.state.tagId : undefined;

        return {
            title,
            isWholeComponent,
            requestType: 'parts',
            quantity: '1',
            basketId: this.state.selectedBasketId,
            partId: part.id,
            parentPartId,
            description: '',
        };
    };

    private addToBasket = (doLineItemCreateMutation: MutationFn) => async () => {
        const failedItems: IPartModel[] = [];
        let success = false;

        for (const item of this.state.markedItems) {
            const res = await this.doSingleMutation(
                this.convertPartToLineItem(item),
                doLineItemCreateMutation,
            );
            if (!res) {
                failedItems.push(item);
            } else {
                success = true;
            }
        }

        const failed = failedItems.length > 0;

        this.setState(
            (prevState: IState) => ({
                ...prevState,
                markedItems: failedItems,
                success,
                error: failedItems.length > 0,
            }),
            () => {
                if (!failed) {
                    this.props.onClose();
                }
            },
        );
    };

    private doSingleMutation = async (
        lineItem: ILineItemCreateModel,
        doLineItemCreateMutation: MutationFn,
    ): Promise<boolean> => {
        const { selectedBasketId } = this.state;

        const refetchQueries = selectedBasketId
            ? [{ query: BASKET_LINE_ITEMS_QUERY, variables: { id: selectedBasketId } }]
            : undefined;

        const res = await doLineItemCreateMutation({ variables: { lineItem }, refetchQueries });

        if (!res || !res.data || !res.data.createLineItem) {
            return false;
        }
        return true;
    };

    private setTagId = (part: IPartModel) => {
        if (!this.state.tagId || this.state.tagId !== part.id) {
            this.setState((prevState: IState) => ({
                ...prevState,
                tagId: part.id,
            }));
        }
    };

    public render() {
        const { tagNumberAsId, plantId } = this.props;
        const { modalOpen, showBasketSelect, error, success, selectedBasketId } = this.state;

        return (
            <>
                {error && <Error>Some items were not added</Error>}
                {success && <Message type="success">Successfully added items to basket</Message>}
                {tagNumberAsId && plantId && (
                    <>
                        <TagDetailQuery
                            tagNumberAsId={tagNumberAsId}
                            plantId={plantId}
                            onResult={this.setTagId}
                            onResultReturnData={true}
                            renderFetchedTagData={this.renderTagDetails}
                        />
                        {modalOpen && this.showModal()}
                        {showBasketSelect && (
                            <Mutation mutation={LINE_ITEM_CREATE_MUTATION}>
                                {doLineItemCreate => (
                                    <BasketSelectModal
                                        onClose={this.toggleShowBasketSelect}
                                        onUpdate={this.setSelectedBasket}
                                        defaultSelected={
                                            selectedBasketId ? [selectedBasketId] : undefined
                                        }
                                        plantId={plantId}
                                        onSubmit={this.addToBasket(doLineItemCreate)}
                                    />
                                )}
                            </Mutation>
                        )}
                    </>
                )}
            </>
        );
    }
}

export default withRouter(TagViewer);
