import * as React from 'react';

import MultiSelect from '../ui/MultiSelect';

interface IProps {
    defaultSelected?: string;
    onUpdate: (month: string) => void;
    isClearable?: boolean;
}

export default class DateFilterSelectMenu extends React.Component<IProps, any> {
    private handleChange = (month?: { value: string; month: string } | any) => {
        this.props.onUpdate(month ? month.value : undefined);
    };

    private buildSelectObject = () => {
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

        return months.map(m => {
            const item = { value: m, label: m };
            return item;
        });
    };

    public render() {
        const { defaultSelected, isClearable } = this.props;
        const initial = defaultSelected
            ? { value: defaultSelected, label: defaultSelected }
            : undefined;

        return (
            <MultiSelect
                value={initial}
                options={this.buildSelectObject()}
                placeholder="month"
                isClearable={isClearable}
                onChange={this.handleChange}
            />
        );
    }
}
