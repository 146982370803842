import * as _ from 'lodash';
import { Button, Column, Icon, Level } from 'rbx';
import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getTopLevelPlantsFolder } from '../../utils/helpers';
import Pane from '../ui/Pane';
import Search from './../../containers/elastic/Search';
import IPartDocumentPageModel from './../../models/IPartDocumentPageModel';
import IPartModel from './../../models/IPartModel';
import {
    buildPath,
    getUrlParams,
    parseDocumentFilename,
    parseDocumentId,
    parseDocumentMeta,
    parseDocumentSource,
} from './../../utils/queryString';
import PartCreate from './../part/PartCreate';
import PartUpdate from './../part/PartUpdate';
import PartDocumentPageListQuery from './../part/queries/PartDocumentPageListQuery';
import PartDocumentPagesCheckQuery from './../part/queries/PartDocumentPagesCheckQuery';
import PartListQuery from './../part/queries/PartListQuery';
import Accordion from './../ui/accordion/Accordion';
import CreateTagDocumentMutation from './queries/CreateTagDocumentMutation';
import RemoveTagDocumentPageMutation from './queries/RemoveTagDocumentPageMutation';
import TagFillFromSourceMutation from './queries/TagFillFromSourceMutation';
import ToggleCustomerVisibleMutation from './queries/ToggleCustomerVisibleMutation';
import TagDetails from './TagDetails';

interface IRouteParams {
    plantId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    tag: IPartModel;
}

interface IState {
    showPartCreate: boolean;
    partUpdateId?: string;
    partRerenderKey: number;
    linkedDocuments: IPartDocumentPageModel[];
}

class TagSummary extends React.Component<IProps, IState> {
    public state: IState = {
        showPartCreate: false,
        partRerenderKey: 0,
        linkedDocuments: [],
    };

    private toggleEditPart = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            showPartCreate: !prevState.showPartCreate,
        }));
    };

    private onCreatePart = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            partRerenderKey: prevState.partRerenderKey < 1 ? 1 : 0,
            showPartCreate: !prevState.showPartCreate,
        }));
    };

    private showUpdatePart = (partId: string) => () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            partUpdateId: partId,
        }));
    };

    private hideUpdatePart = () => {
        this.setState((prevState: IState) => ({
            ...prevState,
            partUpdateId: undefined,
        }));
    };

    private renderParts = (parts: IPartModel[]) => {
        return (
            parts && (
                <>
                    {parts.map((part, index) => {
                        return (
                            <Level key={index}>
                                <Level.Item align="left">
                                    <div className="ellipsis">
                                        <strong>{part.designation}</strong>&nbsp;
                                        <span>{part.tagNumber && `(${part.tagNumber})`}</span>
                                    </div>
                                </Level.Item>
                                <Level.Item align="right">
                                    <Button
                                        color="primary"
                                        outlined={true}
                                        onClick={this.showUpdatePart(part.id)}
                                    >
                                        Edit
                                    </Button>
                                </Level.Item>
                            </Level>
                        );
                    })}
                </>
            )
        );
    };

    private showDocument = (link: string) => () => {
        this.props.history.push(link);
    };

    private toggleCustomerVisibility = (
        doToggle: any,
        documentPage: IPartDocumentPageModel,
        customerVisible: boolean,
        moreThanOneExists: boolean,
    ) => (): void => {
        const { id } = documentPage;
        if (documentPage.document.customerVisible !== customerVisible) {
            if (moreThanOneExists) {
                if (
                    window.confirm(
                        `This document is linked under other parts as well. This setting will effect all of them. Are you sure?`,
                    )
                ) {
                    doToggle({
                        variables: { document: { id, customerVisible } },
                    });
                }
            } else {
                doToggle({
                    variables: { document: { id, customerVisible } },
                });
            }
        }
    };

    private renderToggleCustomerVisibleButtons = (
        documentPage: IPartDocumentPageModel,
        docExists: boolean,
    ) => (doToggle: MutationFn) => {
        return (
            <>
                <Button
                    size="small"
                    color="danger"
                    outlined={!documentPage.document.customerVisible}
                    onClick={this.toggleCustomerVisibility(doToggle, documentPage, true, docExists)}
                >
                    Yes
                </Button>

                <Button
                    size="small"
                    color="primary"
                    outlined={documentPage.document.customerVisible}
                    onClick={this.toggleCustomerVisibility(
                        doToggle,
                        documentPage,
                        false,
                        docExists,
                    )}
                >
                    No
                </Button>
            </>
        );
    };

    private renderDocument = (documentPage: IPartDocumentPageModel) => (docExists: boolean) => {
        const { plantId } = this.props.match.params;
        const { tagNumberAsId } = this.props.tag;
        const text = parseDocumentFilename(
            documentPage.document.title ? documentPage.document.title : documentPage.document.s3Key,
        );
        const pathInfo = getUrlParams(this.props.location);
        pathInfo.params.docId = `${documentPage.document.s3Key}_${documentPage.pageNumber}`;
        const linkTo = buildPath(pathInfo);
        const docType = getTopLevelPlantsFolder(documentPage.document.s3Key).toLowerCase();
        return (
            <Level key={documentPage.id}>
                <Level.Item
                    align="left"
                    onClick={this.showDocument(linkTo)}
                    className="is-clickable"
                    data-tooltip={text}
                    title={text}
                >
                    <div className="ellipsis">
                        <Icon
                            size="small"
                            color={linkTo.search === this.props.location.search ? 'primary' : ''}
                        >
                            <i className="fa fa-search" />
                        </Icon>
                        &nbsp;
                        <strong>{text}&nbsp;</strong>
                        {documentPage.pageNumber > 1 && <>({documentPage.pageNumber})&nbsp;</>}
                    </div>
                </Level.Item>
                {docType !== 'pnid' && docType !== 'customer_docs' && (
                    <Level.Item align="right">
                        <Button.Group hasAddons={true}>
                            <Button size="small" static={true}>
                                Customer Visible
                            </Button>

                            <ToggleCustomerVisibleMutation
                                renderButtons={this.renderToggleCustomerVisibleButtons(
                                    documentPage,
                                    docExists,
                                )}
                                plantId={plantId}
                                tagNumberAsId={tagNumberAsId}
                            />
                        </Button.Group>
                    </Level.Item>
                )}
            </Level>
        );
    };

    private renderDocuments = (documents: IPartDocumentPageModel[]) => {
        if (!documents || !documents.length) {
            return <span>No documents found</span>;
        }

        const { plantId } = this.props.match.params;

        return (
            <>
                {documents.map(documentPage => (
                    <PartDocumentPagesCheckQuery
                        key={documentPage.id}
                        plantId={plantId}
                        s3Key={documentPage.document.s3Key}
                        renderDocumentsExists={this.renderDocument(documentPage)}
                    />
                ))}
            </>
        );
    };

    private documentHandlerSearchActionButtons = () => {
        const docId = parseDocumentId(this.props.location);
        if (!docId) {
            return;
        }

        const documentSource = parseDocumentSource(docId);
        const documentMeta = parseDocumentMeta(docId);

        const { linkedDocuments } = this.state;

        const selectedDocument = linkedDocuments.find(
            (documentPage: IPartDocumentPageModel) =>
                `${documentPage.document.s3Key}_${documentPage.pageNumber}` === docId,
        );

        const documentHandleMutation = selectedDocument ? (
            <RemoveTagDocumentPageMutation doc={selectedDocument} />
        ) : (
            <CreateTagDocumentMutation idTag={this.props.tag.id} docId={docId} />
        );

        // TODO: add final sap documentSource, waiting for backend
        return (
            <>
                {documentSource === 'foobarbaz' && (
                    <TagFillFromSourceMutation
                        source={documentSource}
                        filekey={documentMeta.filekey}
                        tag={this.props.tag}
                    />
                )}
                {documentHandleMutation}
            </>
        );
    };

    private setLinkedDocuments = (documents: IPartDocumentPageModel[]) => {
        if (documents && !_.isEqual(documents, this.state.linkedDocuments)) {
            this.setState((prevState: IState) => ({
                ...prevState,
                linkedDocuments: documents,
            }));
        }
    };

    public render() {
        const { tag } = this.props;
        const { plantId } = this.props.match.params;
        const { showPartCreate, partUpdateId, partRerenderKey } = this.state;

        return (
            tag && (
                <>
                    <Column.Group clipped={true}>
                        <Column narrow={true} className="tag-details">
                            <Pane>
                                <Accordion title="Tag Details">
                                    <TagDetails tag={tag} />
                                </Accordion>

                                <Accordion title="Parts">
                                    <PartListQuery
                                        key={partRerenderKey}
                                        plantId={plantId}
                                        tagNumberAsId={tag.tagNumberAsId}
                                        renderFetchedPartList={this.renderParts}
                                    />
                                    <Button color="info" onClick={this.toggleEditPart}>
                                        Add part
                                    </Button>
                                </Accordion>

                                <Accordion title="Documents">
                                    <PartDocumentPageListQuery
                                        key={partRerenderKey}
                                        plantId={plantId}
                                        tagNumberAsId={tag.tagNumberAsId}
                                        renderFetchedDocumentList={this.renderDocuments}
                                        onResult={this.setLinkedDocuments}
                                    />
                                </Accordion>
                            </Pane>
                        </Column>

                        <Column>
                            <Search
                                documentHandlerActionButtons={this.documentHandlerSearchActionButtons()}
                            />
                        </Column>
                    </Column.Group>

                    {showPartCreate && (
                        <PartCreate
                            parent={tag}
                            onCancel={this.toggleEditPart}
                            onSuccess={this.onCreatePart}
                        />
                    )}

                    {partUpdateId && (
                        <PartUpdate
                            partId={partUpdateId}
                            parentId={tag.id}
                            plantId={plantId}
                            tagNumberAsId={tag.tagNumberAsId}
                            onCancel={this.hideUpdatePart}
                            // onSuccess={this.hideUpdatePart}
                        />
                    )}
                </>
            )
        );
    }
}

export default withRouter(TagSummary);
