import * as React from 'react';
import Select from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/lib/Async';
import { Props } from 'react-select/lib/Select';

export interface IMultiselectOptionType {
    label: string;
    value?: any;
    options?: IMultiselectOptionType[];
}

export interface IProps<T> extends Props<T> {
    cy?: string;
    getOptionLabel?: (option: T) => any;
    getOptionValue?: (option: T) => any;
    async?: boolean;
}

export interface IPropsAsync<T> extends AsyncProps<T> {
    getOptionLabel?: (option: T) => any;
    getOptionValue?: (option: T) => any;
    async?: boolean;
}

type IProp<T> = IProps<T> | IPropsAsync<T>;

// FIXME:
// No clue how to restyle this into bulma form style, seems impossible to override the built-in styles
// Apparently you can completely disable the blue outline, but not override it...
const styleOverrides = {
    control: (styles: any, { isHovered }: { isHovered: boolean }) => ({
        ...styles,
        border: `1px solid ${isHovered ? '#c7d4dc' : '#d6e2ea'}`,
        boxShadow: 'none',
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        boxShadow: 'none !important',
        border: 'none !important',
    }),
    input: (styles: any) => ({
        ...styles,
        boxShadow: 'none !important',
        border: 'none !important',
    }),
};

/*
 * homepage: https://react-select.com/home
 * github: https://github.com/JedWatson/react-select
 * typings: https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-select
 */
export default class MultiSelect<T = IMultiselectOptionType> extends React.Component<IProp<T>> {
    public render() {
        const { cy, async, styles, ...multiProps } = this.props as any;

        return (
            <>
                <div data-cy={cy}>
                    {(async && (
                        <AsyncSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            styles={{ ...styleOverrides, ...styles }}
                            {...multiProps}
                        />
                    )) || (
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            styles={{ ...styleOverrides, ...styles }}
                            {...multiProps}
                        />
                    )}
                </div>
            </>
        );
    }
}
