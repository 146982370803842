import { Input, Label } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { DELETE_PAGES_FOR_PLANT_MUTATION } from '../../api/GraphQLQueries/Ops';
import { notification } from '../../utils/notification';
import OpsModal from './OpsModal';

export default class DeletePagesForPlantMutation extends React.Component {
    private formSubmit = (doDeletePagesForPlantMutation: any) => (plantId: string): void => {
        doDeletePagesForPlantMutation({ variables: { plantId }, fetchPolicy: 'no-cache' });
    };

    private onError = (): void => {
        notification.error('Error deleting pages for plant');
    };

    private onCompleted = (): void => {
        notification.success('Success - any plant pages will be deleted');
    };

    private onClose = (): void => {
        return;
    };

    private renderBody = (handleChange: any) => {
        return (
            <>
                <Input placeholder="plant id" onChange={handleChange} />
                <hr />
                <Label>
                    After submiting please wait a while for the index to catch-up. Depending on the
                    size of the plant this can take a while. You will see that it is completed, if
                    the onboarding screen, says the plant has 0 indexable files
                </Label>
            </>
        );
    };

    public render() {
        return (
            <>
                <Mutation
                    mutation={DELETE_PAGES_FOR_PLANT_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {doDeletePagesForPlantMutation => (
                        <OpsModal
                            title="Delete Pages For Plant"
                            onSubmit={this.formSubmit(doDeletePagesForPlantMutation)}
                            onClose={this.onClose}
                            renderBody={this.renderBody}
                            doubleCheck={true}
                            requireInput={true}
                        />
                    )}
                </Mutation>
            </>
        );
    }
}
