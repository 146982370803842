import * as React from 'react';

import MultiSelect from '../ui/MultiSelect';

interface IProps {
    defaultSelected?: boolean;
    onUpdate: (archived?: string) => void;
}

export default class StatusSelectMenu extends React.Component<IProps, any> {
    private handleChange = (archived?: any) => {
        this.props.onUpdate(archived ? archived.value : undefined);
    };

    private buildSelectObject = () => {
        return [
            { value: 'true', label: 'Archived' },
            { value: 'false', label: 'Active' },
        ];
    };

    public render() {
        const options = this.buildSelectObject();
        const { defaultSelected } = this.props;

        const initial =
            defaultSelected !== undefined
                ? options.find(opt => opt.value === defaultSelected.toString())
                : undefined;

        return (
            <MultiSelect
                value={initial}
                options={options}
                placeholder="Archive Status"
                isClearable={true}
                onChange={this.handleChange}
            />
        );
    }
}
