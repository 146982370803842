import * as _ from 'lodash';

const recursivelyRemoveProp = (obj: any, propName: string) => {
    for (const prop in obj) {
        if (prop === propName) {
            delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
            recursivelyRemoveProp(obj[prop], propName);
        }
    }
    return obj;
};

const omit = (data: any, omitProps: any = []) => {
    /*
     * __typename is a special prop used by apollo-client.
     * we usually obtain data from apollo, manipulate it and
     * then send it back to the server to update it.
     *
     * however, __typename is not in the schema,
     * so sending it causes a to syntax error.
     */
    data = recursivelyRemoveProp(data, '__typename');
    return _.omit(data, omitProps) as any;
};

export default omit;
