import * as React from 'react';

import { PLANT_LIST_QUERY } from './../../../api/GraphQLQueries/Plant';
import ListQueryContainer, {
    IGraphQLListProps,
} from './../../../containers/common/ListQueryContainer';
import IPlantModel from './../../../models/IPlantModel';

interface IProps extends IGraphQLListProps {
    renderFetchedPlantList: (
        plants: IPlantModel[],
        totalCount: number,
        limit: number,
        offset: number,
    ) => JSX.Element;
}

export default class PlantListQuery extends React.Component<IProps, any> {
    public render = () => {
        const { renderFetchedPlantList } = this.props;

        return (
            <ListQueryContainer
                {...this.props}
                query={PLANT_LIST_QUERY}
                dataProp="plants"
                sortBy={{ field: 'name', direction: 'asc' }}
                renderFetchedList={renderFetchedPlantList}
            />
        );
    };
}
