import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import IsAdminQuery from '../components/auth/IsAdminQuery';
import Routing from '../Routing';
import withMainNavigation from './withMainNavigation';

class DashboardContainer extends React.Component<RouteComponentProps, any> {
    private renderAdminDashboard = () => {
        return <Redirect to={Routing.BASKETS.getPath()} />;
    };

    private renderUserDashboard = () => {
        return <Redirect to={Routing.PLANTS.getPath()} />;
    };

    public render = (): JSX.Element => {
        return (
            <>
                <IsAdminQuery
                    renderAdmin={this.renderAdminDashboard}
                    renderUser={this.renderUserDashboard}
                />
            </>
        );
    };
}

export default withMainNavigation(DashboardContainer);
