import { Button, Icon } from 'rbx';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { SAVE_OVERRIDE_FOR_PAGE_MUTATION } from '../../api/GraphQLQueries/Document';
import { notification } from '../../utils/notification';

interface IProps {
    orginalS3Key?: string;
    page?: number;
    onCompleted?: () => void;
    overrideableSpindexPage?: {
        content: {
            title?: string;
            author?: string;
        };
    };
}

export class DocumentSaveOverrideMutation extends React.Component<IProps, {}> {
    private onError = (): void => {
        notification.error('Error updating document page');
    };

    private onCompleted = (): void => {
        notification.success('Successfully updated document page');
        if (typeof this.props.onCompleted === 'function') {
            this.props.onCompleted();
        }
    };

    private mutate = (overrideMutation: MutationFn) => (): Promise<any> => {
        const { orginalS3Key, page, overrideableSpindexPage } = this.props;

        return overrideMutation({ variables: { orginalS3Key, page, overrideableSpindexPage } });
    };

    public render = () => {
        const { orginalS3Key, page, overrideableSpindexPage } = this.props;
        const disabled = !orginalS3Key || !page || !overrideableSpindexPage;

        return (
            <>
                <Mutation
                    mutation={SAVE_OVERRIDE_FOR_PAGE_MUTATION}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                >
                    {overrideMutation => (
                        <Button
                            size="small"
                            disabled={disabled}
                            onClick={this.mutate(overrideMutation)}
                        >
                            <Icon size="small">
                                <i className="fa fa-check" />
                            </Icon>
                        </Button>
                    )}
                </Mutation>
            </>
        );
    };
}
