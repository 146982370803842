import { ErrorResponse } from 'apollo-link-error';

import { sendError } from '../api/logging';

interface IFriendlyError {
    message: string;
    code:
        | 'INTERNAL_SERVER_ERROR'
        | 'GRAPHQL_PARSE_FAILED'
        | 'GRAPHQL_VALIDATION_FAILED'
        | 'UNAUTHENTICATED'
        | 'FORBIDDEN'
        | 'PERSISTED_QUERY_NOT_FOUND'
        | 'PERSISTED_QUERY_NOT_SUPPORTED'
        | 'BAD_USER_INPUT';
}

const expectedErrors = [
    'UNAUTHENTICATED',
    'FORBIDDEN',
    'NOT_FOUND',
    'GRAPHQL_VALIDATION_FAILED',
    'BAD_USER_INPUT',
];

const unknownErrorMsg = 'An unexpected error ocurred. Please try again later.';

const parseGraphQLErrors = (error: { [key: string]: any } | undefined) => {
    const { code } = error.extensions;

    switch (code) {
        /*
         * Error: 'Unauthenticated - please log in.'
         *  - Session expired OR user was not logged in to begin with
         * To fix:
         *  - Log in
         */
        case 'UNAUTHENTICATED':
            return { message: 'Unauthenticated. Please log in.', code };

        /*
         * Error: 'Forbidden - action not allowed for this user.'
         *  - Action not permitted as this user. Usually when user tries admin
         *    stuff, but could also be admin changing baskets or something.
         * To fix:
         *  - Log in as another user
         */
        case 'FORBIDDEN':
            const message = [
                'Not allowed. This user does not have to correct access rights.',
                error.message,
                ...(error.errors || []).map(err => err.message),
            ]
                .filter(x => x)
                .join('\n');
            return { message, code };

        /*
         * Error: 'Not found.'
         *  - Resource does not exist
         * To fix:
         *  - Stop asking for the resource or create it first
         */
        case 'NOT_FOUND':
            return { message: 'Not found.', code };

        /*
         * Error: 'Bad request - missing or malformed data.'
         *  - GraphQL resolver received unexpected data
         * To fix:
         *  - Resending different data may not resolve this. Either the client query is invalid (should have failed in
         *    CI), or some data was not provided when required (client should not POST without it). Needs code fix.
         */
        case 'GRAPHQL_VALIDATION_FAILED':
            return { message: 'Bad request: Missing or invalid data.', code };

        /*
         * Error: 'Invalid data.'
         *  - The form submission / request has a validation error
         * To fix:
         *  - Send the correct data. In the future, make sure the frontend cannot submit invalid form data
         */
        case 'BAD_USER_INPUT':
            return {
                message: [error.message, ...(error.errors || []).map(err => err.message)].join(
                    '\n',
                ),
                code,
            };
    }

    return {
        code,
        message: unknownErrorMsg,
    };
};

export const transformApolloErr = (err: ErrorResponse): IFriendlyError[] => {
    try {
        if (err.networkError && err.networkError.message === 'Failed to fetch') {
            return [
                {
                    message: 'Network Error. Please try again later.',
                    code: null,
                },
            ];
        }

        if (err.graphQLErrors && err.graphQLErrors.length) {
            let auth = false;
            let validation = false;

            const friendlyErrors: IFriendlyError[] = err.graphQLErrors.reduce((all, error) => {
                // print only 1 unauthed message, if it's present
                if (auth) {
                    return all;
                }

                const friendly = parseGraphQLErrors(error);

                if (friendly.code === 'GRAPHQL_VALIDATION_FAILED') {
                    // print only 1 validation if there are many (same message to user)
                    if (validation) {
                        return all;
                    } else {
                        validation = true;
                    }
                } else if (friendly.code === 'FORBIDDEN' || friendly.code === 'UNAUTHENTICATED') {
                    auth = true;
                    return [friendly];
                }

                return all.concat(friendly);
            }, []);

            const unexplainedErrors = friendlyErrors.filter(
                ({ code }) => !expectedErrors.includes(code),
            );
            if (unexplainedErrors.length) {
                sendError(
                    JSON.stringify({
                        logMessage:
                            'transformApolloErr: received an unusual error from the server (likely 500)',
                        userAgent: navigator.userAgent,
                        url: window.location.href,
                        errors: unexplainedErrors,
                    }),
                );
                console.error('transformApolloErr: unexplainedErrors');
                console.error(unexplainedErrors);
            }

            return friendlyErrors;
        }

        /*
         * Apparently this triggers when there is no observable error - so don't send to logs
         *     graphQLErrors?: {}
         *     networkError?: {}
         *     response?: ExecutionResult;
         *     operation: full gql garbage, with nothing suspicious
         *     forward: null;
         *     {
         *         "operation": { full gql garbage, with nothing particularly useful },
         *         "networkError": {}
         *     }
         */
        console.warn('transformApolloErr: unhandled gql error');
        console.warn(err);
    } catch (ex) {
        sendError(
            JSON.stringify({
                logMessage: 'transformApolloErr: Client threw while parsing gql errors',
                userAgent: navigator.userAgent,
                url: window.location.href,
                ex,
            }),
        );

        console.error('transformApolloErr: unhandled client error');
        console.error(ex);
    }

    return [{ message: unknownErrorMsg, code: null }];
};
