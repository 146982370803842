import * as React from 'react';

import MultiSelect from '../ui/MultiSelect';

export const BasketShippingTerms = [
    { value: 'FCA', label: 'FCA (Free Carrier Alongside)' },
    { value: 'EXW', label: 'EXW (Ex Work)' },
    { value: 'FAS', label: 'FAS (Free Alongside Ship)' },
    { value: 'FOB', label: 'FOB (Free On Board)' },
    { value: 'CFR', label: 'CFR (Cost and Freight)' },
    { value: 'CIF', label: 'CIF (Cost Insurance & Freight)' },
    { value: 'CPT', label: 'CPT (Carriage Paid To)' },
    { value: 'CIP', label: 'CIP (Carriage and Insurance Paid To)' },
    { value: 'DPU', label: 'DPU (Delivery at Place Unloaded)' },
    { value: 'DAP', label: 'DAP (Delivery at Place)' },
    { value: 'DDP', label: 'DDP (Delivered Duty Paid)' },
];

interface IProps {
    defaultSelected?: string;
    onUpdate: (shippingTerm: string) => void;
}

export default class ShippingTermsSelectMenu extends React.Component<IProps, any> {
    private handleChange = (shippingTerm: { value: string; label: string } | any) => {
        this.props.onUpdate(shippingTerm.value);
    };

    private buildSelectObject = () => {
        return BasketShippingTerms;
    };

    public render() {
        const { defaultSelected } = this.props;

        const initial = defaultSelected
            ? { value: defaultSelected, label: defaultSelected }
            : { value: 'FCA', label: 'FCA' };

        return (
            <MultiSelect
                value={initial}
                options={this.buildSelectObject()}
                placeholder="Shipping terms"
                onChange={this.handleChange}
            />
        );
    }
}
