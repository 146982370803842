import { Title } from 'rbx';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { doUserLogin } from '../../api/authorization';
import AuthLayout from '../AuthLayout';
import { ACCEPT_INVITATION_MUTATION } from './../../api/GraphQLQueries/Auth';
import AuthForm from './../../components/auth/AuthForm';
import AccessTokenModel from './../../models/AccessTokenModel';
import Routing from './../../Routing';
import { todo } from './../../utils/translate';

interface IState {
    title: string;
    text: string;
}

export default class AcceptInvitationContainer extends React.Component<any, IState> {
    public state: IState = {
        title: todo('Accept invitation'),
        text: todo('Enter a password'),
    };

    public componentDidMount() {
        const {
            match: { params },
        } = this.props;
        if (!params.hash) {
            this.changePasswordFailed();
        }
    }

    private formSubmit = (doSetPasswordMutation: any) => (
        email: string,
        password: string,
    ): void => {
        void email;
        const {
            match: { params },
        } = this.props;
        doSetPasswordMutation({
            variables: { hash: params.hash, password },
            fetchPolicy: 'no-cache',
        });
    };

    private onCompleted = async ({
        acceptInvitation,
    }: {
        acceptInvitation: AccessTokenModel;
    }): Promise<void> => {
        await doUserLogin(acceptInvitation);
        this.props.history.push(Routing.INDEX.route);
    };

    private onError = (): void => {
        this.changePasswordFailed();
    };

    private changePasswordFailed = (): void => {
        this.setState({
            title: todo('Registration failed'),
            text: todo('Unable to set password'),
        });
    };

    public render() {
        const { title, text } = this.state;

        return (
            <AuthLayout>
                <div className="content">
                    <Title size="4">{title}</Title>
                    <p>{text}</p>
                    <Mutation
                        mutation={ACCEPT_INVITATION_MUTATION}
                        onCompleted={this.onCompleted}
                        onError={this.onError}
                    >
                        {doSetPassword => (
                            <AuthForm
                                buttonText={todo('Set your password')}
                                onSubmit={this.formSubmit(doSetPassword)}
                                isResetPassword={true}
                            />
                        )}
                    </Mutation>
                </div>
            </AuthLayout>
        );
    }
}
