import { Button, Content } from 'rbx';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { API_URI } from '../../api/connection';
import IsAdminQuery from '../auth/IsAdminQuery';
import DatePicker from '../ui/datePicker/DatePicker';
import Popover from '../ui/Popover';

const env = process.env.REACT_APP_ENV;
const jenkinsUrl = 'https://ps-j.isarbits.net';

interface IState {
    [key: string]: any;
    showPopover?: boolean;
    popoverEventTarget?: HTMLElement;
}

export default class DevIndex extends React.Component<any, any, any> {
    public state: IState = {
        modal1open: false,
        modal2open: false,
        showPopover: false,
    };

    private togglePopover = (e: React.SyntheticEvent) => {
        const eventTarget = e.currentTarget;

        this.setState((prevState: IState) => ({
            ...prevState,
            popoverEventTarget: eventTarget,
            showPopover: !this.state.showPopover,
        }));
    };

    private renderPopOver = () => {
        const { popoverEventTarget } = this.state;

        return (
            <Popover
                title="Test popover"
                popoverEventTarget={popoverEventTarget}
                onClose={this.togglePopover}
            >
                <Content>
                    <p>Test popover content</p>
                    <Button onClick={this.togglePopover} color="primary">
                        Test Button
                    </Button>
                </Content>
            </Popover>
        );
    };

    private notAdminPage = () => {
        return <p>Welcome, Normie!</p>;
    };

    private adminPage = () => {
        return <p>Welcome, Admin!</p>;
    };

    public render() {
        const { showPopover } = this.state;

        return (
            <Content>
                <p>This is the dev container.</p>

                <strong>Links:</strong>
                <ul>
                    <li>
                        <Link to="/dev/edittag">Edit Tag</Link>
                    </li>
                    <li>
                        <Link to="/dev/basket">Edit Basket</Link>
                    </li>
                </ul>

                <hr />

                <p>
                    <Button onClick={this.togglePopover}>Trigger popover</Button>
                </p>

                {showPopover && this.renderPopOver()}

                <br />
                <div>
                    <span>This is a date picker</span>
                    <br />
                    <DatePicker />
                </div>
                <br />

                <IsAdminQuery renderAdmin={this.adminPage} renderUser={this.notAdminPage} />

                <h1>
                    Current Environment: <strong>{env}</strong>
                </h1>
                <h2>Usefull links for {env}</h2>
                <ul>
                    <li>
                        <a href={API_URI + ':15672'} target="_blank" rel="noopener noreferrer">
                            Rabbit
                        </a>
                        <br />
                    </li>
                    <li>
                        <a href={API_URI + ':4340'} target="_blank" rel="noopener noreferrer">
                            Spindex
                        </a>
                        <br />
                    </li>
                    <li>
                        <a href={API_URI + ':4340/#/log'} target="_blank" rel="noopener noreferrer">
                            Log
                        </a>
                        <br />
                    </li>
                    <li>
                        <a
                            href={API_URI + ':9200/_plugin/kibana/app/kibana'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Kibana
                        </a>
                        <br />
                    </li>
                    <li>
                        <a href={jenkinsUrl} target="_blank" rel="noopener noreferrer">
                            Jenkins
                        </a>
                        <br />
                    </li>
                </ul>
            </Content>
        );
    }
}
